import { useRef, useState, Children, cloneElement } from 'react';
import { ExpandableContainer, Flexbox, Icon } from '../../../../components';
import { EMPTY_OBJECT } from '../../../../constants';
import { isNil, isBoolean } from '../../../../fp/pred';
import { CHEVRON_DOWN, CHEVRON_RIGHT } from '../../../../icons';
import { isTrue } from '../../../../utils';
import { withClassNameModifiers } from '../../../../utils/withClassNameModifiers';
import './styles.css';

const toExpandedIcon = (isOpen) => {
  return (
    <Icon
      icon={isOpen ? CHEVRON_DOWN : CHEVRON_RIGHT}
      defaultIconColor={true}
      className="margin-vertical-auto"
    />
  );
};

const toInitialExpandedState = (defaultExpanded, isOpen, isSet, children) => {
  if (defaultExpanded) return true;
  return isOpen === 'y' || (Children.count(children) > 0 && isSet);
};

export const ExpandableNode = (props) => {
  const { children, ItemElement, checked, node, defaultExpanded } = props;

  const { IsOpen, IsSet, XeSmartBookInstance } = node;

  const [isExpanded, setIsExpanded] = useState(
    toInitialExpandedState(defaultExpanded, IsOpen, IsSet, children)
  );

  const checkedRef = useRef(checked);
  if (checkedRef.current !== checked) {
    checkedRef.current = checked;
    if (checked) {
      setIsExpanded(checked);
    }
  }

  const showAsSection =
    !XeSmartBookInstance?.length ||
    XeSmartBookInstance.some((smartBookChild) => {
      const { IsVisible } = smartBookChild;
      return isBoolean(IsVisible) ? isTrue(IsVisible) : true;
    });

  const showAsSectionChildren = Children.toArray(children).some(
    ({ props = EMPTY_OBJECT }) => {
      const { node = EMPTY_OBJECT } = props;
      const { IsVisible } = node;
      return isBoolean(IsVisible) ? isTrue(IsVisible) : true;
    }
  );

  return showAsSection && showAsSectionChildren ? (
    <div
      className={`${withClassNameModifiers('smartbook__section', {
        'active-path': isNil(checked) ? true : checked,
      })} margin-vertical-small`}
    >
      <ExpandableContainer
        className="smartbook__expandable-node"
        style={{ border: 'none', backgroundColor: 'transparent' }}
        Header={({ Controller }) => (
          <Flexbox>
            {Controller}
            {ItemElement}
          </Flexbox>
        )}
        Controller={toExpandedIcon(isExpanded)}
        controllerWrapperClassName="smartbook__expandable-node-toggle"
        headerClassName="smartbook__expandable-node-header"
        bodyClassName="smartbook__expandable-node-body"
        onChange={setIsExpanded}
        isExpanded={isExpanded}
        preserveChildrenOnCollapse
      >
        {children}
      </ExpandableContainer>
    </div>
  ) : (
    cloneElement(ItemElement, {
      className: `${ItemElement.props.className ?? ''} margin-vertical-small`,
    })
  );
};
