import { Component, createRef } from 'react';
import { Panel, IconButton } from '../../components';
import PropTypes from 'prop-types';
import { PRINT } from '../../icons';
import { useXeLabels } from '../../contexts/XeLabelContext';

const PrintButton = (props) => {
  const labels = useXeLabels();
  return (
    <div>
      <IconButton description={labels.Print} icon={PRINT} {...props} />
    </div>
  );
};

export class HTMLFileViewer extends Component {
  iframeRef = createRef();

  getRef = () => this.iframeRef && this.iframeRef.current;

  writeContent = () => {
    const { file } = this.props;
    if (!file) {
      console.warn(
        'No file to display in HTMLFileViewer. Did you forget to pass it in?'
      );
      return;
    }
    const iframeRef = this.getRef();
    if (!iframeRef) {
      console.warn(
        'HTMLFileViewer tried to write content to an element that is not on the screen'
      );
      return;
    }

    const iframeDocument = iframeRef.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(file);
    iframeDocument.close();
  };

  handlePrintClick = () => {
    const iframeWindowFrame = window.frames['__HTML_FILE_VIEWER_FRAME'];
    iframeWindowFrame.focus();
    iframeWindowFrame.print();
  };

  componentDidMount = () => {
    this.writeContent();
  };

  render() {
    const { frameClassName = '' } = this.props;
    return (
      <Panel
        className="flex-1 file-viewer__panel flex-container-column"
        Header={<PrintButton onClick={this.handlePrintClick} />}
      >
        <iframe
          id="__HTML_FILE_VIEWER_FRAME"
          name="__HTML_FILE_VIEWER_FRAME"
          data-element-name="__HTML_FILE_VIEWER_FRAME"
          ref={this.iframeRef}
          className={`file-viewer__iframe ${frameClassName}`}
        />
      </Panel>
    );
  }
}

export default HTMLFileViewer;

HTMLFileViewer.propTypes = {
  file: PropTypes.any.isRequired,
};
