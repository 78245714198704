import { useSchema } from '../../../../schema/SchemaReducer';
import { Flexbox } from '../../../../components';
import Slider from '../../../../components/Slider';
import { NameLabel } from '../../components/NameLabel';
import { baseNodeValidator } from '../../validators';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

// This is rigid but can be loosened if the slider-like components start
// needing different applied properties (JDM)
export const SliderBase = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    dataPath,
    readOnly,
    node,
    onValidated,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsVisible, ResultMin, ResultMax, IsRequired } =
    nodeWithDefaults;

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  return (
    <WrappingComponent>
      <NameLabel required={IsRequired} invalid={!valid || !!validityMessage}>
        {Name}
      </NameLabel>
      <Slider
        disabled={readOnly}
        className="margin-left-small"
        value={parseFloat(value)}
        min={parseInt(ResultMin)}
        max={parseInt(ResultMax)}
        onChange={(value /* , valid */) => {
          onValueChange(value);
          onValidChange(baseNodeValidator(true, value, IsRequired));
        }}
      />
    </WrappingComponent>
  );
};
