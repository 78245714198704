/**
 * @typedef LetterWizardParams
 * @property {number} IPID
 * @property {number} IVID
 * @property {string} LetterType
 * @property {string=} SubType
 * @property {string=} LOB
 * @property {string=} CorrespondenceInfoID
 * @property {boolean=} CanEditRecipient
 * @property {*=} PrePopup
 * @property {*=} PostPopup
 * @property {number=} VisitCorrespondenceID
 * @property {number=} IssueID
 * @property {string=} Visit_Enterprise
 * @property {string=} VisitStatus
 * @property {*=} WorkList
 * @property {boolean=} IsWorkList
 * @property {String} Status
 * @property {String} CorrespondenceCode
 */

export const AD_HOC_SUBTYPE = 'AUTH_ADHOC';
export const LETTER_STATUS_DRAFT = 'DRAFT';
export const LETTER_STATUS_FINAL = 'FINAL';
export const SUBTYPE_FAX = 'FAX';
export const SUBTYPE_MAIL = 'MAIL';

export const AUTH_PRE_POPUP_KEY = 'authPrePopupTransient';

export const WIZARD_STEPS = {
  SELECT_LETTER: 0,
  CUSTOM_LANGUAGE: 1,
  DATA_ENTRY: 2,
  SELECT_SIGNATURE: 3,
  ATTACHMENTS: 4,
  RECIPIENTS: 5,
};

export const MANUALLY_ATTACHED_FILE = 1;
export const REPORT_FLAG = 4;