import { Component } from 'react';
import PropTypes from 'prop-types';

export class ScrollToContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { elementClassToScrollTo = '' } = this.props;
    const scrollAnchor =
      this.container.getElementsByClassName(elementClassToScrollTo)[0] || null;
    if (scrollAnchor) scrollAnchor.scrollIntoView();
  }

  render() {
    const { className = '', children } = this.props;
    return (
      <div
        className={className}
        ref={(container) => (this.container = container)}
      >
        {children}
      </div>
    );
  }
}

ScrollToContainer.propTypes = {
  className: PropTypes.string,
  elementClassToScrollTo: PropTypes.string,
};
