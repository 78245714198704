import { convertToSimpleType } from '../../schema/schemaTraversal';
import { isOneOf } from '../../fp/pred';
import { get } from '../../fp/object';
import castPath from 'lodash/_castPath';
import { getSchemaProperties } from '../../utils/schema';
import { generate } from '../../expressions/functionGenerator';
import parser from '../../expressions/parser';
import * as requiresRuntimeFns from './requiresRuntimeFns';

export const toWellKnownIDPathMap = (map, path, node) => {
  const { WellKnownID, XeSmartBookInstance = [] } = node;

  if (WellKnownID) {
    const { [WellKnownID]: knownPath } = map;

    if (knownPath !== undefined) {
      console.error(`Duplicate well known id ${WellKnownID}`);
    }

    map[WellKnownID] = path;
  }

  return XeSmartBookInstance.reduce((acc, node, index) => {
    const childPath = `XeSmartBookInstance[${index}]`;
    const nodePath = path ? `${path}.${childPath}` : childPath;

    return {
      ...acc,
      ...toWellKnownIDPathMap(map, nodePath, node),
    };
  }, map);
};

const toScopeBuilder =
  (wellKnownIDPathMap) =>
  (s0 = {}) =>
  (dependencies) => {
    return dependencies.reduce(
      (accFn, identifier) => {
        const { name: WellKnownID } = identifier;

        const wellKnownPath = wellKnownIDPathMap[WellKnownID];
        if (wellKnownPath === undefined) {
          throw new Error(
            `Requires expression requires ${WellKnownID} but ID does not exist in document`
          );
        }

        return (smartBook) => {
          return {
            ...accFn(smartBook),
            [WellKnownID]: get(wellKnownPath, smartBook),
          };
        };
      },
      () => s0
    );
  };

const setWithModified = (path, value, object) => {
  const pathSegments = castPath(path);

  const innerSetWithModified = (o = {}, head, ...rest) => {
    const child = rest.length ? innerSetWithModified(o[head], ...rest) : value;

    if (Array.isArray(o)) {
      const index = +head;
      return [...o.slice(0, index), child, ...o.slice(index + 1)];
    }

    return {
      ...o,
      [head]: child,
      Modified: true,
    };
  };

  return innerSetWithModified(object, ...pathSegments);
};

const isSchemaTypeSimple = isOneOf(
  'integer',
  'number',
  'string',
  'boolean',
  'null'
);

const withNewFunctions = (
  map,
  path,
  node,
  toScopeFn,
  XeSmartBookInstanceSchema
) => {
  const { Requires, WellKnownID: targetWellKnownID } = node;

  const smartBookInstanceSchemaProperties = getSchemaProperties(
    XeSmartBookInstanceSchema
  );

  const toTypeForChangedProperty = (property) => {
    const { [property]: { type } = {} } = smartBookInstanceSchemaProperties;
    return type;
  };

  if (!Requires) return map;
  try {
    const targets = parser.parse(Requires) || {};

    return targets.reduce((acc, target) => {
      const {
        dependencies = [],
        expression,
        identifier: { name: property },
      } = target;

      const toScope = toScopeFn(dependencies);
      const toValue = generate(expression);

      const reducer = (smartBook) => {
        const scope = toScope(smartBook);
        const value = toValue(scope);
        const currentValue = get(`${path}${property}`, smartBook);
        // Opting against type checking since ref data value types often mismatch smart book types
        if (currentValue != value) {
          const schemaTypeOfChangedProperty =
            toTypeForChangedProperty(property);
          return setWithModified(
            `${path}.${property}`,
            isSchemaTypeSimple(schemaTypeOfChangedProperty)
              ? convertToSimpleType(schemaTypeOfChangedProperty, value)
              : value,
            smartBook
          );
        }

        return smartBook;
      };

      return dependencies.reduce((acc, { name }) => {
        const existing = acc[name] || [];

        return {
          ...acc,
          [name]: [
            ...existing,
            {
              target: targetWellKnownID,
              reducer,
            },
          ],
        };
      }, acc);
    }, map);
  } catch (e) {
    console.error(`Error parsing "Requires" expression:\n${Requires}`);
    console.error(e);

    return map;
  }
};

const toWellKnownIDReducerMap = (
  wellKnownIDPathMap,
  XeSmartBookInstanceSchema
) => {
  const toInnerReducerMap = (map, path, node, outerScope) => {
    const { XeSmartBookInstance = [] } = node;

    const updatedMap = withNewFunctions(
      map,
      path,
      node,
      toScopeBuilder(wellKnownIDPathMap)(outerScope),
      XeSmartBookInstanceSchema
    );
    return XeSmartBookInstance.reduce((acc, node, index) => {
      const childPath = `XeSmartBookInstance[${index}]`;
      const nodePath = path ? `${path}.${childPath}` : childPath;

      return {
        ...acc,
        ...toInnerReducerMap(acc, nodePath, node, outerScope),
      };
    }, updatedMap);
  };

  return toInnerReducerMap;
};

const toRequiresByWellKnownIdReducer = (wellKnownIDReducerMap) => {
  const innerReducer = (state, wellKnownId) => {
    const reducerEntries = wellKnownIDReducerMap[wellKnownId];

    if (!reducerEntries) return state;

    return reducerEntries.reduce((s0, entry) => {
      const { target, reducer } = entry;

      const s1 = reducer(s0);
      if (s1 === s0) return s0;

      //there was a change, find what else may need to run
      return innerReducer(s1, target);
    }, state);
  };

  return innerReducer;
};

const arrayToObjectNotation = /\[(\d+?)\]/g;
const toRequiresByActionReducer = (
  wellKnownIDPathMap,
  wellKnownIDReducerMap
) => {
  const idReducer = toRequiresByWellKnownIdReducer(wellKnownIDReducerMap);
  const pathToWellKnownId = Object.entries(wellKnownIDPathMap).reduce(
    (acc, [k, v = '']) => {
      return {
        ...acc,
        [v.replace(arrayToObjectNotation, '.$1')]: k,
      };
    },
    {}
  );

  return (state, action = {}) => {
    const { path = '' } = action;

    const [p1] = path.match(/.*\d/) || [];

    const wellKnownId = pathToWellKnownId[p1];
    if (!wellKnownId) return state;

    return idReducer(state, wellKnownId);
  };
};

export const toRequiresReducer = (
  initialSmartBookInstance,
  referenceData,
  XeSmartBookInstanceSchema
) => {
  const preAppliedRuntimeRequiresFns = Object.entries(
    requiresRuntimeFns
  ).reduce((acc, [k, fn]) => {
    return { ...acc, [k]: fn({ referenceData }) }; //preparing for the data we need to pass more to these functions
  }, {});

  const outerScope = { ...preAppliedRuntimeRequiresFns, referenceData };

  const wellKnownIDPathMap = toWellKnownIDPathMap(
    {},
    undefined,
    initialSmartBookInstance
  );

  try {
    const wellKnownIDReducerMap = toWellKnownIDReducerMap(
      wellKnownIDPathMap,
      XeSmartBookInstanceSchema
    )({}, undefined, initialSmartBookInstance, outerScope);
    return toRequiresByActionReducer(wellKnownIDPathMap, wellKnownIDReducerMap);
  } catch (e) {
    console.error('Failure parsing and building requires expressions');
    console.error(e);

    return (state) => state;
  }
};
