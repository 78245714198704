import { identity } from '../../../fp/fp';
import { ExpandableItemList } from './ExpandableItemList';
import { SelectableItem } from './SelectableItem';

const toHierarchyPath = (path = '', nextIndex) =>
  path === '' ? `${nextIndex}` : `${path}_${nextIndex}`;
const toFlattenedLeafChildren = (nodes = [], branchFn, currentPath) => {
  return nodes.flatMap((currentNode, index) => {
    const nextPath = toHierarchyPath(currentPath, index);
    const nodes = branchFn(currentNode);
    return Array.isArray(nodes) && nodes.length > 0
      ? toFlattenedLeafChildren(nodes, branchFn, nextPath)
      : [{ node: currentNode, path: nextPath }];
  });
};
export const FilterTree = (props) => {
  const {
    node,
    path,
    branchFn,
    branchLabelFn,
    valueFn,
    labelFn = identity,
    filterFn,
    shouldFlatten,
    checkableSections,
    isSelectedFn,
    onSelect,
    multiple,
    onNodeExpanded,
  } = props;

  const branchItems = branchFn(node) || [];
  const childNodes = branchItems.filter(filterFn);
  if (childNodes.length > 0) {
    if (shouldFlatten) {
      const allLeafChildren = toFlattenedLeafChildren(
        branchItems,
        branchFn,
        path
      );
      return allLeafChildren.map(({ path, node }) => {
        return <FilterTree {...props} node={node} path={path} key={path} />;
      });
    }
    const { some: atLeastOneSelectedChild, every: allChildrenSelected } =
      childNodes.reduce(
        (result, childNode) => {
          const { some: currentSome, every: currentEvery } = result;
          if (currentSome === true && currentEvery === false) return result;
          const isNodeSelected = isSelectedFn(childNode);
          return {
            some: currentSome || isNodeSelected,
            every: currentEvery === false ? currentEvery : isNodeSelected,
          };
        },
        { some: false, every: true }
      );
    const headerIsChecked = checkableSections
      ? isSelectedFn(node)
      : // ? isSelectedFn(path)
        allChildrenSelected;
    const headerIsIndeterminate = !headerIsChecked && atLeastOneSelectedChild;
    const children = childNodes.map((node, index) => {
      const nextPath = toHierarchyPath(path, index);
      return (
        <FilterTree {...props} node={node} path={nextPath} key={nextPath} />
      );
    });
    const descriptor = branchLabelFn(node);
    return (
      <ExpandableItemList
        dataElementName={descriptor}
        multiple={multiple}
        onSelect={(checked) => {
          if (multiple) {
            const allLeafChildren = toFlattenedLeafChildren(
              branchItems,
              branchFn,
              path
            ).filter(filterFn);
            return onSelect(
              [{ path, node }, ...allLeafChildren],
              checked,
              childNodes.length > 0,
              checkableSections
            );
          }
          return onSelect([{ path, node }], checked);
        }}
        onNodeExpanded={onNodeExpanded}
        checkableSections={checkableSections}
        descriptor={descriptor}
        children={children}
        headerIsChecked={headerIsChecked}
        headerIsIndeterminate={headerIsIndeterminate}
        key={path}
      />
    );
  }
  const nodeValue = valueFn(node);
  if (nodeValue == null || !filterFn(node) || node?.Active === false) {
    return null;
  }
  const valueLabel = labelFn(node);
  return (
    <SelectableItem
      dataElementName={valueLabel}
      onSelect={(checked) => {
        return onSelect([{ path, node }], checked);
      }}
      multiple={multiple}
      descriptor={valueLabel}
      checked={isSelectedFn(node)}
      key={path}
    />
  );
};
