import PropTypes from 'prop-types';
import { Slider as SliderKendo } from '@progress/kendo-react-inputs';
import { useSchema } from '../../schema/SchemaReducer';
import { NOOP_FUNCTION } from '../../constants';

export const Slider = (props) => {
  const {
    dataPath,
    value: propsValue,
    onChange = NOOP_FUNCTION,
    onValidation = NOOP_FUNCTION,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value = propsValue,
    onValidationChange = onValidation,
    // There isn't any real documentation on how `required` is supposed to work here,
    // and the change event doesn't include any sort of validity information (JDM)
    // required = false,
  } = useSchema(dataPath);

  return (
    <SliderKendo
      {...passThroughProps}
      onChange={({ value }) => {
        onValueChange(value);
        onValidationChange(true);
      }}
      value={value}
    />
  );
};

export default Slider;

Slider.propTypes = {
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
};
