export const SHOULD_UPDATE_COMMENTS_TO = 'shouldUpdateCommentsTo';
export const SHOULD_SET_DEFAULT_FILE_SET_NAME_TO =
  'shouldSetDefaultFileSetNameTo';
export const SHOULD_SET_DEFAULT_DOCUMENT_SET_TO =
  'shouldSetDefaultDocumentSetTo';
export const SHOULD_ADD_NEW_FILES = 'shouldAddNewFiles';
export const SHOULD_SET_IS_CATEGORY_HIDDEN_TO = 'shouldSetIsCategoryHiddenTo';
export const SHOULD_SAVE_DOCUMENT = 'shouldSaveDocument';
export const REQUEST_SAVE_DOCUMENT = 'request/SaveDocument';
export const REQUEST_TOAST_ON_SAVE_FAILURE = 'request/toastOnSaveFailure';
export const SHOULD_DOWNLOAD_DOCUMENT = 'shouldDownloadDocument';
export const DID_DOWNLOAD_DOCUMENT = 'didDownloadDocument';
export const SHOULD_SET_FILE_SET_ENTERPRISE_ID = 'shouldSetFileSetEnterpriseID';
