import { IconButton } from '../IconButton';
import { XeIconMap } from '../../icons';
import { useScopedDispatch } from '../../hooks/scopedReducer';
import { queryForNew } from 'services/notification-folders/xe-notification-folders-svc';
import { useXeQuery } from '../../data/useXeQuery';
import { identity } from '../../fp/fp';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import { toXsDateString } from '../../g11n/ISODates';
import { toCacheKeyFromRequest } from '../../service/serviceCache';
import { useQueryClient } from 'react-query';
import './styles.css';

// TODO: long-term, the UserAction component just seems overloaded and janky, especially with needing to include
// User Notifications specific logic in here. Still, perhaps better than it's original location in XeApplication (JCM)
const Badge = () => {
  const { userData = {} } = useEnterprise();
  const { Username, KeepAliveTimerInterval } = userData;

  const { data: [{ UnreadRecentMessage = 0 } = {}] = [] } = useXeQuery(
    queryForNew(
      { username: Username, sinceDate: toXsDateString(new Date()) },
      identity
    ),
    {
      refetchInterval: KeepAliveTimerInterval * 60 * 1000,
    }
  );

  return (
    <div className="user-action__notification-count">{UnreadRecentMessage}</div>
  );
};

export const UserAction = (props) => {
  const { HtmlComponentName, Icon, Name, onClick, className = '' } = props;

  const xeMenuIcon = XeIconMap[Icon] || 'alert-outline';
  const dispatch = useScopedDispatch();
  const queryClient = useQueryClient();

  const wrappedOnClick = (ev) => {
    if (HtmlComponentName === 'XeUserNotifications') {
      const request = queryForNew({}, identity);
      const cacheKey = toCacheKeyFromRequest(request);
      queryClient.invalidateQueries(cacheKey.slice(0, 2));
    }

    if (onClick) {
      onClick(ev);
      return;
    }

    dispatch({ type: 'open' });
  };

  return (
    <span className="user-action__button-span">
      <IconButton
        dataElementName={Name}
        description={Name}
        className={`${className} user-action__action-button`}
        iconClassName="user-action__icon"
        icon={xeMenuIcon}
        look="flat"
        onClick={wrappedOnClick}
      />
      {HtmlComponentName === 'XeUserNotifications' ? <Badge /> : null}
    </span>
  );
};
