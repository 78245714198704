import { pluck } from '../../../../fp/object';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { UPSERT } from '../../../../schema/JSONSchemaReducer';
import {
  useSchemaDispatch,
  useSchemaSelector,
} from '../../../../schema/SchemaReducer';
import { useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  ExpandablePanel,
  Flexbox,
  Grid,
  HeaderLabel,
  Icon,
  IconButton,
  Label,
  List,
  OverflowContainer,
  Panel,
  Popup,
  toDefaultPopupFooter,
} from '../../../';
import { CellRendererContext } from '../../../../components/Grid/contexts/CellRendererContext';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { fileSizeFormatter } from '../../../../format/fileSizeFormatter';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import {
  ADD_FILLED,
  CHECK_CIRCLE_OUTLINE,
  DELETE,
  DOWNLOAD_DOCUMENT,
  SEARCH,
} from '../../../../icons';
import { formatCommonName } from '../../../../utils';
import {
  REQUEST_ATTACHMENTS_POPUP_DATA,
  SHOULD_DOWNLOAD_ATTACHMENT,
  SHOULD_DOWNLOAD_CLINDOC,
  SHOULD_DOWNLOAD_REPORT,
  SHOULD_GET_CLINDOCS,
  UPDATE_ATTACHMENTS_VIEW_STATE,
} from '../../actions';
import { MANUALLY_ATTACHED_FILE, REPORT_FLAG } from '../../constants';
import '../../styles.css';
import './styles.css';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '../../../../constants';

const toReportName = pluck('ReportID', 'Name');
const toXeVisitAssessmentName = pluck('XeVisitAssessment', 'Name');
const toFileName = pluck('FileID', 'FileName');
const toNextSequenceNo = (attachments) => {
  const [lastAttachment = {}] = attachments.slice(-1);
  const { SequenceNo = 0 } = lastAttachment;
  return SequenceNo + 1;
};

const isSequenceNoEqual =
  (SequenceNo) =>
  ({ SequenceNo: itemSequenceNo } = {}) =>
    itemSequenceNo === SequenceNo;

const DeleteButton = ({ data = EMPTY_OBJECT, rowIndex }) => {
  const {
    dispatch,
    attachments = [],
    attachmentsViewState = [],
  } = useContext(CellRendererContext);
  const schemaDispatch = useSchemaDispatch();
  const { IsRequired, SequenceNo } = data;
  return (
    <IconButton
      icon={DELETE}
      onClick={() => {
        const indexToDelete = attachmentsViewState.findIndex(
          isSequenceNoEqual(SequenceNo)
        );
        schemaDispatch({
          type: UPSERT,
          path: 'XeVisitCorrespAttach',
          value: [
            ...attachments.slice(0, rowIndex),
            ...attachments.slice(rowIndex + 1),
          ],
        });
        dispatch({
          type: UPDATE_ATTACHMENTS_VIEW_STATE,
          value: [
            ...attachmentsViewState.slice(0, indexToDelete),
            ...attachmentsViewState.slice(indexToDelete + 1),
          ],
        });
      }}
      disabled={IsRequired}
    />
  );
};

const documentsColumnDefs = [
  {
    headerLabelKey: 'Sequence',
    field: 'SequenceNo',
  },
  {
    headerLabelKey: 'Name',
    valueGetter: ({ data = {} }) => {
      const { UsageFlag, FileID } = data;
      if (!!FileID) {
        return toFileName(data);
      }
      if (UsageFlag === REPORT_FLAG) {
        return toReportName(data);
      }
      return toXeVisitAssessmentName(data);
    },
  },
  {
    headerLabelKey: 'Summary',
    field: 'XeVisitAssessment.SummaryField',
  },
  {
    headerLabelKey: 'SignedDate',
    field: 'XeVisitAssessment.SignedDate',
    valueFormatter: ({ value }) => {
      return toDisplayDateFromISOString(value, localeFormat.MEDIUM);
    },
  },
  {
    headerLabelKey: 'SignedBy',
    field: 'XeVisitAssessment.SignedBy',
  },
  {
    cellRenderer: DeleteButton,
    width: 112,
    resizable: false,
    enableRowGroup: false,
  },
];

const ActionButtons = ({ onAttach, onDownload }) => {
  const labels = useXeLabels();
  return (
    <Flexbox alignItems="center">
      <Button look="default" onClick={onAttach}>
        {labels.Attach}
      </Button>
      <IconButton
        icon={DOWNLOAD_DOCUMENT}
        description={labels.Download}
        onClick={onDownload}
      />
    </Flexbox>
  );
};

const DocumentActionButtons = ({ data = EMPTY_OBJECT }) => {
  const schemaDispatch = useSchemaDispatch();
  const {
    ReportID,
    VisitAssessmentID,
    IPID,
    Name,
    SignedDate,
    SignedBy: { ResourceID } = {},
  } = data;

  const {
    dispatch,
    attachments = [],
    attachmentsViewState = [],
  } = useContext(CellRendererContext);

  return (
    <ActionButtons
      onAttach={() => {
        schemaDispatch({
          type: UPSERT,
          path: 'XeVisitCorrespAttach',
          value: [
            ...attachments,
            {
              VisitAssessmentID,
              UsageFlag: MANUALLY_ATTACHED_FILE,
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
        dispatch({
          type: UPDATE_ATTACHMENTS_VIEW_STATE,
          value: [
            ...attachmentsViewState,
            {
              XeVisitAssessment: {
                VisitAssessmentID,
                Name,
                SignedDate,
                SignedBy: ResourceID,
              },
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
      }}
      onDownload={() =>
        dispatch({
          type: SHOULD_DOWNLOAD_CLINDOC,
          value: {
            ReportID,
            VisitAssessmentID,
            IPID,
          },
        })
      }
    />
  );
};

const DocumentContent = ({
  dispatch,
  IPID,
  attachments,
  attachmentsViewState,
  clinDocs = EMPTY_ARRAY,
  clinDocRefData: typeRefData = EMPTY_ARRAY,
}) => {
  const labels = useXeLabels();
  const typeMenuData = typeRefData.reduce((acc, item) => {
    const label = item.AssessmentTypeID;
    const group = acc.find((i) => i.label === label) || { label, list: [] };
    return [
      { ...group, list: [...group.list, item] },
      ...acc.filter((i) => i.label !== label),
    ];
  }, []);

  const [filterCriteria, updateFilterCriteria] = useState({
    active: true,
    ipid: IPID,
    assessmentId: [],
  });
  const { active, assessmentId } = filterCriteria;

  return (
    <div className="display-grid create-letter-wizard-attachments__add-attachment-document">
      <Flexbox direction="column">
        <Flexbox justifyContent="space-between">
          <HeaderLabel dataElementName="letterWizard__documentType__label">
            {labels.DocumentTypes}
          </HeaderLabel>
          <div>
            <Checkbox
              label={labels.Active}
              dataElementName="letterWizard__active"
              checked={active}
              onChange={(value) =>
                updateFilterCriteria({ ...filterCriteria, active: value })
              }
            />
            <IconButton
              icon={SEARCH}
              dataElementName="letterWizard__search"
              description={labels.Search}
              onClick={() =>
                dispatch({
                  type: SHOULD_GET_CLINDOCS,
                  value: filterCriteria,
                })
              }
            />
          </div>
        </Flexbox>
        <Panel>
          <OverflowContainer>
            <List
              data={typeMenuData}
              renderItem={({ item }) => {
                const name = pluck('label')(item);
                const list = pluck('list')(item) || [];
                return (
                  <ExpandablePanel
                    dataElementName={name}
                    title={name}
                    key={name}
                  >
                    <List
                      data={list}
                      renderItem={({ item }) => {
                        const { Name, AssessmentID } = item;
                        return (
                          <Flexbox
                            key={AssessmentID}
                            justifyContent="space-between"
                          >
                            <Label dataElementName={`${Name}__label`}>
                              {Name}
                            </Label>
                            <Checkbox
                              dataElementName={Name}
                              checked={
                                assessmentId.findIndex(
                                  (id) => id === AssessmentID
                                ) >= 0
                              }
                              onChange={(value) =>
                                value
                                  ? updateFilterCriteria({
                                      ...filterCriteria,
                                      assessmentId: [
                                        ...assessmentId,
                                        AssessmentID,
                                      ],
                                    })
                                  : updateFilterCriteria({
                                      ...filterCriteria,
                                      assessmentId: assessmentId.filter(
                                        (id) => id !== AssessmentID
                                      ),
                                    })
                              }
                            />
                          </Flexbox>
                        );
                      }}
                    />
                  </ExpandablePanel>
                );
              }}
            />
          </OverflowContainer>
        </Panel>
      </Flexbox>
      <Flexbox direction="column">
        <HeaderLabel dataElementName="letterWizard__signedDocuments">
          {labels.SignedDocuments}
        </HeaderLabel>
        <Grid
          columnDefs={[
            {
              headerLabelKey: 'Status',
              field: 'Status',
              maxWidth: 112,
            },
            {
              headerLabelKey: 'Signed',
              field: 'SignedDate',
              valueFormatter: ({ value }) =>
                toDisplayDateFromISOString(value, localeFormat.MEDIUM),
              maxWidth: 144,
            },
            {
              headerLabelKey: 'SignedBy',
              field: 'SignedBy',
              valueFormatter: ({ value }) =>
                value && formatCommonName(value, 'F, G'),
            },
            {
              headerLabelKey: 'Summary',
              field: 'Name',
            },
            {
              //action cell attach ReportID and VisitAssessmentID from clindoc
              cellRenderer: DocumentActionButtons,
              width: 112,
              resizable: false,
              enableRowGroup: false,
            },
          ]}
          data={clinDocs}
          cellRendererContext={{
            dispatch,
            attachments,
            attachmentsViewState,
          }}
        />
      </Flexbox>
    </div>
  );
};

const AttachmentActionCell = ({ data = EMPTY_OBJECT }) => {
  const { FileName, FileID } = data;
  const schemaDispatch = useSchemaDispatch();
  const {
    dispatch,
    attachments = [],
    attachmentsViewState = [],
  } = useContext(CellRendererContext);

  return (
    <ActionButtons
      onAttach={() => {
        schemaDispatch({
          type: UPSERT,
          path: 'XeVisitCorrespAttach',
          value: [
            ...attachments,
            {
              FileID,
              UsageFlag: MANUALLY_ATTACHED_FILE,
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
        dispatch({
          type: UPDATE_ATTACHMENTS_VIEW_STATE,
          value: [
            ...attachmentsViewState,
            {
              FileID: {
                FileID,
                FileName,
              },
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
      }}
      onDownload={() =>
        dispatch({
          type: SHOULD_DOWNLOAD_ATTACHMENT,
          value: data,
        })
      }
    />
  );
};

const AttachmentSetCell = ({ data = EMPTY_OBJECT }) => {
  const { Description, FileName, FileSize } = data;
  return (
    <Flexbox direction="column">
      <HeaderLabel>{Description}</HeaderLabel>
      <Panel className="create-letter-wizard-attachments__panel--cell">
        <Flexbox justifyContent="space-between" alignItems="center">
          <Label className="padding-horizontal-medium">{FileName}</Label>
          <Label className="padding-horizontal-medium">
            {fileSizeFormatter(FileSize)}
          </Label>
        </Flexbox>
      </Panel>
    </Flexbox>
  );
};

const IsLinkedIcon = ({ data = EMPTY_OBJECT }) => {
  const { ScanDocID: { IsLinked } = {} } = data;
  if (!IsLinked) return null;
  return <Icon icon={CHECK_CIRCLE_OUTLINE} />;
};

const AttachmentContent = ({
  dispatch,
  attachments,
  attachmentsViewState,
  attachmentSets = EMPTY_ARRAY,
}) => {
  const labels = useXeLabels();

  return (
    <Flexbox direction="column">
      <HeaderLabel dataElementName="letterWizardAttachments__attachment__label">
        {labels.Attachments}
      </HeaderLabel>
      <Grid
        dataElementName="letterWizardAttachments__attachment__grid"
        columnDefs={[
          {
            headerLabelKey: 'AttachmentSet',
            cellRenderer: AttachmentSetCell,
            enableRowGroup: false,
            minWidth: 255,
          },
          {
            headerLabelKey: 'UploadBy',
            field: 'UploadUser',
            valueFormatter: ({ value }) => {
              return formatCommonName(value, 'F, G');
            },
          },
          {
            headerLabelKey: 'Time',
            field: 'UploadDate',
            valueFormatter: ({ value }) =>
              toDisplayDateFromISOString(value, localeFormat.LONG),
          },
          {
            headerLabelKey: 'Category',
            field: 'CategoryID.Name',
          },
          {
            headerLabelKey: 'IsLinked',
            cellRenderer: IsLinkedIcon,
            width: 112,
            resizable: false,
            enableRowGroup: false,
          },
          {
            headerLabelKey: 'Actions',
            cellRenderer: AttachmentActionCell,
            enableRowGroup: false,
          },
        ]}
        data={attachmentSets}
        cellRendererContext={{
          dispatch,
          attachments,
          attachmentsViewState,
        }}
        rowHeight={96}
      />
    </Flexbox>
  );
};

const ReportActionButtons = ({ data = EMPTY_OBJECT }) => {
  const { ReportID, Name } = data;
  const schemaDispatch = useSchemaDispatch();
  const {
    dispatch,
    attachments = [],
    attachmentsViewState = [],
  } = useContext(CellRendererContext);

  return (
    <ActionButtons
      onAttach={() => {
        schemaDispatch({
          type: UPSERT,
          path: 'XeVisitCorrespAttach',
          value: [
            ...attachments,
            {
              ReportID,
              UsageFlag: REPORT_FLAG,
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
        dispatch({
          type: UPDATE_ATTACHMENTS_VIEW_STATE,
          value: [
            ...attachmentsViewState,
            {
              ReportID: {
                ReportID,
                Name,
              },
              UsageFlag: REPORT_FLAG,
              SequenceNo: toNextSequenceNo(attachments),
            },
          ],
        });
      }}
      onDownload={() => {
        dispatch({
          type: SHOULD_DOWNLOAD_REPORT,
          value: data,
        });
      }}
    />
  );
};

const ReportContent = ({
  dispatch,
  attachments,
  attachmentsViewState,
  reports = EMPTY_ARRAY,
}) => {
  const labels = useXeLabels();

  return (
    <Flexbox direction="column">
      <HeaderLabel dataElementName="letterWizard__pamphlets__label">
        {labels.Pamphlets}
      </HeaderLabel>
      <Grid
        dataElementName="letterWizard__pamphlets__grid"
        columnDefs={[
          {
            headerLabelKey: 'Name',
            field: 'Name',
          },
          {
            cellRenderer: ReportActionButtons,
            width: 112,
            resizable: false,
            enableRowGroup: false,
          },
        ]}
        data={reports}
        cellRendererContext={{
          dispatch,
          attachments,
          attachmentsViewState,
        }}
      />
    </Flexbox>
  );
};

const AttachmentsTab = (props) => {
  const {
    dispatch,
    title,
    Content,
    IPID,
    attachments,
    attachmentsViewState,
    clinDocRefData,
    clinDocs,
    attachmentSets,
    reports,
    dataElementName,
  } = props;

  return (
    <div className="display-grid fill-parent-height create-letter-wizard-attachments__attachment-content">
      <HeaderLabel
        dataElementName={
          !!dataElementName
            ? `letterWizardAttachment__${dataElementName}__label`
            : ''
        }
      >
        {title}
      </HeaderLabel>
      <Content
        dispatch={dispatch}
        IPID={IPID}
        attachments={attachments}
        attachmentsViewState={attachmentsViewState}
        clinDocRefData={clinDocRefData}
        clinDocs={clinDocs}
        attachmentSets={attachmentSets}
        reports={reports}
      />
    </div>
  );
};

const AddDialog = (props) => {
  const {
    dispatch,
    onClose,
    IPID,
    attachments,
    attachmentsViewState,
    clinDocRefData,
    clinDocs,
    attachmentSets,
    reports,
  } = props;
  const labels = useXeLabels();

  const tabs = [
    { label: labels.Document, key: 'document' },
    { label: labels.Attachment, key: 'attachment' },
    { label: labels.Pamphlet, key: 'pamphlet' },
  ];

  const contentData = {
    document: {
      title: labels.SelectDocumentTypeForMatching,
      Content: DocumentContent,
      dataElementName: 'selectDocument',
    },
    attachment: {
      title: labels.AttachPreviousPDF,
      Content: AttachmentContent,
      dataElementName: 'AttachPreviousPDF',
    },
    pamphlet: {
      title: labels.AttachPamphlet,
      Content: ReportContent,
      dataElementName: 'attachmentPamphlet',
    },
  };

  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  return (
    <Popup
      dataElementName="letterWizardAttachments__add__popup"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'Close',
        confirmLabelKey: 'Save',
        onClose,
      })}
      size="large"
      contentDisplayType="grid"
      contentStyle={{ gridTemplateRows: 'auto 2fr 1fr' }}
    >
      <div className="create-letter-wizard-attachments__tab-toggle">
        <ButtonGroup
          data={tabs}
          value={currentTab}
          valueFn={pluck('key')}
          labelFn={pluck('label')}
          onChange={setCurrentTab}
        />
      </div>
      <AttachmentsTab
        {...contentData[currentTab]}
        dispatch={dispatch}
        attachments={attachments}
        attachmentsViewState={attachmentsViewState}
        clinDocRefData={clinDocRefData}
        clinDocs={clinDocs}
        attachmentSets={attachmentSets}
        reports={reports}
        IPID={IPID}
      />
      <Flexbox direction="column">
        <HeaderLabel dataElementName="letterWizard__documents__label">
          {labels.Documents}
        </HeaderLabel>
        <Grid
          dataElementName="letterWizard__documents__grid"
          data={attachmentsViewState}
          columnDefs={documentsColumnDefs}
          cellRendererContext={{ dispatch, attachments, attachmentsViewState }}
        />
      </Flexbox>
    </Popup>
  );
};

export const LetterWizardAttachments = (props) => {
  const {
    dispatch,
    IPID,
    attachmentsViewState,
    clinDocRefData,
    clinDocs,
    attachmentSets,
    reports,
  } = props;
  const labels = useXeLabels();

  const [XeVisitCorrespAttach] = useSchemaSelector((instance = {}) => {
    const { XeVisitCorrespAttach = [] } = instance;

    return [XeVisitCorrespAttach];
  });

  const [showAddDialog, updateShowAddDialog] = useState(false);

  return (
    <div
      data-element-name="letterWizard__attachments"
      className="display-grid create-letter-wizard-attachments flex-1"
    >
      <Flexbox justifyContent="space-between">
        <Label
          dataElementName="letterWizard__attachedDocuments__label"
          className="bold"
        >
          {labels.AttachedDocuments}
        </Label>
        <IconButton
          dataElementName="letterWizardAttachments__add"
          icon={ADD_FILLED}
          onClick={() => {
            updateShowAddDialog(true);
            dispatch({ type: REQUEST_ATTACHMENTS_POPUP_DATA });
          }}
        />
      </Flexbox>
      <Grid
        dataElementName="letterWizardAttachments__grid"
        data={attachmentsViewState}
        columnDefs={documentsColumnDefs}
        cellRendererContext={{
          dispatch,
          attachments: XeVisitCorrespAttach,
          attachmentsViewState,
        }}
      />
      {showAddDialog && (
        <AddDialog
          dispatch={dispatch}
          IPID={IPID}
          attachments={XeVisitCorrespAttach}
          attachmentsViewState={attachmentsViewState}
          clinDocRefData={clinDocRefData}
          clinDocs={clinDocs}
          attachmentSets={attachmentSets}
          reports={reports}
          onClose={() => updateShowAddDialog(false)}
        />
      )}
    </div>
  );
};

export default LetterWizardAttachments;
