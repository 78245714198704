import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { Flexbox, Label } from '../../../../components';
import { InfoButton } from '../../components/InfoButton';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import { ExpandableNode } from '../../components/ExpandableNode';
import { isSetLabelValidator } from '../../validators';
import { useEffect } from 'react';
import '../Label/styles.css';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

export const ResultLabel = (props) => {
  const {
    children,
    node,
    dataPath = primarySchemaKeys.ResultLabel,
    onValidated,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    Name,
    InfoID,
    IsRequired: initialIsRequired,
    IsVisible: initialIsVisible,
  } = nodeWithDefaults;

  const { value, validityMessage, fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [
    IsRequired = initialIsRequired,
    IsVisible = initialIsVisible,
    isValid,
  ] = useSchemaSelector((instance = {}) => {
    const { IsSet, IsVisible, IsRequired } = instance;

    const isValid = isSetLabelValidator(IsSet, IsRequired);

    return [IsRequired, IsVisible, isValid];
  });

  useEffect(() => {
    return onValidChange(isValid);
  }, [fullPath, onValidChange, isValid]);

  if (!IsVisible) return null;

  return (
    <ExpandableNode
      node={node}
      ItemElement={
        <Flexbox alignItems="center">
          <NameLabel
            dataElementName={Name}
            required={IsRequired}
            invalid={!valid || !!validityMessage}
          >
            {Name}
          </NameLabel>
          <Label wrapText={true} className="smartbook-label margin-left-small">
            {value}
          </Label>
          <div className="padding-left-small">
            <InfoButton infoId={InfoID} />
          </div>
        </Flexbox>
      }
    >
      {children}
    </ExpandableNode>
  );
};
