import { useSchema } from '../../../../schema/SchemaReducer';
import { useEffect, useRef, Children } from 'react';
import { Flexbox, RadioButton } from '../../../../components';
import { ExpandableNode } from '../../components/ExpandableNode';
import { InfoButton } from '../../components/InfoButton';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import { isSetValidator } from '../../validators';
import './styles.css';
import { NOOP_FUNCTION } from '../../../../constants';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

export const RadioList = (props) => {
  const {
    node,
    dataPath = primarySchemaKeys.RadioList,
    onValidated = NOOP_FUNCTION,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { IsVisible } = node;

  const {
    onValueChange,
    value = false,
    validityMessage,
    fullPath,
  } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  return (
    <RadioListInner
      {...props}
      node={nodeWithDefaults}
      isSet={value}
      valid={valid && !validityMessage}
      fullPath={fullPath}
      onChange={onValueChange}
      onValidChange={onValidChange}
    />
  );
};

const RadioListInner = (props) => {
  const {
    node,
    isSet,
    onValidChange,
    readOnly,
    fullPath,
    onChange,
    valid,
    children,
  } = props;
  const { Name, IsRequired, InfoID } = node;

  const prevValueRef = useRef();
  const isSection = Children.count(children) > 0;

  useEffect(() => {
    // Handle updating validity via external changes to this component's value
    if (prevValueRef.current !== isSet) {
      prevValueRef.current = isSet;

      return onValidChange(isSetValidator(isSet, IsRequired, isSection));
    }
  }, [fullPath, onValidChange, isSet, isSection, IsRequired]);

  return (
    <ExpandableNode
      node={node}
      checked={isSet}
      ItemElement={
        <Flexbox>
          <RadioButton
            type="radio"
            className="smartbook__radio-list"
            disabled={readOnly}
            checked={isSet}
            onClick={(ev) => {
              const {
                target: { checked },
              } = ev;

              prevValueRef.current = checked;

              onChange(checked);
              onValidChange(isSetValidator(checked, IsRequired, isSection));
            }}
          />
          <NameLabel required={IsRequired} invalid={valid !== null && !valid}>
            {Name}
          </NameLabel>
          <InfoButton infoId={InfoID} />
        </Flexbox>
      }
    >
      {children}
    </ExpandableNode>
  );
};
