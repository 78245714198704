import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { isNil } from '../../fp/pred';
import { unescape } from '../../fp/unescape';
import { useCleanup } from '../../hooks/useCleanup';
import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { withClassNameModifiers } from '../../utils/withClassNameModifiers';
import { handleInputTrim } from '../../validators/inputPreds';
import { GridLayout } from '../GridLayout';
import { Label, UIControlLabel } from '../Label';
import './styles.css';

export const TextArea = (props) => {
  const {
    dataElementName = '',
    dataPath,
    className,
    style = EMPTY_OBJECT,
    onChange = NOOP_FUNCTION,
    value: propsValue = '',
    descriptor,
    descriptorClassName,
    descriptorStyle,
    required: propsRequired = false,
    onValidation = NOOP_FUNCTION,
    maxLength,
    resize = false,
    resizeX = false,
    resizeY = false,
    descriptorPosition,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value = propsValue,
    required: schemaRequired = false,
    onTouched = NOOP_FUNCTION,
    onValidationChange = onValidation,
    onCleanup = NOOP_FUNCTION,
    schemaNode = {},
  } = useSchema(dataPath);

  const { maxLength: schemaMaxLength } = schemaNode;
  const _maxLength = !isNil(maxLength) ? maxLength : schemaMaxLength;

  const required = schemaRequired || propsRequired;

  useCleanup(onCleanup);

  const _textAreaRef = useRef();

  useEffect(() => {
    const element = _textAreaRef.current;
    element.checkValidity();
    const { validity, validationMessage } = element;
    const existingValidity = toUIValid(validity);
    onValidationChange(existingValidity, validationMessage);
  }, [required, onValidationChange]);

  const baseClassName = withClassNameModifiers('k-textarea', {
    resize,
    'resize-x': resizeX,
    'resize-y': resizeY,
  });

  const hasMaxLength = !isNil(_maxLength);

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName && dataElementName !== ''
            ? dataElementName + '__label'
            : ''
        }
        className={descriptorClassName}
        style={descriptorStyle}
        required={required}
        descriptorPosition={descriptorPosition}
      >
        {descriptor}
      </UIControlLabel>
      <GridLayout
        style={style}
        className={className}
        templateRows={hasMaxLength ? 'auto max-content' : 'auto'}
      >
        <textarea
          data-element-name={dataElementName}
          ref={_textAreaRef}
          className={`${baseClassName} stretch-x`}
          type="text"
          data-component-name="TextArea"
          value={value}
          required={required}
          onChange={({ target: { value, validity, validationMessage } }) => {
            const unescapedValue = unescape(value);
            onValueChange(unescapedValue);
            onValidationChange(toUIValid(validity), validationMessage);
          }}
          onBlur={(event) => {
            // Ported same onBlur trimming from TextInput (AZ)
            const {
              target: { value, type, validity, element },
            } = event;
            if (type == 'textarea') {
              const trimmedValue = handleInputTrim(event);
              const isNewTrimValue = trimmedValue !== value;
              // only update the value if anything was trimmed
              if (isNewTrimValue) {
                onValueChange(trimmedValue);
                onValidationChange(
                  toUIValid({
                    ...validity,
                    valueMissing:
                      trimmedValue == '' && required
                        ? true
                        : validity.valueMissing,
                  }),
                  element?.validationMessage
                );
              }
            }
            onTouched();
          }}
          maxLength={_maxLength}
          {...passThroughProps}
        />
        {hasMaxLength ? (
          <div style={{ textAlign: 'right' }}>
            <Label
              className={withClassNameModifiers('text', {
                warning: value.length === _maxLength,
              })}
            >
              {`${value.length} / ${_maxLength}`}
            </Label>
          </div>
        ) : null}
      </GridLayout>
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  value: PropTypes.string,
  dataPath: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  autoComplete: PropTypes.string,
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  spellCheck: PropTypes.oneOf(['true', 'false']),
  required: PropTypes.bool,
  dataElementName: PropTypes.string,
};
