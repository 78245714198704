import { Label } from '../../Label';
import ToolTip from '../../ToolTip';
import { ALERT_OUTLINE } from '../../../icons';
import { buildDueDateWithOffset, buildActualDateWithOffset } from '../utils';
import { Icon } from '../../Icon';
import { EMPTY_OBJECT } from '../../../constants';
const MemberProviderDisplay = ({
  member,
  provider,
  memberOverdue = false,
  providerOverdue = false,
  isDueDate,
  DecisionOverdueLabel,
  DecisionAfterDeadlineLabel,
}) => {
  const memberStyle = memberOverdue ? { color: 'red' } : EMPTY_OBJECT;
  const providerStyle = providerOverdue ? { color: 'red' } : EMPTY_OBJECT;
  return (
    <>
      <div style={memberStyle}>
        {`Member: ${member}`}
        {memberOverdue ? (
          <ToolTip
            value={
              isDueDate ? DecisionOverdueLabel : DecisionAfterDeadlineLabel
            }
            placement="top-end"
          >
            <Icon
              icon={ALERT_OUTLINE}
              color="red"
              className="margin-left-small"
            />
          </ToolTip>
        ) : null}
      </div>
      <div style={providerStyle}>
        {`Provider: ${provider}`}
        {providerOverdue ? (
          <ToolTip
            value={
              isDueDate ? DecisionOverdueLabel : DecisionAfterDeadlineLabel
            }
            placement="top-end"
          >
            <Icon
              icon={ALERT_OUTLINE}
              color="red"
              className="margin-left-small"
            />
          </ToolTip>
        ) : null}
      </div>
    </>
  );
};

export const NotificationDisplay = (props) => {
  const {
    MemberDueDate,
    MemberActualDate,
    ProviderDueDate,
    ProviderActualDate,
    DueLabel,
    ActualLabel,
    DecisionOverdueLabel,
    DecisionAfterDeadlineLabel,
  } = props;

  const { date: realizedMemberDueDate, offset: hasMemberDueOffset } =
    buildDueDateWithOffset(MemberDueDate, !!MemberActualDate);
  const { date: realizedProviderDueDate, offset: hasProviderDueOffset } =
    buildDueDateWithOffset(ProviderDueDate, !!ProviderActualDate);

  const { date: realizedMemberActualDate, offset: hasMemberActualOffset } =
    buildActualDateWithOffset(MemberDueDate, MemberActualDate);
  const { date: realizedProviderActualDate, offset: hasProviderActualOffset } =
    buildActualDateWithOffset(ProviderDueDate, ProviderActualDate);

  return (
    <>
      <Label
        descriptor={DueLabel}
        className="align-left"
        descriptorPosition="right top"
      >
        <MemberProviderDisplay
          member={realizedMemberDueDate}
          provider={realizedProviderDueDate}
          memberOverdue={hasMemberDueOffset}
          providerOverdue={hasProviderDueOffset}
          isDueDate={true}
          DecisionOverdueLabel={DecisionOverdueLabel}
          DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
        />
      </Label>
      <Label
        descriptor={ActualLabel}
        className="align-left"
        descriptorPosition="right top"
      >
        <MemberProviderDisplay
          member={realizedMemberActualDate}
          provider={realizedProviderActualDate}
          memberOverdue={hasMemberActualOffset}
          providerOverdue={hasProviderActualOffset}
          isDueDate={false}
          DecisionOverdueLabel={DecisionOverdueLabel}
          DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
        />
      </Label>
    </>
  );
};
