import { useSchema } from '../../schema/SchemaReducer';
import { identity } from '../../fp/fp';
import { isNil } from '../../fp/pred';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useRef } from 'react';
import { RadioButton } from '../RadioButton';
import { UIControlLabel } from '../Label';
import { EMPTY_ARRAY } from '../../constants';

export const RadioGroup = (props) => {
  const {
    dataPath,
    descriptor,
    descriptorClassName,
    name,
    dataElementName,
    data = EMPTY_ARRAY,
    onChange,
    required,
    disabled,
    value: propsValue,
    valueFn = identity,
    vertical = false,
    className = '',
    ...rest
  } = props;
  const {
    value = propsValue,
    onValueChange = onChange,
    onValidationChange,
  } = useSchema(dataPath);

  const radioGroupIdRef = useRef(uniqid());

  return (
    <>
      <UIControlLabel
        dataElementName={!!dataElementName ? `${dataElementName}__label` : ''}
        required={required}
        className={descriptorClassName}
      >
        {descriptor}
      </UIControlLabel>
      <div
        {...rest}
        className={`k-form-field ${className}`}
        data-element-name={dataElementName}
      >
        {data.map((buttonProps = {}, index) => {
          const { key, value: buttonValue, radioDataElementName } = buttonProps;
          return (
            <RadioButton
              key={key || `${radioGroupIdRef.current}_${index}`}
              name={radioGroupIdRef.current}
              dataElementName={radioDataElementName ? radioDataElementName : dataElementName}
              onChange={(value) => {
                onValueChange(value);
              }}
              onValidation={onValidationChange}
              disabled={disabled}
              {...buttonProps}
              style={vertical ? { display: 'block', marginLeft: 0 } : {}}
              value={buttonValue}
              checked={
                !isNil(buttonValue)
                  ? value == buttonValue
                  : value == valueFn(buttonProps)
              }
              required={required}
            />
          );
        })}
      </div>
    </>
  );
};

RadioGroup.propTypes = {
  dataPath: PropTypes.string,
  data: PropTypes.array,
  name: PropTypes.string,
};
