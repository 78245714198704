import { useState, useRef, useEffect } from 'react';
import { Popup as PopUpComponent, toDefaultPopupFooter } from '../../../Popup';
import primarySchemaKeys from '../../primarySchemaKeys';
import { useSchema } from '../../../../schema/SchemaReducer';
import { Button } from '../../../../components';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import './style.css';

export const PopUp = (props) => {
  const {
    children,
    node,
    dataPath = primarySchemaKeys.PopUp,
    onValidated,
    readOnly,
    childrenContext: { children: childrenNodes },
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { IsVisible, Name = '' } = nodeWithDefaults;

  const { value, onValueChange, fullPath } = useSchema(dataPath);
  const initialRefXeSmartBookInstance = useRef(childrenNodes);
  const currentRefXeSmartBookInstance = useRef(childrenNodes);
  const firstOpen = useRef(true);

  const { valid, onValidChange, validityMessage } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [clicked, setClicked] = useState(false);

  // We have to use firstOpen to update the refXeSmartBookInstance with childrenNodes on first PopUp
  // open due to the Requires property calculations happen and update only after it is rendered.
  // check SmartBook/index.jsx and search s1 (schemaReducer) and i2 (requiresReducer) and childrenContext
  // to see the chronological order that Smartbook updates and why we have to do this hacky update.
  // However, we don't want the ref to keep updating as childrenNodes will keep updating as the user uses the
  // application, so we only do this once by using the firstOpen flag. SNET-927 (JCh)
  useEffect(() => {
    if (clicked) {
      firstOpen.current = false;
    } else {
      // the reference update below is made so the reference inside the PopUp data will always be the latest
      // reference BEFORE the PopUp was clicked as the user might be doing something else in the clindoc
      // to change what should be populated inside the PopUp when the PopUp is opened again. SNET-948 (JCh)
      currentRefXeSmartBookInstance.current = childrenNodes;
    }
  }, [clicked, childrenNodes]);

  useEffect(() => {
    firstOpen.current = clicked ? false : true;
  }, [childrenNodes, clicked, firstOpen]);

  useEffect(() => {
    if (firstOpen.current && !clicked) {
      initialRefXeSmartBookInstance.current = childrenNodes;
    }
  }, [firstOpen, childrenNodes, clicked]);

  if (!IsVisible) return null;

  return (
    <div>
      {
        <Button
          dataElementName="popUp"
          className={`${withClassNameModifiers('smartbook-descriptor', {
            invalid: !valid || !!validityMessage,
          })}`}
          look="outline"
          onClick={() => setClicked(true)}
        >
          {Name}
        </Button>
      }
      {clicked ? (
        <PopUpComponent
          title={Name}
          size="x-large"
          FooterComponent={toDefaultPopupFooter({
            closeLabelKey: 'Cancel',
            confirmLabelKey: 'Confirm',
            onClose: () => {
              setClicked(false);
              const value = firstOpen.current
                ? initialRefXeSmartBookInstance
                : currentRefXeSmartBookInstance.current;
              onValueChange(value);
              onValidChange(true);
            },
            onConfirm: () => {
              // there is no onValueChange here due to the children of this PopUp's
              // onValueChange is what changes the value when the user confirms the changes
              // SNET-948 (JCh)
              currentRefXeSmartBookInstance.current = value;
              onValidChange(true);
              setClicked(false);
            },
            disableConfirm: readOnly,
          })}
        >
          <div className="PopUp__div">{children}</div>
        </PopUpComponent>
      ) : null}
    </div>
  );
};
