import { pluck } from '../../../fp/object';
import { isNil } from '../../../fp/pred';
import { pipe } from '../../../fp/fp';
import { discard, keyNamed } from '../../../fp/transformation';
import {
  UPSERT,
  DELETE as DELETE_VERB,
  MERGE_PATCH,
  withDefaultJSONSchemaReducer,
} from '../../../schema/JSONSchemaReducer';
import {
  useSchemaDispatch,
  SchemaReducer,
} from '../../../schema/SchemaReducer';
import { castSchema } from '../../../schema/schemaCaster';
import {
  useScopedSelector,
  useScopedDispatch,
} from '../../../hooks/scopedReducer';
import {
  localeFormat,
  luxonToDisplayString,
} from '../../../format/luxonToDisplayString';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import ScanDocSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
import {
  DropDownList,
  Flexbox,
  Icon,
  IconButton,
  Label,
  Panel,
  Popup,
  TextInput,
  toDefaultPopupFooter,
} from '../../../components';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import { SHOULD_UPLOAD_FILES } from '../../../files/actions';
import { isoStrAsLuxon } from '../../../g11n/ISODates';
import { DELETE, FILE_UPLOADED, FILE_UPLOAD } from '../../../icons';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../../validators/schemaValidators';
import { XePatientSearchWidget } from '../../../widgets';
import { SHOULD_DOWNLOAD_DOCUMENT, SHOULD_SAVE_DOCUMENT } from '../actions';
import '../styles.css';
import { toFormatDocType } from '../utils';
import { useXePatient } from '../../../contexts/XePatientContext';
import { EMPTY_OBJECT, EMPTY_ARRAY, NOOP_FUNCTION } from '../../../constants';

const toFileSetChanged = (
  initialDocSet = EMPTY_ARRAY,
  updatedDocSet = EMPTY_ARRAY
) => {
  const hasDifferentNumberOfDocs =
    initialDocSet.length !== updatedDocSet.length;

  const fileSetChanged =
    hasDifferentNumberOfDocs ||
    initialDocSet.some((initialItem, index) => {
      const updatedItem = updatedDocSet[index] || EMPTY_OBJECT;

      const { FileID, Active } = initialItem;
      const { FileID: updatedFileID, Active: updatedActive } = updatedItem;

      // checking for FileID and Active (non-deleted vs. deleted) matches
      return FileID !== updatedFileID || Active !== updatedActive;
    });
  return fileSetChanged;
};

const CategoryDropDownList = (props) => {
  const { data, ...dropDownListProps } = props;

  const schemaDispatch = useSchemaDispatch();

  return (
    <DropDownList
      {...dropDownListProps}
      data={data}
      onChange={(value) => {
        schemaDispatch({
          type: UPSERT,
          path: 'DocTypeID',
          value,
        });
        const IsIPIDRequired = pluck(
          'item',
          'IsIPIDRequired'
        )(data.find(({ id }) => id === value));
        if (!IsIPIDRequired) {
          schemaDispatch({
            type: DELETE_VERB,
            path: 'IPID',
          });
        }
      }}
    />
  );
};

const ScanDocItem = (props) => {
  const { item, index } = props;
  const { Active, FileName, ScanDocID, FileID, Description, UploadDate } = item;

  const labels = useXeLabels();

  const dispatch = useScopedDispatch();

  const disableAdd = useScopedSelector(pluck('disableAdd'));

  const uploadLuxon = UploadDate ? isoStrAsLuxon(UploadDate) : DateTime.local();

  if (!Active) return null;
  return (
    <div className="uploaded-file padding-all-medium">
      <Flexbox
        className="uploaded-file__details"
        justifyContent="space-between"
      >
        <div>
          <span
            data-element-name="uploadedFile"
            onClick={() => {
              dispatch({
                type: SHOULD_DOWNLOAD_DOCUMENT,
                value: { FileName, FileID },
              });
            }}
            className="file-name underline margin-right-medium"
          >
            {FileName}
          </span>
          <TextInput
            dataElementName="documentDescription"
            placeholder={`${labels.Description}...`}
            onChange={(value) => {
              dispatch({
                type: MERGE_PATCH,
                path: `documentSet.${index}.Description`,
                value,
              });
            }}
            value={Description}
            spellCheck="true"
            disabled={disableAdd}
          />
        </div>
        {!disableAdd && (
          <Flexbox alignItems="center" className="uploaded-file__actions">
            <Icon dataElementName="fileUploaded__icon" icon={FILE_UPLOADED} />

            <IconButton
              dataElementName="delete"
              description={labels.Delete}
              tooltipExtras={{
                className: 'uploaded-file__action-wrapper',
              }}
              className="uploaded-file__action"
              onClick={() => {
                dispatch({
                  type: MERGE_PATCH,
                  path: `documentSet.${index}.Active`,
                  value: false,
                });
              }}
              icon={DELETE}
            />
          </Flexbox>
        )}
      </Flexbox>
      <Label dataElementName="fileUploaded__date">
        {`${labels.UploadDate}: ${luxonToDisplayString(
          uploadLuxon,
          localeFormat.MEDIUM
        )}`}
      </Label>
      <div className={`${isNil(ScanDocID) ? 'bar-green' : 'bar-white '} bar`} />
    </div>
  );
};

const RULES = [[({ DocTypeID } = {}) => DocTypeID, defaultBusinessRuleReducer]];

const validator = toDefaultValidator(RULES);

export const UploadFilesPopup = (props) => {
  const {
    onCancel = NOOP_FUNCTION,
    disableAdd,
    docTypeId = '',
    defaultFileSetName = '',
    fileSetIpid,
  } = props;

  const fileSet = useScopedSelector(pluck('fileSet')) || {};
  const { IPID: documentIpid, XeScanDocSet: initialDocumentSet } = fileSet;
  const documentPatientId = pluck('PatientID')(documentIpid);

  const labels = useXeLabels();
  const { XeScanDocTypeCategory } = useXeRefData();
  const dispatch = useScopedDispatch();

  const [isValid, seIsValid] = useState();

  const { ipid: contextIPID } = useXePatient();

  const initialFileSetRequestBody = useScopedSelector(
    pluck('initialFileSetRequestBody')
  );

  const initialFileSetRequestBodyWithDefaultsRef = useRef(
    castSchema(ScanDocSchema)({
      ...(initialFileSetRequestBody || {}),
      Comments:
        pluck('Comments')(initialFileSetRequestBody) || defaultFileSetName,
      IPID:
        pluck('IPID')(initialFileSetRequestBody) || fileSetIpid || contextIPID,
      DocTypeID: pluck('DocTypeID')(initialFileSetRequestBody) || docTypeId,
    })
  );

  const [fileSetRequestBody, setFileSetRequestBody] = useState(
    initialFileSetRequestBodyWithDefaultsRef.current
  );
  const documentSet = useScopedSelector(pluck('documentSet')) || [];

  const docTypes = contextIPID
    ? XeScanDocTypeCategory.filter(
        ({ item: { IsIPIDRequired } } = {}) => IsIPIDRequired
      )
    : XeScanDocTypeCategory;

  const isIpidRequired = (() => {
    const selectedDocTypeId = pluck('DocTypeID')(fileSetRequestBody);
    const refDatem = docTypes.find(({ id }) => id === selectedDocTypeId) || {};
    return refDatem?.item?.IsIPIDRequired;
  })();

  const isAtFileLimit =
    documentSet.filter(({ Active }) => Active).length >=
    Number.MAX_SAFE_INTEGER;

  return (
    <Popup
      dataElementName="uploadFiles__popup"
      title={labels.UploadFiles}
      className="upload-files-popup"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'Cancel',
        onClose: onCancel,
        onConfirm: !disableAdd
          ? () => {
              const filteredRequestBody = pipe(
                discard(keyNamed('UploadUser')),
                discard(keyNamed('XeEnterpriseData'))
              )(fileSetRequestBody);

              const changed = toFileSetChanged(initialDocumentSet, documentSet);

              dispatch({
                type: SHOULD_SAVE_DOCUMENT,
                value: filteredRequestBody,
                changed,
              });
            }
          : undefined,
        disableConfirm:
          !isValid || (isIpidRequired && !pluck('IPID')(fileSetRequestBody)),
      })}
    >
      <SchemaReducer
        schema={ScanDocSchema}
        initialValue={initialFileSetRequestBodyWithDefaultsRef.current}
        toJsonReducer={withDefaultJSONSchemaReducer(validator)}
        onChange={(params) => {
          const { instance, valid } = params;
          seIsValid(valid);
          setFileSetRequestBody(instance);
        }}
        dangerouslyRetainCompletelyOutdatedState={true}
      >
        <div
          data-component-name="UploadFiles"
          className="upload-files-popup flex-1 overflow-auto"
        >
          <Panel
            className="upload-files-popup__inputs-wrapper padding-all-small"
            shouldOverflow={false}
          >
            <div>
              <TextInput
                dataElementName="fileSetName"
                className="upload-files-popup__input"
                descriptor={labels.FileSetName}
                placeholder={labels.FileSetName}
                dataPath="Comments"
                disabled={disableAdd}
                spellCheck="true"
              />
            </div>
            {!docTypeId && (
              <div className="upload-files-popup__input">
                <Label dataElementName="fileCategoryLabel">
                  {labels.Category}
                </Label>
                <CategoryDropDownList
                  dataElementName="fileCategory"
                  data={docTypes}
                  className="upload-files-popup__list-items margin-left-small"
                  labelFn={toFormatDocType(labels)}
                  value={pluck('DocTypeID')(fileSetRequestBody)}
                  valueFn={(item) => {
                    // DocTypeID is a oneOf and can come in as either
                    // a string or an object
                    if (typeof item === 'string') return item;
                    return pluck('id')(item);
                  }}
                />
                {documentPatientId && (
                  <span className="upload-files-popup__patient-name">
                    {documentIpid.FamilyName}, {documentIpid.GivenName} (
                    {documentPatientId})
                  </span>
                )}
              </div>
            )}
            {!(contextIPID || documentPatientId || fileSetIpid) &&
              isIpidRequired && (
                <Flexbox
                  className="member-search upload-files-popup__input"
                  alignItems="center"
                >
                  <Label
                    dataElementName="patientLabel"
                    className="member-search-label"
                    required={isIpidRequired}
                  >
                    {labels.Patient}
                  </Label>
                  <XePatientSearchWidget
                    dataElementName="consumer"
                    dataPath="IPID"
                    valueFn={pluck('IPID')}
                  />
                </Flexbox>
              )}
          </Panel>
          <Panel>
            {!isAtFileLimit && (
              <div className="upload-files-popup__add-files padding-all-medium">
                <label
                  className="upload-files-popup__add-files-label padding-horizontal-medium padding-vertical-large inline-flex-container align-items-center"
                  data-element-name="addFiles"
                >
                  <Icon icon={FILE_UPLOAD} className="system-icon" />
                  <Label className="upload-files-popup__add-files-title">
                    {labels.AddFiles}
                  </Label>
                  <input
                    data-element-name="uploadFileInput"
                    type="file"
                    onClick={({ target }) => (target.value = null)} // allows uploading of same file after a delete
                    onChange={(ev) => {
                      dispatch({
                        type: SHOULD_UPLOAD_FILES,
                        value: Array.prototype.slice.call(ev.target.files, 0),
                      });
                    }}
                    multiple
                  />
                </label>
              </div>
            )}
            <div className="current-uploaded-files">
              {documentSet.map((item, index) => {
                return (
                  <ScanDocItem
                    item={item}
                    index={index}
                    key={pluck('FileID')(item)}
                  />
                );
              })}
            </div>
          </Panel>
        </div>
      </SchemaReducer>
    </Popup>
  );
};

UploadFilesPopup.propTypes = {
  onCancel: PropTypes.func,
  defaultFileSetName: PropTypes.string,
  docTypeId: PropTypes.string,
};

export default UploadFilesPopup;
