import { isNil } from '../../../fp/pred';
import { useCallback, useRef, useState } from 'react';
import { EMPTY_OBJECT } from '../../../constants';
import { identity } from '../../../fp/fp';
import { exists } from '../../../fp/pred';

export const useSynchronousValue = (value, callback) => {
  const [syncValue, setSyncValue] = useState(value);
  const lastValueRef = useRef(value);

  const changeHandler = useCallback(
    (value, ...rest) => {
      callback(value, ...rest);
      setSyncValue(value);
    },
    [callback]
  );

  if (value !== lastValueRef.current) {
    lastValueRef.current = value;
    setSyncValue(value);
  }

  return [syncValue, changeHandler];
};

/**
 * @param {string} timeInterval
 * @returns {import('@progress/kendo-react-dateinputs').DateInputIncrementalSteps | null}
 */
export const toTimeAttributeParts = (timeInterval = '') => {
  if (!timeInterval) return undefined;

  const [hour, minute, second] = timeInterval.split(':');

  const parts = {
    hour,
    minute,
    second,
  };

  return Object.entries(parts).reduce(
    (partsReturnObj, [partName, partStringValue]) => {
      if (isNil(partStringValue)) {
        return partsReturnObj;
      }

      const partIntValue = parseInt(partStringValue);

      if (partIntValue < 1) return partsReturnObj;

      return {
        ...partsReturnObj,
        [partName]: partIntValue,
      };
    },
    {}
  );
};

/**
 * Returns an object representing a smart book node with applied defaults from any source
 * @example
 * ```js
 * const default = {
 *  required: true,
 *  descriptor: 'Hello world'
 * };
 *
 * // where `node` is `undefined`
 * const { Name, IsRequired } = toNodePropertiesWithDefaults(node, defaults);
 *
 * // Yields:
 * {
 *   Name: 'Hello world',
 *   IsRequired: true
 * }
 *
 * ```
 * @param {*} node
 * @param {{
 *  descriptor?: string,
 *  required?: boolean
 * }} defaults
 */
export const toNodePropertiesWithDefaults = (node = EMPTY_OBJECT, defaults) => {
  const { descriptor, required } = defaults;

  const { Name = descriptor, IsRequired = required, IsVisible = true } = node;

  return {
    ...node,
    IsRequired,
    IsVisible,
    Name,
  };
};

export const toComparatorFn =
  (toValue = identity) =>
  (a, b) => {
    const valA = toValue(a);
    const valB = toValue(b);
    return exists(valA) && exists(valB) && valA === valB;
  };
