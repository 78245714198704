import { useSchema } from '../../schema/SchemaReducer';
import { isNil } from '../../fp/pred';
import { toUIValid } from '../../schema/UIValidationProjection';
import PropTypes from 'prop-types';
import { useState } from 'react';
import uniqid from 'uniqid';
import { castFunction } from '../../fp/fp';
import { NOOP_FUNCTION } from '../../constants';

const toIsChecked = (schemaValue, checkboxValue, defaultValue) => {
  if (schemaValue !== undefined && checkboxValue !== undefined)
    return schemaValue === checkboxValue;
  else if (schemaValue !== undefined) return schemaValue;
  else return defaultValue;
};

export const RadioButton = (props) => {
  const [id] = useState(uniqid());
  const {
    label,
    className = '',
    onChange,
    dataPath,
    value: radioValue,
    checked,
    dataElementName = '',
    onValidation = NOOP_FUNCTION,
    style,
    ...passThroughProps
  } = props;

  const {
    onValueChange = onChange,
    value: schemaValue,
    schemaNode = {},
    onValidationChange = onValidation,
    //required,
  } = useSchema(dataPath);

  const { defaultValue } = schemaNode;
  const controlledChecked =
    toIsChecked(schemaValue, radioValue, defaultValue) || checked;

  return (
    <span
      className={`${className} xjs-radio-button`}
      style={{ display: 'inline-block', ...style }}
      data-component-name="RadioButton"
    >
      <input
        data-element-name={dataElementName}
        type="radio"
        className="k-radio"
        checked={controlledChecked}
        onChange={(ev) => {
          const { target: element } = ev;
          const { checked, value, validity } = element;

          const schemaUpdateValue = !isNil(checked)
            ? value || checked
            : undefined;

          castFunction(onValueChange)(schemaUpdateValue);
          castFunction(onValidationChange)(toUIValid(validity));
        }}
        value={radioValue}
        {...passThroughProps}
        id={id}
      />
      <label
        data-element-name={
          dataElementName !== '' ? `${dataElementName}__label` : ''
        }
        className="k-radio-label"
        htmlFor={id}
      >
        {label}
      </label>
    </span>
  );
};

RadioButton.propTypes = {
  dataElementName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onValidation: PropTypes.func,
};

export default RadioButton;
