import { toUIValid } from '../schema/UIValidationProjection';

/**
 * @param {{ validity: any, required: boolean }} component
 */
export const toIsKendoComponentValid = (component) => {
  if (!component) return true;
  const {
    required,
    validity,
    validity: { valid },
  } = component;
  if (!required && valid) {
    return toUIValid({ ...validity, valueMissing: false });
  }
  return toUIValid(validity);
};
