import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { TimePicker as KendoTimePicker } from '@progress/kendo-react-dateinputs';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  toFormatterAndParserByType,
  toParseWithToken,
  DateTimeFormatNamePropType,
} from '../../g11n/ISODates';
import { toDateSchemaNodeFormat } from '../../utils/schema';
import { UIControlLabel } from '../Label';
import { NOOP_FUNCTION } from '../../constants';

const defaultFormatPlaceholder = {
  hour: 'hh',
  minute: 'mm',
};

export const TimePicker = (props) => {
  const {
    dataElementName,
    dataPath,
    min,
    max,
    format: propsFormat = 'local-date-time',
    descriptor,
    descriptorClassName,
    onChange = NOOP_FUNCTION,
    value: propValue,
    required: propsRequired,
    onValidation = NOOP_FUNCTION,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [internalTouched, setInternalTouched] = useState(false);

  const {
    onValueChange = onChange,
    value = propValue,
    schemaNode,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = () => setInternalTouched(true),
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  const dateFormat = schemaNode
    ? toDateSchemaNodeFormat(schemaNode)
    : propsFormat;

  const validityStyles = schemaNode ? touched : internalTouched;
  const required = schemaRequired || propsRequired;

  const timePickerRef = useRef();

  const { parser, formatter } = useMemo(
    () => toFormatterAndParserByType(dateFormat),
    [dateFormat]
  );

  const valueAsDate = useMemo(() => {
    if (!value) return null;
    return parser(value);
  }, [value, parser]);

  const memoizedMin = useMemo(
    () => toParseWithToken(parser)(min),
    [min, parser]
  );

  const memoizedMax = useMemo(
    () => toParseWithToken(parser)(max),
    [max, parser]
  );

  const memoizedOnChange = useCallback(
    (ev) => {
      const { target: { value, validity } = {} } = ev;
      //Due to how moment works, passing it a null will result in a date at timestamp 0.
      //We need the actual value of null to be passed through to update dates in the database. This ensures
      //null is always passed back when an incomplete or fully deleted date is entered.
      const realizedValue = value !== null ? formatter(value) : '';
      const { valueMissing, valid, ...rest } = validity;

      onValueChange(realizedValue);
      onValidationChange(
        toUIValid({
          ...rest,
          valid,
          valueMissing: required ? valueMissing : false,
        })
      );
    },
    [onValueChange, onValidationChange, formatter, required]
  );

  const memoizedValidity = useMemo(() => {
    if (!validityMessage) return undefined;
    return false;
  }, [validityMessage]);

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__label` : 'label'
          }
          className={descriptorClassName}
          required={required}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <KendoTimePicker
        ref={(ref) => {
          if (ref && timePickerRef.current !== ref) {
            ref.element.setAttribute('data-component-name', 'TimePicker');
            ref.element.setAttribute('data-element-name', dataElementName);
          }
          timePickerRef.current = ref;
        }}
        value={valueAsDate}
        required={required}
        formatPlaceholder={defaultFormatPlaceholder}
        onChange={memoizedOnChange}
        min={memoizedMin}
        max={memoizedMax}
        onBlur={onTouched}
        valid={memoizedValidity}
        validityStyles={validityStyles}
        {...passThroughProps}
      />
    </>
  );
};

export default TimePicker;

TimePicker.propTypes = {
  dataPath: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  format: DateTimeFormatNamePropType,
  desciptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
};
