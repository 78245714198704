import 'hammerjs';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';
import PropTypes from 'prop-types';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useRef } from 'react';

const defaultLabelFn = (props) => {
  const { field } = props;
  const { dataItem: { [field]: label } = EMPTY_OBJECT } = props;

  return label;
};

const defaultLegendLabelFn = (e) => {
  return e.text;
};

/**
 *
 * @name PieChart
 * @type {function}
 * @property {array} series -- collection of data objects for the chart
 * @property {string} title -- the Title to display on the chart.
 * @property {boolean} legend -- boolean to decide if the legend should show
 * @property {string} legendPosition -- can be 'top', 'left', 'bottom', 'right' or 'custom' (will offsetX and offsetY for custom)
 * @property {function} labelFn -- label function for display on the series
 * @property {function} legendLabelFn -- label function for display on the legend
 * @property {string} field -- name of the field in the series to use for displaying values
 * @property {string} categoryField -- name of the field in the series to use for displaying labels
 * @property {boolean} showLabels -- boolean to decide if series labels show
 * @property {function} onLegendItemClick -- callback function for when you click on a legend item
 * @property {function} onSeriesClick -- callback function fro when you click on a series piece
 *
 */
export const PieChart = (props) => {
  const {
    series = EMPTY_ARRAY,
    title = '',
    legend = true,
    legendPosition = 'bottom',
    labelFn = defaultLabelFn,
    legendLabelFn = defaultLegendLabelFn,
    field = 'value',
    categoryField = 'category',
    showLabels = true,
    onLegendItemClick = NOOP_FUNCTION,
    onSeriesClick = NOOP_FUNCTION,
    dataElementName = '',
  } = props;
  const _chartRef = useRef();
  return (
    <Chart
      ref={(ref) => {
        if (ref && _chartRef.current !== ref) {
          ref.element.setAttribute('data-component-name', 'Chart');
          ref.element.setAttribute('data-element-name', dataElementName);
        }
        _chartRef.current = ref;
      }}
      title={title}
      onSeriesClick={onSeriesClick}
      onLegendItemClick={onLegendItemClick}
    >
      {!!legend && (
        <ChartLegend
          labels={{ content: legendLabelFn }}
          position={legendPosition}
        />
      )}
      <ChartSeries>
        <ChartSeriesItem
          type="pie"
          data={series}
          field={field}
          categoryField={categoryField}
          labels={{ visible: showLabels, content: labelFn }}
        />
      </ChartSeries>
    </Chart>
  );
};

PieChart.propTypes = {
  series: PropTypes.array,
  title: PropTypes.string,
  legend: PropTypes.bool,
  legendPosition: PropTypes.string,
  labelFn: PropTypes.func,
  legendLabelFn: PropTypes.func,
  showLabels: PropTypes.bool,
  field: PropTypes.string,
  categoryField: PropTypes.string,
  onLegendItemClick: PropTypes.func,
  onSeriesClick: PropTypes.func,
};

export default PieChart;
