import { castSchema } from '../../../schema/schemaCaster';
import { SchemaReducer, useSchema } from '../../../schema/SchemaReducer';
import { useRef$ } from '../../../hooks/useRef$';
import { useEffect$ } from '../../../hooks/useEffect$';
import { useFactory } from '../../../hooks/useFactory';
import { useReducer, useRef, useState } from 'react';
import {
  distinctUntilKeyChanged,
  pluck as pluckFrp,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import XeVisitCorrespRecipSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter$XeVisitCorrespRecip.json';
import { Button, Flexbox, HeaderLabel, Label } from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { EditPatientDetailsLayout } from '../../../layouts/EditPatientDetailsLayout';
import RecipientForm from './RecipientForm';
import '../styles.css';
import Contacts from './Contacts';
import { withDefaultJSONSchemaReducer } from '../../../schema/JSONSchemaReducer';
import { toDefaultValidator } from '../../../validators/schemaValidators';
import { isValidFaxNumber } from '../../../validators/inputPreds';

const toVisitCorrespRecip = castSchema(XeVisitCorrespRecipSchema);

/**
 * @typedef {import('services/generated/types').VisitCorrespondenceEnter$XeVisitCorrespRecip} VisitCorrespondenceEnter$XeVisitCorrespRecip
 */

/**
 * @param {VisitCorrespondenceEnter$XeVisitCorrespRecip} item
 * @param {number} index
 */

const deactivateIndex = (arr, index) => {
  return arr.reduce((agg, item = {}, idx) => {
    /**
     * @type {VisitCorrespondenceEnter$XeVisitCorrespRecip}
     */
    const { VisitCorrespRecipID } = item;
    if (idx === index) {
      if (!!VisitCorrespRecipID) {
        /**
         * @type {VisitCorrespondenceEnter$XeVisitCorrespRecip}
         */
        const nextValue = {
          ...item,
          Active: false,
        };
        return [...agg, nextValue];
      }
      return agg;
    }
    return [...agg, item];
  }, []);
};

const RECIPIENT_RULES = [
  [
    (data) => {
      const faxNumber = data?.Fax ?? '';

      if (faxNumber.trim().length === 0) {
        return true;
      }

      return isValidFaxNumber(faxNumber);
    },
    () => ({
      Fax: {
        message: 'Must have a valid Fax number',
      },
    }),
  ],
];

const Form = ({ item, index, onChange, context }) => {
  const labels = useXeLabels();

  const prevItemKeyRef = useRef(index);
  const prevItemRef = useRef(item);
  if (prevItemKeyRef.current !== item) {
    prevItemKeyRef.current = item;
  }

  const initialValue = useFactory(() => {
    return prevItemRef.current;
  }, [index]);

  return (
    <SchemaReducer
      initialValue={initialValue}
      onChange={({ instance, valid }) => {
        onChange({
          value: instance,
          valid,
          itemKey: index,
          index,
        });
      }}
      schema={XeVisitCorrespRecipSchema}
      toJsonReducer={withDefaultJSONSchemaReducer(
        toDefaultValidator(RECIPIENT_RULES)
      )}
    >
      <Flexbox
        direction="column"
        justifyContent="space-between"
        className="create-letter-wizard__recipients"
      >
        <RecipientForm recipient={item} context={context} />
        <Flexbox className="create-letter-wizard__changes padding-all-small">
          <Label dataElementName="letterWizard__changesInfo__label">
            {labels.ChangesMadeHereWillOnlyAffectThisLetter}
          </Label>
        </Flexbox>
      </Flexbox>
    </SchemaReducer>
  );
};

const calculateInvalidKeySet = (currentInvalidKeys, currentItemKey, isDelete) =>
  new Set(
    Array.from(currentInvalidKeys).map((key) =>
      key > currentItemKey && isDelete ? key - 1 : key
    )
  );

export const Recipients = (props) => {
  const {
    useRecipientsIndex,
    IPID,
    onPreview,
    isAllPagesValid,
    languages,
    selectedCustomLanguage,
    WrittenLanguageID,
  } = props;

  const labels = useXeLabels();

  const {
    value: XeVisitCorrespRecip,
    onValueChange,
    onValidationChange,
  } = useSchema('XeVisitCorrespRecip');

  const [viewContacts, setViewContacts] = useState(false);
  const [recipientValidity, validityDispatch] = useReducer(
    (state = {}, action) => {
      const { value: isValid, itemKey, isDelete } = action;
      /**
       * @type {{ invalidKeys: Set<string> }}
       */
      const { invalidKeys = new Set() } = state;

      isValid ? invalidKeys.delete(itemKey) : invalidKeys.add(itemKey);

      const newInvalidKeys = calculateInvalidKeySet(
        invalidKeys,
        itemKey,
        isDelete
      );

      return {
        ...state,
        invalidKeys: newInvalidKeys,
        valid: newInvalidKeys.size === 0,
      };
    },
    {}
  );

  const recipientValidity$ = useRef$(recipientValidity);
  const onValidationChange$ = useRef$(onValidationChange);

  /*
   * Observe changes to the validity of the list of recipients as a whole
   * and invoke the validation change handler with the result
   */
  useEffect$(() => {
    return recipientValidity$.pipe(
      distinctUntilKeyChanged('valid'),
      pluckFrp('valid'),
      withLatestFrom(onValidationChange$),
      tap(([valid, onValidationChange]) => {
        return onValidationChange(valid);
      })
    );
  }, [recipientValidity$, onValidationChange$]);

  return (
    <>
      <EditPatientDetailsLayout
        useRecipientsIndex={useRecipientsIndex}
        context={{ languages, selectedCustomLanguage, WrittenLanguageID }}
        list={XeVisitCorrespRecip}
        listRenderItem={({ item }) => {
          const { Name, SendMethod } = item;

          return (
            <Flexbox direction="column">
              <Label className="bold">{Name}</Label>
              <Label>{SendMethod}</Label>
              <Button
                disabled={!isAllPagesValid}
                onClick={() => {
                  return onPreview({ recipient: item });
                }}
              >
                {labels.Preview}
              </Button>
            </Flexbox>
          );
        }}
        onChange={(ev) => {
          const { value, valid, index, itemKey } = ev;
          validityDispatch({
            value: valid,
            itemKey,
            isDelete: false,
          });
          onValueChange([
            ...XeVisitCorrespRecip.slice(0, index),
            value,
            ...XeVisitCorrespRecip.slice(index + 1),
          ]);
        }}
        canAdd={true}
        onAdd={() => {
          /**
           * @type {VisitCorrespondenceEnter$XeVisitCorrespRecip}
           */
          const nextRecipient = {
            Active: true,
          };
          onValueChange([...XeVisitCorrespRecip, nextRecipient]);
        }}
        canDeletePred={() => true}
        onDelete={(params) => {
          const { index } = params;

          const updatedRecipients = deactivateIndex(XeVisitCorrespRecip, index);

          validityDispatch({
            value: true,
            itemKey: index,
            isDelete: true,
          });
          onValueChange(updatedRecipients);
        }}
        keyFn={(item, index) => {
          /**
           * @type {VisitCorrespondenceEnter$XeVisitCorrespRecip}
           */
          const { VisitCorrespRecipID } = item;
          return VisitCorrespRecipID || `XeVisitCorrespRecip.${index}`;
        }}
        Form={Form}
        Header={() => (
          <>
            <HeaderLabel
              dataElementName="letterWizard__recipientsHeader__label"
              className="margin-right-medium"
            >
              {labels.Recipients}
            </HeaderLabel>
            <Button
              dataElementName="letterWizard__recipients__socialContacts"
              onClick={() => setViewContacts(true)}
            >
              {labels.Contacts}
            </Button>
          </>
        )}
      />
      {viewContacts && (
        <Contacts
          ipid={IPID}
          onAdd={(contacts) => {
            setViewContacts(false);

            onValueChange([
              ...XeVisitCorrespRecip,
              ...contacts.map(toVisitCorrespRecip),
            ]);
          }}
          onCancel={() => setViewContacts(false)}
          labels={labels}
        />
      )}
    </>
  );
};

export default Recipients;
