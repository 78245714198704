import { pluck } from '../../fp/object';
import { isEmpty } from '../../fp/pred';
import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { schemaGet } from '../../schema/schemaTypeBuilder';
import { transform, discard, keyNamed } from '../../fp/transformation';
import { REMOVE_FEATURE } from '../../hooks/scopedReducer';
import { toVerbActionReducer } from '../../schema/JSONSchemaReducer';
import pipe from 'lodash/fp/pipe';
import XeScanDocSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
import { SHOULD_UPDATE_SCAN_DOCS_TO } from '../../files/actions';
import {
  SHOULD_ADD_NEW_FILES,
  SHOULD_SET_DEFAULT_DOCUMENT_SET_TO,
  SHOULD_SET_DEFAULT_FILE_SET_NAME_TO,
  SHOULD_UPDATE_COMMENTS_TO,
  SHOULD_SET_FILE_SET_ENTERPRISE_ID,
} from './actions';

const addFilesToDocSet = (state, action) => {
  const { documentSet = [] } = state;
  const { value: filesToAdd } = action;
  if (!filesToAdd) {
    return {
      ...state,
      documentSet: undefined,
    };
  }
  return {
    ...state,
    documentSet: [...documentSet, ...filesToAdd],
  };
};

export default [
  when(() => true)(toVerbActionReducer({})),
  // Note: fileSetEnterpriseId isn't used in UploadFiles directly, but is expected in state by the
  // mix-in epics from src/files/epics
  when(isType(SHOULD_SET_FILE_SET_ENTERPRISE_ID))(
    toActionValueReducer('fileSetEnterpriseId')
  ),
  when(isType(SHOULD_UPDATE_COMMENTS_TO, SHOULD_SET_DEFAULT_FILE_SET_NAME_TO))(
    toActionValueReducer('comments')
  ),
  when(isType(SHOULD_ADD_NEW_FILES, SHOULD_SET_DEFAULT_DOCUMENT_SET_TO))(
    addFilesToDocSet
  ),
  when(isType(SHOULD_UPDATE_SCAN_DOCS_TO))(
    toActionValueReducer('initialFileSetRequestBody', (value) => {
      return pipe(
        transform('DocTypeID')(pluck('DocTypeID')),
        transform('IPID')(pluck('IPID')),
        transform('CategoryID')(pluck('CategoryID')),
        transform('DocumentTypeID')(pluck('DocumentTypeID')),
        discard(keyNamed('Active')),
        discard(keyNamed('CreationUser')),
        discard(keyNamed('IVID')),
        discard(keyNamed('IsError')),
        discard(keyNamed('IsLinked')),
        discard(keyNamed('ModifiedTStamp')),
        discard(keyNamed('ModifiedUser')),
        discard(keyNamed('UploadDate')),
        discard(keyNamed('UploadUser')),
        discard(keyNamed('XeEnterpriseData')),
        discard(keyNamed('XeScanDocSet'))
      )(value);
    }),
    (state, action = {}) => {
      const { value } = action;
      if (isEmpty(value)) return state;
      return {
        ...state,
        fileSetEnterpriseId: schemaGet(
          XeScanDocSchema,
          'XeEnterpriseData.EnterpriseID',
          value
        ),
      };
    },
    toActionValueReducer('fileSet'),
    toActionValueReducer('documentSet', (v) => {
      const documentSet = pluck('XeScanDocSet')(v);
      if (!documentSet || !documentSet.length) return [];
      return documentSet.map((x) => {
        return {
          ...x,
          FileID: pluck('FileID', 'FileID')(x),
        };
      });
    })
  ),
  when(isType(REMOVE_FEATURE))(
    toActionValueReducer('fileSet', () => undefined),
    toActionValueReducer('documentSet', () => []),
    toActionValueReducer('initialFileSetRequestBody', () => undefined)
  ),
];
