import { isType } from '../../connection/toConnectedComponent';
import { EMPTY_ARRAY } from '../../constants';
import { when } from '../../predication/when';
import { inactivePathMarker } from './constants';
import { toSmartBookNodePath } from './utils';

const DEFAULT_STATE = {
  invalidNodePaths: EMPTY_ARRAY,
};

const nodeValidityReducer = (prevState, action) => {
  const { path: fullDataPath, value: nextValid, isSection } = action;
  const { invalidNodePaths = [] } = prevState;

  const pathToNode = toSmartBookNodePath(fullDataPath);

  if (invalidNodePaths.includes(pathToNode) && !nextValid) {
    return {
      ...prevState,
      invalidNodePaths: [...invalidNodePaths],
    };
  }

  let nextInvalidNodePaths = [...invalidNodePaths];

  if (isSection) {
    // Section is going dormant. Mark descendants so that they are not included in the smartbook's validity.
    if (nextValid === null) {
      nextInvalidNodePaths = invalidNodePaths.map((inp) => {
        if (inp.startsWith(pathToNode)) {
          return `${inactivePathMarker}${inp}`;
        }
        return inp;
      });
      return {
        ...prevState,
        invalidNodePaths: nextInvalidNodePaths,
      };
    }

    nextInvalidNodePaths = invalidNodePaths.map((inp) => {
      if (inp.startsWith(`${inactivePathMarker}${pathToNode}`)) {
        const [, p] = inp.split(inactivePathMarker);
        return p;
      }
      return inp;
    });
  }

  const invalidNodePathsSet = new Set(nextInvalidNodePaths);
  if (nextValid == true) {
    invalidNodePathsSet.delete(pathToNode) ||
      invalidNodePathsSet.delete(`${inactivePathMarker}${pathToNode}`);
    return {
      ...prevState,
      invalidNodePaths: Array.from(invalidNodePathsSet),
    };
  }

  const nextSet = invalidNodePathsSet.add(pathToNode);

  return {
    ...prevState,
    invalidNodePaths: Array.from(nextSet),
  };
};

export default [
  when((state) => state === undefined)(() => DEFAULT_STATE),
  when(isType('updateNodeValidity'))(nodeValidityReducer),
];
