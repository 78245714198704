import { useState, useEffect } from 'react';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';
import { useXeQuery } from '../../../../data/useXeQuery';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '../../../../constants';
import {
  Flexbox,
  HeaderLabel,
  Panel,
  TogglableList,
} from '../../../../components';
import { useSchema } from '../../../../schema/SchemaReducer';
import { toComparatorFn } from '../utils';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { schemaPluck } from '../../../../schema/schemaTypeBuilder';
import { browse } from '../../../../../../services/src/correspondence-infos/xe-correspondence-infos-svc.js';
import CorrespondenceInfoResponseSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';
import './styles.css';
import { identity } from '../../../../../src/fp/fp';
import { baseNodeValidator } from '../../validators';

const RESULT_VALUE = 'ResultValue';
const DEFAULT_PARENT_RESULT_VALUE = 'undefined|undefined|undefined|undefined';

export const Correspondence = (props) => {
  const {
    node,
    dataPath = primarySchemaKeys.Correspondence,
    onValidated,
    childrenContext: {
      parent: {
        ResultValue: parentResultValue = DEFAULT_PARENT_RESULT_VALUE,
      } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    readOnly,
  } = props;

  const { onValueChange: onResultValueChange, fullPath } =
    useSchema(RESULT_VALUE);
  const { onValueChange: onResultIDValueChange } = useSchema(dataPath);

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { IsRequired, IsVisible } = node;

  const { onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const labels = useXeLabels();

  const [registerTemplate, setRegisterTemplate] = useState(EMPTY_OBJECT);

  const [letterType, subType, correspondenceCode, lineOfBusiness] =
    parentResultValue.split('|');

  const { data: templates = EMPTY_ARRAY } = useXeQuery(
    browse(
      {
        letterType,
        subType,
        lineOfBusiness,
        correspondenceCode,
      },
      identity
    ),
    { enabled: !!IsVisible }
  );

  const toCorrespondenceInfoID = schemaPluck(
    CorrespondenceInfoResponseSchema,
    'CorrespondenceInfoID'
  );

  const shouldAutoSelect = templates?.length === 1;
  const [autoSelectionTemplate] = shouldAutoSelect ? templates : EMPTY_ARRAY;
  const previouslySelectedTemplate =
    readOnly || !!node.ResultValueID
      ? templates.find(
          (template) => template.CorrespondenceInfoID == node?.ResultValueID
        )
      : undefined;

  // since the user cannot select a template when correspondence is in a readOnly state
  // when viewing the completed document or when continuing a "Save and Close" document,
  // or when there is only one template, we need to pre-populate the selectedTemplate
  // (SYNUI-8996)(SNET-538)(JCh)
  const selectedTemplate =
    previouslySelectedTemplate || autoSelectionTemplate || registerTemplate;

  useEffect(() => {
    if (readOnly) return;

    if (shouldAutoSelect) {
      onResultIDValueChange(autoSelectionTemplate.CorrespondenceInfoID);
      onResultValueChange(autoSelectionTemplate.Name);
      onValidChange(
        baseNodeValidator(
          true,
          autoSelectionTemplate.CorrespondenceInfoID,
          IsRequired
        )
      );
    }
  }, [
    shouldAutoSelect,
    autoSelectionTemplate,
    onResultIDValueChange,
    onResultValueChange,
    onValidChange,
    readOnly,
    IsRequired,
  ]);

  if (!IsVisible) return null;

  return (
    <Panel>
      <Flexbox direction="column" className="flex-1">
        <div className="display-grid">
          <HeaderLabel
            className="correspondence__section-header"
            required={IsRequired}
          >
            {labels.Correspondence}
          </HeaderLabel>
          <TogglableList
            dataElementName="selectLetter"
            required={IsRequired}
            className="correspondence__letter-template-list overflow-auto"
            descriptorClassName="flex-shrink-0"
            data={templates}
            comparator={toComparatorFn(toCorrespondenceInfoID)}
            value={selectedTemplate}
            renderItem={({ item }) => {
              const Name = schemaPluck(
                CorrespondenceInfoResponseSchema,
                'Name'
              )(item);
              const Description = schemaPluck(
                CorrespondenceInfoResponseSchema,
                'Description'
              )(item);
              return `${Name}${Description ? ` - ${Description}` : ''}`;
            }}
            onChange={({ item } = EMPTY_OBJECT) => {
              if (readOnly) return;

              onResultIDValueChange(item.CorrespondenceInfoID);
              onResultValueChange(item.Name);
              onValidChange(
                baseNodeValidator(true, item.CorrespondenceInfoID, IsRequired)
              );
              setRegisterTemplate(item);
            }}
          />
        </div>
      </Flexbox>
    </Panel>
  );
};

export default Correspondence;
