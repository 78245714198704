import document from '../../globals/document';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';
import { Children, useMemo } from 'react';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import './styles.css';
import { Footer } from './components/Footer';
import {
  getFooterActionBar,
  getPopupBody,
  toClassName,
  toPopupSizes,
  useViewportSize,
} from './utils';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

/**
 * @typedef PopupAction
 * @property {string | (labels: Object) => string=} label
 * @property {import('../../components/Button').ButtonProps['onClick']=} onAction
 * @property {number} [order=-1]
 * @property {boolean=} disabled
 * @property {boolean=} hidden
 * @property {import('react').HTMLAttributes<HTMLButtonElement>['style']=} style
 * @property {string=} className
 * @property {import('../../components/Button').XJSButtonProps['look']=} look
 * @property {import('react').ButtonHTMLAttributes=} buttonProperties
 */

/**
 * @typedef DefaultActionConfig
 * @property {PopupAction=} close
 * @property {PopupAction=} save
 *
 * @typedef {Record<string, PopupAction> & DefaultActionConfig} ActionConfig
 * Configuration for specifying what actions should be shown in the footer. By default, `close` and `save` have predefined labels
 */

/**
 * @type {ActionConfig}
 */
export const DefaultPopupActions = {
  close: {
    label: (labels) => labels.Close,
  },
  save: {
    label: (labels) => labels.Save,
  },
};

/**
 * Similar to `toFooterFromActions` except that only the footer buttons are returned as an array.
 * Primary useful when you need to customize the container wrapping the footer buttons.
 * @param {*} labels Object with labels to display for each action
 * @param {ActionConfig} actions
 */
export const toButtonsFromActions = (labels, actions) => {
  const configEntries = Object.entries(actions);

  return configEntries
    .map((entry, index) => {
      const [key, value] = entry;
      if ('order' in value) {
        return entry;
      }
      /**
       * @type {[string, PopupAction]}
       */
      const res = [key, { ...value, order: index }];
      return res;
    })
    .sort(([, { order: lhs = -1 }], [, { order: rhs = -1 }]) => {
      if (rhs < 0 || lhs < 0) return 1;
      if (rhs > lhs) return -1;
      if (rhs < lhs) return 1;
      return 0;
    })
    .map(([type, config]) => {
      const baseConfig = DefaultPopupActions[type] || {};
      const {
        label,
        onAction,
        disabled,
        hidden,
        style,
        className,
        dataElementName,
        buttonProperties = {},
      } = {
        ...baseConfig,
        ...config,
      };

      if (hidden) return null;

      const _label = typeof label === 'function' ? label(labels) : label;
      return (
        <Button
          {...buttonProperties}
          dataElementName={dataElementName}
          className={
            className ? `${className} xe-popup__button` : 'xe-popup__button'
          }
          onClick={onAction}
          disabled={disabled}
          style={style}
          look="outline"
          key={type}
        >
          {_label}
        </Button>
      );
    });
};

/**
 * Returns a basic footer component that builds each button from the `actions` configuration object
 * @param {*} labels Object with labels to display for each action
 * @param {ActionConfig} actions
 */
export const toFooterFromActions = (labels, actions) => () => {
  return <div>{toButtonsFromActions(labels, actions)}</div>;
};

const Header = ({ children }) => (
  <div data-element-name="popupHeader" className="xe-popup__header">
    {children}
  </div>
);

const DefaultHeaderComponent = (props) => {
  const { title } = props;
  return (
    <Label
      dataElementName={title.replace(/\s/g, '')}
      className="xe-popup__title"
    >
      {title}
    </Label>
  );
};

/**
 * @description xnetjs Popup component. Prefer importing and using the `Footer` component instead of
 * the `FooterComponent` prop or the `toDefaultPopupFooter` function.
 * @returns
 */
export const Popup = (props) => {
  const {
    dataElementName = '',
    children,
    size = 'medium',
    className = '',
    title = '',
    HeaderComponent = DefaultHeaderComponent,
    FooterComponent = null,
    onClose = NOOP_FUNCTION,
    contentDisplayType = 'flex',
    contentStyle = EMPTY_OBJECT,
    footerStyle = EMPTY_OBJECT,
    popupRootSelector = '#appTarget #popup',
    closeIcon = false,
  } = props;
  const popupRoot = document.querySelector(popupRootSelector);

  const elements = Children.toArray(children);

  // This pattern mimicks how kendo distinguishes between the popup body and footer (DialogActionsBar) elements.
  const PopupBody = getPopupBody(elements);
  const PopupFooterActionsBar = getFooterActionBar(elements);

  const viewportSize = useViewportSize();
  const { width, height } = useMemo(
    () => toPopupSizes(size, viewportSize),
    [size, viewportSize]
  );

  return (
    <Dialog
      ref={(ref) => {
        ref?.element?.setAttribute('data-element-name', dataElementName);
      }}
      closeIcon={closeIcon}
      title={
        <Header>
          <HeaderComponent title={title} />
        </Header>
      }
      width={width}
      height={height}
      className={toClassName(size, className)}
      contentStyle={{ display: contentDisplayType, ...contentStyle }}
      onClose={onClose}
      autoFocus={true}
      appendTo={popupRoot}
    >
      {PopupBody}
      {PopupFooterActionsBar ? (
        <DialogActionsBar>{PopupFooterActionsBar}</DialogActionsBar>
      ) : null}
      {FooterComponent && (
        <DialogActionsBar>
          <Footer style={footerStyle}>
            <FooterComponent />
          </Footer>
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

Popup.propTypes = {
  dataElementName: PropTypes.string,
  children: PropTypes.node.isRequired,
  FooterComponent: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.oneOf([
    'auto',
    'small',
    'medium',
    'large',
    'x-large',
    'stretch',
  ]),
  className: PropTypes.string,
  contentDisplayType: PropTypes.oneOf(['block', 'grid', 'flex']),
  contentStyle: PropTypes.object,
  HeaderComponent: PropTypes.func,
  onClose: PropTypes.func,
  footerStyle: PropTypes.object,
  popupRootSelector: PropTypes.string,
  closeIcon: PropTypes.bool,
};

/**
 * @typedef PopupFooterOptions
 * @type {object}
 * @property {import('../../components/Button').ButtonProps['onClick']=} [onConfirm]
 * @property {import('../../components/Button').ButtonProps['onClick']=} [onClose]
 * @property {boolean} [disableConfirm]
 * @property {boolean} [disableClose]
 * @property {string} [confirmLabelKey]
 * @property {string} [closeLabelKey]
 */

/**
 * @param {PopupFooterOptions} options
 */
export const toDefaultPopupFooter = (options) => {
  const {
    onConfirm,
    onClose,
    disableConfirm,
    disableClose,
    confirmLabelKey = 'Save',
    closeLabelKey = 'Close',
  } = options;
  return () => {
    const labels = useXeLabels();
    return (
      <div data-element-name="defaultPopupFooter__buttonGroup">
        {onClose && (
          <Button
            dataElementName="close"
            className="xe-popup__button"
            onClick={onClose}
            disabled={disableClose}
            look="outline"
            primary
          >
            {labels[closeLabelKey] || ''}
          </Button>
        )}
        {onConfirm && (
          <Button
            dataElementName="confirm"
            className="xe-popup__button"
            look="outline"
            primary
            onClick={onConfirm}
            disabled={disableConfirm}
          >
            {labels[confirmLabelKey] || ''}
          </Button>
        )}
      </div>
    );
  };
};
