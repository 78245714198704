export const toFormatDocType =
  (labels = {}) =>
  (docType) => {
    if (!docType) return undefined;

    const { text, item = {} } = docType;

    const { IsIPIDRequired = '' } = item;
    if (!text) return undefined;

    const root = IsIPIDRequired ? `${labels.Patient} - ` : '';
    return `${root}${text}`;
  };
