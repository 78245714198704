import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { pluck } from '../../fp/object';
import { castSchema } from '../../schema/schemaCaster';
import { schemaPluck } from '../../schema/schemaTypeBuilder';
import { toVerbActionReducer } from '../../schema/JSONSchemaReducer';
import MedicalDirectorSigneeSchema from 'services/schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json';
import VisitCorrespondenceDetailSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';
import VisitCorrespondenceEnterSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter.json';
import {
  AUTO_SELECT_LETTER_TEMPLATE,
  DID_GET_ATTACHMENTS,
  DID_GET_CLINDOCS,
  DID_GET_CLINDOC_REF_DATA,
  DID_GET_REPORTS,
  DID_MARK_IN_ERROR_PREVIEW,
  DID_QUICKSAVE_LETTER,
  DISPLAY_LETTER_PREVIEW,
  HIDE_LETTER_PREVIEW,
  RESPONSE_CUSTOM_LANGUAGES,
  RESPONSE_QUERY_BOOK_AS_INSTANCE,
  RESPONSE_QUERY_DEFAULT_SPEC,
  SET_AVAILABLE_STEPS,
  SET_COVER_SHEET_DATA,
  SET_CURRENT_STEP,
  SET_IS_SEARCH_FOR_ALL_PROGRAMS,
  SET_LETTER_DETAILS,
  SET_LETTER_TEMPLATES,
  SET_LOB,
  SET_MEDICAL_DIRECTORS,
  SET_PRE_POPUP,
  SET_SELECTED_CUSTOM_LANGUAGE,
  SET_SELECTED_FAX_COVER,
  SET_SELECTED_LETTER_TEMPLATE,
  SET_SELECTED_MAIL_COVER,
  SET_SELECTED_COVER_SHEETS,
  SET_SUBTYPE,
  SET_WIZARD_MODE,
  SHOW_WIZARD,
  UPDATE_ATTACHMENTS_VIEW_STATE,
  UPDATE_SELECTED_SIGNEE,
  SET_INITIAL_LETTER,
} from './actions';
import {
  AD_HOC_SUBTYPE,
  AUTH_PRE_POPUP_KEY,
  SUBTYPE_FAX,
  SUBTYPE_MAIL,
} from './constants';
import { pipe } from '../../fp/fp';
import { transform } from '../../fp/transformation';
import { isObjectLike } from '../../fp/pred';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

export const SHOW_WIZARD_KEY = 'showLetterWizard';

const toMedicalDirectorSchema = castSchema(MedicalDirectorSigneeSchema);

export const DEFAULT_STATE = {
  visitCorrespondenceEnter: {},
  subType: AD_HOC_SUBTYPE,
  initialLetter: {},
};

const verbActionReducer = toVerbActionReducer({});

export default [
  when((state) => state === undefined)(() => {
    return DEFAULT_STATE;
  }),
  when(() => true)(verbActionReducer),
  when(
    isType(
      RESPONSE_QUERY_DEFAULT_SPEC,
      DID_QUICKSAVE_LETTER,
      SET_LETTER_DETAILS
    )
  )((state = {}, action) => {
    const { value } = action;
    if (!value) return state;

    const notifications = {
      DecisionDate: value?.DecisionDate,
      DecisionDueDate: value?.DecisionDueDate,
      NotifyMemberDueDate: value?.NotifyMemberDueDate,
      NotifyMemberLetterDate: value?.NotifyMemberLetterDate,
      NotifyProviderDueDate: value?.NotifyProviderDueDate,
      NotifyProviderLetterDate: value?.NotifyProviderLetterDate,
    };
    const initialLetter = pipe(
      transform('SignerID')((letter) => {
        const staffID = isObjectLike(letter) ? letter.StaffID : letter;
        return staffID;
      }),
      castSchema(VisitCorrespondenceEnterSchema)
    )(value);
    return {
      ...state,
      initialLetter,
      notifications,
    };
  }),
  when(isType(SET_PRE_POPUP))(toActionValueReducer(AUTH_PRE_POPUP_KEY)),
  when(isType(SET_SELECTED_LETTER_TEMPLATE, AUTO_SELECT_LETTER_TEMPLATE))(
    toActionValueReducer('selectedLetterTemplate')
  ),
  when(isType(SET_SELECTED_COVER_SHEETS))((state, { value }) => ({
    ...state,
    ...value,
  })),
  when(isType(SET_SELECTED_FAX_COVER))(
    toActionValueReducer('selectedFaxCover')
  ),
  when(isType(SET_SELECTED_MAIL_COVER))(
    toActionValueReducer('selectedMailCover')
  ),
  when(isType(SET_LOB))(toActionValueReducer('lineOfBusiness')),
  when(isType(SET_SUBTYPE))((state, action) => {
    const { value } = action;

    return {
      ...state,
      subType: value,
    };
  }),
  when(isType(SET_IS_SEARCH_FOR_ALL_PROGRAMS))(
    toActionValueReducer('isSearchForAllPrograms')
  ),

  when(isType(SET_MEDICAL_DIRECTORS))((state, action) => {
    const { value: medDirectorsArray } = action;

    const newMedicalDirectors = medDirectorsArray.map((item) =>
      toMedicalDirectorSchema(item)
    );

    return {
      ...state,
      medicalDirectors: newMedicalDirectors,
    };
  }),
  when(isType(SET_CURRENT_STEP))(
    toActionValueReducer('currentStep'),
    (state, action) => {
      const { value } = action;
      const { availableSteps = EMPTY_ARRAY } = state;
      return {
        ...state,
        availableSteps: [
          ...availableSteps.slice(0, value),
          true,
          ...availableSteps.slice(value + 1),
        ],
      };
    }
  ),
  when(isType(RESPONSE_QUERY_DEFAULT_SPEC))(
    toActionValueReducer('letterTemplateSpec'),
    toActionValueReducer(
      'attachmentsViewState',
      schemaPluck(VisitCorrespondenceDetailSchema, 'XeVisitCorrespAttach')
    ),
    (state, action) => {
      const xeCustomLanguage = pluck('value', 'XeCustomLanguage')(action);

      // Use the length of XeCustomLanguage to decide if a refetch is
      // necessary on the DataEntry page when there is no required smartbook
      if (!xeCustomLanguage || xeCustomLanguage.length === 0) {
        return {
          ...state,
          selectedCustomLanguage: EMPTY_OBJECT,
          hasNoCustomLanguage: true,
        };
      }
      if (xeCustomLanguage.length === 1) {
        const [language] = xeCustomLanguage;
        return {
          ...state,
          selectedCustomLanguage: language,
          hasNoCustomLanguage: false,
        };
      }

      return { ...state, hasNoCustomLanguage: false };
    }
  ),
  when(isType(SHOW_WIZARD))(toActionValueReducer(SHOW_WIZARD_KEY, () => true)),
  when(isType(SET_SELECTED_CUSTOM_LANGUAGE))(
    toActionValueReducer('selectedCustomLanguage')
  ),
  when(isType(RESPONSE_QUERY_BOOK_AS_INSTANCE))(
    toActionValueReducer('selectedCustomLanguageInstance')
  ),
  when(isType(SET_WIZARD_MODE))(toActionValueReducer('isEditMode')),
  when(isType(SET_AVAILABLE_STEPS))(toActionValueReducer('availableSteps')),
  when(isType(SET_LETTER_DETAILS, DID_QUICKSAVE_LETTER))(
    (state = {}, action = {}) => {
      const { value } = action;
      const { isEditMode } = state;

      if (!value) {
        return state;
      }

      const { selectedLetterTemplate = {} } = state;

      // TODO: (SYNUI-5483) A lot of this can probably be cleaned up with the updates to use the json reducer (JDM)
      const letterStatus = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'LetterStatus',
        'StatusID'
      )(value);
      const subType = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'XeCorrespondenceInfo',
        'SubType'
      )(value);
      const letterType = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'XeCorrespondenceInfo',
        'LetterType'
      )(value);
      const CorrespondenceInfoID = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'CorrespondenceInfoID'
      )(value);

      const XeCorrespondenceInfo = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'XeCorrespondenceInfo'
      )(value);

      const ComplianceDate = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'ComplianceDate'
      )(value);

      const letterTemplateSpec = {
        XeCorrespondenceInfo,
        ComplianceDate,
      };
      const selectedSignee = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'SignerID'
      )(value);
      const selectedFaxCoverName = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'FaxCoverName'
      )(value);
      const selectedMailCoverName = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'MailCoverName'
      )(value);
      const selectedCustomLanguage = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'CustomLanguageID'
      )(value);

      const attachmentsViewState = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'XeVisitCorrespAttach'
      )(value);

      const visitCorrespondenceId = schemaPluck(
        VisitCorrespondenceDetailSchema,
        'VisitCorrespondenceID'
      )(value);

      const result = {
        letterStatus,
        subType,
        letterType,
        selectedLetterTemplate: {
          ...selectedLetterTemplate,
          CorrespondenceInfoID,
        },
        letterTemplateSpec,
        selectedSignee,
        selectedFaxCover: { Name: selectedFaxCoverName },
        selectedMailCover: { Name: selectedMailCoverName },
        selectedCustomLanguage,
        attachmentsViewState,
      };

      if (!isEditMode) {
        return {
          ...state,
          ...result,
          previewVisitCorrespondenceId: visitCorrespondenceId,
        };
      }

      return {
        ...state,
        ...result,
      };
    }
  ),
  when(isType(DID_MARK_IN_ERROR_PREVIEW))((state, action) => {
    return {
      ...state,
      previewVisitCorrespondenceId: undefined,
    };
  }),
  when(isType(UPDATE_ATTACHMENTS_VIEW_STATE))(
    toActionValueReducer('attachmentsViewState')
  ),
  when(isType(RESPONSE_CUSTOM_LANGUAGES))((state, action) => {
    const { letterTemplateSpec, initialLetter } = state;

    const { value: { XeCustomLanguage = [] } = {} } = action;
    const selectedLangId = initialLetter?.CustomLanguageID;

    const selectedCustomLanguage = XeCustomLanguage.find(
      ({ CustomLanguageID }) => {
        return CustomLanguageID === selectedLangId;
      }
    );

    return {
      ...state,
      letterTemplateSpec: { ...letterTemplateSpec, XeCustomLanguage },
      selectedCustomLanguage,
    };
  }),
  when(isType(UPDATE_SELECTED_SIGNEE))(toActionValueReducer('selectedSignee')),
  when(isType(SET_LETTER_TEMPLATES))(toActionValueReducer('letterTemplates')),
  when(isType(SET_COVER_SHEET_DATA))((state, action) => {
    const { value = [] } = action;
    /**
     * @type {{ mailCoverSheets?: Array, faxCoverSheets?: Array }}
     */
    const coverSheets = value.reduce(
      (o, x) => {
        const subType = pluck('SubType')(x);
        if (subType === SUBTYPE_MAIL) {
          return {
            ...o,
            mailCoverSheets: [...o.mailCoverSheets, x],
          };
        }
        if (subType === SUBTYPE_FAX) {
          return {
            ...o,
            faxCoverSheets: [...o.faxCoverSheets, x],
          };
        }

        return o;
      },
      {
        mailCoverSheets: [],
        faxCoverSheets: [],
      }
    );

    return {
      ...state,
      ...coverSheets,
    };
  }),
  when(isType(DID_GET_CLINDOC_REF_DATA))(
    toActionValueReducer('clinDocRefData')
  ),
  when(isType(DID_GET_CLINDOCS))(toActionValueReducer('clinDocs')),
  when(isType(DID_GET_REPORTS))(toActionValueReducer('reports')),
  when(isType(DID_GET_ATTACHMENTS))(toActionValueReducer('attachmentSets')),
  when(isType(DISPLAY_LETTER_PREVIEW))(
    toActionValueReducer('currentLetterPreview')
  ),
  when(isType(HIDE_LETTER_PREVIEW))(
    toActionValueReducer('currentLetterPreview', () => undefined)
  ),
  when(isType(SET_INITIAL_LETTER))((state) => {
    //by resetting this initialLetter, we force the call to pull down a fresh
    //smartbook when we select a new custom language.
    const initialLetter = state?.initialLetter;

    return {
      ...state,
      initialLetter: { ...initialLetter, BookID: undefined },
    };
  }),
];
