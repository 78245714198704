import { useEffect, useState } from 'react';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';
import { useXeQuery } from '../../../../data/useXeQuery';
import {
  EMPTY_OBJECT,
  EMPTY_ARRAY,
} from '../../../../../../xnetjs/src/constants';
import { pluck } from '../../../../../../xnetjs/src/fp/object';
import {
  Flexbox,
  HeaderLabel,
  IconButton,
  Label,
  Panel,
  TextInput,
  TextArea,
  TogglableList,
} from '../../../../components';
import { useSchema } from '../../../../schema/SchemaReducer';
import { SEARCH, CLEAR, DUPLICATE } from '../../../..//icons';
import { toComparatorFn } from '../utils';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { getCustomLanguage } from '../../../../../../services/src/correspondence-infos/xe-correspondence-infos-svc.js';
import { queryBookAsInstance } from '../../../../../../services/src/smart-books/xe-smart-books-svc.js';
import { Splitter } from '@progress/kendo-react-layout';
import './style.css';
import { identity } from '../../../../../src/fp/fp';
import { baseNodeValidator } from '../../validators';

const toCustomLanguageID = pluck('CustomLanguageID');

const CORRESPONDENCE = 'correspondence';
const RESULT_VALUE = 'ResultValue';
const SELECT_TRANSLATION_LABEL = 'SelectTranslationLabel';

const CUSTOM_LANGUAGE_RIGHT_PANE = {
  size: '30%',
  min: '30%',
};

export const CustomLanguage = (props) => {
  const {
    node,
    dataPath = primarySchemaKeys.CustomLanguage,
    onValidated,
    childrenContext: { siblings } = EMPTY_ARRAY,
    readOnly,
    rootNode: {
      IPID: { IPID = undefined },
      IVID: { IVID = undefined },
    } = EMPTY_OBJECT,
  } = props;

  const { onValueChange: onResultValueChange, fullPath } =
    useSchema(RESULT_VALUE);
  const { onValueChange: onResultIDValueChange } = useSchema(dataPath);

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { IsRequired, IsVisible } = node;

  const { onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const labels = useXeLabels();

  const CorrespondenceInfoID = siblings?.find(
    (sibling) => sibling.AttrType === CORRESPONDENCE
  )?.ResultValueID;

  const [filter, setFilter] = useState('');
  const [filteredCustomLanguage, setFilteredCustomLanguages] =
    useState(EMPTY_ARRAY);
  const [registerLanguage, setRegisterLanguage] = useState(undefined);
  const [customLangCode, setCustomLangCode] = useState('');
  const [panes, setPanes] = useState([
    EMPTY_OBJECT,
    CUSTOM_LANGUAGE_RIGHT_PANE,
  ]);
  const onPanesChange = ({ newState } = EMPTY_OBJECT) => {
    setPanes(newState);
  };
  const [SavedCorrespondenceInfoID, setSavedCorrespondenceInfoID] =
    useState(CorrespondenceInfoID);

  const { data: customLanguages = EMPTY_ARRAY } = useXeQuery(
    getCustomLanguage(
      {
        correspondenceInfoId: CorrespondenceInfoID,
      },
      identity
    ),
    { enabled: !!IsVisible && !!CorrespondenceInfoID }
  );

  const doesfilteredXeCustomLanguageExist =
    !!filteredCustomLanguage?.XeCustomLanguage && !!CorrespondenceInfoID;

  const XeCustomLanguages = doesfilteredXeCustomLanguageExist
    ? filteredCustomLanguage?.XeCustomLanguage
    : EMPTY_ARRAY;

  const shouldAutoSelectSavedCustomLanguage =
    readOnly || SavedCorrespondenceInfoID == CorrespondenceInfoID;

  const autoSelectCustomLanguage =
    doesfilteredXeCustomLanguageExist && shouldAutoSelectSavedCustomLanguage
      ? customLanguages?.XeCustomLanguage?.find(
          (customLang) => customLang?.CustomLanguageID == node?.ResultValueID
        )
      : undefined;

  // since the user cannot select a XeCustomLanguages when CustomLanguage is in a readOnly state
  // when viewing the completed document or when continuing a "Save and Close" document, we need to
  // pre-populate the selectedLanguage
  // (SYNUI-8996)(SNET-538)(JCh)
  const selectedLanguage = autoSelectCustomLanguage || registerLanguage;

  const { XeSmartBook: { BookID = undefined } = EMPTY_OBJECT } =
    selectedLanguage || EMPTY_OBJECT;

  const { data: customLanguageInstanceDetails = EMPTY_ARRAY } = useXeQuery(
    queryBookAsInstance(
      {
        bookId: BookID,
        ipid: IPID,
        ivid: IVID,
      },
      identity
    ),
    { enabled: !!IsVisible && !!BookID }
  );

  const [instanceDetail] = customLanguageInstanceDetails;
  const selectedCustomLanguageDescription =
    instanceDetail?.XeSmartBookInstance?.find(
      (instance) => instance?.AttrType === SELECT_TRANSLATION_LABEL
    )?.Name;

  useEffect(() => {
    setFilteredCustomLanguages(customLanguages);
  }, [customLanguages]);

  useEffect(() => {
    if (shouldAutoSelectSavedCustomLanguage) {
      setCustomLangCode(selectedLanguage?.CustomLanguageCode);
    }
  }, [selectedLanguage, shouldAutoSelectSavedCustomLanguage]);

  useEffect(() => {
    if (shouldAutoSelectSavedCustomLanguage) return;

    onResultValueChange(undefined);
    onResultIDValueChange(undefined);
    onValidChange(true);
    setRegisterLanguage(undefined);
    setCustomLangCode('');
    setFilter('');
    setSavedCorrespondenceInfoID(CorrespondenceInfoID);
  }, [
    CorrespondenceInfoID,
    onResultValueChange,
    onResultIDValueChange,
    onValidChange,
    shouldAutoSelectSavedCustomLanguage,
  ]);

  if (!IsVisible) return null;

  return (
    <Panel>
      {
        <Flexbox direction="column" className="flex-1">
          <div className="display-grid custom-language__grid">
            <HeaderLabel
              className="custom-language__section-header"
              required={IsRequired}
            >
              {labels.CustomLanguage}
            </HeaderLabel>
            <TextInput
              descriptor={labels.Content}
              value={filter}
              onChange={setFilter}
            />
            <IconButton
              icon={SEARCH}
              description={labels.SEARCH}
              onClick={() => {
                const filteredItems = customLanguages.XeCustomLanguage.filter(
                  (item = EMPTY_OBJECT) => {
                    const { Description = '', CustomLanguageCode = '' } = item;
                    return (
                      Description.toLowerCase().includes(
                        filter.toLowerCase()
                      ) ||
                      CustomLanguageCode.toLowerCase().includes(
                        filter.toLowerCase()
                      )
                    );
                  }
                );
                setFilteredCustomLanguages({
                  ...filteredCustomLanguage,
                  XeCustomLanguage: filteredItems,
                });
              }}
            />
            <IconButton
              icon={CLEAR}
              description={labels.CLEAR}
              onClick={() => {
                setFilteredCustomLanguages(customLanguages);
                setFilter('');
              }}
            />
          </div>
          <Splitter panes={panes} onChange={onPanesChange}>
            <div>
              <TogglableList
                className="custom-language__code-list"
                data={XeCustomLanguages}
                value={selectedLanguage}
                IsRequired={IsRequired}
                comparator={toComparatorFn(toCustomLanguageID)}
                keyFn={toCustomLanguageID}
                renderItem={({ item = EMPTY_OBJECT } = EMPTY_OBJECT) => {
                  const { Description, CustomLanguageCode } = item;
                  return (
                    <Flexbox justifyContent="space-between">
                      <Label descriptor={labels.Code}>
                        {CustomLanguageCode}
                      </Label>
                      <Label className="padding-horizontal-medium">
                        {Description}
                      </Label>
                    </Flexbox>
                  );
                }}
                onChange={({ item } = EMPTY_OBJECT) => {
                  if (readOnly) return;

                  const { CustomLanguageID, CustomLanguageCode, Description } =
                    item;

                  onResultValueChange(`${CustomLanguageCode} - ${Description}`);
                  onResultIDValueChange(CustomLanguageID);
                  onValidChange(
                    baseNodeValidator(true, CustomLanguageID, IsRequired)
                  );
                  setRegisterLanguage(item);
                  setCustomLangCode(CustomLanguageCode);
                }}
              />
            </div>
            {!!selectedLanguage && (
              <div>
                <Panel className="custom-language__right-pane-panel">
                  <HeaderLabel className="custom-language__section-header">
                    {'Letter Text'}
                  </HeaderLabel>

                  <div className="custom-language__code-grid">
                    <Label className="custom-language__code">
                      {customLangCode}
                    </Label>
                    <IconButton
                      icon={DUPLICATE}
                      className="custom-language__copy-button"
                      dataElementName="copy"
                      description={labels.Copy}
                      look="default"
                      onClick={() => {
                        const copyText = document.getElementById(
                          'custom-language__description'
                        );
                        copyText.select();
                        navigator.clipboard.writeText(copyText.value);
                      }}
                    />
                  </div>
                  <TextArea
                    id="custom-language__description"
                    className="custom-language__description"
                    resizeY={true}
                    value={selectedCustomLanguageDescription}
                  />
                </Panel>
              </div>
            )}
          </Splitter>
        </Flexbox>
      }
    </Panel>
  );
};

export default CustomLanguage;
