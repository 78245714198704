import { Checkbox } from '../../../components/Checkbox';
import { RadioButton } from '../../../components/RadioButton';
import { Flexbox } from '../../../components/Flexbox';

export const SelectableItem = (props) => {
  const { descriptor, checked, onSelect, multiple, dataElementName } = props;
  const InputComponent = multiple ? Checkbox : RadioButton;

  return (
    <Flexbox
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="selectable-item"
    >
      <InputComponent
        dataElementName={dataElementName}
        label={descriptor}
        onChange={onSelect}
        checked={checked}
      />
    </Flexbox>
  );
};

export default SelectableItem;
