import {
  TEMPLATE_ID_PLAN_OF_CARE_LITE,
  TEMPLATE_ID_TELEPHONIC_NOTE,
  TEMPLATE_ID_TREE_DOC,
} from './constants';
import { DefaultTemplate } from './DefaultTemplate';
import { TelephonicNote } from './TelephonicNote';
import { TreeDoc } from './TreeDoc';

const Templates = {
  default: TreeDoc,
  [TEMPLATE_ID_TREE_DOC]: TreeDoc,
  [TEMPLATE_ID_PLAN_OF_CARE_LITE]: TreeDoc,
  [TEMPLATE_ID_TELEPHONIC_NOTE]: TelephonicNote,
};

const templateProxyTraps = {
  get: (templates, templateId) => {
    if (typeof templateId !== 'string' || templateId === 'undefined') {
      return TreeDoc;
    }
    if (templateId in templates) {
      return templates[templateId];
    }

    return DefaultTemplate;
  },
};

const TemplatesProxy = new Proxy(Templates, templateProxyTraps);

export { TemplatesProxy as Templates };
