/*eslint-disable no-unused-vars*/
import { ReactElement } from 'react';
/*eslint-disable no-unused-vars*/
import { useSchema, useSchemaDispatch } from '../../../../schema/SchemaReducer';
import { Flexbox, MultiSelectDropDown } from '../../../../components';
import { useSmartBookNodeContext } from '../../hooks';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toNodePropertiesWithDefaults } from '../utils';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { toAttrDetail, toAttributesObject } from '../../utils';
import { pluck } from '../../../../fp/object';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { MERGE_PATCH } from '../../../../schema/JSONSchemaReducer';
import { baseNodeValidator } from '../../validators';
import { EMPTY_ARRAY } from '../../../../constants';
import './styles.css';
import { fromStrTrueFalseToBoolTrueFalse } from '../../../../utils';

const RESULT_VALUE_DELIMITER = ' ';
const RESULT_VALUE_ID_DELIMITER = '|';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

/**
 * Reduces selected values down to single object meant to merge with current node state
 * @param {Array} values
 * @returns {Object}
 */
const defaultValueFn = (values = EMPTY_ARRAY) => {
  const processedValues = values.reduce(
    (acc, curr) => {
      const { text, id } = curr;

      return {
        ResultValue: [...acc.ResultValue, text],
        ResultValueID: [...acc.ResultValueID, `${id}`],
      };
    },
    { ResultValue: EMPTY_ARRAY, ResultValueID: EMPTY_ARRAY }
  );

  return {
    ResultValue: processedValues.ResultValue.join(RESULT_VALUE_DELIMITER),
    ResultValueID: processedValues.ResultValueID.join(
      RESULT_VALUE_ID_DELIMITER
    ),
  };
};

/**
 *
 * @param {String | undefined} val
 * @returns {Array}
 */
const toArrFromPipeDelimString = (val) => {
  // Not defaulting to empty string as that would split into [''], not an empty array. (MM)
  if (typeof val === 'string') {
    return val.split(RESULT_VALUE_ID_DELIMITER);
  }
  return EMPTY_ARRAY;
};

/**
 *
 * @param {String} selections
 * @param {Array} refData
 * @returns {Array}
 */
const toFilteredSelections = (selections, refData) => {
  const selectionsArr = toArrFromPipeDelimString(selections);

  const matchedSelections = selectionsArr.map((selection) => {
    return refData.find((dataItem) => dataItem.id === selection);
  });

  return matchedSelections;
};

/**
 *
 * @param {Object} [props]
 * @param {ReactElement} [props.component]
 * @param {String} [props.dataPath]
 * @param {Object} [props.node]
 * @param {Function} [props.onValidated]
 * @param {Boolean} [props.readOnly]
 * @param {String} [props.refDataId]
 * @param {Function} [props.valueFn]
 * @returns {ReactElement}
 */
const MultiSelectDropDownWithRefData = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    dataPath = primarySchemaKeys.MultiSelectDropdown,
    node,
    onValidated,
    readOnly,
    refDataId: propsRefDataId,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, IsRequired, IsVisible, Name } = node;

  const attrDetailObject = toAttributesObject(AttrDetail);
  const { required: refDataId = propsRefDataId, Active } = attrDetailObject;
  const booleanActive = fromStrTrueFalseToBoolTrueFalse(Active);

  const { [refDataId]: RefData } = useXeRefData();

  const { value, validityMessage, fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const schemaDispatch = useSchemaDispatch();

  if (!IsVisible) return null;

  const invalid = !valid || !!validityMessage;

  const filteredSelections = toFilteredSelections(value, RefData);

  return (
    <>
      <WrappingComponent>
        <MultiSelectDropDown
          data={RefData}
          dataElementName={Name}
          descriptor={Name}
          descriptorClassName={`${withClassNameModifiers(
            'smartbook-descriptor',
            {
              invalid,
            }
          )} margin-right-small`}
          className="smartbook-multiselect"
          disabled={readOnly}
          labelFn={pluck('text')}
          onChange={(value) => {
            const stringVal = valueFn(value);

            schemaDispatch({
              type: MERGE_PATCH,
              value: stringVal,
            });

            onValidChange(
              baseNodeValidator(
                true,
                stringVal[primarySchemaKeys.MultiSelectDropdown],
                IsRequired
              )
            );
          }}
          required={IsRequired}
          value={filteredSelections}
          useActiveFilter={booleanActive}
        />
      </WrappingComponent>
    </>
  );
};

/**
 *
 * @param {Object} [props]
 * @param {ReactElement} [props.component]
 * @param {String} [props.dataPath]
 * @param {Object} [props.node]
 * @param {Function} [props.onValidated]
 * @param {Boolean} [props.readOnly]
 * @param {Function} [props.valueFn]
 * @returns {ReactElement}
 */
const MultiSelectDropDownWithInstanceData = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    dataPath = primarySchemaKeys.MultiSelectDropdown,
    node,
    onValidated,
    readOnly,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { IsRequired, IsVisible, Name, XeSmartBookInstance } = node;

  const { value, validityMessage, fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const schemaDispatch = useSchemaDispatch();

  if (!IsVisible) return null;

  const invalid = !valid || !!validityMessage;

  const filteredSelections = toFilteredSelections(value, XeSmartBookInstance);

  return (
    <>
      <WrappingComponent>
        <MultiSelectDropDown
          data={XeSmartBookInstance}
          dataElementName={Name}
          descriptor={Name}
          descriptorClassName={`${withClassNameModifiers(
            'smartbook-descriptor',
            {
              invalid,
            }
          )} margin-right-small`}
          disabled={readOnly}
          labelFn={pluck('text')}
          onChange={(value) => {
            const stringVal = valueFn(value);

            schemaDispatch({
              type: MERGE_PATCH,
              value: stringVal,
            });

            onValidChange(
              baseNodeValidator(
                true,
                stringVal[primarySchemaKeys.MultiSelectDropdown],
                IsRequired
              )
            );
          }}
          required={IsRequired}
          value={filteredSelections}
        />
      </WrappingComponent>
    </>
  );
};

export const MultiSelectDropdown = (props) => {
  const { node } = props;
  const { required: refDataSource } = toAttributesObject(toAttrDetail(node));

  if (refDataSource === 'XeSmartBookInstance') {
    return <MultiSelectDropDownWithInstanceData {...props} />;
  }

  return <MultiSelectDropDownWithRefData {...props} />;
};
