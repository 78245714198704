import { EMPTY_ARRAY } from '../../../constants';
import { SchemaReducer } from '../../../schema/SchemaReducer';
import { SmartBookInstance } from '../components/SmartBookInstance';

/**
 * Derives the index where the BookID to display lives within XeSmartBookInstance
 * @param {any} rootNode
 * @param {number} bookId
 * @param {string} dataKey
 * @returns {number}
 */
const toBookInstanceIndexFromBookId = (rootNode, bookId) => {
  const { UISetID, XeSmartBookInstance = EMPTY_ARRAY } = rootNode;

  // If we don't have the UISetID specifying the individual tabs for a document,
  // we will assume that the rootNode is already the thing that we want to be displayed
  if (!UISetID) return -1;

  return XeSmartBookInstance.findIndex(({ BookID }) => BookID === bookId);
};

export const TreeDoc = (props) => {
  const { bookId, ...rest } = props;
  const { schema, rootNode } = rest;

  const bookInstanceIndex = toBookInstanceIndexFromBookId(rootNode, bookId);

  if (bookInstanceIndex !== -1) {
    const dataPath = `XeSmartBookInstance.${bookInstanceIndex}`;
    const { XeSmartBookInstance } = rootNode;
    return (
      <SchemaReducer schema={schema} dataPath={dataPath}>
        <SmartBookInstance
          {...rest}
          instance={XeSmartBookInstance[bookInstanceIndex]}
          fullPath={dataPath}
        />
      </SchemaReducer>
    );
  }

  return <SmartBookInstance {...rest} instance={rootNode} />;
};
