import { pluck } from '../../fp/object';
import { identity } from '../../fp/fp';
import { exists } from '../../fp/pred';
import { AD_HOC_SUBTYPE } from './constants';
import { localeFormat } from '../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { Interval } from 'luxon';
import { isoStrAsLuxon } from '../../g11n/ISODates';
import { DateTime } from 'luxon';
const isWorkListLetterType = (t) => t === 'ISSUE' || t === 'AUTH';

export const toSubType = (subType, letterType) => {
  if (!letterType) return subType;
  if (isWorkListLetterType(letterType) && !subType) return AD_HOC_SUBTYPE;
  return subType;
};

export const toShouldSkipDataEntry = (spec) => {
  const customLanguageBookId = pluck(
    'XeCustomLanguage',
    0,
    'XeSmartBook',
    'BookID'
  )(spec);
  const faxCoverBookId = pluck('FaxCoverBookID', 'BookID')(spec);
  const mailCoverBookId = pluck('MailCoverBookID', 'BookID')(spec);

  return !(customLanguageBookId || faxCoverBookId || mailCoverBookId);
};

export const toComparatorFn =
  (toValue = identity) =>
  (a, b) => {
    const valA = toValue(a);
    const valB = toValue(b);
    return exists(valA) && exists(valB) && valA === valB;
  };

// Added date format utils. SNETEXT-341(MF)
export const dateTimeWithSecondsFormatter = (dateTime) =>
  toDisplayDateFromISOString(dateTime, localeFormat.LONG_WITH_SECONDS);

export const formatDate = (date) => dateTimeWithSecondsFormatter(date) || '-';

export const calculateOffset = (date1, date2) => {
  const daysOffset = Math.trunc(
    Interval.fromDateTimes(date1, date2).length('days')
  );
  const hoursOffset = Math.trunc(
    Interval.fromDateTimes(date1, date2).length('hours')
  );

  const offsetSign = date1 !== date2 ? '-' : null;
  return !!daysOffset
    ? `${offsetSign}${daysOffset}d`
    : `${offsetSign}${hoursOffset}h`;
};

export const buildDueDateWithOffset = (dueDate, hasActualDate) => {
  if (!dueDate)
    return {
      date: `-`,
      offset: false,
    };

  const currentDate = DateTime.now();
  const luxonDueDate = isoStrAsLuxon(dueDate);

  if (hasActualDate || currentDate < luxonDueDate) {
    return { date: formatDate(dueDate), offset: false };
  }
  return {
    date: `${formatDate(dueDate)} (${calculateOffset(
      luxonDueDate,
      currentDate
    )})`,
    offset: true,
  };
};

export const buildActualDateWithOffset = (dueDate, actualDate) => {
  if (!actualDate)
    return {
      date: `-`,
      offset: false,
    };

  const luxonActualDate = isoStrAsLuxon(actualDate);
  const luxonDueDate = !!dueDate ? isoStrAsLuxon(dueDate) : null;

  if (!dueDate || (luxonDueDate && luxonActualDate < luxonDueDate)) {
    return { date: formatDate(actualDate), offset: false };
  }
  return {
    date: `${formatDate(actualDate)} (${calculateOffset(
      luxonDueDate,
      luxonActualDate
    )})`,
    offset: true,
  };
};
