import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useSchemaSelector } from '../../schema/SchemaReducer';
import { isSectionLike } from './utils';
import { DefaultNodeValidators } from './validators';

export const SmartBookNodeContext = createContext({
  isValidNode: () => true,
  onValidChange: NOOP_FUNCTION,
});

/**
 * Performs initial validation for a node at a given path and returns an object representing information specific
 * to that node (Note: Currently only returns the component's validity and a handler to update the validity)
 * @param {*} node
 * @param {string} dataPath
 * @param {string} fullPath
 * @param {(isValid: boolean) => void} onValidated
 */
export const useSmartBookNodeContext = (
  node,
  dataPath,
  fullPath,
  onValidated
) => {
  useSetInitialSmartBookNodeValidity(node, dataPath, fullPath, onValidated);

  const { isValidNode } = useContext(SmartBookNodeContext);

  const { XeSmartBookInstance = EMPTY_ARRAY } = node;

  const hasChildren = !!XeSmartBookInstance.length;
  const isSection = isSectionLike(node) && hasChildren;

  const memoizedOnValidChangeCallback = useCallback(
    (nextValid) => {
      onValidated(fullPath, nextValid, isSection);
    },
    [onValidated, fullPath, isSection]
  );

  return {
    valid: isValidNode(fullPath),
    onValidChange: memoizedOnValidChangeCallback,
  };
};

const useSetInitialSmartBookNodeValidity = (
  node,
  dataPath,
  fullPath,
  onValidated
) => {
  const {
    AttrType,
    IsVisible: initialIsVisible,
    IsRequired,
    XeSmartBookInstance = EMPTY_ARRAY,
  } = node;

  const hasChildren = !!XeSmartBookInstance.length;
  const isSection = isSectionLike(node) && hasChildren;

  const [IsVisible = initialIsVisible] = useSchemaSelector(
    (instance = EMPTY_OBJECT) => {
      const { IsVisible } = instance;
      return [IsVisible];
    }
  );

  const initialValidRef = useRef(
    DefaultNodeValidators[AttrType]
      ? DefaultNodeValidators[AttrType](true, node[dataPath], IsRequired, node)
      : true
  );

  useEffect(() => {
    if (!fullPath) {
      return;
    }
    if (IsVisible === false) {
      onValidated(fullPath, true, isSection);
    } else if (!initialValidRef.current) {
      onValidated(fullPath, initialValidRef.current, isSection);
    }
  }, [fullPath, onValidated, isSection, IsVisible]);
};
