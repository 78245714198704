import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import '@mdi/font/css/materialdesignicons.css';

export const Icon = forwardRef((props, ref) => {
  const {
    defaultIconColor = false,
    onClick,
    icon,
    className = '',
    color,
    dataElementName = '',
  } = props;
  console.assert(!onClick, 'onClick should not be provided to Icon directly');

  if (!icon) return null;

  const baseClassName = `mdi mdi-${icon} xjs-icon xjs-icon--${
    defaultIconColor ? 'default' : icon
  } ${className}`;

  return (
    <i
      data-element-name={dataElementName}
      ref={ref}
      className={baseClassName}
      style={color ? { color } : undefined}
    />
  );
});

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  defaultIconColor: PropTypes.bool,
  dataElementName: PropTypes.string,
};
