import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { Flexbox, TextArea } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toAttributesObject } from '../../utils';
import { textValidator } from '../../validators';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { toNodePropertiesWithDefaults, useSynchronousValue } from '../utils';
import { useCallback } from 'react';
import { EMPTY_OBJECT } from '../../../../constants';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox direction="column" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Text = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node = EMPTY_OBJECT,
    onValidated,
    dataPath = primarySchemaKeys.Text,
    resizeY = true,
    className,
    descriptorPosition,
    placeholder,
    rows = '5',
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);

  const {
    AttrDetail,
    Name,
    IsRequired: initialIsRequired,
    IsVisible: initialIsVisible,
    ResultMax,
  } = nodeWithDefaults;

  const [IsVisible = initialIsVisible, IsRequired = initialIsRequired] =
    useSchemaSelector(({ IsVisible, IsRequired } = {}) => {
      return [IsVisible, IsRequired];
    });

  const {
    value = '',
    validityMessage,
    onValueChange,
    schemaNode = EMPTY_OBJECT,
    fullPath,
  } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [syncValue, changeHandler] = useSynchronousValue(value, onValueChange);

  const onValidation = useCallback(
    (next) => {
      onValidChange(textValidator(next, value, IsRequired));
    },
    [onValidChange, value, IsRequired]
  );

  if (!IsVisible) return null;

  const { maxLength } = schemaNode;

  const attributes = toAttributesObject(AttrDetail);
  const { Rows = rows } = attributes;
  const invalid = !valid || !!validityMessage;

  const maxTextLength = ResultMax || maxLength;

  return (
    <WrappingComponent>
      <TextArea
        dataElementName={Name}
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        descriptorPosition={descriptorPosition}
        required={IsRequired}
        readOnly={readOnly}
        className={`fill-parent-width ${className}`}
        value={syncValue}
        onChange={(value) => {
          changeHandler(value);
        }}
        onValidation={onValidation}
        maxLength={maxTextLength}
        rows={parseInt(Rows)}
        resizeY={resizeY}
        placeholder={placeholder}
      />
    </WrappingComponent>
  );
};
