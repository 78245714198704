import { Button } from '../../Button';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
// const TooltipContent = (props) => {
//     return <div>Hello world!</div>
// }

export const ExpandButton = (props) => {
  const {
    AlertsCount,
    ExpandLabel,
    ContentFunc,
    notifications,
    DueLabel,
    ActualLabel,
    DecisionOverdueLabel,
    DecisionAfterDeadlineLabel,
  } = props;

  const Badge = (props) => {
    const { AlertsCount } = props;

    return (
      <div className="notification-badge flex-item-margin-left">
        {AlertsCount}
      </div>
    );
  };

  return (
    <div className="notification-expand-button-container">
      <Tippy
        content={ContentFunc(
          notifications,
          DueLabel,
          ActualLabel,
          DecisionOverdueLabel,
          DecisionAfterDeadlineLabel
        )}
        offset={[0, 5]}
        zIndex={200000}
        placement="bottom-end"
        theme="light"
        maxWidth="none"
        interactive={true}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                rootBoundary: 'document',
              },
            },
          ],
        }}
      >
        <Button
          dataElementName="DecisionExpand"
          className="xe-popup__button"
          look="outline"
        >
          {ExpandLabel}
          <Badge AlertsCount={AlertsCount} />
        </Button>
      </Tippy>
    </div>
  );
};
