import { pluck } from '../../../fp/object';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { formatStaffName } from '../../../utils';
import { Label, Panel } from '../../';
import { localeFormat } from '../../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../../g11n/displayDates';
import { DecisionDisplay } from './DecisionDisplay';

export const WizardHeader = (props) => {
  const {
    letter,
    xeCorrespondenceInfoName,
    selectedCustomLanguage,
    selectedSignee,
    selectedFax,
    selectedMail,
    complianceDate,
    notifications,
  } = props;
  const labels = useXeLabels();

  const recipients = (pluck('XeVisitCorrespRecip')(letter) || [])
    .map(pluck('Name'))
    .join(', ');

  return (
    <Panel className="flex-0">
      <div className="create-wizard__grid">
        {/* left panel */}
        <div className="flex-0 left-half-panel">
          <div className="flex-0">
            <Label
              dataElementName="letterWizard__letter"
              descriptor={labels.Letter}
            >
              {xeCorrespondenceInfoName}
            </Label>
            <Label
              descriptorClassName="flex-item-margin-left"
              dataElementName="letterWizard__faxCover"
              descriptor={labels.FaxCover}
            >
              {`${pluck('Name')(selectedFax) || labels.None}`}
            </Label>
          </div>
          <div className="flex-0">
            <Label
              dataElementName="letterWizard__letterText"
              className={
                complianceDate
                  ? 'letter-information__text_with_compliance'
                  : 'letter-information__text_without_compliance'
              }
              descriptor={labels.Text}
            >
              {`${pluck('Description')(selectedCustomLanguage) || ''}`}
            </Label>
          </div>
          {complianceDate && (
            <div className="flex-0">
              <Label
                dataElementName="letterWizard__compliance"
                descriptor={labels.Compliance}
              >
                {toDisplayDateFromISOString(complianceDate, localeFormat.LONG)}
              </Label>
            </div>
          )}
          <div className="flex-0">
            <Label
              dataElementName="letterWizard__recipients"
              className="letter-information__recipients"
              descriptor={labels.Recipients}
            ></Label>
            {recipients}
          </div>
          <div className="flex-0">
            <Label
              dataElementName="letterWizard__signer"
              className="letter-information__signer"
              descriptor={labels.Signer}
            >
              {formatStaffName(selectedSignee)}
            </Label>
          </div>
        </div>
        {/* right panel */}
        <div className="flex-0">
          <div className="flex-0 align-right">
            <Label
              dataElementName="letterWizard__mailCover"
              descriptor={labels.MailCover}
            >
              {`${pluck('Name')(selectedMail) || labels.None}`}
            </Label>
          </div>

          <DecisionDisplay
            notifications={notifications}
            DecisionOverdueLabel={labels.DecisionOverdue}
            DecisionAfterDeadlineLabel={labels.DecisionAfterDeadline}
            ExpandLabel={labels.Expand}
            DueLabel={labels.NotificationDue}
            ActualLabel={labels.NotificationActual}
          />
        </div>
      </div>
    </Panel>
  );
};
