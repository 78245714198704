import PropTypes from 'prop-types';
import { MaskedTextBox } from '../MaskedTextBox';

const DEFAULT_KENDO_TELEPHONE_MASK = '(C00)C00-0000';

/**
 * Input to handle telephone inputs with a format masking and HTML validation.
 * @param {String} [mask="(000)000-0000"] Mask pattern. Masks `value` to match pattern. Passed through to Kendo input directly. Currently only supports "#" for values.
 */
export const TelephoneInput = (props) => {
  const { mask = DEFAULT_KENDO_TELEPHONE_MASK, ...passthroughProps } = props;

  return (
    <MaskedTextBox
      {...passthroughProps}
      mask={mask}
      rules={{
        C: /[2-9]/,
      }}
    />
  );
};

TelephoneInput.propTypes = {
  mask: PropTypes.string,
};
