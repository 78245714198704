import { useEffect, useState } from 'react';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import { Footer } from './components/Footer';

const BASE_CLASS_NAME = 'xe-popup';

export const useViewportSize = () => {
  const getViewportSize = () => {
    const { innerWidth: vw, innerHeight: vh } = window;
    return { width: vw, height: vh };
  };

  const [windowDimensions, setWindowDimensions] = useState(getViewportSize);

  useEffect(() => {
    const handleViewportResize = () => {
      setWindowDimensions(getViewportSize());
    };

    window.addEventListener('resize', handleViewportResize);
    return () => window.removeEventListener('resize', handleViewportResize);
  }, []);

  return windowDimensions;
};

const dialogWidthsForSize = {
  small: 332,
  medium: 704,
  large: 952,
  'x-large': 1440,
  stretch: true,
};

/* Based on 4:3 */
const scaleFactor = 0.75;

const MAX_WIDTH_MULTIIPLIER = 0.93;
const MAX_HEIGHT_MULTIPLIER = 0.93;

/**
 * Returns the dimensions to be used in a dialog component along with the initial position
 * @param {String} size
 * @param {{ width: Number, height: Number }} viewportSize
 * @returns {{ width: Number, height: Number, top: Number, left: Number }}
 */
export const toPopupSizes = (size, viewportSize) => {
  const { width: vw, height: vh } = viewportSize;
  const maxPossibleWidth = vw * MAX_WIDTH_MULTIIPLIER;
  const maxPossibleHeight = vh * MAX_HEIGHT_MULTIPLIER;
  const dialogWidth = dialogWidthsForSize[size];

  if (!dialogWidth) return {};

  if (size === 'stretch') {
    return {
      width: maxPossibleWidth,
      height: maxPossibleHeight,
    };
  }

  const defaultCalculatedSizes = {
    width: dialogWidth,
    height: dialogWidth * scaleFactor,
  };

  const width = Math.min(defaultCalculatedSizes.width, maxPossibleWidth);
  const height = Math.min(
    defaultCalculatedSizes.width * scaleFactor,
    maxPossibleHeight
  );

  return {
    width: width,
    height: height,
    left: (vw - width) / 2,
    top: (vh - height) / 2,
  };
};

export const toClassName = (size, propsClassName) => {
  return `${withClassNameModifiers(BASE_CLASS_NAME, size)} ${propsClassName}`;
};

export const getPopupBody = (children) => {
  return children.filter((child) => child && child.type !== Footer);
};

export const getFooterActionBar = (children) => {
  return children.find((child) => child && child.type === Footer);
};
