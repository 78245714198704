import { IconButton } from '../../../../components';
import { INFORMATION } from '../../../../icons';
import { getDetails } from 'services/service-infos/xe-service-infos-svc';
import { useXeQuery } from '../../../../data/useXeQuery';
import { identity } from '../../../../fp/fp';
import { openUrlInNewTab } from '../../../../utils/navigation';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../constants';

export const InfoButton = (props) => {
  const { infoId } = props;

  const { data = EMPTY_ARRAY } = useXeQuery(getDetails({ infoId }, identity), {
    enabled: !!infoId,
  });

  const [{ Info, InfoLink } = EMPTY_OBJECT] = data;

  if (!infoId) return null;

  return (
    <IconButton
      tooltip={!InfoLink}
      description={Info}
      dataElementName="info"
      onClick={() => {
        if (InfoLink) {
          openUrlInNewTab(InfoLink);
        }
      }}
      icon={INFORMATION}
    />
  );
};
