import React from 'react';
import { phone } from 'phone';
import { useSchema } from '../../schema/SchemaReducer';
import { PHONE_OUTGOING } from '../../icons';
import { Icon } from '..';
import { NOOP_FUNCTION } from '../../constants';

// ts-check-start

/**
 * @typedef QuickCallButton
 * @property {string} [dataPath]
 * @property {string} [telephoneNumber]
 * @property {(ev: React.MouseEvent<HTMLAnchorElement>) => void} [onClick]
 * @property {import('react').ReactNode} children
 * @param {QuickCallButton} props
 * */

export const QuickCallButton = (props) => {
  const { telephoneNumber = '', dataPath, onClick = NOOP_FUNCTION } = props;

  const { value = '' } = useSchema(dataPath);

  //TODO we are presuming that all phone numbers are US numbers at the moment
  const phoneObj = dataPath ? phone(value) : phone(telephoneNumber);

  if (!value && !telephoneNumber) {
    return null;
  }

  if (phoneObj.isValid === false) {
    console.error(
      `Invalid phone number ${
        telephoneNumber || value
      } provided to QuickCallButton`
    );
  }

  const disabled = phoneObj.isValid === false;

  return (
    <a
      className={`k-button k-outline xe-button xjs-icon-button xjs-icon-button--${PHONE_OUTGOING} icon-button ${
        disabled ? 'k-state-disabled' : ''
      }`}
      onClick={(ev) => {
        onClick(ev);
        //per html spec if the onClick function returns true we continue with the href, this forces always true
        //so upstream doesn't need to know this
        return true;
      }}
      href={`tel: ${phoneObj.phoneNumber}`}
    >
      <Icon
        // forward ref issues
        // eslint-disable-next-line
        icon={PHONE_OUTGOING}
        className={disabled ? 'color-disabled' : ''}
      />
    </a>
  );
};

export default QuickCallButton;
