import { Flexbox } from '../../../../components';
import { NameLabel } from '../../components/NameLabel';
import './styles.css';
import { GridLayout } from '../../../../components/GridLayout';

export const Grid = (props) => {
  const { children, node } = props;
  const { Name, AttrDetail = '1|1', IsVisible } = node;

  const layoutStringArray = AttrDetail.split('|');
  const [cols, rows] = layoutStringArray.map(parseInt);

  if (!IsVisible) return null;

  return (
    <Flexbox direction="column" className="padding-all-small">
      <NameLabel dataElementName={Name}>{Name}</NameLabel>
      <GridLayout
        templateColumns={`repeat(${cols}, minmax(min-content,max-content))`}
        templateRows={`repeat(${rows}, minmax(min-content,max-content)))`}
      >
        {children}
      </GridLayout>
    </Flexbox>
  );
};
