import { useSchema } from '../../../schema/SchemaReducer';
import { pluck } from '../../../fp/object';
import { isNil } from '../../../fp/pred';
import { useEffect } from 'react';
import { Flexbox, Label, Panel, TogglableList } from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { formatStaffName } from '../../../utils';
import '../styles.css';
import { EMPTY_OBJECT } from '../../../constants';

const toStaffID = pluck('StaffID');

export const Signature = (props) => {
  const { medicalDirectors, onUpdateSignee } = props;

  const labels = useXeLabels();

  const { value, onValueChange, onValidationChange } = useSchema('SignerID');

  useEffect(() => {
    if (isNil(value)) {
      return onValidationChange(false);
    }
  }, [value, onValidationChange]);

  return (
    <Flexbox
      dataElementName="letterWizard__signature__flex_1"
      direction="column"
      className="flex-1"
    >
      <Label
        dataElementName="letterWizard__selectStaffForSign__label"
        className="signature__label flex-shrink-0"
      >
        {labels.SelectStaffForSign}
      </Label>
      <Panel
        dataElementName="letterWizard__signaturePanel"
        className="overflow-auto flex-1"
      >
        <TogglableList
          data={medicalDirectors}
          value={value}
          comparator={(item, value) => {
            return toStaffID(item) === value;
          }}
          keyFn={toStaffID}
          onChange={({ item } = {}) => {
            const staffId = toStaffID(item);
            onValueChange(staffId);
            onValidationChange(!isNil(staffId));
            onUpdateSignee(item);
          }}
          renderItem={({ item } = EMPTY_OBJECT) => {
            return <Label>{formatStaffName(item)}</Label>;
          }}
        />
      </Panel>
    </Flexbox>
  );
};

export default Signature;
