import { useState, useRef, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import NavBar from './Components/NavBar';
import { withClassNameModifiers } from '../../utils/withClassNameModifiers';
import IconButton from '../IconButton';
import { ADD, REMOVE, MULTI_PAGE, SPLIT, ROTATE_RIGHT } from '../../icons';
import './styles.css';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { Flexbox } from '../../components';
import { Popup, toDefaultPopupFooter } from '../Popup';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

const SCALE_STEP = 0.1;

const SELECT = 'select';
const DESELECT = 'deselect';
const SET_SELECTION = 'setSelection';
const CLEAR = 'clear';

const STARTING_ROTATION = 0;
const ENDING_ROTATION = 270;
const ROTATION_STEP = 90;

const pageReducer = (state, action) => {
  const { type, keys = [] } = action;

  switch (type) {
    case SELECT:
      return [...state, ...keys];
    case DESELECT:
      return state.filter((k) => !keys.includes(k));
    case SET_SELECTION:
      return keys;
    case CLEAR:
      return [];
  }

  return state;
};

// Returns an array of consecutive integers beginning at low and ending at high
const toFilledInterval = ([low, high]) => {
  return Array.from({ length: high - low + 1 }, (_, i) => low + i);
};

const toPageSplits = (selectedPages) => {
  const splits = selectedPages
    .slice()
    .sort()
    .reduce((acc, currPageNumber) => {
      const [currentSegment = {}] = acc.slice(-1);
      const { startPage = currPageNumber, endPage = currPageNumber } =
        currentSegment;

      // If we have a page more than a page away from the last segment, create a new segment
      if (currPageNumber - endPage > 1) {
        return [...acc, { startPage: currPageNumber, endPage: currPageNumber }];
      }

      // Otherwise update the endPage of the current segment
      return [...acc.slice(0, -1), { startPage, endPage: currPageNumber }];
    }, []);

  return splits;
};

const PageThumbnail = ({
  pageNumber,
  pageDispatch,
  selectedPages,
  width,
  disabledPages = EMPTY_ARRAY,
}) => {
  const disabled = disabledPages.includes(pageNumber);
  const selected = !disabled && selectedPages.includes(pageNumber);

  const onThumbnailClick = disabled
    ? NOOP_FUNCTION
    : (click = {}) => {
        const { shiftKey } = click;

        if (!shiftKey || selectedPages.length === 0) {
          pageDispatch({
            type: selected ? DESELECT : SELECT,
            keys: [pageNumber],
          });
        } else {
          // Shift + Click selection
          // Implementations seem to differ across applications where I tested this behavior for reference
          // Windows Explorer selects a range from the last clicked item to the current clicked item and deselects all other items
          const [lastSelected] = selectedPages.slice(-1);
          const selectionInterval = [pageNumber, lastSelected].sort();

          const pages = toFilledInterval(selectionInterval).filter(
            (e) => !disabledPages.includes(e)
          );

          pageDispatch({
            type: SET_SELECTION,
            keys: pages,
          });
        }
      };

  return (
    <div
      // ACCESSIBILITY: Either figure out how to make these page thumbnails a button, or add keyboard accessibility.
      key={pageNumber}
      className={`margin-all-small align-center ${withClassNameModifiers(
        'pdf-viewer__page',
        { selected, disabled }
      )}`}
      onClick={onThumbnailClick}
      role="button"
    >
      <Page pageNumber={pageNumber} width={width} />
      <span>{pageNumber}</span>
    </div>
  );
};

export const PDFViewer = (props) => {
  const {
    file,
    enableSelection = false,
    startingPage = 1,
    onLoadError = NOOP_FUNCTION,
    onLoadSuccess = NOOP_FUNCTION,
    onSplit = NOOP_FUNCTION,
    disabledPages,
  } = props;

  const refContainer = useRef();

  const [scale, setScale] = useState(1.0);
  const [rotation, setRotation] = useState(STARTING_ROTATION);

  const [viewPageSelection, setViewPageSelection] = useState(false);
  const [currentPage, updateCurrentPage] = useState(startingPage);
  const [totalPages, setTotalPages] = useState(undefined);
  const onLoad = (o) => {
    const { numPages } = o;
    setTotalPages(numPages);
    onLoadSuccess(o);
  };

  const width = refContainer.current && refContainer.current.clientWidth;

  const pageArray = Array.from(Array(totalPages).keys());
  const [selectedPages = [], pageDispatch] = useReducer(pageReducer, []);

  const labels = useXeLabels();

  useEffect(() => {
    updateCurrentPage(1);
  }, [file]);

  return (
    <Flexbox
      data-component-name="PDFViewer"
      direction="column"
      className="fill-parent"
    >
      <Flexbox justifyContent="center" className="flex-0 pdf-viewer__controls">
        {enableSelection && (
          <IconButton
            icon={MULTI_PAGE}
            description={labels.Selection}
            onClick={() => {
              setRotation(STARTING_ROTATION);
              setViewPageSelection(!viewPageSelection);
              pageDispatch({ type: CLEAR });
            }}
          />
        )}
        {viewPageSelection && (
          <IconButton
            icon={SPLIT}
            description={labels.Split}
            onClick={() => {
              onSplit(...toPageSplits(selectedPages));
              pageDispatch({ type: CLEAR });
            }}
          />
        )}
        {!viewPageSelection && (
          <>
            <IconButton
              icon={REMOVE}
              look="flat"
              dataElementName="PDFViewer__zoomOut"
              description={labels.ZoomOut}
              className="pdf-viewer__zoom-out"
              onClick={() => setScale(scale - SCALE_STEP)}
            />
            <IconButton
              icon={ADD}
              look="flat"
              dataElementName="PDFViewer__zoomIn"
              description={labels.ZoomIn}
              className="pdf-viewer__zoom-in"
              onClick={() => setScale(scale + SCALE_STEP)}
            />
            <IconButton
              icon={ROTATE_RIGHT}
              look="flat"
              dataElementName="PDFViewer__rotate"
              description={labels.Rotate}
              className="pdf-viewer__rotate"
              onClick={() =>
                setRotation(
                  rotation === ENDING_ROTATION
                    ? STARTING_ROTATION
                    : rotation + ROTATION_STEP
                )
              }
            />
          </>
        )}
      </Flexbox>
      <Document
        file={file}
        noData={labels.PleaseSelectPDF}
        onLoadSuccess={onLoad}
        onLoadError={onLoadError}
        error={labels.ProblemLoadingFileMessage}
        //TODO, this is a work around for proptype bug
        //https://github.com/wojtekmaj/react-pdf/issues/458
        inputRef={(r) => (refContainer.current = r)}
        className="flex-1 overflow-auto"
        rotate={rotation}
      >
        {viewPageSelection ? (
          <Flexbox direction="row" wrap>
            {pageArray.map((key) => {
              // Note that pages are 1-indexed to match the UI presentation and server API
              const pageNumber = key + 1;
              return (
                <PageThumbnail
                  key={pageNumber}
                  pageNumber={pageNumber}
                  pageDispatch={pageDispatch}
                  selectedPages={selectedPages}
                  disabledPages={disabledPages}
                  width={width / 2 - 20}
                />
              );
            })}
          </Flexbox>
        ) : (
          <Page pageNumber={currentPage} scale={scale} />
        )}
      </Document>
      {!viewPageSelection && (
        <NavBar
          currentPage={currentPage}
          totalPages={totalPages}
          onPageUpdate={updateCurrentPage}
        />
      )}
    </Flexbox>
  );
};

PDFViewer.propTypes = {
  enableSelection: PropTypes.bool,
  startingPage: PropTypes.number,
  onLoadError: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  onSplit: PropTypes.func,
};

export const PDFViewerPopup = (props) => {
  const { size = 'large', onClose = NOOP_FUNCTION } = props;

  return (
    <Popup
      size={size}
      FooterComponent={toDefaultPopupFooter({
        onClose,
      })}
    >
      <PDFViewer {...props} />
    </Popup>
  );
};

PDFViewerPopup.propTypes = {
  size: PropTypes.string,
  onClose: PropTypes.func,
  ...PDFViewer.propTypes,
};
