import { memo, useMemo } from 'react';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../constants';
import { isShallowEqual } from '../../../../fp/object';
import {
  SchemaReducer,
  useSchemaSelector,
} from '../../../../schema/SchemaReducer';
import AttrTypeRenders from '../../AttrTypes';
import '../../styles.css';
import { PARENT_CHILDREN_ATTRTYPES } from '../../constants';

const FallbackAttrTypeRender = (props) => {
  const { node, children } = props;
  const { AttrType } = node;

  if (!AttrType) {
    return children;
  }

  return (
    <div style={{ border: 'solid 1px red' }}>
      <span>Missing {AttrType}</span>
      {children}
    </div>
  );
};

export const SmartBookInstance = memo((props) => {
  const {
    instance: currentInstance = EMPTY_OBJECT,
    rootNode,
    schema,
    validityStyles,
    readOnly,
    onValidated,
    ipid,
    enterpriseId,
    siblings,
    parent,
  } = props;

  const { AttrType, AttrDetail, BookID, BookTypeID } = currentInstance;

  const [XeSmartBookInstance = EMPTY_ARRAY] = useSchemaSelector((instance) => {
    return [instance?.XeSmartBookInstance];
  });

  const AttrTypeRender = useMemo(
    () =>
      !BookID || BookTypeID === 'BOOK'
        ? ({ children }) => children
        : AttrTypeRenders[AttrType] || FallbackAttrTypeRender,
    [AttrType, BookID, BookTypeID]
  );

  // We are trying to find the root XeSmartBookInstance node to enable childrenContext between sibilings
  // of the same level. This was made specifically for "MD Medical Director Determination Form" but can be
  // used for other SmartBooks. We want to try and limit this use as this copies the entire SmartBook into
  // childrenContext and should only be used as a last case to access other node's data. SNET-874 (JCh)
  const [rootXeSmartbookInstance = EMPTY_ARRAY] = rootNode.XeSmartBookInstance;
  const isRootXeSmartBookInstance = rootXeSmartbookInstance?.BookID == BookID;

  // We are adding gates for 'AttrTypeRender' (parent node) and 'SmartBookInstance' (child node) so we are only
  // passing parent, children, and siblings datas to the AttrTypes we want share to the data amongs themselves
  // so it doesn't slow down smartbook too much. We can add more AttrTypes in 'isParentChildrenNodes' if the
  // future need more of these type of parent/children AttrTypes.
  // parent node: 'visitcorrespondence', children node: 'correspondence', 'customLang'
  // SYNUI-8920 (JCh)
  const isParentChildrenNodes = !!PARENT_CHILDREN_ATTRTYPES?.find(
    (type) => AttrType === type || isRootXeSmartBookInstance
  );

  const childrenContext = isParentChildrenNodes
    ? {
        parent,
        children: XeSmartBookInstance,
        siblings,
      }
    : undefined;

  return (
    <AttrTypeRender
      node={currentInstance}
      dataElementName={AttrDetail}
      rootNode={rootNode}
      readOnly={readOnly}
      onValidated={onValidated}
      ipid={ipid}
      enterpriseId={enterpriseId}
      childrenContext={childrenContext}
    >
      {XeSmartBookInstance.map((instance, index) => {
        const { AttrType: childAttrType, BookID } = instance;
        const instancePath = `XeSmartBookInstance.${index}`;

        return (
          <SchemaReducer
            schema={schema}
            key={`${BookID}_${childAttrType}_${index}`}
            dataPath={instancePath}
          >
            <SmartBookInstance
              schema={schema}
              instance={instance}
              rootNode={rootNode}
              validityStyles={validityStyles}
              readOnly={readOnly}
              onValidated={onValidated}
              ipid={ipid}
              enterpriseId={enterpriseId}
              parent={isParentChildrenNodes ? currentInstance : undefined}
              siblings={isParentChildrenNodes ? XeSmartBookInstance : undefined}
            />
          </SchemaReducer>
        );
      })}
    </AttrTypeRender>
  );
}, isShallowEqual);
