import PropTypes from 'prop-types';
import { Button } from '../../components/Button';
import { ToolTip } from '../../components/ToolTip';
import { Icon } from '../../components/Icon';
import { Label } from '../../components/Label';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const SimpleButton = (props) => {
  const {
    dataElementName,
    icon,
    className,
    iconClassName,
    disabled,
    onClick,
    look,
    itemRef,
    togglable,
    selected,
    children,
  } = props;

  return (
    <Button
      togglable={togglable}
      selected={selected}
      dataElementName={dataElementName}
      className={`xjs-icon-button xjs-icon-button--${icon} icon-button ${className}`}
      disabled={disabled}
      onClick={onClick}
      look={look}
      ref={itemRef}
    >
      <Icon
        icon={icon}
        className={`${iconClassName} ${disabled ? 'color-disabled' : ''}`}
      />
      {children}
    </Button>
  );
};

const LabelButton = (props) => {
  const {
    dataElementName,
    icon,
    description,
    className,
    iconClassName,
    labelClassName,
    disabled,
    onClick,
    look,
    itemRef,
    togglable,
    selected,
  } = props;

  return (
    <Button
      togglable={togglable}
      selected={selected}
      data-element-name={dataElementName}
      className={`xjs-icon-button xjs-icon-button--${icon} icon-button ${className}`}
      disabled={disabled}
      onClick={onClick}
      look={look}
      ref={itemRef}
    >
      <Icon
        icon={icon}
        className={`${iconClassName} ${disabled ? 'color-disabled' : ''}`}
      />
      <Label className={labelClassName}>{description}</Label>
    </Button>
  );
};

export const ToolTipButton = (props) => {
  const {
    dataElementName,
    icon,
    description,
    disabledDescription,
    className,
    iconClassName,
    tooltipExtras,
    disabled,
    onClick,
    look,
    itemRef,
    togglable,
    selected,
    children,
  } = props;

  return (
    <ToolTip
      value={disabled ? disabledDescription : description}
      {...tooltipExtras}
    >
      <Button
        togglable={togglable}
        dataElementName={dataElementName}
        selected={selected}
        className={`xjs-icon-button xjs-icon-button--${icon} icon-button ${className}`}
        disabled={disabled}
        onClick={onClick}
        look={look}
        ref={itemRef}
      >
        <Icon
          icon={icon}
          className={`${iconClassName} ${disabled ? 'color-disabled' : ''}`}
        />
        {children}
      </Button>
    </ToolTip>
  );
};

export const IconButton = (props) => {
  const {
    tooltip = true,
    dataElementName = '',
    description,
    tooltipExtras = EMPTY_OBJECT,
    look = 'outline',
    className = '',
    iconClassName = '',
    labelClassName = '',
    disabled = false,
    onClick = NOOP_FUNCTION,
    ...rest
  } = props;

  if (!description) {
    return (
      <SimpleButton
        {...rest}
        dataElementName={dataElementName}
        className={className}
        iconClassName={iconClassName}
        disabled={disabled}
        onClick={onClick}
        look={look}
      />
    );
  }

  if (!tooltip) {
    return (
      <LabelButton
        {...rest}
        dataElementName={dataElementName}
        description={description}
        className={className}
        iconClassName={iconClassName}
        labelClassName={labelClassName}
        disabled={disabled}
        onClick={onClick}
        look={look}
      />
    );
  }
  return (
    <ToolTipButton
      {...rest}
      dataElementName={dataElementName}
      description={description}
      className={className}
      iconClassName={iconClassName}
      tooltipExtras={tooltipExtras}
      disabled={disabled}
      onClick={onClick}
      look={look}
    />
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  dataElementName: PropTypes.string,
  look: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipExtras: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default IconButton;
