import { pluck } from '../../../fp/object';
import {
  useSchemaDispatch,
  useSchemaSelector,
} from '../../../schema/SchemaReducer';
import { DELETE, UPSERT } from '../../../schema/JSONSchemaReducer';
import { useState } from 'react';
import {
  Flexbox,
  HeaderLabel,
  IconButton,
  Label,
  Panel,
  TextInput,
  TogglableList,
  Icon,
} from '../../';
import { SEARCH, CLEAR, REQUIRED } from '../../../icons';
import '../styles.css';
import { toComparatorFn } from '../utils';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeQuery } from '../../../data/useXeQuery';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { identity } from '../../../fp/fp';
import { getCustomLanguage } from 'services/correspondence-infos/xe-correspondence-infos-svc.js';
import { isNil } from '../../../fp/pred';

const toCustomLanguageID = pluck('CustomLanguageID');

const toInitialFilterValue = (selectedCustomLanguage) => {
  const { CustomLanguageCode = '', Description = '' } = selectedCustomLanguage;

  return CustomLanguageCode || Description
    ? `${CustomLanguageCode} ${Description}`
    : undefined;
};

export const CustomLanguage = (props) => {
  const {
    customLanguages = EMPTY_ARRAY,
    selectedCustomLanguage,
    onSelectLanguage,
  } = props;
  const CorrespondenceInfoID = useSchemaSelector(pluck('CorrespondenceInfoID'));

  const labels = useXeLabels();
  const schemaDispatch = useSchemaDispatch();
  const [filter, setFilter] = useState(
    toInitialFilterValue(selectedCustomLanguage)
  );
  const [filterStateForQuery, setFilterStateForQuery] = useState(
    toInitialFilterValue(selectedCustomLanguage)
  );

  /**
   * Deletes any of the smartbook information that exists in the letter wizard.
   * Should only be invoked when the user selects a language.
   */
  const deleteExistingDataEntryData = () => {
    schemaDispatch({
      type: DELETE,
      path: 'BookID',
    });
    schemaDispatch({
      type: DELETE,
      path: 'FaxCoverBookID',
    });
    schemaDispatch({
      type: DELETE,
      path: 'MailCoverBookID',
    });
  };

  // Getting filtered data
  const {
    NumberOfResults,
    data: { XeCustomLanguage: filteredCustomLanguages = customLanguages } = {},
  } = useXeQuery(
    getCustomLanguage(
      {
        searchText: filterStateForQuery,
        correspondenceInfoId: CorrespondenceInfoID,
      },
      identity
    ),
    {
      enabled: filterStateForQuery !== undefined,
    }
  );

  return (
    <Flexbox direction="column" className="flex-1">
      <div className="display-grid custom-language__grid">
        <HeaderLabel className="custom-language__section-header">
          {labels.CustomLanguage}
        </HeaderLabel>
        <TextInput
          descriptor={labels.Content}
          value={filter}
          onChange={setFilter}
        />
        <IconButton
          icon={SEARCH}
          onClick={() => {
            setFilterStateForQuery(filter);
          }}
        />
        <IconButton icon={CLEAR} onClick={() => setFilter('')} />
      </div>
      {isNil(NumberOfResults) && <div>{labels.SearchForMoreResults}</div>}
      <Flexbox>
        <Icon className="margin-left-x-small md-12" icon={REQUIRED} />
        <Panel className="overflow-auto flex-1">
          <TogglableList
            data={filteredCustomLanguages}
            value={selectedCustomLanguage}
            comparator={toComparatorFn(toCustomLanguageID)}
            keyFn={toCustomLanguageID}
            renderItem={({ item = EMPTY_OBJECT } = EMPTY_OBJECT) => {
              const { Description, CustomLanguageCode } = item;
              return (
                <Flexbox justifyContent="space-between">
                  <Label descriptor={labels.Code}>{CustomLanguageCode}</Label>
                  <Label className="padding-horizontal-medium">
                    {Description}
                  </Label>
                </Flexbox>
              );
            }}
            onChange={({ item } = {}) => {
              deleteExistingDataEntryData();
              schemaDispatch({
                type: UPSERT,
                path: 'BookID',
                value: {
                  XeSmartBookInstance: pluck('XeSmartBookInstance')(item),
                },
              });
              schemaDispatch({
                type: UPSERT,
                path: 'CustomLanguageID',
                value: {
                  CustomLanguageID: toCustomLanguageID(item),
                },
              });
              onSelectLanguage(item);
            }}
          />
        </Panel>
      </Flexbox>
    </Flexbox>
  );
};

export default CustomLanguage;
