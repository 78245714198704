import { pluck } from '../../fp/object';
import isString from 'lodash/fp/isString';
import { DateTime } from 'luxon';
import { toFormatterAndParserByType } from '../../g11n/ISODates';

const KVP_DELIMITER = '=';
const ATTR_DELIMITER = '~';

export const toAttrDetail = pluck('AttrDetail');

/**
 *
 * @param {string} attrDetail
 */
const toAttributesArray = (attrDetail) => {
  if (!isString(attrDetail)) return [];

  return attrDetail.split(ATTR_DELIMITER);
};

const isRequiredAttribute = (attribute) => {
  return !attribute.includes(KVP_DELIMITER);
};

/**
 * @returns {{ [x: string]: string }}
 */
export const toAttributesObject = (attrDetail) => {
  const attributes = toAttributesArray(attrDetail);

  return attributes.reduce((attrPairs, attr = '', idx) => {
    // If the first attribute value has no name, it is required (JDM)
    if (idx === 0) {
      if (isRequiredAttribute(attr)) {
        return { required: attr };
      }
    }

    const [key, value] = attr.split(KVP_DELIMITER);

    return Object.assign({}, attrPairs, { [key]: value });
  }, {});
};

export const toNodeWidthStyle = (width) => {
  return {
    width: width ? parseInt(width) : 'auto',
  };
};

/**
 * @param {'StartOfDay' | 'EndOfDay'} dateModifier
 * @param {*} value
 */
export const toResultDateFromModifier = (
  dateModifier,
  value,
  format,
  uiValid = true
) => {
  const { formatter, parser } = toFormatterAndParserByType(format);
  // Return here if the ui is not valid, since the date modifcation doesn't
  // play nicely with invalid dates (unpredictable timezones). Defaulting
  // uiValid to true as to not change functionality in unintended spots. (DAK)
  if (!value || !uiValid) return value;
  switch (dateModifier) {
    case 'EndOfDay': {
      const endOfDayDateTime = DateTime.fromJSDate(parser(value)).endOf('day');
      return formatter(endOfDayDateTime);
    }
    case 'StartOfDay': {
      const startOfDayDateTime = DateTime.fromJSDate(parser(value)).startOf(
        'day'
      );
      return formatter(startOfDayDateTime);
    }
    default: {
      return value;
    }
  }
};

export const isRadioComponent = ({ AttrType } = {}) =>
  AttrType === 'RadioGroup' || AttrType === 'RadioList';

export const isSectionLike = ({ AttrType } = {}) =>
  AttrType === 'RadioGroup' ||
  AttrType === 'RadioList' ||
  AttrType === 'SelectLabel';

export const toSmartBookNodePath = (fullDataPath) => {
  const [path] = fullDataPath.match(/.*\d/) ?? [];

  return path;
};
