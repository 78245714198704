import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { isNil } from '../../fp/pred';
import { pluck } from '../../fp/object';
import { unescape } from '../../fp/unescape';
import { Input } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { handleInputTrim } from '../../validators/inputPreds';
import { UIControlLabel } from '../Label';
import { NOOP_FUNCTION } from '../../constants';
const ENTER_KEY = 13;

/**
 * Input to handle texts. Automatically trims values when it loses focus
 * @param {String} value Raw Text value.
 * @param {Function} onChange Callback fired with text value and an object with validity and validation Message.
 * @param {Function} onEnter Callback fired with text value when pressing the ENTER_KEY 13
 * @param {String} pattern a string which contains a regex pattern that when matched determines if the input is valid or not
 * @param {Function} onBlur Callback fired with the input loses focus
 */
export const TextInput = (props) => {
  const {
    dataElementName = '',
    dataPath,
    type = 'text',
    value: providedValue,
    onChange = NOOP_FUNCTION,
    onEnter = NOOP_FUNCTION,
    pattern,
    onBlur = NOOP_FUNCTION,
    descriptor,
    descriptorClassName = '',
    required: propsRequired,
    validityStyles: propsValidityStyles,
    onValidation = NOOP_FUNCTION,
    ...passthroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [internalTouched, setInternalTouched] = useState(false);

  const {
    onValueChange = onChange,
    value = providedValue,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = () => setInternalTouched(true),
    schemaNode,
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  const [syncValue, setSyncValue] = useState(value);
  const lastValueRef = useRef(value);
  const inputRef = useRef();

  if (value !== lastValueRef.current) {
    lastValueRef.current = value;
    setSyncValue(value);
  }

  const valid = !validityMessage ? undefined : false;
  const validityStyles = schemaNode ? touched : internalTouched;
  const required = schemaRequired || propsRequired;

  useEffect(() => {
    if (!isNil(syncValue) && onValueChange && syncValue !== value) {
      onValueChange(syncValue);
    }
    if (onValidationChange) {
      const element = inputRef.current.element;
      element.checkValidity();
      const { validity, validationMessage } = element;
      onValidationChange(toUIValid(validity), validationMessage);
    }
  }, [syncValue, required, value, onValueChange, onValidationChange]);

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? dataElementName + '__label' : 'label'
        }
        className={descriptorClassName}
        required={required}
      >
        {descriptor}
      </UIControlLabel>
      <Input
        data-element-name={dataElementName}
        ref={inputRef}
        type={type}
        value={syncValue || ''}
        required={required}
        validityStyles={
          isNil(propsValidityStyles) ? validityStyles : propsValidityStyles
        }
        valid={valid}
        validationMessage={validityMessage}
        onChange={(ev) => {
          const { value } = ev;
          const unescapedValue = unescape(value);
          setSyncValue(unescapedValue);
        }}
        onBlur={(event) => {
          const {
            target: { value, type, validity, element },
          } = event;
          /* Trims the leading and trailing spaces when losing focus
            This sort of input sanitation should probably done either with the schema stuff or before we make the network request
            Done here to try and capture most of the app in the meantime
            Using the onChange(callback) we update the new trimmed value when onBlur event occurs
            Note this does leave us a slight timing issue where we don't know a field is empty until we leave the field,
            so rules validations may pass before we leave focus. The timing from testing seems like it will still stop the save,
            however edge cases always exist.
          */
          if (type === 'text') {
            const trimmedValue = handleInputTrim(event);
            const isNewTrimValue = trimmedValue !== value;
            // only update the value if anything was trimmed
            if (isNewTrimValue) {
              onValueChange(trimmedValue);
              onValidationChange(
                toUIValid(validity),
                element?.validationMessage
              );
            }
          }
          onBlur(event);
          onTouched();
        }}
        onKeyPress={(event) => {
          const keyCode = event.keyCode || event.which;
          if (keyCode === ENTER_KEY) {
            event.preventDefault();
            onEnter(value);
          }
        }}
        //At the moment pattern throws a generic error, so we need to be careful using it in broader cases
        pattern={pattern}
        maxLength={pluck('maxLength')(schemaNode)}
        {...passthroughProps}
      />
    </>
  );
};
export default TextInput;
TextInput.propTypes = {
  dataPath: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  descriptorClassName: PropTypes.string,
  spellCheck: PropTypes.oneOf(['true', 'false']),
  dataElementName: PropTypes.string,
};
