import { useState } from 'react';
import { Flexbox } from '..';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { Label } from '../Label';
import { Popup, toDefaultPopupFooter } from '../Popup';
import TextArea from '../TextArea';

export const MarkInErrorPopup = (props) => {
  const labels = useXeLabels();
  const { onConfirm, onClose, minLength } = props;

  const [reason, updateReason] = useState('');

  return (
    <Popup
      dataElementName="markInError__popup"
      size="small"
      title={labels.ErrorReason}
      FooterComponent={toDefaultPopupFooter({
        disableConfirm: !reason.trim(),
        confirmLabelKey: 'MarkInError',
        onConfirm: () => onConfirm(reason),
        onClose,
      })}
    >
      <Flexbox direction="column">
        <Label dataElementName="inErrorLabel" required>
          {labels.MarkInErrorMessage}
        </Label>
        <TextArea
          dataElementName="reason"
          minLength={minLength}
          className="flex-1"
          value={reason}
          autoFocus={true}
          onChange={updateReason}
        />
      </Flexbox>
    </Popup>
  );
};

export default MarkInErrorPopup;
