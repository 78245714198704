import { useEffect, useMemo } from 'react';
import primarySchemaKeys from '../../primarySchemaKeys';
import { useSchema } from '../../../../schema/SchemaReducer';

const LETTER_NAME = 'NOA Rationale Library';
const LETTER_LOB = 'LETTER_LOB';
const LETTER_TYPE = 'LETTER_TYPE';
const LETTER_SUBTYPE = 'LETTER_SUBTYPE';
const CORRESPONDENCE_CODE = 'CORRESPONDENCE_CODE';

const toInstanceWithWellKnownID = (wellKnownID) => (instance) =>
  instance?.WellKnownID === wellKnownID;

export const LineOfBusiness = (props) => {
  const {
    children,
    node,
    dataPath = primarySchemaKeys.LineOfBusiness,
    childrenContext: { siblings },
  } = props;
  const { IsVisible } = node;

  const { onValueChange } = useSchema(dataPath);

  const letter = useMemo(
    () =>
      siblings?.find((instance) => instance?.Name === LETTER_NAME)
        ?.XeSmartBookInstance,
    [siblings]
  );

  const lineOfBusiness = useMemo(
    () => siblings?.find(toInstanceWithWellKnownID(LETTER_LOB))?.ResultValueID,
    [siblings]
  );

  const letterType = letter?.find(
    toInstanceWithWellKnownID(LETTER_TYPE)
  )?.ResultValue;
  const subType = letter?.find(
    toInstanceWithWellKnownID(LETTER_SUBTYPE)
  )?.ResultValue;
  const correspondenceCode = letter?.find(
    toInstanceWithWellKnownID(CORRESPONDENCE_CODE)
  )?.ResultValue;

  const currentValue = `${letterType}|${subType}|${correspondenceCode}|${lineOfBusiness}`;
  useEffect(() => {
    onValueChange(currentValue);
  }, [currentValue, onValueChange]);

  if (!IsVisible) return null;

  return <div>{children}</div>;
};
