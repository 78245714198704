import { Component } from 'react';
import { isObjectLike, isNil } from '../fp/pred';

/**
 * Takes a series of keys and returns a fn that predicates a reducer on the type is in the key list
 * @param {...string} validStrings
 * @return {(state:string, action:{value:any,type:string}) => boolean}
 */
export const isType =
  (...target) =>
  (state, action = {}) =>
    target.includes(action.type);

export const isScoped =
  (scope) =>
  (_state, { type } = { type: '' }) => {
    return type.startsWith(scope);
  };

export const isStateAssertionKey =
  (...targets) =>
  (state, action) => {
    const { type } = action;
    return targets.find((target) => type.includes(target));
  };

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, info) {}

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      const { message, stack } = (isObjectLike(error) && error) || {};

      const defaultMessage = 'Unknown Error';
      const defaultStack = new Error().stack;

      return (
        <div>
          <h1>An error has occurred.</h1>
          <h2>{(!isNil(message) && message) || defaultMessage}</h2>
          <pre>{(!isNil(stack) && stack) || defaultStack}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}
