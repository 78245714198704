import { SliderBase } from '../SliderBase';
import primarySchemaKeys from '../../primarySchemaKeys';

export const ScoreValueResult = props => {
  return (
    <SliderBase {...props} dataPath={primarySchemaKeys.ScoreValueResult} />
  );
};

export default ScoreValueResult;
