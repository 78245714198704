import { withClassNameModifiers } from '../../../../utils/withClassNameModifiers';
import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { useEffect } from 'react';
import { Flexbox, Label as LabelXJS } from '../../../../components';
import { ExpandableNode } from '../../components/ExpandableNode';
import { InfoButton } from '../../components/InfoButton';
import { Children } from 'react';
import './styles.css';
import { isSetLabelValidator } from '../../validators';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';
import primarySchemaKeys from '../../primarySchemaKeys';

export const Label = (props) => {
  const {
    children,
    node,
    dataPath = primarySchemaKeys.Label,
    onValidated,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    Name,
    InfoID,
    IsRequired: initialIsRequired,
    IsVisible: initialIsVisible,
  } = nodeWithDefaults;

  const { fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const isSection = Children.count(children) > 0;

  const [
    IsRequired = initialIsRequired,
    IsVisible = initialIsVisible,
    isValid,
  ] = useSchemaSelector((instance = {}) => {
    const { IsSet, IsVisible, IsRequired } = instance;

    const isValid = isSetLabelValidator(IsSet, IsRequired);

    return [IsRequired, IsVisible, isValid];
  });

  useEffect(() => {
    return onValidChange(isValid);
  }, [fullPath, onValidChange, isValid]);

  if (!IsVisible) return null;

  return (
    <ExpandableNode
      checked={false}
      node={node}
      ItemElement={
        <Flexbox>
          <LabelXJS
            className={`${withClassNameModifiers('smartbook-label', {
              invalid: valid !== null && !valid,
            })} margin-vertical-small`}
            wrapText={false}
            required={isSection && IsRequired}
          >
            {Name}
          </LabelXJS>
          <InfoButton infoId={InfoID} />
        </Flexbox>
      }
    >
      {children}
    </ExpandableNode>
  );
};
