import { EMPTY_OBJECT } from '../../../../constants';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { CARE_PLAN_INTERVENTION } from '../../../../icons';
import { formatStaffName } from '../../../../utils';
import Flexbox from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Label } from '../../../Label';
import ToolTip from '../../../ToolTip';
import './styles.css';

const ToolTipLine = ({ dataElementName, label, value }) => (
  <Flexbox justifyContent="space-between">
    <Label dataElementName={`${dataElementName}Label`}>{label}</Label>
    <Label dataElementName={dataElementName}>{value}</Label>
  </Flexbox>
);

const CarePlanToolTip = (props) => {
  const { node, nodeStatus } = props;

  const { ResultValue, StartedBy, EndDate, CreateTStamp } = node;

  const labels = useXeLabels();

  return (
    <div>
      <ToolTipLine
        dataElementName="intervention"
        label={labels.Intervention}
        value={ResultValue}
      />
      <ToolTipLine
        dataElementName="due"
        label={labels.Due}
        value={toDisplayDateFromISOString(EndDate, localeFormat.MEDIUM)}
      />
      <ToolTipLine
        dataElementName="status"
        label={labels.Status}
        value={nodeStatus.text}
      />
      <ToolTipLine
        dataElementName="added"
        label={labels.Added}
        value={`${
          toDisplayDateFromISOString(CreateTStamp, localeFormat.MEDIUM) || ''
        } ${labels.By} ${formatStaffName(StartedBy)}`}
      />
    </div>
  );
};

export const CarePlanIntervention = (props) => {
  const { node } = props;

  const { ResultValue, StatusID, IsVisible, Active } = node;

  const { CARE_PLAN_ITEM_STATUS } = useXeRefData();

  const nodeStatus =
    CARE_PLAN_ITEM_STATUS.find(({ id }) => id == StatusID) || EMPTY_OBJECT;

  if (!IsVisible || !Active) return null;

  return (
    <Flexbox alignItems="center" className="flex-1 margin-vertical-small">
      <Flexbox alignItems="center" className="flex-1">
        <ToolTip
          value={<CarePlanToolTip node={node} nodeStatus={nodeStatus} />}
        >
          <Icon
            dataElementName="carePlanIntervention"
            icon={CARE_PLAN_INTERVENTION}
            color="white"
            className="mdi--circle care-plan-item__icon"
          />
        </ToolTip>
        <Label className="font-bold margin-left-small padding-right-large">
          {ResultValue}
        </Label>
      </Flexbox>
      <Label className="flex-0">{nodeStatus.text}</Label>
    </Flexbox>
  );
};
