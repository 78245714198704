import { useSchema } from '../../../../schema/SchemaReducer';
import { useEffect, useRef, Children } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Flexbox } from '../../../../components';
import { ExpandableNode } from '../../components/ExpandableNode';
import { InfoButton } from '../../components/InfoButton';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import './styles.css';
import { NOOP_FUNCTION } from '../../../../constants';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

/***
 * If the checkbox is not selected we set the schema value to ''.  If we were to set it to null or
 * undefined, the schema value would be removed from the Smartbook instance instead of as we have now
 * a value that is empty.
 */
const NOT_SELECTED_VALUE = undefined;

export const SelectTranslationLabel = (props) => {
  const {
    readOnly,
    node,
    children,
    onValidated = NOOP_FUNCTION,
    dataPath = primarySchemaKeys.SelectTranslationLabel,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, InfoID, IsRequired, IsVisible } = node;

  const {
    value = NOT_SELECTED_VALUE,
    validityMessage,
    onValueChange,
    fullPath,
  } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const prevValueRef = useRef();
  const isSection = Children.count(children) > 0;

  useEffect(() => {
    // If checked our Value is Name, otherwise it should be our NOT_SELECTED_VALUE.
    const selectedValue = value === Name ? Name : NOT_SELECTED_VALUE;
    onValueChange(selectedValue);

    // Handle updating validity via external changes to this component's value
    if (prevValueRef.current !== value) {
      prevValueRef.current = value;

      // if this is not required or it is required and there is a selected value, this AttrType is
      // valid.
      const isUIValid = !IsRequired || (IsRequired && !!selectedValue);
      return onValidChange(isUIValid);
    }
  }, [
    fullPath,
    onValidChange,
    onValueChange,
    prevValueRef,
    value,
    isSection,
    IsRequired,
    Name,
  ]);

  if (!IsVisible) return null;

  /**
   * Our value will either be a valid string or and empty string.  If the value is a valid string, the checkbox
   * will be checked.  Otherwise if it is the NOT_SELECTED_VALUE, the checkbox should be unchecked.
   */
  const isChecked = value === Name;

  return (
    <ExpandableNode
      node={node}
      checked={isChecked}
      ItemElement={
        <Flexbox alignItems="center">
          <Checkbox
            dataElementName={Name}
            disabled={readOnly}
            checked={isChecked}
            onClick={(ev) => {
              const {
                target: { checked },
              } = ev;

              prevValueRef.current = checked;

              // If checked our Value is Name, otherwise it should be our NOT_SELECTED_VALUE.
              const selectedValue = checked ? Name : NOT_SELECTED_VALUE;

              // if this is not required or it is required and there is a selected value, this AttrType is
              // valid.
              const isUIValid = !IsRequired || (IsRequired && !!selectedValue);

              onValueChange(selectedValue);
              onValidChange(isUIValid);
            }}
            wrapperClassName="smartbook__select-translation-label"
          />
          <NameLabel
            dataElementName={`${Name}Label`}
            required={IsRequired}
            invalid={(valid !== null && !valid) || !!validityMessage}
          >
            {Name}
          </NameLabel>
          <div className="padding-left-small">
            <InfoButton infoId={InfoID} />
          </div>
        </Flexbox>
      }
    >
      {children}
    </ExpandableNode>
  );
};

SelectTranslationLabel.propTypes = {
  onValidated: PropTypes.func,
};
