import { pluck } from '../../../fp/object';
import { isNil, exists } from '../../../fp/pred';
import { schemaPluck } from '../../../schema/schemaTypeBuilder';
import { Checkbox, DropDownList, HeaderLabel, TogglableList } from '../../';
import {
  SET_SUBTYPE,
  SET_IS_SEARCH_FOR_ALL_PROGRAMS,
  SET_LOB,
  SET_SELECTED_LETTER_TEMPLATE,
  SET_SELECTED_FAX_COVER,
  SET_SELECTED_MAIL_COVER,
} from '../actions';
import '../styles.css';
import { toComparatorFn } from '../utils';
import CorrespondenceInfoResponseSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';
import { useXeLabels } from '../../../contexts/XeLabelContext';

const toCorrespondenceInfoID = schemaPluck(
  CorrespondenceInfoResponseSchema,
  'CorrespondenceInfoID'
);
//TODO, update these to schemaPlucks, grouped them up here for easier updating (PDD)
const toName = pluck('Name');
const toTypeID = pluck('TypeID');
const toWellKnownID = pluck('WellKnownID');
const coverSheetComparator = (a, b) => {
  const wkidA = toWellKnownID(a);
  const wkidB = toWellKnownID(b);
  return exists(wkidA) && exists(wkidB) && wkidA === wkidB;
};
const toDefaultSelectedCoverSheet = (coverSheets, defaultCoverSheetWKID) =>
  coverSheets.find((sheet) => toWellKnownID(sheet) === defaultCoverSheetWKID) ||
  coverSheets[0] ||
  {};

export const SelectLetter = (props) => {
  const {
    dispatch,
    lineOfBusiness,
    lobRefData,
    categoryRefData,
    faxCoverSheets,
    mailCoverSheets,
    templates,
    selectedTemplate,
    selectedFaxCover,
    selectedMailCover,
    defaultLob,
    isSearchForAllPrograms,
    subType,
    isWorkList,
  } = props;

  const labels = useXeLabels();

  const selectedLineOfBusiness = lineOfBusiness || defaultLob;

  const allowCoverSelect = schemaPluck(
    CorrespondenceInfoResponseSchema,
    'AllowCoverSelect'
  )(selectedTemplate);
  const faxCoverSelectionEnabled = exists(selectedFaxCover) && allowCoverSelect;
  const mailCoverSelectionEnabled =
    exists(selectedMailCover) && allowCoverSelect;

  return (
    <div
      data-element-name="letterWizard__selectLetter__flex__1"
      className="flex-1 display-grid select-letter"
    >
      <HeaderLabel dataElementName="letterWizard__selectLetter__label">
        {labels.Letter}
      </HeaderLabel>
      <div
        data-element-name="letterWizard__selectLetter__grid"
        className="display-grid select-letter__letter-detail-grid"
      >
        <DropDownList
          dataElementName="businessLine"
          descriptor={labels.LineOfBusiness}
          data={[{}, ...lobRefData]}
          valueFn={pluck('id')}
          labelFn={pluck('text')}
          value={selectedLineOfBusiness}
          onChange={(value) => {
            dispatch({
              type: SET_LOB,
              value,
            });
          }}
        />
        <DropDownList
          dataElementName="category"
          descriptor={labels.Category}
          data={categoryRefData}
          value={subType}
          valueFn={toTypeID}
          labelFn={toName}
          disabled={!!isWorkList}
          comparator={(item, value) => {
            return item === value;
          }}
          onChange={(value) => {
            dispatch({
              type: SET_SUBTYPE,
              value: value,
            });
          }}
        />
        <Checkbox
          dataElementName="allPrograms"
          label={labels.ViewAll}
          wrapperClassName="select-letter__all-programs-checkbox"
          checked={isSearchForAllPrograms}
          onChange={(value) => {
            dispatch({
              type: SET_IS_SEARCH_FOR_ALL_PROGRAMS,
              value,
            });
          }}
        />
        <TogglableList
          dataElementName="selectLetter"
          required={true}
          className="select-letter__letter-template-list overflow-auto"
          descriptor={labels.Letter}
          descriptorClassName="flex-shrink-0"
          data={templates}
          comparator={toComparatorFn(toCorrespondenceInfoID)}
          value={selectedTemplate}
          renderItem={({ item }) => {
            const Name = schemaPluck(
              CorrespondenceInfoResponseSchema,
              'Name'
            )(item);
            const Description = schemaPluck(
              CorrespondenceInfoResponseSchema,
              'Description'
            )(item);
            return `${Name}${Description ? ` - ${Description}` : ''}`;
          }}
          onChange={({ item } = {}) => {
            dispatch({
              type: SET_SELECTED_LETTER_TEMPLATE,
              value: item,
            });
          }}
        />
      </div>
      <div>
        <HeaderLabel dataElementName="letterWizard__coverPagesHeader__label">
          {labels.CoverPages}
        </HeaderLabel>
        <div className="display-grid select-letter__cover-pages-grid">
          <Checkbox
            dataElementName="faxCoverPage"
            label={labels.FaxCoverPage}
            checked={faxCoverSelectionEnabled}
            onChange={(value) => {
              dispatch({
                type: SET_SELECTED_FAX_COVER,
                value: value
                  ? toDefaultSelectedCoverSheet(
                      faxCoverSheets,
                      selectedTemplate.DefaultFaxCoverWKID
                    )
                  : undefined,
              });
            }}
            disabled={!allowCoverSelect}
          />
          <DropDownList
            dataElementName="faxCoverSheet"
            data={faxCoverSheets}
            comparator={coverSheetComparator}
            labelFn={toName}
            value={selectedFaxCover}
            onChange={(value) =>
              dispatch({ type: SET_SELECTED_FAX_COVER, value })
            }
            disabled={!faxCoverSelectionEnabled}
          />
          <Checkbox
            dataElementName="mailCoverPage"
            label={labels.MailCoverPage}
            checked={mailCoverSelectionEnabled}
            onChange={(value) => {
              dispatch({
                type: SET_SELECTED_MAIL_COVER,
                value: value
                  ? toDefaultSelectedCoverSheet(
                      mailCoverSheets,
                      selectedTemplate.DefaultMailCoverWKID
                    )
                  : undefined,
              });
            }}
            disabled={!allowCoverSelect}
          />
          <DropDownList
            dataElementName="mailCoverSheet"
            data={mailCoverSheets}
            labelFn={toName}
            comparator={coverSheetComparator}
            value={selectedMailCover}
            onChange={(value) =>
              dispatch({ type: SET_SELECTED_MAIL_COVER, value })
            }
            disabled={!mailCoverSelectionEnabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectLetter;
