import { IconButton, Label } from '../../components';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { REMOVE } from '../../icons';
import './styles.css';

export const Tag = (props) => {
  const { item, onRemove, labelFn, className, dataElementName = '' } = props;
  const labels = useXeLabels();
  return (
    <span key={labelFn(item)} className={`xjs-tag ${className || ''}`}>
      <Label>{labelFn(item)}</Label>
      {!!onRemove ? (
        <IconButton
          dataElementName={
            !!dataElementName ? `${dataElementName}__remove` : 'remove'
          }
          icon={REMOVE}
          description={labels.Remove}
          onClick={() => onRemove(item)}
        />
      ) : null}
    </span>
  );
};

export default Tag;
