/* eslint-disable no-console */
import { useRef, useEffect, useMemo, createElement } from 'react';
import { useScopedReducer, useScopedSelector } from '../hooks/scopedReducer';
import { ErrorBoundary } from '../connection/toConnectedComponent';
import { doBasicCallbacks } from '../legacy/callbacks';
import {
  ReactXeMenuNodeContext,
  useDescendentComponentContextValue,
} from '../contexts/XeMenuNodeContext';
import { defaultFeatureContextSet } from '../contexts';
import { isObservable, of } from 'rxjs';

export const useObservableContext$ = (contextFn) => {
  const value = contextFn();
  return useMemo(() => {
    if (isObservable(value)) return value;
    return of(value);
  }, [value]);
};

const usePrevious = (next) => {
  const ref = useRef();
  // useEffect(() => {
  //   if (!isShallowEqual(ref.current, next)) {
  //     ref.current = next;
  //   }
  // });
  const current = ref.current;
  ref.current = next;

  return current;
};

export const toXeConnectedComponent = (
  connectionSymbol,
  reducer,
  toEpic,
  view,
  callbacks,
  contexts = defaultFeatureContextSet
) => {
  const contextEntries = Object.entries(contexts).map(([k, v]) => {
    return [`${k}$`, v];
  });

  const XeEpicReducerComponent = (props) => {
    const context$s = contextEntries.reduce((acc, [key, fn]) => {
      //To be clear, hooks must be called in a fixed order. the linting rules just dont realize this is a guaranteed fixed order
      acc[key] = useObservableContext$(fn); // eslint-disable-line react-hooks/rules-of-hooks
      return acc;
    }, {});

    const { propsSideEffect } = useScopedReducer(
      connectionSymbol,
      reducer,
      toEpic,
      context$s,
      callbacks
    );

    useEffect(() => {
      propsSideEffect(props);
    }, [propsSideEffect, props]);

    const state = useScopedSelector((x) => x);
    const previousState = usePrevious(state);

    useEffect(() => {
      doBasicCallbacks(props, state, undefined, previousState);
    }, [props, state, previousState]);

    const InteractionType = 'fine';

    if (!state) return null;

    return createElement(
      ErrorBoundary,
      { key: 'ErrorBoundary' },
      view(state, {
        ...props,
        connectionSymbol,
        InteractionType,
        DisplayRegion: 'CHILD',
      })
    );
  };

  const XeConnectedComponent = (props) => {
    const { MenuNodeID } = props;

    const descendentContextValue = useDescendentComponentContextValue(
      connectionSymbol,
      MenuNodeID
    );

    if (descendentContextValue) {
      //If we are being called without a XeAppMenuNode, we need to make a new implict context...
      //This is a little weird still and would like to separate it, but it would go a lot deeper to do so
      return createElement(
        ReactXeMenuNodeContext.Provider,
        {
          value: descendentContextValue,
          key: 'XeMenuNodeContext.Provider.XeMenuNodeContext',
        },
        createElement(XeEpicReducerComponent, props)
      );
    }

    return createElement(XeEpicReducerComponent, props);
  };

  return XeConnectedComponent;
};
