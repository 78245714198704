import { createElement } from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef ListProps
 * @property {T[]} data
 * @property {({ item: T, index: number }) => import('react').ReactNode} renderItem
 * @property {string} className
 * @property {import('react').ComponentType<T>} component
 * @property {import('react').ReactNode} NoItemsElement
 * @template T
 */

/**
 *
 * @param {ListProps<T>} props
 * @template T
 */
export const List = (props) => {
  const {
    data,
    renderItem,
    className = '',
    component = 'div',
    dataElementName = '',
    NoItemsElement,
    ...componentProps
  } = props;

  const children =
    !data || !data.length
      ? NoItemsElement
      : data.map((d, idx) => renderItem({ item: d, index: idx }));

  return createElement(
    component,
    {
      className: `${className} xjs-list`,
      'data-element-name': dataElementName,
      ...componentProps,
    },
    children
  );
};

List.propTypes = {
  data: PropTypes.array,
  renderItem: PropTypes.func,
  className: PropTypes.string,
  component: PropTypes.elementType,
  NoItemsElement: PropTypes.node,
};
