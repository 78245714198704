import { pluck as objectPluck } from '../../fp/object';
import { downloadFile } from '../../download/downloadFile';
import { ofType } from '../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { castSchema } from '../../schema/schemaCaster';

import {
  distinctUntilChanged,
  map,
  mapTo,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import XeScanDocSetItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json';
import { NOTIFICATION } from '../../enterpriseContainer/XeAppContainer/actions';
import {
  DID_GET_SCAN_DOC_ID,
  DID_UPLOAD_FILES,
  SHOULD_ADD_DOCUMENT,
  SHOULD_UPDATE_DOCUMENT,
} from '../../files/actions';
import { toMenuNodeRequestFn } from '../../operators/toMenuNodeRequestFn';
import { DEFAULT_ALERT_TIME, ERROR_NOTIFICATION } from '../../constants';
import {
  DID_DOWNLOAD_DOCUMENT,
  REQUEST_SAVE_DOCUMENT,
  REQUEST_TOAST_ON_SAVE_FAILURE,
  SHOULD_ADD_NEW_FILES,
  SHOULD_DOWNLOAD_DOCUMENT,
  SHOULD_SAVE_DOCUMENT,
  SHOULD_SET_DEFAULT_FILE_SET_NAME_TO,
  SHOULD_SET_IS_CATEGORY_HIDDEN_TO,
  SHOULD_SET_FILE_SET_ENTERPRISE_ID,
} from './actions';

export const toScanDocIDEpic$ = (action$, state$, { props$ }) => {
  return props$.pipe(
    pluck('scanDocId'),
    distinctUntilChanged(),
    map((scanDocId) => {
      return {
        type: DID_GET_SCAN_DOC_ID,
        value: scanDocId,
      };
    })
  );
};

const toFileDocuments = (documentSet, categoryId, documentTypeId) => {
  return documentSet.map((document) => {
    const { response, file } = document;
    return castSchema(XeScanDocSetItemSchema)({
      ...response,
      Active: true,
      FileID: objectPluck('FileID')(response),
      FileName: file.name,
      CategoryID: categoryId,
      DocumentTypeID: documentTypeId,
    });
  });
};

export const toNewAddedDocumentsEpic$ = (action$, state$, { props$ }) => {
  return action$.pipe(
    ofType(DID_UPLOAD_FILES),
    pluck('value'),
    withLatestFrom(
      props$.pipe(pluck('categoryId')),
      props$.pipe(pluck('documentTypeId'))
    ),
    map(([documentSet, categoryId, documentTypeId]) => ({
      type: SHOULD_ADD_NEW_FILES,
      value: toFileDocuments(documentSet, categoryId, documentTypeId),
    }))
  );
};

export const toDefaultFileSetNameEpic$ = (action$, state$, { props$ }) => {
  return props$.pipe(
    pluck('defaultFileSetName'),
    map((value) => ({
      type: SHOULD_SET_DEFAULT_FILE_SET_NAME_TO,
      value,
    }))
  );
};

export const toCategoryDisplayEpic$ = (action$, state$, { props$ }) => {
  return props$.pipe(
    pluck('docTypeId'),
    mapTo({
      type: SHOULD_SET_IS_CATEGORY_HIDDEN_TO,
      value: true,
    })
  );
};

export const toSaveActionHandlerEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_SAVE_DOCUMENT),
    withLatestFrom(state$.pipe(pluck('documentSet'))),
    map(([value, documentSet = []]) => {
      const { changed, value: docToAdd } = value;
      if (!documentSet) {
        return {
          type: REQUEST_TOAST_ON_SAVE_FAILURE,
          value: documentSet,
        };
      }
      return {
        type: REQUEST_SAVE_DOCUMENT,
        value: {
          ...docToAdd,
          XeScanDocSet: documentSet,
        },
        changed,
      };
    })
  );
};

export const toToastOnSaveFailureEpic$ = (action$) => {
  return action$.pipe(
    ofType(REQUEST_TOAST_ON_SAVE_FAILURE),
    pluck('value'),
    map(() => {
      return {
        type: NOTIFICATION,
        value: {
          toMessage: objectPluck('AddFilesMessage'),
          timeout: DEFAULT_ALERT_TIME,
          style: ERROR_NOTIFICATION,
        },
      };
    })
  );
};

export const toSaveDocumentEpic$ = (action$) => {
  return action$.pipe(
    ofType(REQUEST_SAVE_DOCUMENT),
    map((data) => {
      const { value, changed } = data;
      // Existing scandocs
      if (objectPluck('ScanDocID')(value)) {
        return {
          type: SHOULD_UPDATE_DOCUMENT,
          value,
          changed,
        };
      }

      // New scandocs
      return {
        type: SHOULD_ADD_DOCUMENT,
        value,
        changed,
      };
    })
  );
};

export const toGetDocumentEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_DOCUMENT),
    pluck('value'),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([file, toRequest$]) => {
      const { FileName, FileID } = file;
      return getFile({ fileId: FileID }, toRequest$()).pipe(
        map((response) => {
          downloadFile(response, FileName);
          return {
            type: DID_DOWNLOAD_DOCUMENT,
          };
        })
      );
    })
  );
};

export const toFileSetEnterpriseIDEpic$ = (action$, state$, { props$ }) => {
  return props$.pipe(
    pluck('fileSetEnterpriseId'),
    map((value) => ({
      type: SHOULD_SET_FILE_SET_ENTERPRISE_ID,
      value,
    }))
  );
};

export default [
  toSaveDocumentEpic$,
  toGetDocumentEpic$,
  toDefaultFileSetNameEpic$,
  toScanDocIDEpic$,
  toCategoryDisplayEpic$,
  toSaveActionHandlerEpic$,
  toToastOnSaveFailureEpic$,
  toNewAddedDocumentsEpic$,
  toFileSetEnterpriseIDEpic$,
];
