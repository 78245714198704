// NOTE: Will likely need to revisit this to make the vertical spacing better (RA)
import { pluck } from '../../fp/object';
import { SchemaReducer, useSchemaDispatch } from '../../schema/SchemaReducer';
import {
  UPSERT,
  MERGE_PATCH,
  withDefaultJSONSchemaReducer,
} from '../../schema/JSONSchemaReducer';
import { useScopedSelector } from '../../hooks/scopedReducer';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import PatientContactRequest from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
import {
  Button,
  Checkbox,
  DropDownList,
  Flexbox,
  Input,
  Label,
  Table,
  TelephoneInput,
  TextArea,
} from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { withEllipsis } from '../../utils';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../validators/schemaValidators';
import { ZipCodeInput } from '../ZipCodeInput';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const RULES = [
  [({ RelationTypeID }) => RelationTypeID, defaultBusinessRuleReducer],
  [({ GivenName }) => GivenName, defaultBusinessRuleReducer],
  [({ FamilyName }) => FamilyName, defaultBusinessRuleReducer],
];
const validator = toDefaultValidator(RULES);

const DECLINED = 'DECLINED';

const SetContactAddressButton = ({
  label,
  disabled,
  address = EMPTY_OBJECT,
}) => {
  const dispatch = useSchemaDispatch();
  const {
    AddressLine1 = '',
    AddressLine2 = '',
    City = '',
    StateProvince = '',
    CountryID = '',
    ZipPostalCode = '',
  } = address;

  return (
    <Button
      dataElementName="sameAddressAsConsumer"
      onClick={() =>
        dispatch({
          type: MERGE_PATCH,
          value: {
            AddressLine1,
            AddressLine2,
            City,
            StateProvince,
            CountryID,
            ZipPostalCode,
          },
        })
      }
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

const DeclinedToSpecifyCheckbox = ({
  label,
  path,
  checked,
  disabled,
  dataElementName,
}) => {
  const dispatch = useSchemaDispatch();
  return (
    <Checkbox
      dataElementName={dataElementName}
      label={label}
      onChange={(selected) =>
        dispatch({ type: UPSERT, path, value: selected ? DECLINED : '' })
      }
      checked={checked}
      disabled={disabled}
    />
  );
};

export const PersonalContactForm = (props) => {
  const {
    value = EMPTY_OBJECT,
    initialValue = EMPTY_OBJECT,
    canEditPatientContact,
    isNewPatientContact,
    consumerAddress = EMPTY_OBJECT,
    onContactUpdate = NOOP_FUNCTION,
  } = props;

  const userEnterprise = useScopedSelector(
    pluck('contexts', 'enterprise', 'userData', 'EnterpriseID')
  );

  const {
    XeSocialRelationType = [],
    XeStateProvince = [],
    XeCountry = [],
    XgAppUserNotificationMethod = [],
    XeEthnicGroup = [],
    XeLanguage = [],
    XeEducation = [],
  } = useXeRefData();
  const contactInstanceRef = useRef(value);

  const labels = useXeLabels();
  const selectLabel = withEllipsis(labels.Select);

  const { AddressLine1, EthnicGroupID, LanguageID } = value || {};

  const { EnterpriseID: { EnterpriseID } = {} } = initialValue;

  const editable =
    isNewPatientContact ||
    (EnterpriseID === userEnterprise && canEditPatientContact);

  const activeXeEthnicGroup =
    EthnicGroupID === DECLINED
      ? XeEthnicGroup
      : XeEthnicGroup.filter((ethnic) => !!ethnic?.Active);

  return (
    <div
      data-element-name="personalContactForm"
      className="flex-1 personal-contact-form"
    >
      <SchemaReducer
        schema={PatientContactRequest}
        onChange={onContactUpdate}
        toJsonReducer={withDefaultJSONSchemaReducer(validator)}
        initialValue={contactInstanceRef.current}
        dangerouslyRetainCompletelyOutdatedState={true}
      >
        <Table.Layout>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label
                dataElementName="personalContactForm__relationship__label"
                className="personal-contact-information__label"
                required
              >
                {labels.Relationship}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeSocialRelationType}
                dataPath="RelationTypeID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="relationship"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__middleName__label">
                {labels.MiddleName}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="SecondName"
                disabled={!editable}
                dataElementName="middleName"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label
                dataElementName="personalContactForm__givenName__label"
                className="personal-contact-information__label margin-right-small"
                required={true}
              >
                {labels.GivenName}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="GivenName"
                disabled={!editable}
                dataElementName="firstName"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label
                dataElementName="personalContactForm__familyName__label"
                className="personal-contact-information__label margin-right-small"
                required={true}
              >
                {labels.FamilyName}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="FamilyName"
                disabled={!editable}
                dataElementName="lastName"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label
                dataElementName="personalContactForm__organization__label"
                className="personal-contact-information__label margin-right-small"
                required={false}
              >
                {labels.Organization}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="OrganizationName"
                disabled={!editable}
                dataElementName="organizationName"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__emergency__label">
                {labels.Emergency}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsEmergency"
                disabled={!editable}
                dataElementName="isEmergency"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="table-row-display">
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__isPreferred__label">
                {labels.Preferred}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsDefault"
                disabled={!editable}
                dataElementName="isPreferred"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__guardian__label">
                {labels.Guardian}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsGuardian"
                disabled={!editable}
                dataElementName="isGuardian"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__guarantor__label">
                {labels.Guarantor}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsGuarantor"
                disabled={!editable}
                dataElementName="isGuarantor"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__cohabitant__label">
                {labels.Cohabitant}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsCohabitant"
                disabled={!editable}
                dataElementName="isCohabitant"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__isHIPAACleared__label">
                {labels.HIPAACleared}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataPath="IsHIPAAClear"
                disabled={!editable}
                dataElementName="isHIPPAClear"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__nextOfKin__label">
                {labels.NextOfKin}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                dataElementName="isNextOfKin"
                dataPath="IsNextOfKin"
                disabled={!editable}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Layout>
        <hr className="horizontal-line align-center" />
        <Table.Layout>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small" />
            <Table.Cell className="padding-right-small">
              <SetContactAddressButton
                label={labels.PatientSameAddress}
                address={consumerAddress}
                disabled={!consumerAddress || !editable}
              />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__line1__label">
                {labels.LineOne}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="AddressLine1"
                disabled={!editable}
                dataElementName="addressLine1"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__line2__label">
                {labels.LineTwo}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="AddressLine2"
                disabled={!AddressLine1 || !editable}
                dataElementName="addressLine2"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="table-row-display">
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__city__label">
                {labels.City}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="text"
                className="app-input"
                dataPath="City"
                disabled={!editable}
                dataElementName="city"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__state__label">
                {labels.State}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeStateProvince}
                dataPath="StateProvince"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="stateProvince"
                className="flex"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__zipCode__label">
                {labels.Zip}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <ZipCodeInput
                className="app-input"
                dataPath="ZipPostalCode"
                disabled={!editable}
                dataElementName="zipCode"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__country__label">
                {labels.Country}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeCountry}
                dataPath="CountryID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="country"
                className="flex"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__phone__label">
                {labels.Phone}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <TelephoneInput
                className="app-input"
                dataPath="Phone"
                disabled={!editable}
                dataElementName="phoneNumber"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__mobilePhone__label">
                {labels.MobilePhone}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <TelephoneInput
                className="app-input"
                dataPath="MobilePhone"
                disabled={!editable}
                dataElementName="mobileNumber"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__email__label">
                {labels.Email}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Input
                type="email"
                className="app-input"
                dataPath="Email"
                disabled={!editable}
                dataElementName="email"
              />
            </Table.Cell>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__fax__label">
                {labels.Fax}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <TelephoneInput
                className="app-input"
                dataPath="Fax"
                disabled={!editable}
                dataElementName="fax"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__preferredMethod__label">
                {labels.Preferred}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XgAppUserNotificationMethod}
                dataPath="PreferredCommMethod"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="preferred"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
        </Table.Layout>
        <hr className="horizontal-line align-center" />
        <Table.Layout>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__ethnicity__label">
                {labels.Ethnicity}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={activeXeEthnicGroup}
                dataPath="EthnicGroupID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                disabled={EthnicGroupID === DECLINED || !editable}
                placeholder={selectLabel}
                dataElementName="ethnicity"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DeclinedToSpecifyCheckbox
                label={labels.DeclinedToSpecify}
                path="EthnicGroupID"
                checked={EthnicGroupID === DECLINED}
                disabled={!editable}
                dataElementName="ethnicGroup"
              />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__speaks__label">
                {labels.Speaks}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeLanguage}
                dataPath="LanguageID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                disabled={LanguageID === DECLINED || !editable}
                placeholder={selectLabel}
                dataElementName="speakingLanguage"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DeclinedToSpecifyCheckbox
                label={labels.DeclinedToSpecify}
                path="LanguageID"
                checked={LanguageID === DECLINED}
                disabled={!editable}
                dataElementName="speakingLanguageId"
              />
            </Table.Cell>
            <Table.Cell className="padding-right-small" />
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__read__label">
                {labels.Reads}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeLanguage}
                dataPath="WrittenLanguageID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="writtenLanguage"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <Checkbox
                label={labels.NeedsInterpreter}
                dataPath="IsInterpreterReqd"
                disabled={!editable}
                dataElementName="needsInterpreter"
              />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
          <Table.Row>
            <Table.Cell className="align-right padding-right-small">
              <Label dataElementName="personalContactForm__education__label">
                {labels.Education}
              </Label>
            </Table.Cell>
            <Table.Cell className="padding-right-small">
              <DropDownList
                data={XeEducation}
                dataPath="EducationID"
                labelFn={pluck('text')}
                valueFn={pluck('id')}
                placeholder={selectLabel}
                disabled={!editable}
                dataElementName="education"
                className="flex"
              />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
        </Table.Layout>
        <Flexbox direction="column" className="text-container">
          <Label dataElementName="personalContactForm__comments__label">
            {labels.Comments}
          </Label>
          <TextArea
            className="text-area fill-parent-width margin-bottom-large"
            dataPath="Comments"
            rows={5}
            disabled={!editable}
            dataElementName="comments"
          />
        </Flexbox>
      </SchemaReducer>
    </div>
  );
};

PersonalContactForm.propTypes = {
  value: PropTypes.object,
  initialValue: PropTypes.object,
  canEditPatientContact: PropTypes.bool,
  isNewPatientContact: PropTypes.bool,
  consumerAddress: PropTypes.object,
  onContactUpdate: PropTypes.func,
};

export default PersonalContactForm;
