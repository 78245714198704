import { cloneElement, useMemo, useRef, useState } from 'react';
import { ButtonGroup, Flexbox } from '../../../../components';
import { EMPTY_OBJECT } from '../../../../constants';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { pluck } from '../../../../fp/object';
import { isNil } from '../../../../fp/pred';
import { CUSTOM_DATE_RANGE } from '../../../../icons';
import { DELETE, MERGE_PATCH } from '../../../../schema/JSONSchemaReducer';
import {
  useSchema,
  useSchemaDispatch,
  useSchemaSelector,
} from '../../../../schema/SchemaReducer';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import CustomDateRangePickerPopup from '../../../CustomDateRangePickerPopup';
import { Icon } from '../../../Icon';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toAttributesObject } from '../../utils';
import { baseNodeValidator } from '../../validators';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

const ID_CUSTOM_DATE_OPTION = 'Custom';
const CUSTOM_DATE_OPTION = { id: ID_CUSTOM_DATE_OPTION };

const DateBarCustomOptionComponent = (props) => {
  const { element, onValueChange, onValidationChange, format } = props;

  const buttonRef = useRef();
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const { StartDate, EndDate } = useSchemaSelector(
    (instance = EMPTY_OBJECT) => {
      const { StartDate, EndDate } = instance;

      return { StartDate, EndDate };
    }
  );

  return (
    <>
      {cloneElement(
        element,
        {
          ref: buttonRef,
          onClick: () => setShowCustomPicker(true),
        },
        [<Icon key={CUSTOM_DATE_RANGE} icon={CUSTOM_DATE_RANGE} />]
      )}
      <CustomDateRangePickerPopup
        showPopup={showCustomPicker}
        value={{
          start: StartDate,
          end: EndDate,
        }}
        anchor={buttonRef.current}
        onClose={() => setShowCustomPicker(false)}
        onChange={({ range: { from, to } }) => {
          onValueChange({
            id: ID_CUSTOM_DATE_OPTION,
            item: {
              StartDate: from(),
              EndDate: to(),
            },
          });
          onValidationChange(true);
          setShowCustomPicker(false);
        }}
        format={format}
      />
    </>
  );
};

const defaultValueFn = (value) => {
  const { text: ResultValue, id: ResultValueID, item } = value;

  if (ResultValueID === ID_CUSTOM_DATE_OPTION) {
    return item;
  }

  return {
    ScoreValue: pluck('ScoreValue')(item),
    ResultValue,
    ResultValueID: `${ResultValueID}`,
  };
};

export const DateBar = (props) => {
  const {
    readOnly,
    component: WrappingComponent = DefaultComponent,
    node,
    onValidated,
    dataPath = primarySchemaKeys.DateBar,
    refDataId: propsRefDataId,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, IsRequired, IsVisible } = node;

  const { required: refDataId = propsRefDataId, Custom } =
    toAttributesObject(AttrDetail);

  const { [refDataId]: RefData } = useXeRefData();

  const buttonGroupData = useMemo(() => {
    if (Custom) {
      return [...RefData, CUSTOM_DATE_OPTION];
    }

    return RefData;
  }, [Custom, RefData]);

  const schemaDispatch = useSchemaDispatch();

  const {
    value: schemaHookValue,
    validityMessage,
    fullPath,
  } = useSchema(dataPath);

  const { StartDate, EndDate } = useSchemaSelector(
    (instance = EMPTY_OBJECT) => {
      const { StartDate, EndDate } = instance;

      return { StartDate, EndDate };
    }
  );

  const customOptionSelected = !isNil(StartDate) && !isNil(EndDate);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <WrappingComponent data-component-name="SmartbookDateBar">
      <ButtonGroup
        dataElementName={Name}
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        required={IsRequired}
        disabled={readOnly}
        className="margin-left-small"
        data={buttonGroupData}
        value={customOptionSelected ? ID_CUSTOM_DATE_OPTION : schemaHookValue}
        labelFn={pluck('text')}
        comparator={(value, { id } = {}) => {
          return value == id;
        }}
        renderItem={({ element, item, ...rest }) => {
          if (item.id === ID_CUSTOM_DATE_OPTION) {
            return (
              <DateBarCustomOptionComponent
                key={item.id}
                element={element}
                {...rest}
              />
            );
          }
          return element;
        }}
        onChange={(value) => {
          const { id: ResultValueID, item } = value;

          if (ResultValueID === ID_CUSTOM_DATE_OPTION) {
            schemaDispatch({
              type: DELETE,
              path: 'ResultValue',
            });
            schemaDispatch({
              type: DELETE,
              path: 'ResultValueID',
            });

            const { StartDate, EndDate } = item;

            schemaDispatch({
              type: MERGE_PATCH,
              value: valueFn(value),
            });
            onValidChange(
              baseNodeValidator(
                true,
                StartDate && EndDate ? true : null,
                IsRequired
              )
            );
          } else {
            schemaDispatch({
              type: DELETE,
              path: 'StartDate',
            });
            schemaDispatch({
              type: DELETE,
              path: 'EndDate',
            });

            const nextValue = valueFn(value);

            schemaDispatch({
              type: MERGE_PATCH,
              value: nextValue,
            });

            onValidChange(
              baseNodeValidator(
                true,
                nextValue[primarySchemaKeys.DateBar],
                IsRequired
              )
            );
          }
        }}
      />
    </WrappingComponent>
  );
};
