import { pluck } from '../../../fp/object';
import { isEmpty, isNil } from '../../../fp/pred';
import { SchemaReducer } from '../../../schema/SchemaReducer';
import { withDefaultJSONSchemaReducer } from '../../../schema/JSONSchemaReducer';
import { useCallback, useState, useEffect } from 'react';
import VisitCorrespondenceEnterSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter.json';
import { Flexbox, Label, Popup } from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import { useXeRights } from '../../../contexts/XeUserRightsContext';
import { isTrue } from '../../../utils';
import { withClassNameModifiers } from '../../../utils/withClassNameModifiers';
import { PDFViewerPopup } from '../../PDFViewer';
import { ViewSwitcher } from '../../ViewSwitcher';
import {
  HIDE_LETTER_PREVIEW,
  MARK_IN_ERROR_BEFORE_CLOSE,
  QUERY_DEFAULT_SPEC,
  SHOULD_CLOSE_WIZARD,
  SHOULD_PREVIEW_LETTER,
  SHOULD_SAVE_LETTER,
  WIZARD_FLOW_PROCEED,
  WIZARD_FLOW_RETURN,
  SET_SELECTED_CUSTOM_LANGUAGE,
  UPDATE_SELECTED_SIGNEE,
} from '../actions';
import CustomLanguage from '../components/CustomLanguage';
import DataEntry from '../components/DataEntry';
import { toFooter } from '../components/Footer';
import LetterWizardAttachments from '../components/LetterWizardAttachments';
import Recipients from '../components/Recipients';
import SelectLetter from '../components/SelectLetter';
import Signature from '../components/Signature';
import { WizardHeader } from '../components/WizardHeader';
import {
  LETTER_STATUS_DRAFT,
  LETTER_STATUS_FINAL,
  WIZARD_STEPS,
} from '../constants';
import { toFilteredByProgramBits } from '../../../utils/bitFiltering';
import { useXeQuery } from '../../../data/useXeQuery';
import { getTypeTree } from 'services/correspondence-infos/xe-correspondence-infos-svc.js';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { identity } from '../../../fp/fp';

const customLetterJsonReducer = (state, action = {}) => {
  const { path, value } = action;
  const { instance } = state;

  switch (path) {
    case 'BookID.XeSmartBookInstance': {
      return {
        ...state,
        instance: {
          ...instance,
          CustomLanguageID: pluck('CustomLanguageID')(value),
        },
      };
    }
  }

  return state;
};

const letterWizardJsonReducer = withDefaultJSONSchemaReducer(
  customLetterJsonReducer
);

const pageConfig = {
  [WIZARD_STEPS.SELECT_LETTER]: {
    onNext: (dispatch) => {
      return dispatch({
        type: QUERY_DEFAULT_SPEC,
      });
    },
  },
};

const Breadcrumbs = ({ currentStep }) => {
  const labels = useXeLabels();

  const breadcrumbLabels = [
    labels.SelectLetter,
    labels.CustomLanguage,
    labels.DataEntry,
    labels.SelectSignature,
    labels.Attachments,
    labels.Recipients,
  ];

  return (
    <Flexbox className="flex-shrink-0">
      {breadcrumbLabels.map((label, breadcrumbIndex) => {
        const selected = currentStep === breadcrumbIndex;
        return (
          <Label
            dataElementName={label}
            key={breadcrumbIndex}
            className={withClassNameModifiers('letter-wizard__breadcrumb', {
              active: selected,
            })}
          >
            {label}
          </Label>
        );
      })}
    </Flexbox>
  );
};

const useRecipientsIndexState = () => {
  const [recipientsIndex, setRecipientsIndex] = useState(0);
  return () => [recipientsIndex, setRecipientsIndex];
};

export const DefaultView = (props) => {
  const {
    dispatch,
    params: {
      LetterType: letterTypeProp,
      SubType: subTypeProp,
      Status: statusProp = LETTER_STATUS_FINAL,
      IsWorkList: isWorkList,
      IPID,
      LOB,
      WorkList: { IPID: { WrittenLanguageID } = EMPTY_OBJECT } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    state: {
      currentLetterPreview: currentPDFPreview,
      initialLetter,
      letterTemplateSpec = EMPTY_OBJECT,
      faxCoverSheets = EMPTY_ARRAY,
      mailCoverSheets = EMPTY_ARRAY,
      letterTemplates: templates = EMPTY_ARRAY,
      selectedCustomLanguage = EMPTY_OBJECT,
      selectedSignee = EMPTY_OBJECT,
      selectedFaxCover,
      selectedMailCover,
      letterType = letterTypeProp,
      subType = subTypeProp,
      letterStatus = statusProp,
      previewVisitCorrespondenceId,
      isEditMode,
      selectedLetterTemplate: selectedTemplate = EMPTY_OBJECT,
      isSearchForAllPrograms,
      medicalDirectors = EMPTY_ARRAY,
      currentStep,
      lineOfBusiness,
      attachmentsViewState,
      clinDocRefData,
      clinDocs,
      attachmentSets,
      reports,
      notifications = EMPTY_OBJECT,
    },
    filterBits,
  } = props;
  const {
    XeCustomLanguage: customLanguages = EMPTY_ARRAY,
    XeCorrespondenceInfo: {
      XeLanguage: languages = EMPTY_ARRAY,
    } = EMPTY_OBJECT,
  } = letterTemplateSpec;

  /**
   * @type {import('services/generated/types').VisitCorrespondenceEnter}
   */
  const [schemaReducerState, setSchemaReducerState] = useState({});

  /**
   * @type {{ instance: import('services/generated/types').VisitCorrespondenceEnter }}
   */
  const {
    instance = EMPTY_OBJECT,
    valid: instanceValid,
    validity = EMPTY_OBJECT,
  } = schemaReducerState;

  const { CREATE_CORRESPONDENCE_QUICK_LETTER } = useXeRights();

  const [isQuickLetter, setIsQuickLetter] = useState(
    CREATE_CORRESPONDENCE_QUICK_LETTER
  );

  //State management moved from Recipients component because not preserving state after previewing Fax nor Mail. SNETEXT-270(MF)
  const useRecipientsIndex = useRecipientsIndexState();
  useEffect(() => {
    setIsQuickLetter(CREATE_CORRESPONDENCE_QUICK_LETTER);
  }, [CREATE_CORRESPONDENCE_QUICK_LETTER]);

  const labels = useXeLabels();

  const { XeHealthPlanLoB: lobRefData } = useXeRefData();
  const { data: correspondenceTypeTree = EMPTY_ARRAY } = useXeQuery(
    getTypeTree({}, identity)
  );
  const categoryRefData = correspondenceTypeTree
    .filter((item) => {
      const { TypeID } = item;
      return !TypeID || letterTypeProp === TypeID;
    })
    .reduce((acc, curr) => {
      if (!curr) return acc;
      const categories = pluck('XeCorrespondenceType')(curr) || [];
      return [...acc, ...categories];
    }, [])
    .filter((item) => {
      const { TypeID, IsWorkflow = false } = item;
      return (
        (!subTypeProp || subTypeProp === TypeID) &&
        isTrue(isWorkList) === IsWorkflow
      );
    });

  const { onNext } = pageConfig[currentStep] || EMPTY_OBJECT;

  const toIsPageValid = (page) => {
    const invalidProperties = Object.keys(validity);
    /**
     * @param {(keyof import('services/generated/types').VisitCorrespondenceEnter)[]} propArr
     */
    const validateInterestingProperties = (propArr) => {
      return isNil(
        propArr.find((id) => {
          return (
            invalidProperties.includes(id) ||
            invalidProperties.includes(`_${id}`)
          );
        })
      );
    };
    switch (page) {
      case WIZARD_STEPS.SELECT_LETTER: {
        /**
         * @type {import('services/generated/types').CorrespondenceInfoResponse}
         */
        const { CorrespondenceInfoID } = selectedTemplate || {};
        return !isNil(CorrespondenceInfoID);
      }
      case WIZARD_STEPS.CUSTOM_LANGUAGE: {
        return !isEmpty(selectedCustomLanguage);
      }
      case WIZARD_STEPS.DATA_ENTRY: {
        return validateInterestingProperties([
          'BookID',
          'MailCoverBookID',
          'FaxCoverBookID',
        ]);
      }
      case WIZARD_STEPS.SELECT_SIGNATURE: {
        return validateInterestingProperties(['SignerID']);
      }
      case WIZARD_STEPS.RECIPIENTS: {
        return validateInterestingProperties(['XeVisitCorrespRecip']);
      }
      default: {
        return true;
      }
    }
  };

  const toOnSave =
    (saveType) =>
    ({ letterStatus, recipient }) => {
      const _letterStatus = (() => {
        if (isTrue(isWorkList) && letterStatus === LETTER_STATUS_FINAL) {
          return statusProp;
        }

        return letterStatus || LETTER_STATUS_DRAFT;
      })();

      /**
       * @type {import('services/generated/types').VisitCorrespondenceEnter}
       */
      const nextLetter = {
        ...instance,
        LetterStatus: _letterStatus,
        IsQuickLetter: isQuickLetter,
      };

      dispatch({
        type: saveType,
        value: {
          letter: nextLetter,
          //TODO: Instead of keeping selectedTemplate and selectedCustomLanguage outside of the schemaInstance
          // we should try and include it within the rest of the object. (PDD) SYNUI-6018
          selectedTemplate,
          selectedCustomLanguage,
          recipientToPreview: recipient,
        },
      });
    };

  const onClosePDFPopup = useCallback(() => {
    dispatch({
      type: HIDE_LETTER_PREVIEW,
    });
  }, [dispatch]);

  const bitFilteredTemplates =
    filterBits !== undefined && !isSearchForAllPrograms
      ? toFilteredByProgramBits(templates, filterBits)
      : templates;

  return (
    <Popup
      dataElementName="addLetterWizard"
      size="large"
      title={labels.AddLetterWizard}
      FooterComponent={toFooter({
        labels,
        visitCorrespondenceEnter: instance,
        letterType,
        subType,
        letterStatus,
        isQuickLetter,
        checkAutoGenerateEnabled: CREATE_CORRESPONDENCE_QUICK_LETTER,
        onCheckAutoGenerate: () => {
          setIsQuickLetter(!isQuickLetter);
        },
        nextEnabled:
          toIsPageValid(currentStep) && currentStep !== WIZARD_STEPS.RECIPIENTS,
        backEnabled: currentStep !== WIZARD_STEPS.SELECT_LETTER,
        saveEnabled: instanceValid && currentStep === WIZARD_STEPS.RECIPIENTS,
        onSave: toOnSave(SHOULD_SAVE_LETTER),
        onClose: () => {
          if (!!previewVisitCorrespondenceId && !isEditMode) {
            return dispatch({
              type: MARK_IN_ERROR_BEFORE_CLOSE,
              value: previewVisitCorrespondenceId,
            });
          }
          return dispatch({
            type: SHOULD_CLOSE_WIZARD,
          });
        },
        onNext: () => {
          if (onNext) return onNext(dispatch);
          return dispatch({
            type: WIZARD_FLOW_PROCEED,
          });
        },
        onBack: () => {
          return dispatch({
            type: WIZARD_FLOW_RETURN,
          });
        },
      })}
    >
      <Flexbox direction="column" className="overflow-auto flex-1">
        <Breadcrumbs currentStep={currentStep} />
        <WizardHeader
          letter={instance}
          notifications={notifications}
          xeCorrespondenceInfoName={pluck(
            'XeCorrespondenceInfo',
            'Name'
          )(letterTemplateSpec)}
          selectedCustomLanguage={selectedCustomLanguage}
          selectedSignee={selectedSignee}
          selectedFax={selectedFaxCover}
          selectedMail={selectedMailCover}
          complianceDate={letterTemplateSpec?.ComplianceDate}
        />
        <SchemaReducer
          schema={VisitCorrespondenceEnterSchema}
          initialValue={initialLetter}
          onChange={setSchemaReducerState}
          toJsonReducer={letterWizardJsonReducer}
        >
          <ViewSwitcher
            viewComponent={Flexbox}
            viewClassName="overflow-auto flex-1"
            activeViewId={currentStep}
          >
            <ViewSwitcher.View
              dataElementName="selectLetterView"
              viewId={WIZARD_STEPS.SELECT_LETTER}
            >
              <SelectLetter
                dispatch={dispatch}
                lineOfBusiness={lineOfBusiness}
                lobRefData={lobRefData}
                categoryRefData={categoryRefData}
                faxCoverSheets={faxCoverSheets}
                mailCoverSheets={mailCoverSheets}
                templates={bitFilteredTemplates}
                selectedTemplate={selectedTemplate}
                selectedFaxCover={selectedFaxCover}
                selectedMailCover={selectedMailCover}
                defaultLob={LOB}
                isSearchForAllPrograms={isSearchForAllPrograms}
                subType={subType}
                isWorkList={isWorkList}
              />
            </ViewSwitcher.View>
            <ViewSwitcher.View viewId={WIZARD_STEPS.CUSTOM_LANGUAGE}>
              <CustomLanguage
                customLanguages={customLanguages}
                selectedCustomLanguage={selectedCustomLanguage}
                onSelectLanguage={(value) => {
                  dispatch({
                    type: SET_SELECTED_CUSTOM_LANGUAGE,
                    value,
                  });
                }}
              />
            </ViewSwitcher.View>
            <ViewSwitcher.View
              dataElementName="dataEntryView"
              viewId={WIZARD_STEPS.DATA_ENTRY}
            >
              <DataEntry />
            </ViewSwitcher.View>
            <ViewSwitcher.View viewId={WIZARD_STEPS.SELECT_SIGNATURE}>
              <Signature
                medicalDirectors={medicalDirectors}
                onUpdateSignee={(value) => {
                  dispatch({
                    type: UPDATE_SELECTED_SIGNEE,
                    value,
                  });
                }}
              />
            </ViewSwitcher.View>
            <ViewSwitcher.View viewId={WIZARD_STEPS.ATTACHMENTS}>
              <LetterWizardAttachments
                dispatch={dispatch}
                IPID={IPID}
                attachmentsViewState={attachmentsViewState}
                clinDocRefData={clinDocRefData}
                clinDocs={clinDocs}
                attachmentSets={attachmentSets}
                reports={reports}
              />
            </ViewSwitcher.View>
            <ViewSwitcher.View viewId={WIZARD_STEPS.RECIPIENTS}>
              <Recipients
                useRecipientsIndex={useRecipientsIndex}
                selectedCustomLanguage={selectedCustomLanguage}
                languages={languages}
                WrittenLanguageID={WrittenLanguageID}
                isAllPagesValid={instanceValid}
                onPreview={toOnSave(SHOULD_PREVIEW_LETTER)}
                IPID={IPID}
              />
            </ViewSwitcher.View>
          </ViewSwitcher>
        </SchemaReducer>
      </Flexbox>
      {currentPDFPreview && (
        <PDFViewerPopup file={currentPDFPreview} onClose={onClosePDFPopup} />
      )}
    </Popup>
  );
};

export default DefaultView;
