import { props } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { Flexbox, Label, Checkbox, IconButton } from '../../../../components';
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  NOOP_FUNCTION,
} from '../../../../constants';
import { useEnterprise } from '../../../../contexts/XeEnterpriseContext';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { REMOVE } from '../../../../icons';
import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { formatStaffName } from '../../../../utils';
import { withClassNameModifiers } from '../../../../utils/withClassNameModifiers';
import TextArea from '../../../TextArea';
import { ExpandableNode } from '../../components/ExpandableNode';
import { useSmartBookNodeContext } from '../../hooks';
import primarySchemaKeys from '../../primarySchemaKeys';
import { Text } from '../Text';
import { toNodePropertiesWithDefaults } from '../utils';

const NewEntry = (props) => {
  const { node, dataPath, onValidated } = props;
  const labels = useXeLabels();
  return (
    <Flexbox className="care-plan-background-entry margin-bottom-large">
      <Flexbox direction="column" className="flex-1">
        <Text
          node={{
            ...node,
            Name: undefined,
          }}
          className="stretch-x editable"
          dataPath={dataPath}
          onValidated={onValidated}
          rows={2}
          placeholder={labels.CarePlanBackgroundPlaceholder}
        />
      </Flexbox>
    </Flexbox>
  );
};

const HistoryEntry = (props) => {
  const { node, onValidated, dataPath, onMarkInError, currentUser, readOnly } =
    props;

  const { StartedBy = EMPTY_OBJECT, ModifiedUser, ModifiedTStamp } = node;
  const { EnterpriseID: { EnterpriseName = '' } = EMPTY_OBJECT } = StartedBy;

  const { value } = useSchema(dataPath);

  const isAuthorCurrentUser = currentUser === ModifiedUser;
  const isReadOnly = readOnly || (!!ModifiedUser && !isAuthorCurrentUser);

  return (
    <Flexbox className="care-plan-background-entry margin-bottom-large">
      <Flexbox direction="column" className="flex-1">
        {/**
         * Text AttrType doesn't allow the explicit disabling of MaxLength or
         * ResizeY due to both node and schema usage. As a result, using the
         * TextArea xnetjs component to display read-only entries.
         */}
        {isReadOnly ? (
          <TextArea
            readOnly={true}
            className="stretch-x fill-parent-width read-only margin-bottom-small"
            value={value}
            resizeY={false}
            rows={2}
          />
        ) : (
          <Text
            node={{
              ...node,
              Name: undefined,
            }}
            readOnly={isReadOnly}
            className="stretch-x editable"
            dataPath={dataPath}
            onValidated={onValidated}
            rows={2}
          />
        )}
        <Flexbox justifyContent="flex-end">
          <Label>
            {`${formatStaffName(StartedBy)}: ${toDisplayDateFromISOString(
              ModifiedTStamp,
              localeFormat.LONG
            )}, ${EnterpriseName}`}
          </Label>
        </Flexbox>
      </Flexbox>
      <Flexbox direction="column" justifyContent="flex-start">
        <IconButton
          disabled={!isAuthorCurrentUser}
          icon={REMOVE}
          onClick={() => {
            onMarkInError({
              ...node,
              Active: false,
              Modified: true,
            });
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

const CarePlanBackgroundNode = (props) => {
  const {
    node = EMPTY_OBJECT,
    readOnly,
    dataPath,
    onValidated = NOOP_FUNCTION,
    currentUser,
    showOnlyEditable,
    onMarkInError,
  } = props;

  const { ModifiedUser, Active } = node;

  const isAuthorCurrentUser = currentUser === ModifiedUser;
  const isReadOnly = readOnly || (!!ModifiedUser && !isAuthorCurrentUser);
  const shouldHide = showOnlyEditable && isReadOnly;

  // To preserve list order in relation to updating smartbook instance nodes via dataPath,
  // we are ensuring CrisisCarePlanBackgroundNode still renders with nothing in it
  if (shouldHide || !Active) return null;

  // if there's no modified user, it's a new entry
  if (!ModifiedUser) {
    return (
      <NewEntry node={node} dataPath={dataPath} onValidated={onValidated} />
    );
  } else {
    return (
      <HistoryEntry
        node={node}
        dataPath={dataPath}
        currentUser={currentUser}
        onMarkInError={onMarkInError}
        onValidated={onValidated}
        readOnly={readOnly}
      />
    );
  }
};

const EntryList = (props) => {
  const {
    entryList = EMPTY_ARRAY,
    readOnly,
    dataPath,
    onValidated,
    currentUser,
    filterByEditable,
    onMarkInError,
  } = props;
  return (
    <Flexbox className="care-plan-background-entry-list" direction="column">
      {entryList.map((entry, index, entryListArr) => {
        return (
          <CarePlanBackgroundNode
            key={entry?.InstanceID || -1}
            node={entry}
            readOnly={readOnly}
            dataPath={`${dataPath}.${index}.ResultValue`}
            onValidated={onValidated}
            currentUser={currentUser}
            showOnlyEditable={filterByEditable}
            onMarkInError={(updatedNode) => {
              const updatedXeSmartBookInstance = [
                ...entryListArr.slice(0, index),
                updatedNode,
                ...entryListArr.slice(index + 1),
              ];
              onMarkInError(updatedXeSmartBookInstance);
            }}
          />
        );
      })}
    </Flexbox>
  );
};

const byLatestedToEarliestFn = (entryA, entryB) => {
  if (entryA?.ModifiedTStamp > entryB?.ModifiedTStamp) {
    return -1;
  } else if (entryA?.ModifiedTStamp < entryB?.ModifiedTStamp) {
    return 1;
  }

  return 0;
};

const NEW_ENTRY_NODE = {
  AttrType: 'Text',
  Active: true,
  BookTypeID: 'CARE_PLAN_BACKGROUND_ENTRY',
  IsRequired: false,
  IsVisible: true,
  Name: 'Consumer Background Entry',
  ResultMax: 2000,
};

export const CarePlanBackground = (props) => {
  const {
    node,
    dataPath = primarySchemaKeys.CarePlanBackground,
    onValidated,
    readOnly,
  } = props;

  const { userData = EMPTY_OBJECT } = useEnterprise();
  const [hasAddedAdditionalEntry, setHasAddedAdditionalEntry] = useState(false);
  const { Username: currentUser = '' } = userData;
  const labels = useXeLabels();

  const [shouldFilterByEditableEntries, setShouldFilterByEditableEntries] =
    useState(false);

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    Name,
    IsRequired: initialIsRequired,
    IsVisible: initialIsVisible,
    XeSmartBookInstance: entriesToUpdate,
    BookID,
  } = nodeWithDefaults;

  const { fullPath, onValueChange, value = EMPTY_ARRAY } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [IsRequired = initialIsRequired, IsVisible = initialIsVisible] =
    useSchemaSelector((instance = {}) => {
      const { IsVisible, IsRequired } = instance;

      return [IsRequired, IsVisible];
    });

  const firstEntry = entriesToUpdate[0];
  const hasFreeTextNode = firstEntry && firstEntry.ModifiedUser === undefined;

  // useEffect used to initialize CarePlanBackground state so
  // a new input entry is shown on arrival and history is properly
  // sorted
  useEffect(() => {
    const { EnterpriseID, ResourceID } = userData;
    if (!hasAddedAdditionalEntry && !hasFreeTextNode) {
      // order entries by time from latest to earliest
      const entriesByDate = value.sort(byLatestedToEarliestFn);

      onValueChange([
        {
          ...NEW_ENTRY_NODE,
          BookID,
          // setting this to easily display author name and enterprise
          // for history entries
          StartedBy: { EnterpriseID: { EnterpriseID }, ResourceID },
        },
        ...entriesByDate,
      ]);
      setHasAddedAdditionalEntry(true);
    }
  }, [
    value,
    BookID,
    onValueChange,
    userData,
    hasAddedAdditionalEntry,
    hasFreeTextNode,
  ]);

  if (!IsVisible) return null;

  return (
    <ExpandableNode
      defaultExpanded={true}
      node={node}
      ItemElement={
        <Flexbox justifyContent="space-between stretch-x">
          <Label
            className={`${withClassNameModifiers('smartbook-label', {
              invalid: valid !== null && !valid,
            })} margin-vertical-small`}
            wrapText={false}
            required={IsRequired}
          >
            {Name}
          </Label>
          <Flexbox>
            <Checkbox
              checked={shouldFilterByEditableEntries}
              value={shouldFilterByEditableEntries}
              label={labels.ShowOnlyEditableEntries}
              onChange={() => {
                setShouldFilterByEditableEntries(
                  !shouldFilterByEditableEntries
                );
              }}
              className="margin-left-medium margin-vertical-small"
            />
          </Flexbox>
        </Flexbox>
      }
    >
      <EntryList
        entryList={entriesToUpdate}
        readOnly={readOnly}
        dataPath={dataPath}
        onValidated={onValidated}
        currentUser={currentUser}
        filterByEditable={shouldFilterByEditableEntries}
        onMarkInError={(nextSmartBookInstances) => {
          onValueChange(nextSmartBookInstances);
          onValidChange(true);
        }}
      />
    </ExpandableNode>
  );
};
