import { useSchema } from '../../../../schema/SchemaReducer';
import { isNil } from '../../../../fp/pred';
import { DateTime } from 'luxon';
import { DatePicker, Flexbox } from '../../../../components';
import { XS_DATE_TIME_FORMAT } from '../../../../g11n/ISODates';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toAttributesObject, toResultDateFromModifier } from '../../utils';
import { baseNodeValidator } from '../../validators';
import { EMPTY_OBJECT } from '../../../../constants';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, alignItems = 'center', ...rest } = props;
  return (
    <Flexbox alignItems={alignItems} {...rest}>
      {children}
    </Flexbox>
  );
};

const MINUTES_IN_DAY = 1440;

const formatBound = (boundInMs, formatString) => {
  if (Number.isNaN(boundInMs)) return undefined;
  return DateTime.fromMillis(boundInMs).toFormat(formatString);
};

const toDateBound = (boundInDays) =>
  !isNil(boundInDays) ? boundInDays * MINUTES_IN_DAY : undefined;

const toDateBounds = (resultMin, resultMax, formatString) => {
  const rightNowMs = Date.now();
  const minBound = formatBound(
    rightNowMs - toDateBound(resultMin),
    formatString
  );
  const maxBound = formatBound(
    rightNowMs + toDateBound(resultMax),
    formatString
  );
  return {
    min: minBound,
    max: maxBound,
  };
};

const DefaultDateFormat = 'date-time';

const DateComponent = (props) => {
  const {
    className,
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node = EMPTY_OBJECT,
    onValidated,
    dataPath = primarySchemaKeys.Date,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, ResultMin, ResultMax, IsVisible, IsRequired } =
    nodeWithDefaults;

  const { required: DateModifier } = toAttributesObject(AttrDetail);

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  const { min, max } = toDateBounds(ResultMin, ResultMax, XS_DATE_TIME_FORMAT);
  const invalid = !valid || !!validityMessage;

  return (
    <WrappingComponent>
      <DatePicker
        dataElementName={Name}
        format={DefaultDateFormat}
        className={className}
        value={value}
        required={IsRequired}
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        // TODO: (SYNUI-4869) We're getting ResultMin as "0" from the server. Is this semantic? (JDM)
        // min={ResultMin ? toDateFromISOString(ResultMin) : undefined}
        // max={ResultMax ? toDateFromISOString(ResultMax) : undefined}
        // This is using the legacy stuff that the previous tree component was using. This seems odd though.
        // Need to revisit why we are getting 0s and determine what that means. If I remember correctly,
        // it's related to the number of days from today in which we can select a date (JDM)
        min={min}
        max={max}
        disabled={readOnly}
        //There is a change in the DatePicker on how we are are receiving the onChange outside of a schema.
        //It is now passing through the uiValidity so we can update the schema validity. (JAC)
        onChange={(value, uiValid) => {
          const nextValue = toResultDateFromModifier(
            DateModifier,
            value,
            DefaultDateFormat,
            uiValid
          );
          onValueChange(nextValue);
          onValidChange(baseNodeValidator(uiValid, nextValue, IsRequired));
        }}
      />
    </WrappingComponent>
  );
};

export { DateComponent as Date };
