import { isNil } from '../../fp/pred';
import { isValidEmail } from '../../validators/inputPreds';

export const baseNodeValidator = (uiValid, value, isRequired) => {
  if (uiValid == false) return uiValid;

  if (!isRequired) return true;

  const valueExists = !isNil(value);
  return valueExists;
};

export const textValidator = (uiValid, value, isRequired) => {
  const baseValid = baseNodeValidator(uiValid, value, isRequired);
  if (baseValid == false) return baseValid;
  const trimmedValue = (value || '').trim();
  if (isRequired && !trimmedValue) return false;

  return !isNil(trimmedValue);
};

export const emailValidator = (uiValid, value, isRequired) => {
  const trimmedValue = (value || '').trim();

  return (
    textValidator(uiValid, value, isRequired) && isValidEmail(trimmedValue)
  );
};

export const isSetValidator = (isSet, isRequired, isSection) => {
  if (isSection && isSet === false && !isRequired) {
    return null;
  }

  const baseValid = baseNodeValidator(true, isSet, isRequired);
  return baseValid && (isRequired ? isSet : true);
};

export const isSetLabelValidator = (isSet, isRequired) => {
  const baseValid = baseNodeValidator(true, isSet, isRequired);
  return baseValid && (isRequired ? isSet : true);
};

export const substitutionSetValidator = (value, isRequired, wellKnownIds) => {
  if (!wellKnownIds) return true;
  const baseValid = baseNodeValidator(true, value, isRequired);

  const wkiArray = wellKnownIds.split('|');

  const allDataFilledIn = !wkiArray.some((id) => value.includes(`{${id}}`));
  return baseValid && (isRequired ? allDataFilledIn : true);
};

export const DefaultNodeValidators = {
  ButtonBar: baseNodeValidator,
  CarePlanBackground: baseNodeValidator,
  Combo: baseNodeValidator,
  correspondence: baseNodeValidator,
  customLang: baseNodeValidator,
  Date: baseNodeValidator,
  DateTime: baseNodeValidator,
  Decimal: baseNodeValidator,
  Email: emailValidator,
  FileUpload: baseNodeValidator,
  Input: textValidator,
  Integer: baseNodeValidator,
  Label: (_uiValid, value, isRequired) => {
    return isSetLabelValidator(value, isRequired);
  },
  MultiSelectDropDown: baseNodeValidator,
  Phone: baseNodeValidator,
  RadioGroup: (_uiValid, value, isRequired, { XeSmartBookInstance = [] }) => {
    return isSetValidator(value, isRequired, !!XeSmartBookInstance.length);
  },
  get RadioList() {
    return this.RadioGroup;
  },
  ScoreValueResult: baseNodeValidator,
  SelectLabel: (_uiValid, value, isRequired, { XeSmartBookInstance = [] }) => {
    return isSetValidator(value, isRequired, !!XeSmartBookInstance.length);
  },
  SubstitutionSet: (_uiValid, value, isRequired) =>
    substitutionSetValidator(value, isRequired),
  Text: textValidator,
};
