import { exists } from '../../../frp/operators/exists';
import { combineWithLatestFrom } from '../../../frp/operators/combineWithLatestFrom';
import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../../connection/toConnectionDef';
import { useReducer$ } from '../../../hooks/useReducer$';
import { useRef$ } from '../../../hooks/useRef$';
import { useMenuNode } from '../../../contexts/XeMenuNodeContext';
import { isType } from '../../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../../reducers';
import { when } from '../../../predication/when';
import { useCallback } from 'react';
import {
  Popup,
  toDefaultPopupFooter,
  Panel,
  Label,
  Checkbox,
  Flexbox,
} from '../../';
import { XeStaffSearchWidget } from '../../../widgets';
import { getPatientDetail } from 'services/patients/xe-patients-svc';
import { map, mergeMap, distinctUntilChanged, pluck } from 'rxjs/operators';
import { pluck as fpPluck } from '../../../fp/object';
import {
  formatStaffName,
  formatAddress,
  formatCommonName,
} from '../../../utils';
import { of } from 'rxjs';
import { phoneNumberFormatter } from '../../../format/phoneNumberFormatter';

const RESPONSE_PATIENT_DETAIL = 'response/patientDetail';
const SELECT = 'select';
const DESELECT = 'deselect';
const initialState = { selectedContacts: [], patient: {} };

const addContact = (state, { value }) => {
  const { selectedContacts = [] } = state;
  return { ...state, selectedContacts: [...selectedContacts, value] };
};

const removeContact = (state, { value }) => {
  const { selectedContacts = [] } = state;
  const { ProviderID, AddressID, PatientContactID } = value || {};

  return {
    ...state,
    selectedContacts: selectedContacts.filter(
      ({ ProviderID: p, AddressID: a, PatientContactID: pc, StaffID }) =>
        ProviderID !== p ||
        AddressID !== a ||
        PatientContactID !== pc ||
        (!value && typeof StaffID !== 'number')
    ),
  };
};

const toGetPatientDetailEpic$ = (action$, state$, { ipid$, menuNode$ }) =>
  ipid$.pipe(
    exists(),
    distinctUntilChanged(),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([ipid, toRequest$]) =>
      getPatientDetail({ ipid }, toRequest$({ fullRequest: true }))
    ),
    pluck('results'),
    map((patientResp = []) => ({
      type: RESPONSE_PATIENT_DETAIL,
      value: patientResp[0],
    }))
  );

const epic = combineEpics(toGetPatientDetailEpic$);
const reducer = combinePredicatedReducers(
  when((state) => state === undefined)(() => initialState),
  when(isType(RESPONSE_PATIENT_DETAIL))(toActionValueReducer('patient')),
  when(isType(SELECT))(addContact),
  when(isType(DESELECT))(removeContact)
);

// Note: Recipients panel expect contacts in a different structure than the request provides them in
const toFormattedContacts = (
  contacts,
  { FamilyName: patientFN, GivenName: patientGN }
) =>
  contacts.map((contact) => {
    const {
      AddressID,
      PatientContactID,
      ProviderID,
      StaffID,
      FamilyName,
      GivenName,
    } = contact;

    if (AddressID) return { ...contact, Name: `${patientGN} ${patientFN}` };
    if (PatientContactID)
      return { ...contact, Name: `${GivenName} ${FamilyName}` };
    if (ProviderID) {
      const { FamilyName: staffFN, GivenName: staffGN } = StaffID;
      return { ...StaffID, Name: `${staffGN} ${staffFN}`, IsProvider: true };
    }

    return { ...contact, Name: `${GivenName} ${FamilyName}`, IsProvider: true };
  });

const toContactLabel = (contact, faxLabel) => {
  const { Fax, RelationTypeID: { Name } = {} } = contact;
  return `${formatCommonName(contact, 'F, G')} (${Name}) ${formatAddress(
    contact
  )} ${Fax && `${faxLabel} ${phoneNumberFormatter(Fax)}`}`;
};

export const Contacts = ({ ipid, onAdd, onCancel, labels }) => {
  const menuNode = useMenuNode();
  // ipid parameter might be an IPID object
  const ipid$ = useRef$(fpPluck('IPID')(ipid) || ipid);

  const epicWithDeps = useCallback(
    (action$, state$) =>
      epic(action$, state$, { menuNode$: of(menuNode), ipid$ }),
    [menuNode, ipid$]
  );

  const [state = {}, dispatch] = useReducer$(reducer, epicWithDeps);
  const { patient = {}, selectedContacts = [] } = state;
  const {
    XePatientAddress = [],
    XePatientContact = [],
    XePatientProvider = [],
  } = patient;

  return (
    <Popup
      dataElementName="letterWizard__contactsPopup"
      title={labels.Contacts}
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'Cancel',
        confirmLabelKey: 'Add',
        disableConfirm: !selectedContacts.length,
        onClose: onCancel,
        onConfirm: () => onAdd(toFormattedContacts(selectedContacts, patient)),
      })}
      size="medium"
    >
      <div className="flex-1 display-grid">
        <Panel dataElementName="letterWizard__patientAddress">
          <Label
            dataElementName="letterWizard__patientAddress__label"
            className="bold flex-0"
          >
            {labels.PatientAddresses}
          </Label>
          {XePatientAddress.map((address = {}) => {
            const {
              AddressID,
              AddressLine1,
              City,
              StateProvince,
              ContactInfoTypeID: { Name } = {},
            } = address;
            const checked = !!selectedContacts.find(
              ({ AddressID: a }) => AddressID === a
            );

            return (
              <div key={AddressID}>
                <Checkbox
                  label={`${Name} ${AddressLine1} ${City}, ${StateProvince}`}
                  onChange={() =>
                    dispatch({
                      type: checked ? DESELECT : SELECT,
                      value: address,
                    })
                  }
                  checked={checked}
                />
              </div>
            );
          })}
        </Panel>

        <Panel dataElementName="letterWizard__personalContact">
          <Label
            dataElementName="letterWizard__personalContact__label"
            className="bold flex-0"
          >
            {labels.PersonalContacts}
          </Label>
          {XePatientContact.map((contact = {}) => {
            const { PatientContactID } = contact;
            const checked = !!selectedContacts.find(
              ({ PatientContactID: pc }) => PatientContactID === pc
            );

            return (
              <div key={PatientContactID}>
                <Checkbox
                  label={toContactLabel(contact, labels.Fax)}
                  onChange={() =>
                    dispatch({
                      type: checked ? DESELECT : SELECT,
                      value: contact,
                    })
                  }
                  checked={checked}
                />
              </div>
            );
          })}
        </Panel>

        <Panel dataElementName="letterWizard__clinicalContacts">
          <Label className="bold flex-0">{labels.ClinicalContacts}</Label>
          {XePatientProvider.map((provider = {}) => {
            const {
              ProviderID,
              StaffID,
              XePatientProviderType = {},
            } = provider;
            const checked = !!selectedContacts.find(
              ({ ProviderID: p }) => ProviderID === p
            );

            return (
              <div key={ProviderID}>
                <Checkbox
                  label={`${formatStaffName(StaffID)} ${
                    XePatientProviderType.Name
                      ? `(${XePatientProviderType.Name})`
                      : ''
                  }`}
                  onChange={() =>
                    dispatch({
                      type: checked ? DESELECT : SELECT,
                      value: provider,
                    })
                  }
                  checked={checked}
                />
              </div>
            );
          })}
        </Panel>

        <Panel dataElementName="letterWizard__otherProviders">
          <Flexbox justifyContent="space-between" alignItems="center">
            <Label
              dataElementName="letterWizard__otherProviders__label"
              className="bold"
            >
              {labels.OtherProviders}
            </Label>
            <div>
              <Label dataElementName="letterWizard__searchProvider__label">
                {labels.SearchProviders}
              </Label>
              <XeStaffSearchWidget
                dataElementName="letterWizard__searchProvider"
                onChange={(provider) =>
                  dispatch({
                    type: provider ? SELECT : DESELECT,
                    value: provider,
                  })
                }
                clearButton
              />
            </div>
          </Flexbox>
        </Panel>
      </div>
    </Popup>
  );
};

export default Contacts;
