import { localeFormat } from '../../format/luxonToDisplayString';
import { Grid, Label, Panel, Popup, toDefaultPopupFooter } from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { formatCommonName, formatStaffName } from '../../utils';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const columnDefs = [
  {
    headerLabelKey: 'DateTime',
  },
  {
    headerLabelKey: 'ModifiedBy',
  },
  {
    headerLabelKey: 'Date',
  },
  {
    headerLabelKey: 'Status',
  },
  {
    headerLabelKey: 'Priority',
  },
  {
    headerLabelKey: 'Category',
  },
  {
    headerLabelKey: 'Description',
  },
  {
    headerLabelKey: 'Resource',
  },
];

const HeaderItem = ({ label = '', value = '', dataElementName = '' }) => (
  <span data-element-name={dataElementName ? `${dataElementName}__label` : ''}>
    {label}
    <span
      data-element-name={dataElementName ? dataElementName : ''}
      className="bold header-data-item"
    >
      {value}
    </span>
  </span>
);

export const TaskChangeLog = (props) => {
  const { task = EMPTY_OBJECT, onClose = NOOP_FUNCTION } = props;
  const { CreateTStamp, ProviderID = {}, IPID = {} } = task;

  const labels = useXeLabels();

  return (
    <Popup
      title={labels.ChangeLog}
      dataElementName={labels.ChangeLog}
      className="change-log-popup"
      FooterComponent={toDefaultPopupFooter({
        onClose,
      })}
    >
      <Panel className="change-log flex-1">
        <div className="change-log-header">
          <HeaderItem
            dataElementName="taskChangeLog__creationDate"
            label={labels.CreationDate}
            value={toDisplayDateFromISOString(CreateTStamp, localeFormat.LONG)}
          />
          <HeaderItem
            dataElementName="taskChangeLog__createdBy"
            label={labels.CreatedBy}
            value={formatStaffName(ProviderID)}
          />
          <HeaderItem
            dataElementName="taskChangeLog__patientName"
            label={labels.Patient}
            value={formatCommonName(IPID)}
          />
          <HeaderItem
            dataElementName="taskChangeLog__patientId"
            label={labels.PatientID}
            value={IPID.PatientID}
          />
        </div>
        <Label
          className="padding-all-medium"
          dataElementName="taskChangeLog__modifications"
        >
          {labels.Modifications}
        </Label>
        {/*
          TODO: no endpoint exists yet for retrieving change log data
          TODO: once data is available, need to figure out a key for AG Grid getRowId
        */}
        <Grid columnDefs={columnDefs} data={[]} />
      </Panel>
    </Popup>
  );
};

export default TaskChangeLog;
