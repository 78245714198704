import { useMemo } from 'react';
import { toAttributesObject } from '../../utils';
import primarySchemaKeys from '../../primarySchemaKeys';
import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { pluck } from '../../../../fp/object';
import * as Widgets from '../../../../widgets';
import { withClassNameModifiers } from '../../../../utils/withClassNameModifiers';
import { Flexbox } from '../../../Flexbox';
import { baseNodeValidator } from '../../validators';
import { pick } from 'lodash';
import { EMPTY_OBJECT } from '../../../../constants';
import { identity } from '../../../../fp/fp';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const widgetPropsProjectionMap = {
  XeOrgUnitWidget: (attributes) => pick(attributes, 'OrgUnitTypeID'),
};

const toWidgetSpecificProperties = (attrDetail) => {
  const attrDetailObject = toAttributesObject(attrDetail);

  const {
    required: widgetType,
    EnableAddNew = false,
    // TODO: (SYNUI-4869) Need to figure out how these get integrated into the various widgets (JDM)
    // IsNetworkSearch,
    // ResourceTypeID
  } = attrDetailObject;

  const getWidgetProps =
    widgetPropsProjectionMap[widgetType] || (() => EMPTY_OBJECT);

  return {
    widgetType,
    canAdd: EnableAddNew === 'True',
    ...getWidgetProps(attrDetailObject),
  };
};

const WidgetComponentProxy = new Proxy(
  {
    XeStaffWidget: Widgets.XeStaffSearchWidget,
  },
  {
    get: (target, prop) => {
      if (prop in target) {
        return target[prop];
      }
      return Widgets[prop] || (() => `${prop} not found.`);
    },
  }
);

const PrimaryWidgetKeys = {
  XeUserWidget: 'StaffID',
  XeStaffWidget: 'StaffID',
  XeStaffSearchWidget: 'StaffID',
  XeNetworkProviderWidget: 'StaffID',
  XePatientSearchWidget: 'IPID',
  XeOrgUnitWidget: 'OrgUnitID',
};

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox direction="row" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Widget = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node,
    onValidated,
    dataPath = primarySchemaKeys.Widget,
    widgetType: propsWidgetType,
    valueFn,
    widgetProps = EMPTY_OBJECT,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    AttrDetail,
    IsRequired: initialIsRequired,
    IsVisible,
    Name,
  } = nodeWithDefaults;

  const { value, onValueChange, validityMessage, fullPath } =
    useSchema(dataPath);

  const [IsRequired = initialIsRequired] = useSchemaSelector(
    (instance = EMPTY_OBJECT) => {
      const { IsRequired } = instance;

      return [IsRequired];
    }
  );

  const widgetSpecificProps = toWidgetSpecificProperties(AttrDetail);

  const { widgetType = propsWidgetType, ...rest } = widgetSpecificProps;

  const WidgetComponent = useMemo(() => {
    return WidgetComponentProxy[widgetType];
  }, [widgetType]);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  const invalid = !valid || !!validityMessage;
  const primaryKey = PrimaryWidgetKeys[widgetType];

  const numericValue = parseInt(value);
  const widgetValue = Number.isNaN(numericValue) ? value : numericValue;

  return (
    <WrappingComponent>
      <WidgetComponent
        {...widgetProps}
        dataElementName={Name}
        disabled={readOnly}
        value={widgetValue}
        valueFn={valueFn || (primaryKey ? pluck(primaryKey) : identity)}
        onChange={(value /* , valid */) => {
          onValueChange(value);
          onValidChange(baseNodeValidator(true, value, IsRequired));
        }}
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        {...rest}
      />
    </WrappingComponent>
  );
};
