import { DateTime, Interval } from 'luxon';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { toAttributesObject } from './utils';

const isNullOrEmpty = (v) => v === undefined || v.length == 0;

const calculateListValueRange = (
  scoreValue,
  XeClinicalRefData,
  AssetIDColumn
) => {
  if (isNullOrEmpty(scoreValue) || isNullOrEmpty(AssetIDColumn)) return '';

  const numScoreValue = parseFloat(scoreValue);
  const { [AssetIDColumn]: id = '' } =
    XeClinicalRefData.find(({ ScoreMin = -1, ScoreMax = -1 } = {}) => {
      return ScoreMin <= numScoreValue && numScoreValue <= ScoreMax;
    }) || {};

  return id;
};

export const listValueRange =
  ({ referenceData } = EMPTY_OBJECT) =>
  (scoreValue, listControl) => {
    const { AttrDetail } = listControl;

    const { required: refDataId } = toAttributesObject(AttrDetail);

    const { [refDataId]: RefData = EMPTY_OBJECT } = referenceData;

    const { AssetIDColumn = '', XeClinicalRefData = EMPTY_ARRAY } = RefData;

    return calculateListValueRange(
      scoreValue,
      XeClinicalRefData,
      AssetIDColumn
    );
  };

/**
 *
 * @param {import('luxon').Interval} interval
 * @param {'DAY' | 'HOUR' | 'MINUTE'} precision
 * @returns
 */
const toFormattedDatesInterval = (interval, precision) => {
  const intervalAsDuration = interval.toDuration();
  const formatFn = intervalAsDuration.toFormat.bind(intervalAsDuration);
  switch (precision) {
    case 'DAY': {
      return formatFn('d');
    }
    case 'HOUR': {
      return formatFn('h');
    }
    case 'MINUTE': {
      return formatFn('m');
    }
    default: {
      throw new TypeError(`invalid datediff precision supplied`);
    }
  }
};

export const datediff =
  ({ referenceData } = {}) =>
  (start, end, precision = 'DAY') => {
    if (!start || !end) return '';

    const startDateTime = DateTime.fromISO(start);
    const endDateTime = DateTime.fromISO(end);

    if (endDateTime < startDateTime) return '';

    const interval = Interval.fromDateTimes(
      DateTime.fromISO(start),
      DateTime.fromISO(end)
    );

    if (!interval.isValid) return '';

    return toFormattedDatesInterval(interval, precision);
  };
