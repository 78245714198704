import { Button } from '../../Button';

export const Footer = ({ children, className, ...rest }) => {
  const baseClassName = 'xe-popup__footer k-primary';
  const _className = className
    ? `${baseClassName} ${className}`
    : baseClassName;
  return (
    <div {...rest} className={_className}>
      {children}
    </div>
  );
};

export const FooterButton = ({ className, ...rest }) => {
  const baseClassName = 'xe-popup__button';
  const _className = className
    ? `${baseClassName} ${className}`
    : baseClassName;
  return <Button {...rest} className={_className} look="outline" />;
};
