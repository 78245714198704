import { Label } from '../../Label';
import { GridLayout } from '../../GridLayout';
import { ExpandButton } from './ExpandButton';
import { NotificationDisplay } from './NotificationDisplay';
import { DecisionDueDisplay } from './DecisionDueDisplay';
import { DecisionActualDisplay } from './DecisionActualDisplay';
import { alertsNumber } from './NotificationsAlertsNumber';

const NoficationsDetails = (
  notifications,
  DueLabel,
  ActualLabel,
  DecisionOverdueLabel,
  DecisionAfterDeadlineLabel
) => {
  const {
    DecisionDate = '',
    DecisionDueDate = '',
    NotifyMemberDueDate = '',
    NotifyMemberLetterDate = '',
    NotifyProviderDueDate = '',
    NotifyProviderLetterDate = '',
  } = notifications;

  return (
    <GridLayout
      templateColumns="auto auto"
      style={{ background: 'var(--white)', height: 'fit-content' }}
      className="padding-all-small margin-top-small"
    >
      <Label disableTooltip={true} descriptor="Decision Due">
        <DecisionDueDisplay
          DecisionDueDate={DecisionDueDate}
          DecisionDate={DecisionDate}
          DecisionOverdueLabel={DecisionOverdueLabel}
        />
      </Label>
      <Label disableTooltip={true} descriptor="Decision Actual">
        <DecisionActualDisplay
          DecisionDueDate={DecisionDueDate}
          DecisionDate={DecisionDate}
          DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
        />
      </Label>
      <div className="notifications-horizontal-separator"></div>
      <NotificationDisplay
        MemberDueDate={NotifyMemberDueDate}
        MemberActualDate={NotifyMemberLetterDate}
        ProviderDueDate={NotifyProviderDueDate}
        ProviderActualDate={NotifyProviderLetterDate}
        DueLabel={DueLabel}
        ActualLabel={ActualLabel}
        DecisionOverdueLabel={DecisionOverdueLabel}
        DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
      />
    </GridLayout>
  );
};

export const DecisionDisplay = (props) => {
  const {
    notifications,
    DecisionOverdueLabel,
    DecisionAfterDeadlineLabel,
    ExpandLabel,
    DueLabel,
    ActualLabel,
  } = props;

  const { DecisionDate = '', DecisionDueDate = '' } = notifications;

  const gridStyle = {
    background: 'lightgray',
    height: 'fit-content',
    borderRadius: '5px',
  };

  const AlertsNumber = alertsNumber(notifications);
  return (
    <>
      <GridLayout
        templateColumns="auto"
        templateRows="auto"
        style={gridStyle}
        className="padding-all-small"
      >
        <div className="flex-0">
          <Label disableTooltip={true} descriptor="Decision Due">
            <DecisionDueDisplay
              DecisionDueDate={DecisionDueDate}
              DecisionDate={DecisionDate}
              DecisionOverdueLabel={DecisionOverdueLabel}
            />
          </Label>
        </div>
        <div className="flex-0">
          <Label disableTooltip={true} descriptor="Decision Actual">
            <DecisionActualDisplay
              DecisionDueDate={DecisionDueDate}
              DecisionDate={DecisionDate}
              DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
            />
          </Label>
        </div>
        <div className="flex-0 align-right">
          <ExpandButton
            AlertsCount={AlertsNumber}
            ExpandLabel={ExpandLabel}
            ContentFunc={NoficationsDetails}
            notifications={notifications}
            DueLabel={DueLabel}
            ActualLabel={ActualLabel}
            DecisionOverdueLabel={DecisionOverdueLabel}
            DecisionAfterDeadlineLabel={DecisionAfterDeadlineLabel}
          />
        </div>
      </GridLayout>
    </>
  );
};
