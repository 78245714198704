import { useSchema } from '../../../../schema/SchemaReducer';
import { DateTime as DateTimeLuxon } from 'luxon';
import { TimePicker, Flexbox } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { baseNodeValidator } from '../../validators';
import { toFormatterAndParserByType } from '../../../../g11n/ISODates';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { toAttributesObject, toResultDateFromModifier } from '../../utils';
import { toNodePropertiesWithDefaults, toTimeAttributeParts } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const { formatter: dateTimeFormatter } =
  toFormatterAndParserByType('local-date-time');

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Time = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node,
    onValidated,
    dataPath = primarySchemaKeys.Time,
    timeInterval,
    startTime,
    endTime,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, IsRequired, IsVisible } = nodeWithDefaults;

  const {
    required: DateModifier,
    TimeInterval = timeInterval,
    StartTime = startTime,
    EndTime = endTime,
  } = toAttributesObject(AttrDetail);

  const timeIntervalParts = toTimeAttributeParts(TimeInterval);
  const startTimeParts = toTimeAttributeParts(StartTime);
  const endTimeParts = toTimeAttributeParts(EndTime);

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <WrappingComponent>
      <TimePicker
        required={IsRequired}
        descriptor={Name}
        dataElementName={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        disabled={readOnly}
        className="margin-left-small"
        value={value}
        onChange={(value /* , valid */) => {
          const nextValue = toResultDateFromModifier(
            DateModifier,
            value,
            'local-date-time'
          );
          onValueChange(nextValue);
          onValidChange(baseNodeValidator(true, nextValue, IsRequired));
        }}
        steps={timeIntervalParts}
        minTime={
          startTimeParts &&
          dateTimeFormatter(DateTimeLuxon.fromObject(startTimeParts))
        }
        maxTime={
          endTimeParts &&
          dateTimeFormatter(DateTimeLuxon.fromObject(endTimeParts))
        }
      />
    </WrappingComponent>
  );
};
