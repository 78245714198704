import PropTypes from 'prop-types';
import './styles.css';

const HeaderComponent = ({ dataElementName, title }) => (
  <div data-element-name="panel__titleLabel" className="panel__header">
    <span
      data-element-name={
        dataElementName !== '' ? dataElementName + '__label' : ''
      }
    >
      {title}
    </span>
  </div>
);

export const Panel = (props) => {
  const {
    dataElementName = '',
    label,
    Header,
    title,
    children,
    className = '',
    childrenClassName = '',
    shouldOverflow = true,
    applyChildrenWrapper = true,
    ...rest
  } = props;

  const overflowClass = shouldOverflow ? 'overflow-auto' : '';

  const _title = title || label;

  const attrs = Object.keys(props)
    .filter((key) => key.substr(0, 5) === 'data-')
    .reduce((o, key) => Object.assign(o, { [key]: props[key] }), {});

  return (
    <div
      {...rest}
      data-element-name={dataElementName}
      data-component-name="Panel"
      className={`panel xjs-panel ${overflowClass} ${className}`}
      {...attrs}
    >
      {Header ||
        (_title && (
          <HeaderComponent title={_title} dataElementName={dataElementName} />
        ))}
      {/* Not exactly sure why we apply these styles. It makes it impossible to write views without fighting the implementation */}
      {applyChildrenWrapper ? (
        <div
          className={`panel__children ${childrenClassName}`}
          data-element-name={
            !!dataElementName ? `${dataElementName}__panelChild` : 'panelChild'
          }
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

Panel.propTypes = {
  dataElementName: PropTypes.string,
  label: PropTypes.node,
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  shouldOverflow: PropTypes.bool,
};
