import { Fragment, createElement } from 'react';
import { isFunction } from '../fp/pred';
import { identity } from '../fp/fp';
import { isWhen } from '../predication/when';

const flatten = (acc, v) => (Array.isArray(v) ? [...acc, ...v] : [...acc, v]);

export const combineViews = (...preds) => {
  //Adds a stable index, regardless of which view preds are true or false, stable index will be the same
  const flatPredViews = preds
    .reduce(flatten, [])
    .map(({ predicate, fn: viewFn }, stableIndex) => {
      return {
        predicate,
        viewFn,
        stableIndex,
      };
    });
  return (state, props) => {
    const { connectionSymbol = '' } = props;
    const identifierName = connectionSymbol;
    const connections = flatPredViews
      .filter(({ predicate }) => predicate(state, props))
      .map(({ viewFn, stableIndex }) => {
        return {
          viewFn,
          props,
          key: `CHILD_${stableIndex}`,
        };
      });
    if (!connections.length) return null;
    return createElement(
      Fragment,
      {},
      connections.map(({ viewFn, props, key }) => {
        return createElement(viewFn, {
          ...props,
          identifierName,
          key,
        });
      })
    );
  };
};

export const combinePredicatedReducers = (...predsReducers) => {
  const flatPredsReducers = predsReducers.reduce(flatten, []);
  flatPredsReducers.forEach((o) => {
    //Moving these outside of the execution loop. We don't need to validate each time
    //each reducer is run, we can check one in the beginning before we ever reduce
    //plus the error stack will now be where the declaration occurs, not the RX composition
    if (isWhen(o)) {
      const { predicate, fn } = o;
      console.assert(
        isFunction(predicate),
        'Non-predicate passed to combinePredicatedReducers()'
      );
      console.assert(
        isFunction(fn),
        'Non-reducer passed to combinePredicatedReducers()'
      );
    }
  });

  const predReducers = flatPredsReducers.filter(isWhen);
  const flatReducers = flatPredsReducers.filter((o) => !isWhen(o));

  const predReducer = (state, action) =>
    predReducers.reduce((state, { predicate, fn: reducer } = {}) => {
      return predicate(state, action) ? reducer(state, action) : state;
    }, state);

  return flatReducers.length
    ? (state, action) =>
        [predReducer, ...flatReducers].reduce(
          (acc, reducer) => reducer(acc, action),
          state
        )
    : predReducer;
};

export const combineCallbacks = (...predsCallbacks) => {
  const flatPredsCallbacks = predsCallbacks.reduce(flatten, []);
  flatPredsCallbacks.forEach(({ predicate, fn }) => {
    //Moving these outside of the execution loop. We don't need to validate each time
    //each reducer is run, we can check one in the beginning before we ever reduce
    //plus the error stack will now be where the declaration occurs, not the RX composition
    console.assert(
      isFunction(predicate),
      'Non-predicate passed to combineCallbacks()'
    );
    console.assert(isFunction(fn), 'Non-reducer passed to toReducer()');
  });

  return flatPredsCallbacks;
};

export const toCallback = (propName, projection = identity) => {
  return (propsValues, action) => {
    const potentialCallback = propsValues[propName];

    if (isFunction(potentialCallback)) {
      potentialCallback(projection(action));
      // unstable_scheduleCallback(unstable_LowPriority, () =>
      //   potentialCallback(projection(action))
      // );
    }
  };
};
