import { MaskedTextBox as KendoMaskedTextBox } from '@progress/kendo-react-inputs';
import { UIControlLabel } from '../Label';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSchema } from '../../schema/SchemaReducer';
import { NOOP_FUNCTION } from '../../constants';

const toBaseValidation = (validity, value, required) => {
  const { valueMissing, valid } = validity;
  const requiredValueMissing = required && valueMissing;
  const isValidValue = !value || valid;

  return !(requiredValueMissing || !isValidValue);
};

export const MaskedTextBox = (props) => {
  const {
    onChange = NOOP_FUNCTION,
    value: providedValue,
    dataElementName = '',
    onValidation = NOOP_FUNCTION,
    descriptor,
    descriptorClassName,
    required: propsRequired = false,
    mask,
    dataPath,
    ...passthroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [internalTouched, setInternalTouched] = useState(false);

  const {
    onValueChange = onChange,
    value = providedValue,
    required: schemaRequired = false,
    onValidationChange = onValidation,
    validityMessage = '',
    touched = false,
    onTouched = () => setInternalTouched(true),
    schemaNode,
  } = useSchema(dataPath);

  const [lastMaskedValue, setLastMaskedValue] = useState(value);

  const maskedTextBoxRef = useRef(null);
  const lastValidRef = useRef();

  const required = schemaRequired || propsRequired;

  useEffect(() => {
    if (!maskedTextBoxRef.current) return;
    const { validity } = maskedTextBoxRef.current;

    const maskedComponentValue =
      maskedTextBoxRef.current._service.maskRaw(value);
    if (maskedComponentValue !== lastMaskedValue) {
      setLastMaskedValue(maskedComponentValue);
    }

    const nextValid = toBaseValidation(validity, value, required);
    if (lastValidRef.current !== nextValid) {
      lastValidRef.current = nextValid;
      return onValidationChange(nextValid);
    }
  }, [onValidationChange, required, value, lastMaskedValue, mask]);

  const isTouched = schemaNode ? touched : internalTouched;

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__label` : ''
          }
          className={descriptorClassName}
          required={required}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <KendoMaskedTextBox
        ref={(ref) => {
          if (ref && maskedTextBoxRef.current !== ref) {
            ref.element.setAttribute('data-component-name', 'MaskedTextBox');
            ref.element.setAttribute('data-element-name', dataElementName);
          }
          maskedTextBoxRef.current = ref;
        }}
        value={lastMaskedValue}
        mask={mask}
        onChange={(ev) => {
          const {
            target: { rawValue, validity, value },
          } = ev;

          const _trimmedValue = rawValue.trim();
          onValueChange(_trimmedValue);

          setLastMaskedValue(value);

          const nextValid = toBaseValidation(validity, _trimmedValue, required);
          lastValidRef.current = nextValid;
          onValidationChange(nextValid);
        }}
        required={required}
        validityMessage={validityMessage}
        validityStyles={isTouched}
        onBlur={() => {
          onTouched();
        }}
        {...passthroughProps}
      />
    </>
  );
};

MaskedTextBox.propTypes = {
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  mask: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  dataElementName: PropTypes.string,
};
