export default {
  ButtonBar: 'ResultValueID',
  CarePlanBackground: 'XeSmartBookInstance',
  CarePlanCrisis: 'XeSmartBookInstance',
  Combo: 'ResultValueID',
  Date: 'ResultDate',
  DateTime: 'ResultDate',
  Decimal: 'ResultValue',
  Email: 'ResultValue',
  FileUpload: 'ResultValueID',
  Input: 'ResultValue',
  Integer: 'ResultValue',
  Measurement: 'ResultValue',
  MultiSelectDropdown: 'ResultValueID',
  Phone: 'ResultValue',
  Label: 'IsSet',
  RadioList: 'IsSet',
  get RadioGroup() {
    return this.RadioList;
  },
  ResultLabel: 'ResultValue',
  ResultValue: 'ResultLabel',
  ScoreValueResult: 'ResultValue',
  ScoreValueResultSum: 'ResultValue',
  SelectLabel: 'IsSet',
  SelectTranslationLabel: 'ResultValue',
  SubstitutionSet: 'ResultValue',
  Text: 'ResultValue',
  Time: 'ResultDate',
  Widget: 'ResultValueID',
  DateBar: 'ResultValueID',
  Correspondence: 'ResultValueID',
  CustomLanguage: 'ResultValueID',
  LineOfBusiness: 'ResultValue',
  PopUp: 'XeSmartBookInstance',
};
