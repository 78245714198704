import { isNil } from '../../fp/pred';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import './styles.css';

export const ToolTip = (props) => {
  const {
    value = '',
    disabled,
    children,
    placement = 'top',
    ...restOfProps
  } = props;

  const showTooltip = !!value && !disabled;
  const tooltipContent = isNil(value) ? '' : value;

  return (
    <Tippy
      theme="xnetjs"
      content={tooltipContent}
      offset={[0, 5]}
      zIndex={200000}
      disabled={!showTooltip}
      placement={placement}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'document',
            },
          },
        ],
      }}
      {...restOfProps}
    >
      {children}
    </Tippy>
  );
};

ToolTip.propTypes = {
  value: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
};
export default ToolTip;
