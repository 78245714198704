import { useEffect } from 'react';
import { useSchema } from '../../../../schema/SchemaReducer';
import { Flexbox, TextInput } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { textValidator } from '../../validators';
import { toNodePropertiesWithDefaults, useSynchronousValue } from '../utils';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { useSmartBookNodeContext } from '../../hooks';
import { exists } from '../../../../../src/frp/operators/exists';
import { EMPTY_OBJECT } from '../../../../constants';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Input = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    style,
    readOnly,
    node,
    children,
    onValidated,
    dataPath = primarySchemaKeys.Input,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsRequired, ResultMax, IsVisible } = node;

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const visibleChildren = nodeWithDefaults?.XeSmartBookInstance?.filter(
    ({ IsVisible } = EMPTY_OBJECT) => IsVisible
  ).length ? (
    <div>{children}</div>
  ) : null;

  useEffect(() => {
    node.IsSet = exists(node.IsSet) ? !!value : undefined;
  }, [node, value]);

  const [syncValue, changeHandler] = useSynchronousValue(value, onValueChange);

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <>
      <WrappingComponent>
        <TextInput
          dataElementName={Name}
          descriptor={Name}
          descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
            invalid,
          })}
          required={IsRequired}
          style={style}
          disabled={readOnly}
          maxLength={ResultMax}
          value={syncValue}
          onChange={(value /* , valid */) => {
            changeHandler(value);
            onValidChange(textValidator(true, value, IsRequired));
          }}
        />
      </WrappingComponent>
      {visibleChildren}
    </>
  );
};

export default Input;
