import { Checkbox } from '../../../components/Checkbox';
import { RadioButton } from '../../../components/RadioButton';
import { IconButton } from '../../../components/IconButton';
import { Flexbox } from '../../../components/Flexbox';
import { Label } from '../../../components/Label';
import { CHEVRON_DOWN, CHEVRON_RIGHT } from '../../../icons';

export const SelectableHeader = (props) => {
  const {
    dataElementName,
    descriptor,
    onClick,
    onSelect,
    isExpanded,
    checked,
    indeterminate,
    className,
    multiple,
    checkableSections,
  } = props;

  const expandIcon = isExpanded ? CHEVRON_DOWN : CHEVRON_RIGHT;
  const InputComponent = multiple || indeterminate ? Checkbox : RadioButton;

  return (
    <Flexbox
      dataElementName={dataElementName}
      direction="row"
      alignItems="center"
      className={`selectable-header ${className}`}
    >
      <IconButton
        dataElementName={`selectableHeader__${dataElementName}Button`}
        look="flat"
        icon={expandIcon}
        onClick={onClick}
      />
      {checkableSections || multiple ? (
        <InputComponent
          dataElementName={`selectableHeader__${dataElementName}Input`}
          label={descriptor}
          onChange={onSelect}
          checked={checked}
          indeterminate={indeterminate}
        />
      ) : (
        <Label
          dataElementName={`selectableHeader__${dataElementName}Label`}
          className="selectable-header__label"
        >
          {descriptor}
        </Label>
      )}
    </Flexbox>
  );
};

export default SelectableHeader;
