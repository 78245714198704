import { DateTime } from 'luxon';
import { isoStrAsLuxon } from '../../../g11n/ISODates';
import { formatDate, calculateOffset } from '../utils';
import { ALERT_OUTLINE } from '../../../icons';
import ToolTip from '../../ToolTip';
import { Icon } from '../../Icon';
import Flexbox from '../../Flexbox';

export const DecisionDueDisplay = (props) => {
  const { DecisionDueDate, DecisionDate, DecisionOverdueLabel } = props;

  const luxonDueDate = !!DecisionDueDate
    ? isoStrAsLuxon(DecisionDueDate)
    : null;

  if (!DecisionDueDate) return '—';
  if (DateTime.now() > luxonDueDate && !DecisionDate) {
    return (
      <>
        <Flexbox alignItems="center">
          <span style={{ color: 'red' }}>
            {`${formatDate(DecisionDueDate)} (${calculateOffset(
              luxonDueDate,
              DateTime.now()
            )})`}
          </span>
          <ToolTip value={DecisionOverdueLabel} placement="top-end">
            <Icon
              icon={ALERT_OUTLINE}
              color="red"
              className="margin-left-small"
            />
          </ToolTip>
        </Flexbox>
      </>
    );
  } else {
    return (
      <>
        <Flexbox>{formatDate(DecisionDueDate)}</Flexbox>
      </>
    );
  }
};
