import {
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  PAGE_FIRST,
  PAGE_LAST,
} from '../../../icons';
import { Flexbox, IconButton } from '../../../components';

export const NavBar = (props) => {
  const { currentPage, totalPages, onPageUpdate } = props;

  const onNavigate = (navigateFn) => {
    const potentialNextPage = navigateFn(currentPage);
    if (0 < potentialNextPage && potentialNextPage <= totalPages) {
      onPageUpdate(potentialNextPage);
    }
  };

  const atEnd = currentPage === totalPages;

  return (
    <Flexbox justifyContent="space-between" className="flex-0">
      <IconButton
        icon={PAGE_FIRST}
        dataElementName="PDFViewer__pageFirst"
        onClick={() => onNavigate(() => 1)}
        disabled={currentPage == 1}
      />
      <IconButton
        icon={CHEVRON_LEFT}
        dataElementName="PDFViewer__pageLeft"
        onClick={() => onNavigate((currentPage) => currentPage - 1)}
        disabled={currentPage == 1}
      />
      <IconButton
        icon={CHEVRON_RIGHT}
        dataElementName="PDFViewer__pageRight"
        onClick={() => onNavigate((currentPage) => currentPage + 1)}
        disabled={atEnd}
      />
      <IconButton
        icon={PAGE_LAST}
        dataElementName="PDFViewer__pageLast"
        onClick={() => onNavigate(() => totalPages)}
        disabled={atEnd}
      />
    </Flexbox>
  );
};

export default NavBar;
