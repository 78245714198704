export const PlanOfCare = (props) => {
  const { node, children } = props;

  const { IsVisible } = node;

  if (!IsVisible) return null;
  return children;
};

export default PlanOfCare;
