import { useSchema } from '../../../../schema/SchemaReducer';
import { identity } from '../../../../fp/fp';
import PropTypes from 'prop-types';
import { useState, Fragment } from 'react';
import { EMPTY_OBJECT } from '../../../../constants';
import {
  Button,
  Flexbox,
  Label,
  NumberInput,
  GridLayout,
} from '../../../../components';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const IMPERIAL = 'imperial';
const METRIC = 'metric';

const TYPE_HEIGHT = 'height';
const TYPE_WEIGHT = 'weight';
const TYPE_TEMP = 'temperature';

const defaultToResultValFn = (objectWithUnits) => {
  return Object.values(objectWithUnits).reduce((resultString, value) => {
    return resultString.concat(value);
  }, '');
};

const UNIT_TYPE_DATA = {
  [IMPERIAL]: {
    [TYPE_HEIGHT]: {
      units: ['ft', 'in'],
      calc: {
        ft: (value) => Math.floor(value / 12),
        in: (value) => Math.floor(value % 12),
        toResultVal: ({ ft, in: inches }) => ft * 12 + inches,
      },
    },
    [TYPE_WEIGHT]: {
      units: ['lb', 'oz'],
      calc: {
        oz: (value) => Math.floor((value % 1) * 16),
        lb: (value) => Math.floor(value),
        toResultVal: ({ lb, oz }) => lb + oz / 16,
      },
    },
    [TYPE_TEMP]: {
      units: ['F'],
      calc: {
        F: identity,
      },
    },
    symbol: 'I',
  },
  // Assuming imperial for calculations for now per discussion with Mark (JDM)
  [METRIC]: {
    [TYPE_HEIGHT]: {
      units: ['cm'],
      calc: {
        cm: (value) => value * 2.54,
        toResultValue: ({ cm }) => cm / 2.54,
      },
    },
    [TYPE_WEIGHT]: {
      units: ['kg'],
      calc: {
        kg: (value) => value * 0.454,
        toResultValue: ({ kg }) => kg / 0.454,
      },
    },
    [TYPE_TEMP]: {
      units: ['C'],
      calc: {
        C: (value) => (value - 32) * (5 / 9),
        toResultValue: ({ C }) => C * (9 / 5) + 32,
      },
    },
    symbol: 'M',
  },
};

const Fields = (props) => {
  const { dataForMeasurementType, resultValue, disabled, onChange } = props;

  const { units, calc = EMPTY_OBJECT } = dataForMeasurementType;

  const valueMap = units.reduce((map, unit) => {
    const conversionFunc = calc[unit] || identity;
    return {
      ...map,
      [unit]: resultValue ? conversionFunc(resultValue) : 0,
    };
  }, {});

  return (
    <GridLayout
      alignItems="center"
      templateColumns={`repeat(${units.length * 2}, auto)`}
    >
      {units.map((unit) => {
        const value = valueMap[unit];

        return (
          <Fragment key={unit}>
            <NumberInput
              disabled={disabled}
              onChange={(value) => {
                const nextValueMap = {
                  ...valueMap,
                  [unit]: value,
                };

                const { toResultVal = defaultToResultValFn } = calc;

                onChange(toResultVal(nextValueMap));
              }}
              value={value}
            />
            <Label>{unit}</Label>
          </Fragment>
        );
      })}
    </GridLayout>
  );
};

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Measurement = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    unit = IMPERIAL,
    readOnly,
    node,
    type,
    dataPath = primarySchemaKeys.Measurement,
    onValidated,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsVisible, IsRequired } = node;

  const [currentUnitMode, setCurrentUnitMode] = useState(unit);

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  const dataForCurrentMode = UNIT_TYPE_DATA[currentUnitMode];

  return (
    <WrappingComponent>
      <NameLabel required={IsRequired} invalid={!valid || !!validityMessage}>
        {Name}
      </NameLabel>
      <div className="padding-left-small">
        <Flexbox>
          <Fields
            dataForMeasurementType={dataForCurrentMode[type]}
            disabled={readOnly}
            resultValue={parseFloat(value)}
            onChange={onValueChange}
          />
          <Button
            dataElementName="currentUnitMode"
            disabled={readOnly}
            onClick={() =>
              setCurrentUnitMode(
                currentUnitMode === IMPERIAL ? METRIC : IMPERIAL
              )
            }
          >
            {`(${dataForCurrentMode.symbol})`}
          </Button>
        </Flexbox>
      </div>
    </WrappingComponent>
  );
};

export default Measurement;

Measurement.propTypes = {
  unit: PropTypes.oneOf([IMPERIAL, METRIC]),
  type: PropTypes.oneOf([TYPE_HEIGHT, TYPE_WEIGHT, TYPE_TEMP]).isRequired,
  // TODO: More...
};
