import { Children } from 'react';
import { useSchema } from '../../../../schema/SchemaReducer';
import TextInput from '../../../../components/TextInput';
import { Flexbox } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { emailValidator, textValidator } from '../../validators';
import { toNodePropertiesWithDefaults, useSynchronousValue } from '../utils';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Email = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    style,
    readOnly,
    node,
    children,
    onValidated,
    dataPath = primarySchemaKeys.Email,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsRequired, ResultMax, IsVisible } = node;

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [syncValue, changeHandler] = useSynchronousValue(value, onValueChange);

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;

  return (
    <>
      <WrappingComponent>
        <TextInput
          descriptor={Name}
          dataElementName={Name}
          descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
            invalid,
          })}
          required={IsRequired}
          style={style}
          disabled={readOnly}
          maxLength={ResultMax}
          value={syncValue}
          onChange={(value /* , valid */) => {
            changeHandler(value);
            onValidChange(emailValidator(true, value, IsRequired));
          }}
          type="email"
        />
      </WrappingComponent>
      {Children.count(children) > 0 ? <div>{children}</div> : null}
    </>
  );
};

export default Email;
