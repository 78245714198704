import { useRef } from 'react';
import { pluck } from '../../fp/object';
import { SchemaReducer } from '../../schema/SchemaReducer';
import { withDefaultJSONSchemaReducer } from '../../schema/JSONSchemaReducer';
import {
  DropDownList,
  Flexbox,
  Label,
  TextInput,
  NumberInput,
  Panel,
  IconButton,
} from '../../components';
import { REMOVE } from '../../icons';
import { useXeLabels } from '../../contexts/XeLabelContext';
import './styles.css';
import SplitFile from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit$XeScanDocSet.json';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../validators/schemaValidators';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const toRules = (numPages) => [
  [({ StartPage } = {}) => StartPage > 0, defaultBusinessRuleReducer],
  [({ EndPage } = {}) => EndPage <= numPages, defaultBusinessRuleReducer],
  [
    ({ StartPage, EndPage } = {}) => StartPage <= EndPage,
    defaultBusinessRuleReducer,
  ],
];

const toValidator = (numPages) => toDefaultValidator(toRules(numPages));

export const PDFRangePanel = (props) => {
  const {
    onChange = NOOP_FUNCTION,
    onRemove,
    currentRange = EMPTY_OBJECT,
    pdfData = EMPTY_OBJECT,
    rangeReadOnly = false,
  } = props;
  const { StartPage, EndPage } = currentRange;
  const instanceRef = useRef(currentRange);
  const { numPages } = pdfData;

  const labels = useXeLabels();
  const { XeDocumentType } = useXeRefData();

  return (
    <SchemaReducer
      schema={SplitFile}
      onChange={({ instance, valid }) => onChange({ ...instance, valid })}
      toJsonReducer={withDefaultJSONSchemaReducer(toValidator(numPages))}
      initialValue={instanceRef.current}
      dangerouslyRetainCompletelyOutdatedState={true}
    >
      <Panel>
        <Flexbox className="pdf-range-panel flex-nowrap">
          <Flexbox
            direction="column"
            alignItems="center"
            className="pdf-range-panel__range-info"
          >
            <Label className="bold pdf-range-panel__range-header">
              {labels.PageRange}
            </Label>
            <Flexbox alignItems="center">
              {!rangeReadOnly ? (
                <NumberInput
                  exclusiveMinimum={0}
                  className="pdf-range-panel__page-input"
                  dataPath="StartPage"
                  min={1}
                  max={EndPage}
                />
              ) : (
                <Label dataPath="StartPage"></Label>
              )}
              <Label className="margin-horizontal-small">{labels.To}</Label>
              {!rangeReadOnly ? (
                <NumberInput
                  exclusiveMinimum={0}
                  className="pdf-range-panel__page-input"
                  dataPath="EndPage"
                  min={StartPage}
                  max={numPages}
                />
              ) : (
                <Label dataPath="EndPage"></Label>
              )}
            </Flexbox>
          </Flexbox>
          <div className="display-grid pdf-range-panel__info-grid">
            <TextInput
              className="pdf-range-panel__description"
              descriptor={labels.Description}
              dataPath="Comments"
            />
            <DropDownList
              descriptor={labels.Document}
              labelFn={pluck('text')}
              valueFn={pluck('item', 'DocumentTypeID')}
              dataPath="DocumentTypeID"
              data={[{}, ...XeDocumentType]}
            />
          </div>
          <IconButton
            onClick={onRemove}
            icon={REMOVE}
            className="self-start"
            iconClassName="color-red"
            description={labels.Remove}
          />
        </Flexbox>
      </Panel>
    </SchemaReducer>
  );
};

export default PDFRangePanel;
