import { useState } from 'react';
import { EMPTY_OBJECT } from '../../../../constants';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { CHECK_CIRCLE_OUTLINE, EDIT } from '../../../../icons';
import { useSchema } from '../../../../schema/SchemaReducer';
import Flexbox from '../../../Flexbox';
import { Icon } from '../../../Icon';
import IconButton from '../../../IconButton';
import { Label } from '../../../Label';
import { ToolTip } from '../../../ToolTip';
import { ExpandableNode } from '../../components/ExpandableNode';
import { useSmartBookNodeContext } from '../../hooks';
import { ModifyCarePlanPopup } from '../CarePlanIssues';
import { toNodePropertiesWithDefaults } from '../utils';
import './styles.css';

export const CarePlan = (props) => {
  const { children, node, onValidated, readOnly } = props;

  const dataPath = 'XeSmartBookInstance';

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);

  const {
    CategoryID = EMPTY_OBJECT,
    Name,
    IsVisible,
    ResultValue: freeformItemName,
  } = nodeWithDefaults;

  const { Abbr, Name: CategoryName } = CategoryID;

  const labels = useXeLabels();
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  const { value, onValueChange, fullPath } = useSchema('XeSmartBookInstance');

  const { onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  return (
    <>
      <ExpandableNode
        defaultExpanded={true}
        node={node}
        ItemElement={
          <Flexbox
            className="flex-1 margin-vertical-small"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flexbox alignItems="center">
              <Icon icon={CHECK_CIRCLE_OUTLINE} />
              <Label
                className="smartbook-label margin-vertical-small"
                wrapText={false}
              >
                {Name || freeformItemName}
              </Label>
            </Flexbox>
            <Flexbox alignItems="center">
              <ToolTip value={CategoryName}>
                <span className="care-plan__category font-bold">{Abbr}</span>
              </ToolTip>
              {!readOnly ? (
                <IconButton
                  dataElementName="add"
                  icon={EDIT}
                  description={labels.Edit}
                  onClick={() => {
                    setShowModifyDialog(true);
                  }}
                />
              ) : null}
            </Flexbox>
          </Flexbox>
        }
      >
        {children}
      </ExpandableNode>
      {showModifyDialog ? (
        <ModifyCarePlanPopup
          carePlanItems={value}
          catalogItem={nodeWithDefaults}
          onSave={({ XeSmartBookInstance }) => {
            setShowModifyDialog(false);
            onValueChange(XeSmartBookInstance);
            onValidChange(true);
          }}
          onCancel={() => setShowModifyDialog(false)}
        />
      ) : null}
    </>
  );
};
