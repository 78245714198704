import { MaskedTextBox } from '..';

/**
 * Input to handle telephone inputs with a format masking and HTML validation.
 * @param {String} [mask="#####"] Mask pattern. Masks `value` to match pattern. Passed through to Kendo input directly. Currently only supports "#" for values.
 */
export const ZipCodeInput = (props) => {
  return <MaskedTextBox {...props} mask="00000" />;
};

export default ZipCodeInput;
