import { useSchema } from '../../schema/SchemaReducer';
import { identity } from '../../fp/fp';
import { useCallback, useEffect, useRef, useState, Children } from 'react';
import PropTypes from 'prop-types';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import { REQUIRED } from '../../icons';
import { ToolTip } from '../ToolTip';
import { Icon } from '../Icon';
import './styles.css';

const getIsOverflowing = (ele) => {
  if (!ele) return false;

  const { clientWidth, scrollWidth, clientHeight, scrollHeight } = ele;

  return scrollWidth > clientWidth || scrollHeight > clientHeight;
};

export const Label = (props) => {
  const {
    dataElementName = '',
    className = '',
    children,
    interactive = true,
    disableTooltip = false,
    required = false,
    wrapText,
    withColon,
    labelFn = identity,
    dataPath,
    descriptor,
    descriptorClassName = '',
    descriptorPosition = 'left center',
    ...htmlProps
  } = props;

  const { value } = useSchema(dataPath);

  const spanRef = useRef(null);
  const [tooltipText, setTooltipText] = useState('');
  const memoizedOnShow = useCallback(() => {
    return getIsOverflowing(spanRef.current);
  }, [spanRef]);

  useEffect(() => {
    const {
      current: { innerText },
    } = spanRef;

    setTooltipText(innerText);
  }, [children, value]);

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? dataElementName + '__label' : ''
          }
          className={descriptorClassName}
          descriptorPosition={descriptorPosition}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <ToolTip
        interactive={interactive}
        onShow={memoizedOnShow}
        value={tooltipText}
        disabled={disableTooltip}
      >
        <span
          data-element-name={dataElementName}
          ref={spanRef}
          className={`${className} ${withClassNameModifiers('xjs-label', {
            wrap: wrapText,
          })}`}
          {...htmlProps}
        >
          {required && (
            <Icon icon={REQUIRED} className="margin-left-x-small md-12" />
          )}
          {dataPath ? labelFn(value) : ''}
          {children}
          {Children.count(children) > 0 && withColon ? ':' : null}
        </span>
      </ToolTip>
    </>
  );
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  interactive: PropTypes.bool,
  wrapText: PropTypes.bool,
  withColon: PropTypes.bool,
  className: PropTypes.string,
  descriptorClassName: PropTypes.string,
  required: PropTypes.bool,
  labelFn: PropTypes.func,
  descriptor: PropTypes.string,
  dataElementName: PropTypes.string,
  descriptorPosition: PropTypes.string,
};

const toPositionClass = (position) => {
  const top = position.includes('top');
  const bottom = position.includes('bottom');
  const left = position.includes('left');
  const right = position.includes('right');
  const { centerX, centerY } = (() => {
    if (position.includes('center')) {
      if (top || bottom) {
        return {
          centerX: true,
        };
      }
      if (left || right) {
        return {
          centerY: true,
        };
      }
      return {
        centerX: true,
        centerY: true,
      };
    }
    if (!(top || bottom)) {
      if (left || right) {
        return {
          centerY: true,
        };
      }
    }
    if (!(left || right)) {
      if (top || bottom) {
        return {
          centerX: true,
        };
      }
    }
    return {};
  })();
  const xAxisModifiers =
    left || right
      ? {
          left,
          right,
          'center-y': centerY,
        }
      : {};
  const yAxisModifiers =
    top || bottom
      ? {
          top,
          bottom,
          'center-x': centerX,
        }
      : {};
  return withClassNameModifiers('xjs-label--ui-control', {
    wrap: true,
    ...xAxisModifiers,
    ...yAxisModifiers,
  });
};

export const UIControlLabel = (props) => {
  if (Children.count(props.children) === 0 && !props.required) return null;

  const {
    className,
    children,
    descriptorPosition = 'left center',
    wrapText = true,
    withColon = true,
    ...restOfProps
  } = props;

  const baseClassName = toPositionClass(descriptorPosition);

  return (
    <Label
      {...restOfProps}
      className={className ? `${className} ${baseClassName}` : baseClassName}
      wrapText={wrapText}
      withColon={withColon}
    >
      {children}
    </Label>
  );
};

UIControlLabel.propTypes = Label.propTypes;

export const HeaderLabel = (props) => {
  if (Children.count(props.children) === 0) return null;

  const { className = '', children, ...restOfProps } = props;
  const baseClassName = `xjs-label--header xjs-label--wrap`;

  return (
    <Label
      {...restOfProps}
      className={className ? `${className} ${baseClassName}` : baseClassName}
    >
      {children}
    </Label>
  );
};

HeaderLabel.propTypes = Label.propTypes;
