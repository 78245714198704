import { isNil } from '../../fp/pred';
import { useEffect, useRef, cloneElement, Children, memo } from 'react';
import { castFunction } from '../../fp/fp';
import { EMPTY_OBJECT } from '../../constants';

/**
 * @typedef ViewSwitcherProps
 * @property {string | number} activeViewId
 * @property {boolean} [preserveViews=true] Option to enable whether previously opened views should stay mounted when the activeViewId changes
 * @property {React.ReactNode} [viewComponent='div'] Specifies the wrapping element for the child <View> components.
 *                                                   If supplied, the component should handle the html `style` property so that the view is hidden when inactive.
 * @property {string} [viewClassName=''] Specifies a default class name to apply to the child <View> components.
 * @property {boolean} [lazyRenderViews=true] If `true`, views will only be rendered once their respective `viewId` matches the current `activeViewId`. Otherwise, all views
 *                                     will be rendered initially.
 */

/**
 * @param {ViewSwitcherProps} props
 */
export const ViewSwitcher = (props) => {
  const {
    children,
    activeViewId,
    viewComponent = 'div',
    viewClassName = '',
    preserveViews = true,
    lazyRenderViews = true,
  } = props;

  const renderedStepsMapRef = useRef({});
  if (preserveViews) {
    renderedStepsMapRef.current = {
      ...renderedStepsMapRef.current,
      [activeViewId]: true,
    };
  } else {
    renderedStepsMapRef.current = {
      [activeViewId]: true,
    };
  }

  const childrenAsArray = Children.toArray(children);

  return childrenAsArray.map((child) => {
    const {
      props: { viewId },
      key,
    } = child;
    return cloneElement(child, {
      key: !isNil(key) ? key : `view_${viewId}`,
      viewComponent: viewComponent,
      _lazy: lazyRenderViews,
      _active: viewId === activeViewId,
      _touched: !!renderedStepsMapRef.current[viewId],
      _defaultClassName: viewClassName,
    });
  });
};

const HIDDEN_STYLE = {
  position: 'absolute',
  height: 0,
  width: 0,
  opacity: 0,
  overflow: 'hidden',
};

const viewPropsAreEqual = (prevProps, nextProps) => {
  const { _active: prevActive } = prevProps;
  const { _active: nextActive } = nextProps;
  // The explicit boolean comparison is for clarity (JDM)
  if (prevActive === false && nextActive === true) {
    return false;
  }

  return prevActive === false && nextActive === false;
};

/**
 * @typedef ViewSwitcherViewProps
 * @property {string | number} viewId
 * @property {string} [className='']
 * @property {Function} [onShow=]
 * @property {Function} [onHide=]
 * @property {React.ReactNode} [viewComponent='div'] Specifies the wrapping element for the <View>.
 *                                                   If supplied, the component should handle the html `style` property so that the view is hidden when inactive.
 */

/**
 * @param {ViewSwitcherViewProps} props
 */
ViewSwitcher.View = memo((props) => {
  const {
    dataElementName = '',
    _active,
    _touched,
    _lazy,
    _defaultClassName,
    children,
    viewId,
    className = '',
    viewComponent: Component = 'div',
    style = EMPTY_OBJECT,
    onShow,
    onHide,
    ...rest
  } = props;

  const prevActiveRef = useRef(_active);

  useEffect(() => {
    if (prevActiveRef.current !== _active) {
      if (prevActiveRef.current === false && _active === true) {
        prevActiveRef.current = _active;
        return castFunction(onShow)();
      }
      if (prevActiveRef.current === true && _active === false) {
        prevActiveRef.current = _active;
        return castFunction(onHide)();
      }
      prevActiveRef.current = _active;
    }
  }, [onShow, onHide, _active]);

  if (_lazy && !_touched) return null;

  const _className = (() => {
    if (!_defaultClassName && !className) return '';
    if (_defaultClassName && !className) return _defaultClassName;
    if (!_defaultClassName && className) return className;
    return `${_defaultClassName} ${className}`;
  })();

  return (
    <Component
      data-element-name={dataElementName}
      key={viewId}
      className={_className}
      style={!_active ? { ...style, ...HIDDEN_STYLE } : style}
      {...rest}
    >
      {children}
    </Component>
  );
}, viewPropsAreEqual);

ViewSwitcher.View.displayName = 'ViewSwitcher.View';
