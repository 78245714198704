import {
  useSchema,
  useSchemaSelector,
  useSchemaDispatch,
} from '../../../../schema/SchemaReducer';
import { pluck } from '../../../../fp/object';
import { MERGE_PATCH } from '../../../../schema/JSONSchemaReducer';
import { useState } from 'react';
import { Flexbox } from '../../../../components';
import UploadFilesPopup from '../../../../components/UploadFilesPopup';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toAttributesObject } from '../../utils';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { baseNodeValidator } from '../../validators';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const defaultValueFn = (value) => {
  const { Comments, ScanDocID } = value ?? {};

  return {
    ResultValue: Comments ?? null,
    ResultValueID: ScanDocID ? `${ScanDocID}` : null,
  };
};

export const FileUpload = (props) => {
  const {
    readOnly,
    rootNode,
    node,
    onValidated,
    dataPath = primarySchemaKeys.FileUpload,
    ipid,
    enterpriseId,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, IsRequired, IsVisible } = node;

  const { Name: rootSmartBookName, ResultValue: initialResultValue } = rootNode;
  const { CategoryID, DocTypeID, DocumentTypeID } =
    toAttributesObject(AttrDetail);

  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);

  const { value, validityMessage, fullPath } = useSchema(dataPath);
  const [fileSetName = initialResultValue] = useSchemaSelector(
    ({ ResultValue } = {}) => {
      return [ResultValue];
    }
  );
  const schemaDispatch = useSchemaDispatch();

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  return (
    <Flexbox alignItems="center">
      <button
        data-element-name={Name}
        disabled={readOnly}
        onClick={() => setShowAttachmentPopup(true)}
        className={`${withClassNameModifiers('smartbook-descriptor', {
          invalid: !valid || !!validityMessage,
        })}`}
      >
        {Name}
      </button>
      <NameLabel dataElementName={`${Name}__label`} required={IsRequired}>
        {fileSetName}
      </NameLabel>
      {showAttachmentPopup ? (
        // TODO: Due to some issues, the upload files popup is being reverted for now (JDM)
        // Need to discuss full requirements and expectations to figure out how we can merge
        // the two ideas between the popups
        // <AttachmentPopup
        //   onClose={() => setShowAttachmentPopup(false)}
        //   onSave={(response) => {
        //     const { ItemID, Comments } = response;
        //     onValueChanged({
        //       ResultValue: Comments,
        //       ResultValueID: ItemID,
        //     });
        //     setShowAttachmentPopup(false);
        //   }}
        //   itemId={ResultValueID}
        //   comments={currentDocumentName}
        //   docTypeId={DocTypeID}
        // />
        <UploadFilesPopup
          fileSetIpid={ipid}
          fileSetEnterpriseId={enterpriseId}
          onCancel={() => setShowAttachmentPopup(false)}
          onSave={({ value: response } = {}) => {
            const { XeScanDocSet = [] } = response;
            const isEmptyScanDoc = !XeScanDocSet.some(pluck('Active'));
            if (isEmptyScanDoc) {
              const nextValue = valueFn(null);

              schemaDispatch({
                type: MERGE_PATCH,
                value: nextValue,
              });
              onValidChange(baseNodeValidator(true, null, IsRequired));
            } else {
              const nextValue = valueFn(response);

              schemaDispatch({
                type: MERGE_PATCH,
                value: nextValue,
              });
              onValidChange(
                baseNodeValidator(
                  true,
                  nextValue[primarySchemaKeys.FileUpload],
                  IsRequired
                )
              );
            }
            setShowAttachmentPopup(false);
          }}
          scanDocId={parseInt(value)}
          defaultFileSetName={rootSmartBookName}
          docTypeId={DocTypeID}
          categoryId={CategoryID}
          documentTypeId={DocumentTypeID}
        />
      ) : null}
    </Flexbox>
  );
};
