import { ButtonBar } from './ButtonBar';
import { Combo } from './Combo';
import { Date } from './Date';
import { DateTime } from './DateTime';
import { Decimal } from './Decimal';
import { Email } from './Email';
import { FileUpload } from './FileUpload';
import { Grid } from './Grid';
import { Height } from './Height';
import { Input, Input as ShortInput } from './Input';
import { Integer } from './Integer';
import { Label } from './Label';
import { MultiSelectDropdown } from './MultiSelectDropdown';
import { Phone } from './Phone';
import { PlanOfCare } from './PlanOfCare';
import { RadioList } from './RadioList';
import { ResultLabel } from './ResultLabel';
import { ScoreValueResult } from './ScoreValueResult';
import { SelectLabel } from './SelectLabel';
import { SelectTranslationLabel } from './SelectTranslationLabel';
import { Separator } from './Separator';
import { SubstitutionSet } from './SubstitutionSet';
import { Temperature } from './Temperature';
import { Text } from './Text';
import { Time } from './Time';
import { Weight } from './Weight';
import { Widget } from './Widget';
import { Wraplist } from './Wraplist';
import { DateBar } from './DateBar';
import { CarePlanBackground } from './CarePlanBackground';
import { CarePlanCrisis } from './CarePlanCrisis';
import { CarePlanIssues } from './CarePlanIssues';
import { CarePlan } from './CarePlan';
import { CarePlanIntervention } from './CarePlanIntervention';
import { LineOfBusiness } from './LineOfBusiness';
import { Correspondence } from './Correspondence';
import { CustomLanguage } from './CustomLanguage';
import { PopUp } from './PopUp';

export default {
  ButtonBar,
  CarePlan,
  CarePlanCrisis,
  CarePlanBackground,
  CarePlanIntervention,
  CarePlanIssues,
  Combo,
  Date,
  DateTime,
  Decimal,
  Email,
  FileUpload,
  Grid,
  Height,
  Input,
  Integer,
  Label,
  MultiSelectDropdown,
  Phone,
  PlanOfCare,
  RadioGroup: RadioList,
  RadioList,
  ResultLabel,
  ScoreValueResult,
  SelectLabel,
  SelectTranslationLabel,
  Separator,
  ShortInput,
  SubstitutionSet,
  Temperature,
  Text,
  Time,
  Weight,
  Widget,
  Wraplist,
  DateBar,
  visitcorrespondence: LineOfBusiness,
  correspondence: Correspondence,
  customLang: CustomLanguage,
  PopUp: PopUp,
};
