import { Flexbox } from '../../../../components';
import PropTypes from 'prop-types';
import { NameLabel } from '../../components/NameLabel';
import './styles.css';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Wraplist = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    children,
    node,
  } = props;
  const { Name, IsVisible } = node;

  if (!IsVisible) return null;

  return (
    <WrappingComponent>
      <NameLabel>{Name}</NameLabel>
      <Flexbox wrap className="clindoc-wraplist">
        {children}
      </Flexbox>
    </WrappingComponent>
  );
};

Wraplist.propTypes = {
  node: PropTypes.shape({
    Name: PropTypes.string,
  }),
  children: PropTypes.node,
};
