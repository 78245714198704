import { when } from '../../predication/when';
import { hasProperty } from '../../fp/pred';
import { combineEpics } from 'redux-observable';
import {
  combinePredicatedReducers,
  combineCallbacks,
  combineViews,
  toCallback
} from '../../connection/toConnectionDef';
import { isType } from '../../connection/toConnectedComponent';

import { toXeConnectedComponent } from '../../compositions/XeConnectedComponent';
import { DID_SAVE_FILE_SET } from '../../files/actions';
import fileEpics from '../../files/epics';
import { ON_SAVE } from './constants';
import epics from './epics';
import reducers from './reducers';
import DefaultView from './views/DefaultView';

const identifier = 'UploadFilesPopupXJS';

const views = [
  when(hasProperty('fileSet'))(DefaultView),
];

const callbacks = [when(isType(DID_SAVE_FILE_SET))(toCallback(ON_SAVE))];

export const UploadFilesPopup = toXeConnectedComponent(
  identifier,
  combinePredicatedReducers(...reducers),
  combineEpics(...epics, ...fileEpics),
  combineViews(...views),
  combineCallbacks(...callbacks)
);
export default UploadFilesPopup;
