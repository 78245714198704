import { isNil } from '../../fp/pred';
import { useSchema } from '../../schema/SchemaReducer';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Flexbox } from '../Flexbox';
import { UIControlLabel } from '../Label';
import { toIsKendoComponentValid } from '../utils';
import { NOOP_FUNCTION } from '../../constants';

// Per kendo, standard number formatting involves usage of the "n" character.
// Please see https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
// for more information on kendo number formatting.
// Reverting the change for the interger format, as Kendo adds extra formating to numbers when this is in place
// For areas where we want an ID, Kendo will format it to have commas and that is not what we want. (JAC)
export const INTEGER_FORMAT = '#';
export const TWO_DECIMAL_FORMAT = 'n2';

/**
 *
 * @param {number} a
 * @param {number} [b]
 * @returns {number} greater of the two numbers
 */
const toGreatest = (a = -Number.MIN_VALUE, b) => {
  if (isNil(b)) return a;
  if (isNil(a)) return b;
  return Math.max(a, b);
};

/**
 * Returns the lower of the two numbers
 * @param {number} a
 * @param {number} [b]
 * @returns {number} lesser of the two numbers
 */
const toLeast = (a = Number.MAX_VALUE, b) => {
  if (isNil(b)) return a;
  if (isNil(a)) return b;
  return Math.min(a, b);
};

/**
 * @name isNumericallyValid
 * @param {{maximum ?: number, exclusiveMaximum ?: number,minimum ?: number, exclusiveMinimum?: number}} schemaNode
 * @param {{maximum ?: number, exclusiveMaximum ?: number,minimum ?: number, exclusiveMinimum?: number}} overrides
 * @desc validating the provided number value by the supplied ranges from the schema and UI overrides
 * **NOTE**  we've update the exclusiveMin default to allow for ZERO as a valid value.
 * This is intentional, and should be overridden by the controls where needed.
 * This was previously set to Number.MIN_VALUE which prevented Zero from being valid. (PDD)
 */

const isNumericallyValid = (schemaNode = {}, overrides = {}) => {
  const {
    maximum = Number.MAX_VALUE,
    exclusiveMaximum = Number.MAX_VALUE,
    minimum = 0,
    exclusiveMinimum = -Number.MIN_VALUE,
  } = schemaNode || {};

  return (value) => {
    const numeric = Number(value);
    return (
      !isNaN(numeric) &&
      numeric >= toGreatest(minimum, overrides.minimum) &&
      numeric > toGreatest(exclusiveMinimum, overrides.exclusiveMinimum) &&
      numeric < toLeast(exclusiveMaximum, overrides.exclusiveMaximum) &&
      numeric <= toLeast(maximum, overrides.maximum)
    );
  };
};

// SNET-770: Return a numerically valid input based on schemaNode (AZ)
const toReturnNumericallyValidInput = (schemaNode = {}, overrides = {}) => {
  const {
    maximum = Number.MAX_VALUE,
    exclusiveMaximum = Number.MAX_VALUE,
    minimum = 0,
    exclusiveMinimum = -Number.MIN_VALUE,
  } = schemaNode || {};
  return (value) => {
    const numeric = Number(value);
    if (numeric <= toGreatest(minimum, overrides.minimum)) {
      return toGreatest(minimum, overrides.minimum);
    }
    if (numeric < toGreatest(exclusiveMinimum, overrides.exclusiveMinimum)) {
      return toGreatest(exclusiveMinimum, overrides.exclusiveMinimum) + 1;
    }
    if (numeric >= toLeast(exclusiveMaximum, overrides.exclusiveMaximum)) {
      return toLeast(exclusiveMaximum, overrides.exclusiveMaximum) - 1;
    }
    if (numeric > toLeast(maximum, overrides.maximum)) {
      return toLeast(maximum, overrides.maximum);
    }
    return value;
  };
};

export const NumberInput = (props) => {
  const {
    dataElementName = '',
    dataPath,
    min,
    max,
    exclusiveMinimum,
    exclusiveMaximum,
    onChange = NOOP_FUNCTION,
    value: propValue = null,
    validityStyles: propsValidityStyles,
    required: propsRequired,
    descriptor,
    descriptorClassName = '',
    className,
    children,
    onValidation = NOOP_FUNCTION,
    format = INTEGER_FORMAT,
    forceRangeInput = false,
    ...passthroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value = propValue,
    schemaNode,
    touched,
    required: schemaRequired = false,
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  const numberInputRef = useRef();

  const numericValue = isNil(value) ? null : Number(value);
  const required = schemaRequired || propsRequired;

  const isValueValid = (value) => {
    if (value === null) return !required;
    return isNumericallyValid(schemaNode, {
      minimum: min,
      maximum: max,
      exclusiveMinimum,
      exclusiveMaximum,
    })(value);
  };

  const returnNumericallyValidInput = toReturnNumericallyValidInput(
    schemaNode,
    {
      minimum: min,
      maximum: max,
      exclusiveMinimum,
      exclusiveMaximum,
    }
  );

  const validityStyles = schemaNode ? touched : propsValidityStyles;

  const { validityMessage = '' } = schemaNode || {};
  const valid =
    !validityMessage && isValueValid(numericValue) ? undefined : false;

  useEffect(() => {
    if (onValidationChange && numberInputRef.current) {
      const uiValid = toIsKendoComponentValid(numberInputRef.current);
      onValidationChange(
        uiValid,
        numberInputRef.current.element?.validationMessage
      );
    }
  }, [
    value,
    required,
    max,
    min,
    exclusiveMinimum,
    exclusiveMaximum,
    onValidationChange,
  ]);

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? dataElementName + '__label' : ''
        }
        className={descriptorClassName}
        required={required}
      >
        {descriptor}
      </UIControlLabel>
      <Flexbox
        alignItems="center"
        dataElementName={dataElementName}
        className={className}
      >
        <NumericTextBox
          data-element-name={dataElementName}
          valid={valid}
          spinners={false}
          format={format}
          required={required}
          validityStyles={validityStyles}
          ref={numberInputRef}
          onChange={({ target: { value } }) => {
            const returnValue = forceRangeInput
              ? returnNumericallyValidInput(value)
              : value;
            onValueChange(returnValue);
          }}
          min={min}
          max={max}
          value={numericValue}
          rangeOnEnter={true}
          {...passthroughProps}
        />
        {children}
      </Flexbox>
    </>
  );
};

export default NumberInput;

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataPath: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  descriptorClassName: PropTypes.string,
  dataElementName: PropTypes.string,
};
