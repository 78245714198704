import { Children, cloneElement } from 'react';
import {
  TabStrip as KendoTabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import { Flexbox, Label } from '../../components';
import { withClassNameModifiers } from '../../utils/withClassNameModifiers.js';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const TabWrapper = (props) => {
  const { children, title, dataElementName, onClick } = props;
  return (
    <button
      className="xjs-tab-strip__button padding-horizontal-small"
      onClick={onClick}
      title={title}
      data-element-name={dataElementName}
    >
      {children}
    </button>
  );
};

export const TabStrip = (props) => {
  const {
    item,
    // TODO: (SYNUI-5083) `onClick` is never used by any instance of TabStrip. Should it exist? (JDM)
    onClick = NOOP_FUNCTION,
    children,
    keepTabsMounted = true,
    className = '',
    tabContentStyle = EMPTY_OBJECT,
    look = 'normal',
    ...tabStripProps
  } = props;

  const { display: tabContentDisplayStyle = 'flex' } = tabContentStyle;

  return (
    <KendoTabStrip
      {...tabStripProps}
      className={`overflow-auto ${withClassNameModifiers('xjs-tab-strip', {
        light: look === 'light',
        /*
         * Apply flex-related properties to the tab panels _only_ if they are flex items.
         * See https://developer.mozilla.org/en-US/docs/Glossary/Flex_Item for the formal definition
         */
        ['flex-content']: tabContentDisplayStyle.includes('flex'),
      })} ${className}`}
      keepTabsMounted={keepTabsMounted}
      animation={false}
      tabContentStyle={{ display: tabContentDisplayStyle, ...tabContentStyle }}
    >
      {Children.map(children, (child, index) => {
        const { props: childProps, key } = child;
        const {
          title,
          dataElementName,
          itemRenderer: TabItemRenderer = () => <Label>{title}</Label>,
          LeftElement,
          RightElement,
          rendererProps,
          style,
        } = childProps;

        return cloneElement(child, {
          key: key || index,
          contentClassName: 'xjs-tab-strip__tab',
          title: (
            <Flexbox alignItems="center" style={style}>
              {LeftElement}
              <TabWrapper
                onClick={(ev) => onClick(item, ev)}
                title={title}
                dataElementName={dataElementName}
              >
                <TabItemRenderer
                  dataElementName={dataElementName}
                  title={title}
                  {...rendererProps}
                />
              </TabWrapper>
              {RightElement}
            </Flexbox>
          ),
        });
      })}
    </KendoTabStrip>
  );
};

export default TabStrip;

export { TabStripTab };

TabStrip.propTypes = {
  onClick: PropTypes.func,
  look: PropTypes.oneOf(['normal', 'light']),
  tabContentStyle: PropTypes.object,
  className: PropTypes.string,
  keepTabsMounted: PropTypes.bool,
};
