import { buildDueDateWithOffset, buildActualDateWithOffset } from '../utils';

export const alertsNumber = (notifications) => {
  const {
    DecisionDate = '',
    DecisionDueDate = '',
    NotifyMemberDueDate = '',
    NotifyMemberLetterDate = '',
    NotifyProviderDueDate = '',
    NotifyProviderLetterDate = '',
  } = notifications;
  // Member due date
  const { offset: hasMemberDueOffset } = buildDueDateWithOffset(
    NotifyMemberDueDate,
    !!NotifyMemberLetterDate
  );
  // Provider due date
  const { offset: hasProviderDueOffset } = buildDueDateWithOffset(
    NotifyProviderDueDate,
    !!NotifyProviderLetterDate
  );
  //Decision due date
  const { offset: hasDecisionDueOffset } = buildDueDateWithOffset(
    DecisionDueDate,
    !!DecisionDate
  );
  // Member actual date
  const { offset: hasMemberActualOffset } = buildActualDateWithOffset(
    NotifyMemberDueDate,
    NotifyMemberLetterDate
  );
  const { offset: hasProviderActualOffset } = buildActualDateWithOffset(
    NotifyProviderDueDate,
    NotifyProviderLetterDate
  );
  //Decision actual date
  const { offset: hasDecisionActualOffset } = buildActualDateWithOffset(
    DecisionDueDate,
    DecisionDate
  );

  const alertsNumberCalc =
    (hasMemberDueOffset ? 1 : 0) +
    (hasProviderDueOffset ? 1 : 0) +
    (hasDecisionDueOffset ? 1 : 0) +
    (hasMemberActualOffset ? 1 : 0) +
    (hasProviderActualOffset ? 1 : 0) +
    (hasDecisionActualOffset ? 1 : 0);
  return alertsNumberCalc;
};
