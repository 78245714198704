import { useSchema } from '../../schema/SchemaReducer';
import { identity } from '../../fp/fp';
import { isNil } from '../../fp/pred';
import { pluck } from '../../fp/object';
import { Popup } from '@progress/kendo-react-popup';
import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DropDownList } from '../../components/DropDownList';
import { Flexbox } from '../../components/Flexbox';
import { Label, UIControlLabel } from '../../components/Label';
import { TextInput } from '../../components/TextInput';
import { ToolTip } from '../ToolTip';
import { FilterTree } from './components/FilterTree';
import './style.css';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

const defaultLabelFn = (value) =>
  typeof value === 'string' ? value : JSON.stringify(value);

const onClickOutsideofElement = (clickEvent, element, callback) => {
  const { x, y } = clickEvent;

  if (element) {
    const { left, right, top, bottom } = element.getBoundingClientRect();
    if (x < left || x > right || y < top || y > bottom) {
      callback(clickEvent, element);
    }
  }
};

const toCheckedItems = (
  data,
  value,
  branchFn,
  comparator,
  valueFn,
  currentPath = ''
) => {
  return data.reduce((selectedItems, item, index) => {
    const itemPath =
      currentPath === '' ? `${index}` : `${currentPath}_${index}`;
    const leafNodes = Array.isArray(branchFn(item)) || [];
    if (leafNodes.length) {
      const selectedChildren = toCheckedItems(
        leafNodes,
        value,
        branchFn,
        comparator,
        valueFn,
        itemPath
      );
      return { ...selectedItems, ...selectedChildren };
    }

    const isChecked = value.some((valueItem) => {
      return comparator
        ? comparator(valueItem, item)
        : valueFn(item) === valueItem;
    });

    if (isChecked) {
      return { ...selectedItems, [itemPath]: item };
    }

    return selectedItems;
  }, {});
};

const toCheckedItemsString = (
  data,
  branchFn,
  branchLabelFn,
  labelFn,
  checkedPaths = []
) => {
  if (!checkedPaths.length) return '';
  const arrayOfAncestorNodeList = [...checkedPaths].sort().map((path) => {
    const splitPath = path.split('_');
    return splitPath.reduce(
      (result, pathIndex, currentIndex) => {
        if (currentIndex === 0) {
          const dataAtIndex = data[pathIndex];
          const childNodes = branchFn(dataAtIndex) || [];
          return [
            {
              label: childNodes.length
                ? branchLabelFn(dataAtIndex)
                : labelFn(dataAtIndex),
              nodes: childNodes,
            },
          ];
        }

        const [{ nodes: ancestorNodes }] = result.slice(-1);

        const dataAtIndex = ancestorNodes[pathIndex];
        const childNodes = branchFn(dataAtIndex) || [];
        return [
          ...result,
          {
            label: childNodes.length
              ? branchLabelFn(dataAtIndex)
              : labelFn(dataAtIndex),
            nodes: childNodes,
          },
        ];
      },
      [{}]
    );
  });

  const stringResult = arrayOfAncestorNodeList.reduce(
    (agg, ancestorNodeList = []) => {
      const labelForCheckedItem = ancestorNodeList
        .map(pluck('label'))
        .join(' > ');
      return agg === ''
        ? labelForCheckedItem
        : `${agg}\n${labelForCheckedItem}`;
    },
    ''
  );

  return stringResult;
};

/**
 * Groups items from `data` into expandable groupings, allowing multiselection via indivitual selection or by group
 * @param {array} data List of selectable data objects.
 * @param {function} valueFn Function applied to each selected item from `data`. Will be passed into the callback.
 * @param {function} branchFn Function applied to each tree node to project out a branch of nested items.
 * @param {function} [labelFn] Projection from a datem to a human-readable string.
 * @param {function} [branchLabelFn] Function used to display each branch header item within `data`.
 * @param {string} [className] Classname given to the dropdown portion of component.
 * @param {function} [onChange] Callback function to be given all currently selected items.
 * @param {bool} [allowSearch="false"] Boolean to determine whether or not `data` is searchable via text input. Matches on `labelFn` output of each item.
 * @param {bool} [multiple="false"] Boolean to determine whether or not multiple items can be selected in the tree.
 * @param {bool} [checkableSections="false"] Boolean to determine whether selecting a header selects all descendants or selects the header node itself.
 * @param {string} [dataPath] Path of where the data resides for the schema hook.
 */
export const TreeViewList = (props) => {
  const {
    dataElementName = '',
    dataPath,
    data,
    className = '',
    comparator,
    valueFn = identity,
    branchFn,
    labelFn = defaultLabelFn,
    checkableSections = false,
    branchLabelFn = defaultLabelFn,
    value: propsValue = EMPTY_ARRAY,
    onChange,
    allowSearch = false,
    multiple = false,
    descriptor,
    descriptorClassName,
    required: propsRequired,
    forceExpand = false,
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const { onValueChange = onChange, value = propsValue } = useSchema(dataPath);

  const valueAsArray = Array.isArray(value) ? value : [value];

  const [checkedPaths, setCheckedPaths] = useState(
    Object.keys(
      toCheckedItems(data, valueAsArray, branchFn, comparator, valueFn)
    )
  );
  const prevValueRef = useRef(value);

  const [searchText, setSearchText] = useState('');

  if (prevValueRef.current !== value) {
    if (!isNil(value)) {
      setCheckedPaths(
        Object.keys(
          toCheckedItems(data, valueAsArray, branchFn, comparator, valueFn)
        )
      );
      setSearchText('');
    } else {
      setCheckedPaths([]);
    }
    prevValueRef.current = value;
  }

  const onSelect = (
    _itemsToAdd,
    shouldAddItem,
    isSectionChecked,
    checkableSections
  ) => {
    if (multiple) {
      const itemsToAdd = (() => {
        if (isSectionChecked)
          return checkableSections ? _itemsToAdd : _itemsToAdd.slice(1);
        return _itemsToAdd;
      })();
      const previousItems = valueAsArray.filter(
        (item) =>
          !itemsToAdd.find(({ node } = {}) => {
            return comparator ? comparator(item, node) : item === valueFn(node);
          })
      );
      const seed = multiple ? previousItems : [];
      const allUniqueItems = itemsToAdd.reduce((acc, item) => {
        const { node } = item;
        if (shouldAddItem) return [...acc, valueFn(node)];
        return acc;
      }, seed);
      let nextCheckedPaths = [];
      const itemPaths = itemsToAdd.map(pluck('path'));
      if (!shouldAddItem) {
        nextCheckedPaths = checkedPaths.filter(
          (path) => !itemPaths.includes(path)
        );
      } else {
        nextCheckedPaths = Array.from(new Set([...checkedPaths, ...itemPaths]));
      }
      setCheckedPaths(nextCheckedPaths);
      prevValueRef.current = allUniqueItems;
      return onValueChange(allUniqueItems);
    }
    const [singleItem] = _itemsToAdd;
    const { path, node } = singleItem;
    setCheckedPaths([path]);
    prevValueRef.current = node;
    return onValueChange(valueFn(node));
  };

  const [showPopup, setShowPopup] = useState(false);
  const [repositionPending, setRepositionPending] = useState(false);

  const isSelected = (item) =>
    valueAsArray.some((v) =>
      comparator ? comparator(v, item) : valueFn(item) === v
    );

  const filterByText = !!(searchText && searchText.length > 2);

  const filterFn = (node) => {
    if (!filterByText) return true;

    const hasValue = valueFn(node);
    const label = labelFn(node) || '';

    return hasValue && label.toLowerCase().includes(searchText.toLowerCase());
  };

  const anchorRef = useRef(null);
  const dropdownRef = useRef(null);
  const popupRef = useRef(null);

  // Unlike Kendo Dropdowns, Kendo Popups do not collapse when you click away, so we have to add that handler manually
  useEffect(() => {
    const dismissOnClickAway = (event) => {
      onClickOutsideofElement(event, dropdownRef.current, () =>
        setShowPopup(false)
      );
    };
    window.addEventListener('mousedown', dismissOnClickAway);

    return () => window.removeEventListener('mousedown', dismissOnClickAway);
  }, [showPopup]);

  useLayoutEffect(() => {
    if (repositionPending && popupRef.current) {
      popupRef.current.setPosition(popupRef.current._popup);
      setRepositionPending(false);
    }
  }, [repositionPending, setRepositionPending]);

  const dropDownListValue = toCheckedItemsString(
    data,
    branchFn,
    branchLabelFn,
    labelFn,
    checkedPaths
  );

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__label` : ''
          }
          required={propsRequired}
          className={descriptorClassName}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <ToolTip
        disabled={(dropDownListValue || '').split('\n').length <= 1}
        value={
          dropDownListValue ? (
            <Label wrapText={true}>{dropDownListValue}</Label>
          ) : (
            ''
          )
        }
      >
        <div
          ref={(ref) => {
            anchorRef.current = ref;
          }}
        >
          <DropDownList
            dataElementName={dataElementName}
            listNoDataRender={() => null}
            onOpen={() => {
              setShowPopup(true);
            }}
            opened={false}
            data={[dropDownListValue]}
            value={dropDownListValue}
          />
        </div>
      </ToolTip>
      <Popup
        anchor={anchorRef.current}
        ref={(ref) => {
          popupRef.current = ref;
        }}
        show={showPopup}
        popupClass={`tree-view-list__container ${className}`}
        collision={{
          horizontal: 'flip',
          vertical: 'flip',
        }}
        anchorAlign={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <div
          className={`tree-view-list__click-wrapper overflow-auto ${
            showPopup ? 'expanded' : 'collapsed'
          }`}
          ref={(ref) => (dropdownRef.current = ref)}
          style={forceExpand ? {} : { maxHeight: '200px' }}
        >
          <Flexbox
            direction="column"
            className="flex-box__container flex-1"
            // Using the anchor ref element's width. Is there any other way to do this?
            style={
              anchorRef.current
                ? { minWidth: `${anchorRef.current.offsetWidth}px` }
                : {}
            }
          >
            {allowSearch && (
              <TextInput
                value={searchText}
                onChange={(value) => setSearchText(value)}
              />
            )}
            <div className="vertical-flex-container flex-1">
              {data.map((node, index) => {
                return (
                  <FilterTree
                    node={node}
                    branchFn={branchFn}
                    branchLabelFn={branchLabelFn}
                    valueFn={valueFn}
                    labelFn={labelFn}
                    filterFn={filterFn}
                    shouldFlatten={filterByText}
                    checkableSections={checkableSections}
                    isSelectedFn={isSelected}
                    onSelect={onSelect}
                    onNodeExpanded={() => setRepositionPending(true)}
                    multiple={multiple}
                    path={`${index}`}
                    key={index}
                  />
                );
              })}
            </div>
          </Flexbox>
        </div>
      </Popup>
    </>
  );
};

TreeViewList.propTypes = {
  data: PropTypes.array.isRequired,
  valueFn: PropTypes.func,
  branchFn: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelFn: PropTypes.func,
  branchLabelFn: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.any]),
  onChange: PropTypes.func,
  allowSearch: PropTypes.bool,
  multiple: PropTypes.bool,
  checkableSections: PropTypes.bool,
};

export default TreeViewList;
