import { useState } from 'react';
import {
  queryBookRelation,
  queryBookAsInstance,
} from 'services/smart-books/xe-smart-books-svc';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../constants';
import { useEnterprise } from '../../../../contexts/XeEnterpriseContext';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { useXeQuery } from '../../../../data/useXeQuery';
import { identity } from '../../../../fp/fp';
import { pluck } from '../../../../fp/object';
import { isArray, isNil } from '../../../../fp/pred';
import { ADD, EDIT, REMOVE } from '../../../../icons';
import { useSchema } from '../../../../schema/SchemaReducer';
import Checkbox from '../../../Checkbox';
import DatePicker from '../../../DatePicker';
import { DropDownList } from '../../../DropDownList';
import Flexbox from '../../../Flexbox';
import { GridLayout } from '../../../GridLayout';
import IconButton from '../../../IconButton';
import { Label } from '../../../Label';
import { OverflowContainer } from '../../../OverflowContainer';
import { Panel } from '../../../Panel';
import { Popup } from '../../../Popup';
import { Footer, FooterButton } from '../../../Popup/components/Footer';
import { TabStrip, TabStripTab } from '../../../TabStrip';
import TextArea from '../../../TextArea';
import TextInput from '../../../TextInput';
import { ExpandableNode } from '../../components/ExpandableNode';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
// import uniqid from 'uniqid';

const ModifyCarePlanItemSection = (props) => {
  const { labels, sectionData, items = EMPTY_ARRAY, onChange } = props;
  const { AttrType, BookID } = sectionData;

  const { data: [bookIdAsSmartBookInstance] = EMPTY_ARRAY } = useXeQuery(
    queryBookAsInstance({ bookId: BookID }, identity),
    {
      enabled: !isNil(BookID),
    }
  );

  const { CARE_PLAN_ITEM_STATUS } = useXeRefData();
  const [interventionText, setInterventionText] = useState('');

  const {
    userData: {
      ResourceID,
      XeResource: { FamilyName, GivenName },
    },
  } = useEnterprise();

  // This only supports interventions. No other types of care plan items are supported
  // in this UI and probably won't for quite a while (JDM)
  if (AttrType !== 'CarePlanIntervention') return null;

  if (!bookIdAsSmartBookInstance) return null;

  return (
    <GridLayout templateRows="auto 1fr" className="stretch-x">
      <GridLayout templateColumns="auto min-content" templateRows="auto auto">
        <TextArea
          descriptorPosition="left"
          style={{
            gridColumnStart: 1,
          }}
          placeholder={labels.EnterFreeTextIntervention}
          rows={3}
          value={interventionText}
          onChange={setInterventionText}
        />
        <IconButton
          icon={ADD}
          description={labels.Add}
          disabled={!interventionText}
          onClick={() => {
            const nextObject = {
              ...bookIdAsSmartBookInstance,
              ResultValue: interventionText,
              Modified: true,
              // I'm not sure if these really needs to be set, but doing this for parity
              // with the legacy implementation (JDM)
              StartedBy: {
                ResourceID,
                FamilyName,
                GivenName,
              },
            };

            onChange([...items, nextObject]);
            setInterventionText('');
          }}
        />
      </GridLayout>
      {items.length ? (
        <Panel>
          <OverflowContainer>
            {items.map((node, index) => {
              if (!node?.Active) {
                return null;
              }
              const changeHandler = (nextObject) => {
                if (!nextObject.Active && !nextObject.ModifiedTStamp) {
                  return onChange([
                    ...items.slice(0, index),
                    ...items.slice(index + 1),
                  ]);
                }

                return onChange([
                  ...items.slice(0, index),
                  nextObject,
                  ...items.slice(index + 1),
                ]);
              };
              if (!node) return null;
              const { EndDate, StatusID, ResultValue } = node;

              return (
                <div
                  key={`${ResultValue}_${index}`}
                  className="margin-bottom-medium"
                >
                  <Label
                    wrapText={true}
                    className="font-bold margin-bottom-medium"
                  >
                    {ResultValue}
                  </Label>
                  <GridLayout templateColumns="repeat(2, minmax(min-content, max-content) auto) max-content">
                    <DropDownList
                      descriptor={labels.Status}
                      data={CARE_PLAN_ITEM_STATUS}
                      labelFn={pluck('text')}
                      valueFn={pluck('id')}
                      comparator={(listItemValue, value) => {
                        return value == listItemValue;
                      }}
                      value={parseInt(StatusID)}
                      onChange={(value) => {
                        changeHandler({
                          ...node,
                          Modified: true,
                          StatusID: `${value}`,
                        });
                      }}
                    />
                    <DatePicker
                      descriptor={labels.EndDate}
                      value={EndDate}
                      format="date-time"
                      onChange={(value) => {
                        changeHandler({
                          ...node,
                          Modified: true,
                          EndDate: value,
                        });
                      }}
                    />
                    <IconButton
                      icon={REMOVE}
                      description={labels.Remove}
                      onClick={() => {
                        changeHandler({
                          ...node,
                          Active: false,
                          Modified: true,
                        });
                      }}
                    />
                  </GridLayout>
                </div>
              );
            })}
          </OverflowContainer>
        </Panel>
      ) : null}
    </GridLayout>
  );
};

export const ModifyCarePlanPopup = (props) => {
  const { carePlanItems = EMPTY_ARRAY, catalogItem, onSave, onCancel } = props;

  const { BookID, Name, ResultValue: freeformItemName = '' } = catalogItem;

  const labels = useXeLabels();
  const [itemsToUpdate, setItemsToUpdate] = useState(carePlanItems);

  const isCarePlanNotMatching = carePlanItems.length !== itemsToUpdate.length;
  const isItemsUpdated = itemsToUpdate.some((item) => item.Modified);
  const isSaveDisabled = !(isItemsUpdated || isCarePlanNotMatching);

  const { data: [possibleChildren] = EMPTY_ARRAY } = useXeQuery(
    queryBookRelation({ bookId: BookID }, identity)
  );

  const { data: [bookIdAsSmartBookInstance] = EMPTY_ARRAY } = useXeQuery(
    queryBookAsInstance({ bookId: BookID }, identity),
    {
      enabled: !isNil(BookID),
    }
  );

  if (!bookIdAsSmartBookInstance) return null;

  return (
    <Popup title={Name || freeformItemName}>
      {possibleChildren?.XeSmartBook?.map((node) => {
        const { BookID } = node;

        return (
          <ModifyCarePlanItemSection
            key={BookID}
            labels={labels}
            sectionData={node}
            items={itemsToUpdate.map((item) => {
              const { AttrType } = item;
              return AttrType === node?.AttrType ? item : null;
            })}
            onChange={setItemsToUpdate}
          />
        );
      })}
      <Footer>
        <FooterButton dataElementName="close" onClick={onCancel}>
          {labels.Cancel}
        </FooterButton>
        <FooterButton
          disabled={isSaveDisabled}
          dataElementName="confirm"
          onClick={() => {
            onSave({
              ...bookIdAsSmartBookInstance,
              Modified: true,
              XeSmartBookInstance: itemsToUpdate,
            });
          }}
        >
          {labels.Save}
        </FooterButton>
      </Footer>
    </Popup>
  );
};

const FreeformItem = (props) => {
  const { data, onAdd, labels } = props;

  const { ResultValue } = data;

  const { ADD_POC_FREE_TEXT } = useXeRights();

  const [carePlan, setCarePlan] = useState(null);
  const [carePlanName, setCarePlanName] = useState(ResultValue);
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  if (!ADD_POC_FREE_TEXT) {
    return null;
  }

  return (
    <Flexbox className="padding-all-medium">
      <Checkbox
        checked={!!carePlan}
        onChange={() => {
          setShowModifyDialog(true);
        }}
        // TODO: we will need to ensure
        // the related FreeformItem is not a duplicate
        // of other existing FreeformItem's for the sake of
        // disabling this checkbox. This work will be done
        // via SYNUI-8680 (GCH)
        disabled={!carePlanName}
      />
      <TextInput
        placeholder={labels.EnterFreeText}
        value={carePlanName}
        onChange={(value) => {
          setCarePlanName(value);
        }}
        onBlur={() => {
          // TODO: we will need to restore previously lost
          // functionality involving the adding of additional
          // FreeformItem's when filling out the currently existing ones.
          // Work for this functionality have been documented via
          // SYNUI-8680 (GCH)
        }}
        type="text"
      />
      {carePlan && (
        <IconButton
          dataElementName="edit"
          description={labels.Edit}
          icon={EDIT}
          onClick={() => {
            setShowModifyDialog(true);
          }}
        />
      )}
      {showModifyDialog ? (
        <ModifyCarePlanPopup
          carePlanItems={carePlan?.XeSmartBookInstance}
          catalogItem={data}
          onSave={(nextObject) => {
            const updatedObject = { ...nextObject, ResultValue: carePlanName };
            setCarePlan(updatedObject);
            setShowModifyDialog(false);
            onAdd(updatedObject);
          }}
          onCancel={() => setShowModifyDialog(false)}
          title={carePlanName}
        />
      ) : null}
    </Flexbox>
  );
};

const CatalogItem = (props) => {
  const { data, labels, onAdd } = props;

  const { Name } = data;

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [carePlan, setCarePlan] = useState(null);

  const hasCarePlanChildren = !!carePlan?.XeSmartBookInstance.length;

  return (
    <>
      <Flexbox className="padding-all-medium">
        <Checkbox
          className="stretch-x box-sizing-border-box"
          wrapText={true}
          label={Name}
          onClick={() => {
            setShowModifyDialog(true);
          }}
          checked={hasCarePlanChildren}
        />
        {hasCarePlanChildren ? (
          <IconButton
            className="align-self-start"
            description={labels.Edit}
            icon={EDIT}
            onClick={() => {
              setShowModifyDialog(true);
            }}
          />
        ) : null}
      </Flexbox>
      {showModifyDialog ? (
        <ModifyCarePlanPopup
          carePlanItems={carePlan?.XeSmartBookInstance}
          catalogItem={data}
          onSave={(nextObject) => {
            setCarePlan(nextObject);
            setShowModifyDialog(false);
            onAdd(nextObject);
          }}
          onCancel={() => setShowModifyDialog(false)}
        />
      ) : null}
    </>
  );
};

// TODO: the current implementation of Catalog
// does not allow the implicit removal of entries currently.
// Checked items remain checked even when the carePlan's
// XeSmartBookInstance is empty. To fix this,
// we need to ensure the related XeSmartBookInstance
// is populated. If the related XeSmartBookInstance is empty,
// the related Catalog item's (FreeformItem and CatalogItem)
// should remain unchecked in the Add popup.
// Reference for this work can be found via SYNUI-8681 (GCH)
const Catalog = (props) => {
  const { checkedBookIDsSet, data, onSave, onCancel, labels } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [modifiedBookIDData, setModifiedBookIDData] = useState({});

  const filteredModifiedBookIDData = Object.values(modifiedBookIDData).filter(
    (BookIDData) => !!BookIDData?.XeSmartBookInstance.length
  );
  const isSaveDisabled = !filteredModifiedBookIDData?.length;

  return (
    <Popup title={labels.Add}>
      <TabStrip
        onSelect={({ selected }) => setSelectedTabIndex(selected)}
        selected={selectedTabIndex}
      >
        {data.map((catalogTab) => {
          const { CategoryID, Name, XeSmartBook = EMPTY_ARRAY } = catalogTab;

          return (
            <TabStripTab key={CategoryID} title={Name}>
              <GridLayout templateColumns="repeat(3, 1fr)">
                {XeSmartBook.map((item) => {
                  const { BookID } = item ?? EMPTY_OBJECT;
                  if (checkedBookIDsSet.has(BookID)) return null;

                  if (!item?.Name) {
                    return (
                      <FreeformItem
                        key={BookID}
                        onAdd={(nextObject) => {
                          return setModifiedBookIDData({
                            ...modifiedBookIDData,
                            [BookID]: nextObject,
                          });
                        }}
                        data={item}
                        labels={labels}
                      />
                    );
                  }

                  return (
                    <CatalogItem
                      key={BookID}
                      onAdd={(nextObject) => {
                        return setModifiedBookIDData({
                          ...modifiedBookIDData,
                          [BookID]: nextObject,
                        });
                      }}
                      data={item}
                      labels={labels}
                    />
                  );
                })}
              </GridLayout>
            </TabStripTab>
          );
        })}
      </TabStrip>
      <Footer>
        <FooterButton onClick={onCancel}>{labels.Cancel}</FooterButton>
        <FooterButton
          disabled={isSaveDisabled}
          onClick={() => {
            onSave(filteredModifiedBookIDData);
          }}
        >
          {labels.Save}
        </FooterButton>
      </Footer>
    </Popup>
  );
};

export const CarePlanIssues = (props) => {
  const { children, node, readOnly, onValidated } = props;

  const dataPath = 'XeSmartBookInstance';

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { BookID, Name, IsVisible } = nodeWithDefaults;

  const labels = useXeLabels();

  const [showCatalog, setShowCatalog] = useState(false);

  const { data } = useXeQuery(queryBookRelation({ bookId: BookID }, identity), {
    enabled: showCatalog,
  });

  const {
    value = EMPTY_ARRAY,
    onValueChange,
    fullPath,
  } = useSchema('XeSmartBookInstance');

  const { onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;

  return (
    <>
      <ExpandableNode
        defaultExpanded={true}
        node={node}
        ItemElement={
          <Flexbox
            className="flex-1"
            alignItems="center"
            justifyContent="space-between"
          >
            <Label
              className="smartbook-label margin-vertical-small"
              wrapText={false}
            >
              {Name}
            </Label>
            {!readOnly ? (
              <IconButton
                dataElementName="add"
                icon={ADD}
                description={labels.Add}
                onClick={() => {
                  setShowCatalog(true);
                }}
              />
            ) : null}
          </Flexbox>
        }
      >
        {children}
      </ExpandableNode>
      {showCatalog && isArray(data) ? (
        <Catalog
          data={data}
          labels={labels}
          onSave={(nextSmartBookInstances) => {
            onValueChange([...value, ...nextSmartBookInstances]);
            onValidChange(true);
            setShowCatalog(false);
          }}
          onCancel={() => setShowCatalog(false)}
          checkedBookIDsSet={value.reduce((resultSet, node) => {
            const { BookID } = node;
            resultSet.add(BookID);

            return resultSet;
          }, new Set())}
        />
      ) : null}
    </>
  );
};
