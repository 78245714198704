import { pipe, identity } from '../../fp/fp';
import { pluck as pluckFp } from '../../fp/object';
import {
  isEmpty,
  isEmptyObject,
  isNil,
  isObjectLike,
  isStrictlyEqualTo,
  not,
} from '../../fp/pred';
import { castSchema } from '../../schema/schemaCaster';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { DELETE, MERGE_PATCH } from '../../schema/JSONSchemaReducer';
import { exists } from '../../frp/operators/exists';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';
import { ofType } from '../../frp/operators/ofType';
import { schemaGet } from '../../schema/schemaTypeBuilder';
import { transform } from '../../fp/transformation';
import { combineLatest, merge, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  startWith,
  switchMapTo,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { browse as refDataBrowse } from 'services/clindocs/xe-clindocs-svc';
import {
  browse,
  getCustomLanguage,
} from 'services/correspondence-infos/xe-correspondence-infos-svc.js';
import {
  getDefaultSpec,
  getDetails,
  getDraft,
  saveAsStatus,
  markInError,
} from 'services/correspondences/xe-correspondences-svc.js';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import { browse as reportBrowse } from 'services/report-infos/xe-report-infos-svc.js';
import { browse as attachmentBrowse } from 'services/scan-doc-sets/xe-scan-doc-sets-svc';
import AssetXeAppRightsSchema from 'services/schemas/com.thrasys.xnet.app.assets.AssetXeAppRights.json';
import VisitCorrespondenceEnterSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter.json';
import { queryBookAsInstance } from 'services/smart-books/xe-smart-books-svc';
import { searchAsRightsUser } from 'services/staffs/xe-staffs-svc.js';
import { browse as clinDocBrowse } from 'services/visit-clindocs/xe-visit-clindocs-svc';
import {
  DEFAULT_ALERT_TIME,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ERROR_NOTIFICATION,
} from '../../constants';
import { NOTIFICATION } from '../../enterpriseContainer/XeAppContainer/actions';
import { OUTPUT_TYPE_PDF } from '../../files/constants';
import {
  downloadFile,
  getFileData,
  getReportData,
} from '../../files/operators';
import { neverCache } from '../../service/serviceCache';
import { isTrue } from '../../utils';
import {
  AUTO_SELECT_LETTER_TEMPLATE,
  DID_DOWNLOAD_ATTACHMENT,
  DID_DOWNLOAD_CLINDOC,
  DID_DOWNLOAD_REPORT,
  DID_GET_ATTACHMENTS,
  DID_GET_CLINDOCS,
  DID_GET_CLINDOC_REF_DATA,
  DID_GET_REPORTS,
  DID_MARK_IN_ERROR_PREVIEW,
  DID_QUICKSAVE_LETTER,
  DID_SAVE_LETTER,
  DISPLAY_ERROR_TOAST,
  DISPLAY_LETTER_PREVIEW,
  LETTER_SAVE_COMPLETE,
  MARK_IN_ERROR_BEFORE_CLOSE,
  QUERY_DEFAULT_SPEC,
  REQUEST_ATTACHMENTS_POPUP_DATA,
  REQUEST_EDIT_MODE_CUSTOM_LANGUAGES,
  RESPONSE_CUSTOM_LANGUAGES,
  RESPONSE_QUERY_DEFAULT_SPEC,
  RESPONSE_QUERY_LETTERS,
  SET_AVAILABLE_STEPS,
  SET_COVER_SHEET_DATA,
  SET_CURRENT_STEP,
  SET_IS_SEARCH_FOR_ALL_PROGRAMS,
  SET_LETTER_DETAILS,
  SET_LETTER_TEMPLATES,
  SET_LOB,
  SET_MEDICAL_DIRECTORS,
  SET_PRE_POPUP,
  SET_SELECTED_LETTER_TEMPLATE,
  SET_SELECTED_COVER_SHEETS,
  SET_SUBTYPE,
  SET_WIZARD_MODE,
  SHOULD_CLOSE_WIZARD,
  SHOULD_DOWNLOAD_ATTACHMENT,
  SHOULD_DOWNLOAD_CLINDOC,
  SHOULD_DOWNLOAD_REPORT,
  SHOULD_GET_CLINDOCS,
  SHOULD_GET_PDF,
  SHOULD_PREVIEW_LETTER,
  SHOULD_SAVE_LETTER,
  SHOW_WIZARD,
  WIZARD_FLOW_GO_TO,
  WIZARD_FLOW_PROCEED,
  WIZARD_FLOW_RETURN,
  SET_INITIAL_LETTER,
} from './actions';
import { WIZARD_STEPS } from './constants';
import { toShouldSkipDataEntry, toSubType } from './utils';

/**
 * @typedef {import("services/generated/types").VisitCorrespondenceEnter$XeVisitCorrespRecip} XeVisitCorrespRecip
 */

const toGetClinDocTypesEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_ATTACHMENTS_POPUP_DATA),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, toRequest$]) => {
      return refDataBrowse({}, toRequest$());
    }),
    map((value) => ({
      type: DID_GET_CLINDOC_REF_DATA,
      value: value,
    }))
  );
};

const toGetClinDocsEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_GET_CLINDOCS),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([value, toRequest$]) => {
      return clinDocBrowse(value, toRequest$());
    }),
    map((value) => ({
      type: DID_GET_CLINDOCS,
      value: value,
    }))
  );
};

const toGetClinReportDataEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_CLINDOC),
    pluck('value'),
    getReportData((value = {}) => {
      const { IPID: { IPID } = {}, ReportID, VisitAssessmentID } = value;
      return {
        OutputType: OUTPUT_TYPE_PDF,
        Report: [
          {
            Parameter: [
              {
                Name: 'IPID',
                Value: `${IPID}`,
              },
              {
                Name: 'VisitAssessmentID',
                Value: `${VisitAssessmentID}`,
              },
            ],
            ReportID,
          },
        ],
        ReturnResults: true,
      };
    }, menuNode$),
    pluck('results'),
    getFileData(([response]) => {
      const {
        Report: [firstReport],
      } = response;
      return { fileId: firstReport.FileID, Name: firstReport.Name };
    }, menuNode$),
    downloadFile(({ response = {}, fileData = {} }) => {
      const { blob } = response;
      return {
        blob,
        fileName: fileData.Name,
      };
    }),
    mapTo({
      type: DID_DOWNLOAD_CLINDOC,
    })
  );
};

const toGetReportsEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_ATTACHMENTS_POPUP_DATA),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, toRequest$]) => {
      return reportBrowse({ templateType: 'PDF' }, toRequest$());
    }),
    map((value) => ({
      type: DID_GET_REPORTS,
      value: value,
    }))
  );
};

const toDownloadReportEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_REPORT),
    pluck('value'),
    getFileData((data = {}) => {
      const { FileID: { FileID } = {}, FileName } = data;
      return { fileId: FileID, fileName: FileName };
    }, menuNode$),
    downloadFile(({ response = {}, fileData: { fileName } = {} } = {}) => {
      const { blob } = response;
      return {
        blob,
        fileName,
      };
    }),
    mapTo({
      type: DID_DOWNLOAD_REPORT,
    })
  );
};

const toGetAttachmentsEpic$ = (action$, state$, { params$, menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_ATTACHMENTS_POPUP_DATA),
    combineWithLatestFrom(
      params$.pipe(pluck('IPID')),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, ipid, toRequest$]) => {
      return attachmentBrowse(
        { ipid, fileType: 'PDF' },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((value) => ({
      type: DID_GET_ATTACHMENTS,
      value,
    }))
  );
};

const toDownloadAttachmentEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_ATTACHMENT),
    pluck('value'),
    getFileData((data = {}) => {
      const { FileID, FileName } = data;
      return { fileId: FileID, fileName: FileName };
    }, menuNode$),
    downloadFile(({ response = {}, fileData: { fileName } = {} } = {}) => {
      const { blob } = response;
      return {
        blob,
        fileName,
      };
    }),
    mapTo({
      type: DID_DOWNLOAD_ATTACHMENT,
    })
  );
};

/**
 * @type {import('redux-observable').Epic}
 * @description Test if this instance of the wizard is a create or edit.
 * If we are editing, this request will return a record.
 * If there are multiple records, an error should be displayed.
 * Note. This view does assumes create-only mode. More details needed for edits.
 */
const toQueryExistingLettersEpic$ = (action$, state$, { params$, menuNode$ }) =>
  params$.pipe(
    filter(exists),
    take(1),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([data, toRequest$]) => {
      const {
        IVID: ivid,
        LetterType: letterType,
        IsWorkList,
        VisitCorrespondenceID: visitCorrespondenceId,
        SubType: subType,
      } = data;

      const requestProps = {
        letterStatus: 'DRAFT|REVIEW',
        letterType,
        subType,
      };

      //SYNUI-5834 (PDD)
      //If we're in a worklist we'll always search for letters with IVID
      if (isTrue(IsWorkList)) {
        return getDetails(
          { ...requestProps, ivid },
          toRequest$({ fullRequest: true })
        );
      } else {
        //Non-Worklist, if we have an old letterID then use it.
        //Otherwise bail out and return empty result.
        if (!visitCorrespondenceId) {
          return of([]);
        } else {
          return getDetails(
            { ...requestProps, visitCorrespondenceId },
            toRequest$({ fullRequest: true })
          );
        }
      }
    }),
    map(({ results = [] }) => {
      return results.length > 1
        ? {
            type: NOTIFICATION,
            value: {
              timeout: DEFAULT_ALERT_TIME,
              toMessage: (labels) => {
                return labels.MultipleLetters;
              },
              style: ERROR_NOTIFICATION,
            },
          }
        : { type: RESPONSE_QUERY_LETTERS, value: results[0] };
    })
  );

const toSetWizardLetterDetailsEpic$ = (action$) => {
  return action$.pipe(
    ofType(RESPONSE_QUERY_LETTERS),
    pluck('value'),
    map((maybeExistingLetter) => {
      return {
        type: SET_LETTER_DETAILS,
        value: maybeExistingLetter,
      };
    })
  );
};

const toSetWizardModeEpic$ = (action$) =>
  action$.pipe(
    ofType(SET_LETTER_DETAILS),
    pluck('value'),
    map((value) => ({ type: SET_WIZARD_MODE, value: !!value }))
  );

const toGetEditModeCustomLanguagesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_WIZARD_MODE),
    combineWithLatestFrom(
      state$.pipe(pluck('isEditMode')),
      state$.pipe(pluck('selectedLetterTemplate', 'CorrespondenceInfoID'))
    ),
    filter(([, isEditMode]) => isEditMode),
    map(([, , value]) => ({ type: REQUEST_EDIT_MODE_CUSTOM_LANGUAGES, value }))
  );

const toAvailableSteps = (...steps) =>
  Object.values(WIZARD_STEPS).map((ordinality) => steps.includes(ordinality));
const toSetWizardInitialAvailableStepsEpic$ = (action$) =>
  action$.pipe(
    ofType(SET_WIZARD_MODE),
    pluck('value'),
    combineWithLatestFrom(
      action$.pipe(ofType(SET_LETTER_DETAILS), pluck('value'))
    ),
    mergeMap(([isEditMode, letter]) =>
      isEditMode
        ? action$.pipe(
            ofType(RESPONSE_CUSTOM_LANGUAGES),
            pluck('value', 'XeCustomLanguage'),
            map((customLanguages) => [isEditMode, letter, customLanguages])
          )
        : of([isEditMode, letter])
    ),
    map(([isEditMode, letter, customLanguages = []]) => {
      if (!isEditMode)
        return {
          type: SET_AVAILABLE_STEPS,
          value: toAvailableSteps(WIZARD_STEPS.SELECT_LETTER),
        };

      const { BookID = {}, MailCoverBookID = {}, FaxCoverBookID = {} } = letter;

      const editModeConditionalSteps = [
        [
          WIZARD_STEPS.DATA_ENTRY,
          () =>
            !(
              isEmpty(BookID) &&
              isEmpty(MailCoverBookID) &&
              isEmpty(FaxCoverBookID)
            ),
        ],
        [WIZARD_STEPS.CUSTOM_LANGUAGE, () => customLanguages.length > 1],
      ]
        .filter(([, pred]) => pred())
        .map(([step]) => step);
      return {
        type: SET_AVAILABLE_STEPS,
        value: toAvailableSteps(
          WIZARD_STEPS.SELECT_SIGNATURE,
          WIZARD_STEPS.ATTACHMENTS,
          WIZARD_STEPS.RECIPIENTS,
          ...editModeConditionalSteps
        ),
      };
    })
  );

const toSetWizardInitialStepEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_AVAILABLE_STEPS),
    combineWithLatestFrom(state$),
    map(([, state]) => {
      const {
        isEditMode,
        availableSteps,
        selectedLetterTemplate: { CorrespondenceInfoID } = {},
        selectedCustomLanguage,
        faxCoverSheets,
        mailCoverSheets,
      } = state;

      //This logic should drive what the initial page we open in the letter wizard.
      //If we are in a existing letter, we need to default to the first page that fails validation.
      //This is an imperfect solution because at this point we don't have the schema validation,
      //so this is a pared down set of the validation logic. (JAC)

      if (!isEditMode || !CorrespondenceInfoID) {
        return {
          type: WIZARD_FLOW_GO_TO,
          value: WIZARD_STEPS.SELECT_LETTER,
        };
      }

      if (
        availableSteps[WIZARD_STEPS.CUSTOM_LANGUAGE] &&
        isEmpty(selectedCustomLanguage)
      ) {
        return {
          type: WIZARD_FLOW_GO_TO,
          value: WIZARD_STEPS.CUSTOM_LANGUAGE,
        };
      }

      if (
        availableSteps[WIZARD_STEPS.DATA_ENTRY] &&
        !(isEmpty(faxCoverSheets) || isEmpty(mailCoverSheets))
      ) {
        return {
          type: WIZARD_FLOW_GO_TO,
          value: WIZARD_STEPS.DATA_ENTRY,
        };
      }

      return {
        type: WIZARD_FLOW_GO_TO,
        value: WIZARD_STEPS.RECIPIENTS,
      };
    })
  );

const toShowWizardEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_AVAILABLE_STEPS),
    switchMapTo(state$.pipe(ofChangedPropWhenExists('currentStep'))),
    mapTo({ type: SHOW_WIZARD })
  );

/**
 * @type {import('redux-observable').Epic}
 * @description Fetches values for the letter templates list.
 * Requeries data if LOB, SubType, or ProgramBits changes
 */
const toQueryLetterTemplates$ = (action$, state$, { params$, menuNode$ }) => {
  return combineLatest([
    params$.pipe(ofChangedPropWhenExists('IPID'), pluck('ProgramBits')),
    action$.pipe(
      ofType(SET_IS_SEARCH_FOR_ALL_PROGRAMS),
      mapTo(undefined),
      startWith(false)
    ),
    action$.pipe(ofType(SET_SUBTYPE), pluck('value')),
    action$.pipe(ofType(SET_LOB), pluck('value'), startWith(undefined)),
    params$.pipe(pluck('LOB')),
  ])
    .pipe(
      combineWithLatestFrom(
        params$.pipe(pluck('LetterType')),
        params$.pipe(pluck('SubType')),
        params$.pipe(pluck('LOB')),
        params$.pipe(pluck('VisitStatus'), startWith(undefined)),
        params$.pipe(pluck('IsWorkList'), startWith(false)),
        params$.pipe(pluck('VisitCorrespondenceID'), startWith(undefined)),
        params$.pipe(pluck('CorrespondenceCode'), startWith(undefined)),
        menuNode$.pipe(
          pluck('requestFn'),
          map((fn) => fn())
        )
      )
    )
    .pipe(
      mergeMap(
        ([
          [programBits, allPrograms, subType, lineOfBusiness],
          propsLetterType,
          propsSubType,
          propsLob,
          { VisitStatusID: visitStatus } = {},
          isWorkList = 'n',
          VisitCorrespondenceID,
          correspondenceCode,
          toRequest$,
        ]) => {
          return browse(
            {
              programBits: allPrograms ? undefined : programBits,
              letterType: propsLetterType,
              lineOfBusiness: lineOfBusiness || propsLob,
              active: true,
              isSelectable: true,
              isWorkList,
              visitStatus,
              VisitCorrespondenceID,
              subType: subType || propsSubType,
              correspondenceCode,
            },
            toRequest$({ fullRequest: true })
          );
        }
      ),
      pluck('results'),
      map((response) => {
        return {
          type: SET_LETTER_TEMPLATES,
          value: response,
        };
      })
    );
};

/**
 * @type {import('redux-observable').Epic}
 * @description Fetches data for the Cover Sheet dropdown. Calls "browse" with LetterType="COVER"
 */
const toQueryCoverSheetDataEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SET_LETTER_TEMPLATES),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, toRequest$]) => {
      return browse(
        {
          active: true,
          letterType: 'COVER',
        },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((results) => ({
      type: SET_COVER_SHEET_DATA,
      value: results,
    }))
  );
};

const toQueryDefaultSpecEpic$ = (action$, state$, { params$, menuNode$ }) => {
  return action$.pipe(
    ofType(QUERY_DEFAULT_SPEC),
    combineWithLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists(
          'selectedLetterTemplate',
          'CorrespondenceInfoID'
        )
      ),
      state$.pipe(pluck('selectedFaxCover', 'WellKnownID')),
      state$.pipe(pluck('selectedMailCover', 'WellKnownID')),
      params$.pipe(ofChangedPropWhenExists('IPID'), startWith(undefined)),
      params$.pipe(ofChangedPropWhenExists('IVID'), startWith(undefined)),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(
      ([
        ,
        correspondenceInfoId,
        faxCoverWellKnownId,
        mailCoverWellKnownId,
        ipid,
        ivid,
        toRequest$,
      ]) => {
        return getDefaultSpec(
          {
            correspondenceInfoId,
            isIncludeFaxCoverSheet: !!faxCoverWellKnownId,
            isIncludeMailCoverSheet: !!mailCoverWellKnownId,
            faxCoverWellKnownId,
            mailCoverWellKnownId,
            ipid,
            ivid,
          },
          toRequest$({ fullRequest: true })
        );
      }
    ),
    pluck('results'),
    map((results = {}) => {
      return {
        type: RESPONSE_QUERY_DEFAULT_SPEC,
        value: results,
      };
    })
  );
};

const toQueryCustomLanguagesEpic$ = (
  action$,
  state$,
  { params$, menuNode$ }
) => {
  return action$.pipe(
    ofType(REQUEST_EDIT_MODE_CUSTOM_LANGUAGES),
    pluck('value'),
    combineWithLatestFrom(
      params$.pipe(ofChangedPropWhenExists('IPID')),
      params$.pipe(ofChangedPropWhenExists('IVID')),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([correspondenceInfoId, ipid, ivid, toRequest$]) => {
      return getCustomLanguage(
        { correspondenceInfoId, ipid, ivid },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((results) => {
      return {
        type: RESPONSE_CUSTOM_LANGUAGES,
        value: results,
      };
    })
  );
};

/**
 * @type {import('redux-observable').Epic}
 * @description Proceeds to the next step in the wizard depending on the
 * response body from the default spec query.
 */
const toContentSelectionNavEpic$ = (action$) => {
  return action$.pipe(
    ofType(RESPONSE_QUERY_DEFAULT_SPEC),
    pluck('value'),
    map((defaultSpec) => {
      const validationText = pluckFp(
        'XeVisitCorrespondence',
        'ValidationText'
      )(defaultSpec);
      if (validationText) {
        return {
          type: NOTIFICATION,
          value: {
            toMessage: () => validationText,
            style: ERROR_NOTIFICATION,
          },
        };
      }

      const xeCustomLanguage = pluckFp('XeCustomLanguage')(defaultSpec) || [];
      if (xeCustomLanguage.length > 1) {
        return {
          type: WIZARD_FLOW_GO_TO,
          value: WIZARD_STEPS.CUSTOM_LANGUAGE,
        };
      }
      if (toShouldSkipDataEntry(defaultSpec)) {
        return {
          type: WIZARD_FLOW_GO_TO,
          value: WIZARD_STEPS.SELECT_SIGNATURE,
        };
      }
      return {
        type: WIZARD_FLOW_GO_TO,
        value: WIZARD_STEPS.DATA_ENTRY,
      };
    })
  );
};

const markPreviewInError = (state$, params$, menuNode$) => (source$) => {
  return source$.pipe(
    combineWithLatestFrom(
      params$.pipe(ofChangedPropWhenExists('IsWorkList'), startWith(false)),
      params$.pipe(ofChangedPropWhenExists('IPID'), pluck('ProgramBits')),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([value, IsWorkList, ProgramBits = 0, toRequest$]) => {
      return markInError(
        {
          Active: false,
          IsInError: true,
          IsCustomLanguageEdit: false,
          VisitCorrespondenceID: value,
          IsLocked: false,
          IsWorkList: isTrue(IsWorkList),
          ProgramBits,
          ErrorDescription: 'Preview-only version removed',
        },
        {},
        toRequest$({ fullRequest: true })
      );
    })
  );
};

const toMarkPreviewInErrorOnCloseEpic$ = (
  action$,
  state$,
  { params$, menuNode$ }
) => {
  return action$.pipe(
    ofType(MARK_IN_ERROR_BEFORE_CLOSE),
    pluck('value'),
    markPreviewInError(state$, params$, menuNode$),
    pluck('results'),
    mapTo({
      type: SHOULD_CLOSE_WIZARD,
    })
  );
};

const toMarkPreviewInErrorOnLetterSelectEpic$ = (
  action$,
  state$,
  { params$, menuNode$ }
) => {
  return action$.pipe(
    ofType(SET_CURRENT_STEP),
    pluck('value'),
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('previewVisitCorrespondenceId')),
      state$.pipe(ofChangedPropWhenExists('isEditMode'))
    ),
    filter(([currentStep, id, isEditMode]) => {
      return currentStep === WIZARD_STEPS.SELECT_LETTER && !!id && !isEditMode;
    }),
    map(([, id]) => id),
    markPreviewInError(state$, params$, menuNode$),
    pluck('results'),
    map((results) => ({
      type: DID_MARK_IN_ERROR_PREVIEW,
      value: results,
    }))
  );
};

const toMedicalDirectorsEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SET_CURRENT_STEP),
    pluck('value'),
    filter((currentStep) => currentStep === WIZARD_STEPS.SELECT_SIGNATURE),
    combineWithLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists(
          'letterTemplateSpec',
          'XeCorrespondenceInfo',
          'SignatureRoleID'
        )
      ),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, signatureRoleID, toRequest$]) => {
      const rightId = isObjectLike(signatureRoleID)
        ? schemaGet(AssetXeAppRightsSchema, 'RightID', signatureRoleID)
        : signatureRoleID;

      /**
       * @type {{rightId:string, includeSignatureFile:boolean }}
       */
      const options = { rightId, includeSignatureFile: true };

      //Despite this being called `rightId` in the request the field reflects
      //the signatureRolId of string value assigned to the letter.

      return searchAsRightsUser(options, toRequest$({ fullRequest: true }));
    }),
    pluck('results'),
    withLatestFrom(
      action$.pipe(
        ofType(SET_LETTER_DETAILS),
        pluck('value', 'SignerID'),
        filter(exists),
        startWith({})
      )
    ),
    map(([results, initialSignee = {}]) => {
      const shouldNotIncludeInitialSignee =
        isEmpty(initialSignee) ||
        results.find(({ StaffID } = {}) => StaffID === initialSignee.StaffID);

      return {
        type: SET_MEDICAL_DIRECTORS,
        value: shouldNotIncludeInitialSignee
          ? results
          : [initialSignee, ...results],
      };
    })
  );
};

const toDataEntrySmartbookEpic$ = (action$, state$, { params$, menuNode$ }) => {
  return action$.pipe(
    ofType(SET_CURRENT_STEP),
    pluck('value'),
    filter((currentStep) => currentStep === WIZARD_STEPS.DATA_ENTRY),
    combineWithLatestFrom(
      state$.pipe(pluck('initialLetter')),
      state$.pipe(pluck('selectedCustomLanguage')),
      state$.pipe(pluck('hasNoCustomLanguage')),
      params$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    filter(([, initialLetter = EMPTY_OBJECT, , hasNoCustomLanguage]) => {
      const { BookID: initialLetterBookId } = initialLetter;
      // Only continue to merge/delete if there is no BookID (first navigation to DataEntry)
      // and a smartbook is required.
      return !initialLetterBookId && !hasNoCustomLanguage;
    }),
    mergeMap(
      ([
        ,
        ,
        customLanguage = EMPTY_OBJECT,
        ,
        propsParams = EMPTY_OBJECT,
        toRequest$,
      ]) => {
        const { XeSmartBook: { BookID } = EMPTY_OBJECT } = customLanguage;

        if (!BookID) {
          //No BookID with this letter so we need to update state with an empty SmartbookInstance
          return of({ results: EMPTY_ARRAY });
        }
        const { IPID: ipid, IVID: ivid } = propsParams;
        return queryBookAsInstance(
          { bookId: BookID, ipid, ivid },
          toRequest$({ fullRequest: true })
        );
      }
    ),
    pluck('results', '0'),
    map((smartBook) => {
      if (!smartBook) {
        return {
          type: DELETE,
          path: 'initialLetter.BookID.XeSmartBookInstance',
        };
      }
      return {
        type: MERGE_PATCH,
        path: 'initialLetter',
        value: castSchema(VisitCorrespondenceEnterSchema)({
          BookID: {
            XeSmartBookInstance: smartBook,
          },
        }),
      };
    })
  );
};

// (SYNUI-8350) Janky but we need to set the initialLetter to undefined everytime we nav back to the
// customLanguagePage to query a new one if we select a different language (AZ)
const toCustomLanguageEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SET_CURRENT_STEP),
    pluck('value'),
    filter((currentStep) => currentStep === WIZARD_STEPS.CUSTOM_LANGUAGE),
    map(() => ({
      type: SET_INITIAL_LETTER,
    }))
  );
};

/**
 * @function toWizardStepAction
 * @desc Returns a WIZARD_FLOW_GO_TO type action, this specifically doesn't go directly to a page
 * but calls toPageAction flow to validate your selection according to view rules.
 * @param {int} pageIndex
 * @returns {Object} WIZARD_FLOW_GO_TO type action with value of supplied pageIndex
 */
const toWizardStepAction = (pageIndex) => {
  return {
    type: WIZARD_FLOW_GO_TO,
    value: pageIndex,
  };
};

const validateAvailableNextStep = (step, letterProps = {}, options = {}) => {
  const { incrementPage = true } = options;
  const { letterTemplateSpec } = letterProps;
  const signatureRoleID = pluckFp(
    'XeCorrespondenceInfo',
    'SignatureRoleID'
  )(letterTemplateSpec);

  switch (step) {
    case WIZARD_STEPS.SELECT_SIGNATURE:
      if (isNil(signatureRoleID) || isEmptyObject(signatureRoleID)) {
        //no rights to sign letter, skip the signature page (PDD)
        return toWizardStepAction(incrementPage ? step + 1 : step - 1);
      }
      break;
    case WIZARD_STEPS.DATA_ENTRY:
      {
        const {
          selectedFaxCover,
          selectedMailCover,
          selectedCustomLanguage,
          hasNoCustomLanguage,
        } = letterProps;

        const smartbookExists =
          selectedFaxCover ||
          selectedMailCover ||
          (!hasNoCustomLanguage && !!selectedCustomLanguage?.BookID);

        if (!smartbookExists) {
          //no smartbooks, skip the data-entry page (PDD)
          return toWizardStepAction(incrementPage ? step + 1 : step - 1);
        }
      }
      break;
  }
  //SET_CURRENT_STEP action will go directly advance the view directly to the 'step' supplied
  //because we have passed all additional validations.
  return {
    type: SET_CURRENT_STEP,
    value: step,
  };
};

const toPageAction =
  (state$, params$, options = {}) =>
  (source$) => {
    const { incrementPage = true } = options;
    return source$.pipe(
      combineWithLatestFrom(
        state$.pipe(pluck('currentStep')),
        state$.pipe(pluck('availableSteps')),
        state$.pipe(pluck('isEditMode')),
        state$.pipe(pluck('letterTemplateSpec')),
        state$.pipe(pluck('selectedFaxCover', 'Name')),
        state$.pipe(pluck('selectedMailCover', 'Name')),
        state$.pipe(pluck('selectedCustomLanguage', 'XeSmartBook')),
        state$.pipe(pluck('hasNoCustomLanguage'))
      ),
      map(
        ([
          action,
          currentStep,
          availableSteps,
          isEditMode,
          letterTemplateSpec,
          selectedFaxCover,
          selectedMailCover,
          selectedCustomLanguage,
          hasNoCustomLanguage,
        ]) => {
          const { value } = action;

          const letterProps = {
            letterTemplateSpec,
            selectedFaxCover,
            selectedMailCover,
            selectedCustomLanguage,
            hasNoCustomLanguage,
          };

          const nextStep = (() => {
            if (value !== undefined) return value;
            if (incrementPage) {
              return currentStep + 1;
            }
            const availablePreviousStep = availableSteps
              .slice(0, currentStep)
              .lastIndexOf(true);
            if (availablePreviousStep === -1) {
              if (!isEditMode) {
                return WIZARD_STEPS.SELECT_LETTER;
              } else {
                return currentStep;
              }
            }
            return availablePreviousStep;
          })();
          const validatedNextStep = validateAvailableNextStep(
            nextStep,
            letterProps,
            options
          );

          return validatedNextStep;
        }
      )
    );
  };

const toNextPageAction = (state$, params$) =>
  toPageAction(state$, params$, { incrementPage: true });
const toPrevPageAction = (state$, params$) =>
  toPageAction(state$, params$, { incrementPage: false });
const toGoToPageAction = (state$, params$) => toPageAction(state$, params$);

const toWizardFlowHandlerEpic$ = (action$, state$, { params$ }) => {
  return merge(
    action$.pipe(
      ofType(WIZARD_FLOW_PROCEED),
      toNextPageAction(state$, params$)
    ),
    action$.pipe(ofType(WIZARD_FLOW_RETURN), toPrevPageAction(state$, params$)),
    action$.pipe(ofType(WIZARD_FLOW_GO_TO), toGoToPageAction(state$, params$))
  );
};

const saveLetter = (toRequest$) => (source$) => {
  return source$.pipe(
    combineWithLatestFrom(toRequest$),
    mergeMap(
      ([
        { letter = {}, selectedTemplate, selectedCustomLanguage },
        toRequest$,
      ]) => {
        const letterRequestBody = {
          ...letter,
          CorrespondenceInfoID: pluckFp('CorrespondenceInfoID')(
            selectedTemplate
          ),
          CustomLanguageID: pluckFp('CustomLanguageID')(selectedCustomLanguage),
        };

        const transformBookID = (bookIDObj) => {
          if (!bookIDObj) return bookIDObj;
          const { CreateTStamp, BookID, XeSmartBookInstance, ...rest } =
            bookIDObj;

          return Object.assign(
            {},
            XeSmartBookInstance &&
              XeSmartBookInstance.BookID && { XeSmartBookInstance },
            CreateTStamp && { BookID, CreateTStamp },
            rest
          );
        };

        /**
         * @type {import('services/generated/types').VisitCorrespondenceEnter}
         */
        const nextValue = {
          ...pipe(
            transform('BookID')(transformBookID),
            transform('MailCoverBookID')(transformBookID),
            transform('FaxCoverBookID')(transformBookID),
            transform('FaxCoverCustomLanguageID')(pluckFp('CustomLanguageID')),
            transform('MailCoverCustomLanguageID')(pluckFp('CustomLanguageID'))
          )(letterRequestBody),
          WithFullDetailResponse: true,
        };

        return saveAsStatus(
          castSchema(VisitCorrespondenceEnterSchema)(nextValue),
          {},
          toRequest$({ fullRequest: true })
        );
      }
    )
  );
};

export const toSaveLetterEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_SAVE_LETTER),
    pluck('value'),
    saveLetter(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    pluck('results'),
    map((results) => {
      return {
        type: DID_SAVE_LETTER,
        value: results,
      };
    })
  );
};

export const toPreviewLetterEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_PREVIEW_LETTER),
    pluck('value'),
    saveLetter(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    pluck('results'),
    map((results) => {
      return {
        type: DID_QUICKSAVE_LETTER,
        value: results,
      };
    })
  );
};

export const toSaveLetterCompleteEpic$ = (action$) => {
  return action$.pipe(
    ofType(DID_SAVE_LETTER),
    withLatestFrom(action$.pipe(ofType(SET_LETTER_DETAILS))),
    map(([{ value: savedLetter }, { value: previousLetter }]) => {
      return {
        type: LETTER_SAVE_COMPLETE,
        value: { savedLetter, previousLetter },
      };
    })
  );
};

export const toShowPreviewEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(DID_QUICKSAVE_LETTER),
    withLatestFrom(
      action$.pipe(ofType(SHOULD_PREVIEW_LETTER)),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([{ value: letter }, { value }, toRequest$]) => {
      /**
       * @type {import("services/generated/types").VisitCorrespondenceEnter$XeVisitCorrespRecip}
       */
      const recipientToPreview = value.recipientToPreview || {};
      /**
       * @type {import("services/generated/types").VisitCorrespondenceDetail}
       */
      const savedLetter = letter;

      const foundRecip = savedLetter?.XeVisitCorrespRecip?.find((item) => {
        return (
          item.SendMethod === recipientToPreview.SendMethod &&
          item.Name === recipientToPreview.Name &&
          item.AddressLine1 === recipientToPreview.AddressLine1 &&
          (item.ResourceID?.ResourceTypeID === 'FACILITY'
            ? item.ProviderTypeID === recipientToPreview.ProviderTypeID
            : true)
        );
      });

      const visitCorrespondenceId =
        foundRecip?.VisitCorrespondenceID || savedLetter?.VisitCorrespondenceID;

      /**
      /**
       * @type {{visitCorrespondenceId?:number, visitCorrespRecipId?:number }}
         */
      const params = {
        visitCorrespondenceId,
        visitCorrespRecipId: foundRecip?.VisitCorrespRecipID,
      };

      /**
       * @TODO This likely should not make a request if one or both of the params are undefined,
       * yet they are at least possibly undefined based on the schemas & derived types. Confirm expected behavior here
       * (or improve typing if in "real world" use cases these should never be undefined) (CJP) */

      return getDraft(params, toRequest$({ fullRequest: true }));
    }),
    pluck('results', 'FileID'),
    map((FileID) => ({
      type: SHOULD_GET_PDF,
      value: FileID,
    }))
  );
};

export const toSetPrePopupEpic$ = (action$, state$, { params$ }) => {
  return params$.pipe(
    pluck('PrePopup'),
    map((prePopup) => {
      return {
        type: SET_PRE_POPUP,
        value: prePopup,
      };
    })
  );
};

export const toSetSubTypeEpic$ = (action$, state$, { params$ }) => {
  return params$.pipe(
    map((value = {}) => {
      const { LetterType, SubType } = value;
      return {
        type: SET_SUBTYPE,
        value: toSubType(SubType, LetterType),
      };
    })
  );
};

const isWellKnownIdEqualTo = (wkid) =>
  pipe(pluckFp('WellKnownID'), isStrictlyEqualTo(wkid));

/*
 * NOTE: The way that this has to be done initially feels quite a bit cumbersome and verbose
 * However, without reworking a lot of the flow of this thing, it gets us going for SYNUI-6249.
 * The general idea here is that, if an LOB is provider and matches one of the letter templates,
 * we should automatically select that template and skip the first page (JDM)
 */
export const toSetLetterTemplateFromLOBEpic$ = (
  _action$,
  state$,
  { params$ }
) => {
  return params$.pipe(
    ofChangedPropWhenExists('LOB'),
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('letterTemplates'))
    ),
    map(([lob, templates = []]) => {
      const matchingTemplates = templates.filter((template) => {
        /**
         * @type {import('services/generated/types').CorrespondenceInfoResponse}
         */
        const { LineOfBusiness } = template;
        return LineOfBusiness === lob;
      });

      // See SYNUI-6408 (JDM)
      if (matchingTemplates.length === 1) {
        return matchingTemplates[0];
      }

      return undefined;
    }),
    filter(identity),
    map((value) => {
      return {
        type: AUTO_SELECT_LETTER_TEMPLATE,
        value,
      };
    })
  );
};

/**
 * Returns an epic that automatically dispatches an action to request the default spec
 * if we are a new letter.
 *
 * (Existing letters should never see this dispatch)
 */
export const toAutoRequestDefaultSpecEpic$ = (
  action$,
  _state$,
  { params$ }
) => {
  const isEditMode$ = action$.pipe(ofType(SET_WIZARD_MODE), pluck('value'));
  const lob$ = params$.pipe(ofChangedPropWhenExists('LOB'));
  const toAutoRequestAction = () => (source$) => {
    return source$.pipe(
      combineWithLatestFrom(
        lob$,
        action$.pipe(ofType(AUTO_SELECT_LETTER_TEMPLATE)),
        action$.pipe(ofType(SET_SELECTED_COVER_SHEETS))
      ),
      take(1),
      map(() => {
        return {
          type: QUERY_DEFAULT_SPEC,
        };
      })
    );
  };

  return isEditMode$.pipe(filter(not(identity)), toAutoRequestAction());
};

export const toSetCoverSheetsOnLetterTemplateChangeEpic$ = (
  action$,
  state$,
  { params$ }
) => {
  return merge(
    action$.pipe(ofType(SET_SELECTED_LETTER_TEMPLATE), pluck('value')),
    params$.pipe(
      ofChangedPropWhenExists('LOB'),
      combineWithLatestFrom(
        state$.pipe(ofChangedPropWhenExists('letterTemplates'))
      ),
      map(([lob, templates = []]) => {
        return templates.find((template) => {
          /**
           * @type {import('services/generated/types').CorrespondenceInfoResponse}
           */
          const { LineOfBusiness } = template;
          return LineOfBusiness === lob;
        });
      }),
      filter(identity)
    )
  ).pipe(
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('faxCoverSheets')),
      state$.pipe(ofChangedPropWhenExists('mailCoverSheets'))
    ),
    map(
      ([
        { DefaultFaxCoverWKID, DefaultMailCoverWKID },
        faxCoverSheets = [],
        mailCoverSheets = [],
      ]) => {
        return {
          type: SET_SELECTED_COVER_SHEETS,
          value: {
            selectedFaxCover: faxCoverSheets.find(
              isWellKnownIdEqualTo(DefaultFaxCoverWKID)
            ),
            selectedMailCover: mailCoverSheets.find(
              isWellKnownIdEqualTo(DefaultMailCoverWKID)
            ),
          },
        };
      }
    )
  );
};

/**
 * @type {import('redux-observable').Epic}
 */
const toFetchPDFEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_GET_PDF),
    pluck('value'),
    filter(identity),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([fileId, toRequest$]) =>
      getFile({ fileId }, toRequest$()).pipe(
        map((response) => URL.createObjectURL(response)),
        map((blob) => ({
          type: DISPLAY_LETTER_PREVIEW,
          value: blob,
        }))
      )
    ),
    //I don't see how we could ever get here
    catchError(() =>
      of({
        type: DISPLAY_ERROR_TOAST,
      })
    )
  );
};

/**
 * @type {import('redux-observable').Epic}
 */
export const toFetchPDFError$ = (actions$) =>
  actions$.pipe(
    ofType(DISPLAY_ERROR_TOAST),
    map(() => ({
      type: NOTIFICATION,
      value: {
        toMessage: () => 'Error loading selected file',
        timeout: 5000,
        style: ERROR_NOTIFICATION,
      },
    }))
  );

const resetAvailableStepsEpic$ = (action$) =>
  action$.pipe(
    ofType(SET_SELECTED_LETTER_TEMPLATE),
    map(() => ({
      type: SET_AVAILABLE_STEPS,
      value: toAvailableSteps(WIZARD_STEPS.SELECT_LETTER),
    }))
  );

export default [
  toSetLetterTemplateFromLOBEpic$,
  toAutoRequestDefaultSpecEpic$,
  toQueryExistingLettersEpic$,
  toSetWizardLetterDetailsEpic$,
  toSetWizardModeEpic$,
  toSetWizardInitialStepEpic$,
  toGetEditModeCustomLanguagesEpic$,
  toSetWizardInitialAvailableStepsEpic$,
  toShowWizardEpic$,
  toSetPrePopupEpic$,
  toQueryCoverSheetDataEpic$,
  toQueryLetterTemplates$,
  toQueryDefaultSpecEpic$,
  toContentSelectionNavEpic$,
  toMedicalDirectorsEpic$,
  toWizardFlowHandlerEpic$,
  toDataEntrySmartbookEpic$,
  toQueryCustomLanguagesEpic$,
  toSaveLetterEpic$,
  toSaveLetterCompleteEpic$,
  toSetSubTypeEpic$,
  toGetClinDocTypesEpic$,
  toGetClinDocsEpic$,
  toGetClinReportDataEpic$,
  toGetReportsEpic$,
  toDownloadReportEpic$,
  toSetCoverSheetsOnLetterTemplateChangeEpic$,
  toGetAttachmentsEpic$,
  toDownloadAttachmentEpic$,
  toPreviewLetterEpic$,
  toMarkPreviewInErrorOnLetterSelectEpic$,
  toMarkPreviewInErrorOnCloseEpic$,
  toShowPreviewEpic$,
  toFetchPDFEpic$,
  toFetchPDFError$,
  toCustomLanguageEpic$,
  resetAvailableStepsEpic$,
];
