import { pluck } from '../../fp/object';
import {
  Checkbox,
  DropDownList,
  TelephoneInput,
  TextInput,
  GridLayout,
  UIControlLabel,
} from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { ZipCodeInput } from '../ZipCodeInput';
import { EMPTY_OBJECT } from '../../constants';

const MAIL = 'MAIL';
const FAX = 'FAX';

export const RecipientForm = ({ recipient = EMPTY_OBJECT }) => {
  const { SendMethod } = recipient;
  const labels = useXeLabels();

  const {
    OutreachMethod,
    OutreachMethodMail,
    XeStateProvince,
    XeLanguage,
    XeCountry,
  } = useXeRefData();

  return (
    <GridLayout templateColumns="repeat(2, auto 1fr)">
      <DropDownList
        dataPath="SendMethod"
        dataElementName="recipientForm__sendMethod"
        data={OutreachMethod}
        descriptor={labels.Method}
        valueFn={pluck('id')}
        labelFn={pluck('text')}
        required
      />
      {SendMethod === MAIL ? (
        <DropDownList
          dataElementName="recipientForm__detail"
          descriptor={labels.Detail}
          dataPath="SendMethodDetail"
          data={OutreachMethodMail}
          valueFn={pluck('id')}
          labelFn={pluck('text')}
          required
        />
      ) : (
        <>
          <div />
          <div />
        </>
      )}
      <TextInput
        dataElementName="recipientForm__name"
        dataPath="Name"
        descriptor={labels.Name}
        required
      />
      <UIControlLabel dataElementName="recipientForm__provider__label">
        {labels.Provider}
      </UIControlLabel>
      <Checkbox
        dataElementName="recipientForm__provider"
        dataPath="IsProvider"
        wrapperClassName="align-self-center"
      />
      <DropDownList
        dataElementName="recipientForm__language"
        descriptor={labels.Language}
        dataPath="LanguageID"
        data={XeLanguage}
        valueFn={pluck('id')}
        labelFn={pluck('text')}
        comparator={(listItem, value) => {
          const id = value?.LanguageID || value;
          return listItem === id;
        }}
      />
      <TelephoneInput
        dataElementName="recipientForm__fax"
        dataPath="Fax"
        descriptor={labels.Fax}
        required={SendMethod === FAX}
      />
      <TextInput
        dataElementName="recipientForm__addressLine1"
        dataPath="AddressLine1"
        descriptor={labels.LineOne}
        required={SendMethod === MAIL}
      />
      <TextInput
        dataElementName="recipientForm__addressLine2"
        dataPath="AddressLine2"
        descriptor={labels.LineTwo}
      />
      <TextInput
        dataElementName="recipientForm__city"
        dataPath="City"
        descriptor={labels.City}
        required={SendMethod === MAIL}
      />
      <DropDownList
        dataElementName="recipientForm__state"
        dataPath="StateProvince"
        data={XeStateProvince}
        descriptor={labels.State}
        valueFn={pluck('id')}
        labelFn={pluck('text')}
        required={SendMethod === MAIL}
      />
      <DropDownList
        dataElementName="recipientForm__country"
        descriptor={labels.Country}
        dataPath="CountryID"
        data={XeCountry}
        valueFn={pluck('id')}
        labelFn={pluck('text')}
      />
      <ZipCodeInput
        dataElementName="recipientForm__zip"
        dataPath="ZipPostalCode"
        descriptor={labels.Zip}
        required={SendMethod === MAIL}
      />
    </GridLayout>
  );
};

export default RecipientForm;
