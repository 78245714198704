import { castFunction } from '../../fp/fp';
import { useEffect } from 'react';
import './styles.css';

export const Spinner = (props) => {
  const { onShow, onHide } = props;

  useEffect(() => {
    castFunction(onShow)();

    return () => {
      castFunction(onHide)();
    };
  }, [onShow, onHide]);

  return (
    <div data-component-name="Spinner" className="spinner" key="spinner">
      <div className="spinner__dial" key="spinner__dial" />
    </div>
  );
};

export default Spinner;
