import { Children } from 'react';
import { useSchema } from '../../../../schema/SchemaReducer';
import { Flexbox, TelephoneInput } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { baseNodeValidator } from '../../validators';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';
import { isValidPhoneNumber } from '../../../../validators/inputPreds';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Phone = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    style,
    readOnly,
    node,
    children,
    onValidated,
    dataPath = primarySchemaKeys.Phone,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsRequired, IsVisible } = node;

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <>
      <WrappingComponent>
        <TelephoneInput
          dataElementName={Name}
          descriptor={Name}
          descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
            invalid,
          })}
          required={IsRequired}
          style={style}
          disabled={readOnly}
          value={value}
          onChange={(value /* , isValid */) => {
            onValueChange(value);
            const isValid = !!value && isValidPhoneNumber(value);
            onValidChange(baseNodeValidator(isValid, value, IsRequired));
          }}
        />
      </WrappingComponent>
      {Children.count(children) > 0 ? <div>{children}</div> : null}
    </>
  );
};

export default Phone;
