import PropTypes from 'prop-types';
import './styles.css';
import withClassNameModifiers from '../../utils/withClassNameModifiers';

const toTableComponent = (componentClass) => (props) => {
  const { children, className = '', ...viewProps } = props;
  return (
    <div
      data-component-name={componentClass}
      className={`${componentClass} ${className}`}
      {...viewProps}
    >
      {children}
    </div>
  );
};

const toTableClass = (propsClass, inline) => {
  return `${withClassNameModifiers('table', { inline })} ${propsClass}`;
};

const Layout = (props) => {
  const { children, inline = false, className = '', ...viewProps } = props;

  return (
    <div
      className={toTableClass(className, inline)}
      {...viewProps}
      data-component-name="TableLayout"
    >
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

const HeaderGroup = toTableComponent('table-head');
const HeaderCell = toTableComponent('table-header');
const Body = toTableComponent('table-body');
const Row = toTableComponent('table-row');
const Cell = toTableComponent('table-data');

export const Table = {
  Layout,
  HeaderGroup,
  HeaderCell,
  Body,
  Row,
  Cell,
};

export default Table;
