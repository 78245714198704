import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { identity } from '../../fp/fp';
import { List } from '../List';
import { UIControlLabel } from '../Label';
import { NOOP_FUNCTION, TRUE_FUNCTION } from '../../constants';

const toUniquePrivateKey = (index) => `.${index}`;

const defaultComparator = (a, b) => a === b;

export const TogglableList = (props) => {
  const labels = useXeLabels();

  const {
    valueFn = identity,
    renderItem,
    keyFn = NOOP_FUNCTION,
    comparator = defaultComparator,
    data,
    dataElementName,
    value,
    onChange = NOOP_FUNCTION,
    NoItemsElement,
    className = '',
    descriptor,
    descriptorClassName = '',
    required = false,
    showItemPred = TRUE_FUNCTION,
  } = props;

  const valueIndex = data.findIndex((item) => comparator(valueFn(item), value));
  const selectedItemKey = valueIndex >= 0 ? toUniquePrivateKey(valueIndex) : '';

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName
              ? `${dataElementName}__togglableList__label`
              : 'togglableList__label'
          }
          required={required}
          className={descriptorClassName}
          descriptorPosition="top"
        >
          {descriptor}
        </UIControlLabel>
      )}
      <List
        dataElementName={
          dataElementName
            ? `${dataElementName}__togglableList`
            : 'togglableList'
        }
        component={PanelBar}
        className={className}
        data={data}
        onSelect={(event) => {
          const {
            target: {
              props: { item, index },
            },
          } = event;
          onChange({ item: valueFn(item), index });
        }}
        selected={selectedItemKey}
        NoItemsElement={
          <PanelBarItem title={NoItemsElement || labels.NoResults} disabled />
        }
        renderItem={({ item, index }) => {
          if (!showItemPred(item)) return null;
          return (
            <PanelBarItem
              title={renderItem({ item, index })}
              item={item}
              index={index}
              key={keyFn(item, index) || index}
            />
          );
        }}
      />
    </>
  );
};

TogglableList.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  valueFn: PropTypes.func,
  keyFn: PropTypes.func,
  onChange: PropTypes.func,
  comparator: PropTypes.func,
  value: PropTypes.any,
  NoItemsElement: PropTypes.node,
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  dataElementName: PropTypes.string,
};
