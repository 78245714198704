import { useSchemaSelector } from '../../../schema/SchemaReducer';
import {
  default as CustomLanguageSmartBookSchema,
  default as FaxCoverSmartBookSchema,
  default as MailCoverSmartBookSchema,
  default as VCSmartBookInstanceSchema,
} from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter$XeSmartBookInstance.json';
import { HeaderLabel } from '../../';
import { SmartBook } from '../../SmartBook';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import '../styles.css';

export const DataEntry = () => {
  const labels = useXeLabels();

  const [hasLanguageSmartBook, hasFaxSmartBook, hasMailSmartBook] =
    useSchemaSelector((instance = {}) => {
      /**
       * @type {import('services/generated/types').VisitCorrespondenceEnter}
       */
      const {
        BookID: {
          XeSmartBookInstance: {
            XeSmartBookInstance: languageSmartBook = [],
          } = {},
        } = {},
        FaxCoverBookID: {
          XeSmartBookInstance: { XeSmartBookInstance: faxSmartBook = [] } = {},
        } = {},
        MailCoverBookID: {
          XeSmartBookInstance: { XeSmartBookInstance: mailSmartBook = [] } = {},
        } = {},
      } = instance;

      return [
        !!languageSmartBook.length,
        !!faxSmartBook.length,
        !!mailSmartBook.length,
      ];
    });

  return (
    <div className="data-entry flex-1 overflow-auto">
      {hasLanguageSmartBook && (
        <>
          <HeaderLabel dataElementName="dataEntry__correspondenceText__header">
            {labels.CorrespondenceText}
          </HeaderLabel>
          <div className="margin-left-large">
            <SmartBook
              dataElementName="dataEntry__correspondenceText__smartBook"
              validityStyles
              SmartBookSchema={CustomLanguageSmartBookSchema}
              SmartBookInstanceSchema={VCSmartBookInstanceSchema}
              dataPath="BookID.XeSmartBookInstance"
            />
          </div>
        </>
      )}
      {hasFaxSmartBook && (
        <>
          <HeaderLabel dataElementName="dataEntry__faxCoverText__header">
            {labels.FaxCoverText}
          </HeaderLabel>
          <div className="margin-left-large">
            <SmartBook
              dataElementName="dataEntry__faxCoverText__smartBook"
              validityStyles
              SmartBookSchema={FaxCoverSmartBookSchema}
              SmartBookInstanceSchema={VCSmartBookInstanceSchema}
              dataPath="FaxCoverBookID.XeSmartBookInstance"
            />
          </div>
        </>
      )}
      {hasMailSmartBook && (
        <>
          <HeaderLabel dataElementName="dataEntry__mailCoverText__header">
            {labels.MailCoverText}
          </HeaderLabel>
          <div className="margin-left-large">
            <SmartBook
              dataElementName="dataEntry__mailCoverText__smartBook"
              validityStyles
              SmartBookSchema={MailCoverSmartBookSchema}
              SmartBookInstanceSchema={VCSmartBookInstanceSchema}
              dataPath="MailCoverBookID.XeSmartBookInstance"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DataEntry;
