import { useState } from 'react';
import { ExpandableContainer } from '../../ExpandableContainer';
import { SelectableHeader } from './SelectableHeader';

export const ExpandableItemList = (props) => {
  const {
    dataElementName,
    descriptor,
    children,
    headerIsChecked,
    headerIsIndeterminate,
    onSelect,
    multiple,
    checkableSections,
    onNodeExpanded,
  } = props;

  const [isExpanded, setIsExpanded] = useState(
    headerIsChecked || headerIsIndeterminate
  );

  return (
    <ExpandableContainer
      Header={() => (
        <SelectableHeader
          dataElementName={dataElementName}
          descriptor={descriptor}
          onClick={(ev) => {
            onNodeExpanded(ev);
            setIsExpanded(!isExpanded);
          }}
          onSelect={(checked) => {
            if (checked) {
              setIsExpanded(true);
            }
            onSelect(checked);
          }}
          isExpanded={isExpanded}
          checked={headerIsChecked}
          indeterminate={headerIsIndeterminate}
          multiple={multiple}
          checkableSections={checkableSections}
        />
      )}
      className="tree-view-list__expandable-container"
      bodyClassName="tree-view-list__expandable-container-body"
      headerClassName="tree-view-list__expandable-container-header"
      isExpanded={isExpanded}
      preserveChildrenOnCollapse
    >
      {children}
    </ExpandableContainer>
  );
};

export default ExpandableItemList;
