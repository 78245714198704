import { useState, Children } from 'react';
import { castFunction } from '../../fp/fp';
import { Flexbox } from '../../components';
import { useXeLabels } from '../../contexts/XeLabelContext';
import {
  ADD,
  CANNOT_CLEAR,
  CLEAR_FILTERS,
  COLLAPSE_COMPONENT,
  EXPAND_COMPONENT,
  EXPORT_DATA,
  SEARCH,
} from '../../icons';
import { GridLayout } from '../GridLayout';
import IconButton from '../IconButton';
import './styles.css';

const toTruthyChildrenArray = (children) =>
  Children.toArray(children).filter((x) => x);

/**
 * @type {{ [type: string]: HeaderAction }}
 */

const VIEW_HEADER_GAP_SIZE = 15;

const BASE_STANDARD_ACTIONS = {
  search: {
    type: 'search',
    name: 'search',
    icon: SEARCH,
    tooltip: (labels) => labels.Search,
  },
  clear: {
    type: 'clear',
    name: 'clear',
    icon: CLEAR_FILTERS,
    disabledIcon: CANNOT_CLEAR,
    tooltip: (labels) => labels.Clear,
  },
  export: {
    type: 'export',
    name: 'export',
    icon: EXPORT_DATA,
    tooltip: (labels) => labels.Export,
  },
  add: {
    type: 'add',
    name: 'add',
    icon: ADD,
    tooltip: (labels) => labels.Add,
  },
};

/**
 * @typedef HeaderAction
 * @property {string=} type Type of the action
 * @property {string} icon MDI icon to display
 * @property {string=} disabledIcon MDI icon to display when disabled
 * @property {string | ((labels: Object) => string)=} tooltip Tooltip to show on hover
 * @property {boolean=} disabled
 * @property {boolean=} hidden
 */

/**
 * @typedef {'search'|'clear'|'export'|'add'} ActionType
 */

/**
 * @typedef ActionsObject
 * @property {HeaderAction} search
 * @property {HeaderAction} clear
 * @property {HeaderAction} export
 * @property {HeaderAction} add
 */

/**
 * @typedef ViewHeaderProps
 * @property {{ [type: string]: HeaderAction } & ActionsObject} actions
 * @property {boolean} condensed
 * @property {(x: { type: ActionType, nativeEvent: Event }) => void} onAction
 */

/**
 * Standard header for any view in the application. There are four standard actions, and these
 * standard actions have predefined values for `icon`, `disabledIcon`, `tooltip`, and `disabled`:
 *
 * "search", "clear", "export", and "add".
 *
 * Additional actions supplied via the `actions` param will show in between "export" and "add",
 * with "add" always being the last action. Each action object has an optional `type` property.
 * If one isn't added, the object's key will be used as the `type`:
 *
 * @example
 * ```js
 * ...
 * // The result of:
 * actions={{ search: {} }}
 * // is equivalent to
 * actions={{ search: { type: 'search' } }}
 * ...
 * ```
 *
 * Use `type` if you want to customize the action type that is provided in the `onAction` callback.
 *
 * *Exampe usage*
 *
 * @example
 * ```js
 * ...
 * <ViewHeader
 *   actions={{
 *     // Built-in actions (most common)
 *     search: {},
 *     clear: { disabled: foo === bar },
 *     export: { disabled: !data.length },
 *     add: { type: 'baz' },
 *     // Custom
 *     mail: {
 *       icon: MAIL,
 *       disabledIcon: MAIL_OUTLINE,
 *       tooltip: labels => labels.Message,
 *       disabled: noRecipients
 *     }
 *   }}
 *   onAction={({ type, nativeEvent }) => {
 *     if (type === 'search') doSearch();
 *     if (type === 'mail') sendMessage();
 *     // etc.
 *   }}
 *   {...}
 * />
 * ...
 * ```
 *
 * When children are provided to the view, they are bundled into a collapsible container
 * when `condensed={true}`. Otherwise, they are rendered inline as siblings to the action buttons.
 *
 * @param {HTMLDivElement & ViewHeaderProps} props
 */
export const ViewHeader = (props) => {
  const {
    condensed,
    onAction,
    actions,
    className,
    children,
    headerClassName = '',
    dataElementName = '',
  } = props;

  /**
   * @type {ActionsObject}
   * Augmented `actions` object with a `type` property for each value
   */
  const actionsWithType = Object.entries(actions).reduce(
    (actionsWithTypeKey, [type, data]) => {
      if ('type' in data) {
        return { ...actionsWithTypeKey, data };
      }
      return {
        ...actionsWithTypeKey,
        [type]: {
          type,
          ...data,
        },
      };
    },
    {}
  );

  const {
    add: addAction,
    clear: clearAction,
    export: exportAction,
    search: searchAction,
    ...customActions
  } = actionsWithType;

  /**
   * @type {HeaderAction[]}
   */
  const orderedActions = [
    searchAction,
    clearAction,
    exportAction,
    ...Object.values(customActions),
    addAction,
  ];

  const baseClassName = 'xjs-view-header';

  const labels = useXeLabels();
  const [isExpanded, setIsExpanded] = useState(false);

  const filterDropdownIcon = isExpanded ? COLLAPSE_COMPONENT : EXPAND_COMPONENT;
  const filterDescription = isExpanded ? labels.Collapse : labels.Expand;

  const childrenDisplayStyle =
    condensed && isExpanded ? {} : { display: 'none' };

  const FlexboxChildrenElement = (
    <Flexbox
      wrap
      alignItems="center"
      className="xjs-view-header__flexbox-children-element"
      gap={VIEW_HEADER_GAP_SIZE}
    >
      {children}
    </Flexbox>
  );

  const CollapsibleHeaderElement = toTruthyChildrenArray(children).length ? (
    <GridLayout templateColumns="max-content">
      <IconButton
        dataElementName={
          !!dataElementName ? `${dataElementName}__expand` : 'expand'
        }
        style={{ justifySelf: 'start' }}
        look="flat"
        description={filterDescription}
        icon={filterDropdownIcon}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </GridLayout>
  ) : (
    <div />
  );

  return (
    <Flexbox
      dataElementName={dataElementName}
      direction="column"
      className={className ? `${className} ${baseClassName}` : baseClassName}
      data-component-name="ViewHeader"
    >
      <GridLayout
        className={`padding-all-small ${headerClassName}`}
        templateColumns="1fr auto"
      >
        {condensed ? CollapsibleHeaderElement : FlexboxChildrenElement}
        <Flexbox
          alignItems="center"
          className={
            condensed ? 'align-self-end' : 'align-self-end margin-bottom-medium'
          }
        >
          {orderedActions.map((action) => {
            if (!action) return null;

            if (action.hidden) return null;

            const { type } = action;
            const baseAction = BASE_STANDARD_ACTIONS[type] || {};
            const {
              icon,
              name: buttonName,
              tooltip,
              disabled,
              disabledIcon = icon,
              disabledDescription = '',
            } = {
              ...baseAction,
              ...action,
            };

            const _icon = disabled ? disabledIcon : icon;
            return (
              <IconButton
                dataElementName={
                  !!dataElementName
                    ? `${dataElementName}__${buttonName}`
                    : buttonName
                }
                key={type}
                description={
                  typeof tooltip === 'function' ? tooltip(labels) : tooltip
                }
                className="action-bar__button"
                onClick={(ev) =>
                  castFunction(onAction)({ type, nativeEvent: ev })
                }
                icon={_icon}
                disabled={disabled}
                disabledDescription={disabledDescription}
              />
            );
          })}
        </Flexbox>
      </GridLayout>
      {/* Intentional inline style to prevent overriding (JDM) */}
      {condensed ? (
        <div style={{ gridColumn: '1 / -1', ...childrenDisplayStyle }}>
          {FlexboxChildrenElement}
        </div>
      ) : null}
    </Flexbox>
  );
};
