export const SHOULD_SAVE_LETTER = 'letterWizard/save';
export const SHOULD_PREVIEW_LETTER = 'letterWizard/previewLetter';
export const SHOULD_CLOSE_WIZARD = 'letterWizard/close';
export const SET_PRE_POPUP = 'letterWizard/setPrePopup';
export const WIZARD_FLOW_PROCEED = 'letterWizard/proceedToNextPage';
export const WIZARD_FLOW_RETURN = 'letterWizard/returnToPreviousPage';
export const WIZARD_FLOW_GO_TO = 'letterWizard/goToPage';

export const SHOW_WIZARD = 'letterWizard/showWizard';

export const SET_AVAILABLE_STEPS = 'letterWizard/setAvailableSteps';
export const SET_CURRENT_STEP = 'letterWizard/setCurrentStep';
export const SET_LETTER_DETAILS = 'letterWizard/setLetterDetails';
export const SET_COVER_SHEET_DATA = 'letterWizard/setCoverSheetData';
export const SET_LETTER_TEMPLATES = 'letterWizard/setLetterTemplates';
export const SET_MEDICAL_DIRECTORS = 'letterWizard/setMedicalDirectors';
export const SET_IS_SEARCH_FOR_ALL_PROGRAMS =
  'letterWizard/setIsSearchForAllPrograms';
export const SET_LOB = 'letterWizard/setLineOfBusiness';
export const SET_SUBTYPE = 'letterWizard/setSubType';
export const SET_SELECTED_COVER_SHEETS = 'letterWizard/setSelectedCoverSheets';
export const SET_SELECTED_MAIL_COVER = 'letterWizard/setSelectedMailCover';
export const SET_SELECTED_FAX_COVER = 'letterWizard/setSelectedFaxCover';
export const AUTO_SELECT_LETTER_TEMPLATE =
  'letterWizard/autoSelectLetterTemplate';
export const SET_SELECTED_LETTER_TEMPLATE =
  'letterWizard/setSelectedLetterTemplate';
export const SET_WIZARD_MODE = 'letterWizard/setWizardMode';

export const REQUEST_EDIT_MODE_CUSTOM_LANGUAGES =
  'request/EditModeCustomLanguages';
export const RESPONSE_CUSTOM_LANGUAGES = 'response/CustomLanguages';

export const RESPONSE_QUERY_LETTERS = 'response/queryLetters';

export const QUERY_DEFAULT_SPEC = 'letterWizard/queryDefaultSpec';

export const SET_SELECTED_CUSTOM_LANGUAGE =
  'letterWizard/setSelectedCustomLanguage';
export const UPDATE_SELECTED_SIGNEE = 'updateSelectedSignee';

export const DID_GET_CLINDOC_REF_DATA = 'letterWizard/didGetClindocRefData';
export const SHOULD_GET_CLINDOCS = 'letterWizard/shouldGetClindocs';
export const DID_GET_CLINDOCS = 'letterWizard/didGetClindocs';
export const SHOULD_DOWNLOAD_CLINDOC =
  'createLetterWizard/shouldDownloadClinDoc';
export const DID_DOWNLOAD_CLINDOC = 'createLetterWizard/didDownloadClinDoc';

export const DID_GET_REPORTS = 'letterWizard/didGetReports';
export const SHOULD_DOWNLOAD_REPORT = 'letterWizard/downloadReport';
export const DID_DOWNLOAD_REPORT = 'letterWizard/didDownloadReport';

export const DID_GET_ATTACHMENTS = 'letterWizard/didGetAttachments';

export const SHOULD_DOWNLOAD_ATTACHMENT =
  'letterWizard/shouldDownloadAttachment';
export const DID_DOWNLOAD_ATTACHMENT = 'letterWizard/didDownloadAttachment';
export const UPDATE_ATTACHMENTS_VIEW_STATE =
  'letterWizard/updateAttachmentsViewState';

export const REQUEST_ATTACHMENTS_POPUP_DATA =
  'letterWizard/requestAttachmentsPopupData';
// Responses
export const RESPONSE_QUERY_DEFAULT_SPEC = 'letterWizard/didQueryDefaultSpec';
export const DID_SAVE_LETTER = 'letterWizard/didSaveLetter';
export const DID_QUICKSAVE_LETTER = 'letterWizard/didQuickSaveLetter';
export const LETTER_SAVE_COMPLETE = 'letterWizard/letterSaveComplete';
export const RESPONSE_QUERY_BOOK_AS_INSTANCE =
  'letterWizard/didQueryBookAsInstance';
export const DISPLAY_LETTER_PREVIEW = 'letterWizard/displayLetterPreview';
export const HIDE_LETTER_PREVIEW = 'letterWizard/hideLetterPreview';
export const DID_MARK_IN_ERROR_PREVIEW = 'letterWizard/didMarkInErrorPreview';
export const MARK_IN_ERROR_BEFORE_CLOSE = 'letterWizard/markInErrorBeforeClose';
export const SHOULD_GET_PDF = 'letterWizard/shouldGetPDF';
export const DISPLAY_ERROR_TOAST = 'letterWizard/displayErrorToast';
export const SET_INITIAL_LETTER = 'letterWizard/setInitialLetter';
