import { isFunction } from '../../fp/pred';
import { Flexbox } from '../../components/Flexbox';
import { IconButton } from '../../components/IconButton';
import PropTypes from 'prop-types';
import {
  ADD,
  CANNOT_CLEAR,
  CLEAR_FILTERS,
  EXPORT_DATA,
  SEARCH,
  EDIT,
  MAIL,
  EXPAND_COMPONENT,
  COLLAPSE_COMPONENT,
  PRINT,
} from '../../icons';
import { withoutPropagation } from '../../utils';
import './styles.css';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useSelector } from 'react-redux';

export const ToolBar = (props) => {
  const {
    isClearDisabled = true,
    isSearchDisabled = false,
    isEditDisabled = false,
    isEmailDisabled = false,
    onExpand,
    isExpanded = false,
    onSearch,
    onExport,
    disableExport,
    onPrint,
    onClear,
    onAdd,
    onEdit,
    onEmail,
    className = '',
    onAddDescription = '',
    dataElementName = '',
  } = props;
  const isMinimized = useSelector(
    (state) =>
      state['EMPIRX/XeApplication_11000/_-11000/XeAbout_5']
        ?.isAnyWindowMinimized?.isMinimized
  );

  const labels = useXeLabels();
  return (
    <Flexbox
      alignItems="flex-end"
      className={`action-bar flex-shrink-0 ${className}`}
    >
      {isFunction(onExpand) && (
        <IconButton
          dataElementName={
            dataElementName !== ''
              ? `${dataElementName}__expandHeader`
              : 'expandHeader'
          }
          description={isExpanded ? labels.Collapse : labels.Expand}
          icon={isExpanded ? COLLAPSE_COMPONENT : EXPAND_COMPONENT}
          className="action-bar__button"
          onClick={withoutPropagation(() => onExpand(!isExpanded))}
        />
      )}
      {isFunction(onSearch) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__search` : 'search'
          }
          description={labels.Search}
          className="action-bar__button"
          onClick={withoutPropagation(onSearch)}
          icon={SEARCH}
          disabled={isSearchDisabled}
        />
      )}
      {isFunction(onClear) && (
        <IconButton
          icon={isClearDisabled ? CANNOT_CLEAR : CLEAR_FILTERS}
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__clear` : 'clear'
          }
          disabled={isClearDisabled}
          description={labels.Clear}
          className="action-bar__button"
          onClick={withoutPropagation(onClear)}
        />
      )}
      {isFunction(onExport) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__export` : 'export'
          }
          icon={EXPORT_DATA}
          description={labels.Export}
          className="action-bar__button"
          onClick={withoutPropagation(onExport)}
          disabled={disableExport}
        />
      )}
      {isFunction(onPrint) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__print` : 'print'
          }
          description={labels.Print}
          icon={PRINT}
          className="action-bar__button"
          onClick={withoutPropagation(onPrint)}
        />
      )}
      {isFunction(onEdit) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__edit` : 'edit'
          }
          description={labels.Edit}
          disabled={isEditDisabled}
          className="action-bar__button action-bar__add-button"
          onClick={withoutPropagation(onEdit)}
          icon={EDIT}
        />
      )}
      {isFunction(onEmail) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__mail` : 'mail'
          }
          description={labels.NewNotification}
          disabled={isEmailDisabled}
          className="action-bar__button action-bar__add-button"
          onClick={withoutPropagation(onEmail)}
          icon={MAIL}
        />
      )}
      {isFunction(onAdd) && (
        <IconButton
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__add` : 'add'
          }
          description={onAddDescription || labels.Add}
          className="action-bar__button action-bar__add-button"
          onClick={withoutPropagation(onAdd)}
          icon={ADD}
          disabled={isMinimized}
        />
      )}
    </Flexbox>
  );
};

ToolBar.propTypes = {
  onExpand: PropTypes.func,
  onSearch: PropTypes.func,
  onExport: PropTypes.func,
  onPrint: PropTypes.func,
  onClear: PropTypes.func,
  onAdd: PropTypes.func,
  isExpanded: PropTypes.bool,
  isClearDisabled: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  isEmailDisabled: PropTypes.bool,
  className: PropTypes.string,
  onAddDescription: PropTypes.string,
  dataElementName: PropTypes.string,
};

export default ToolBar;
