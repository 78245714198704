import {
  NotificationGroup as NotificationGroupKendo,
  Notification,
} from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import PropTypes from 'prop-types';
import './styles.css';
import { useXeLabels } from '../../contexts/XeLabelContext';
import {
  EMPTY_ARRAY,
  ERROR_NOTIFICATION,
  NOOP_FUNCTION,
} from '../../constants';

// Kendo notification style types: none, success, info, error, warning
export const NotificationGroup = (props) => {
  const { notifications = EMPTY_ARRAY, onCloseNotification = NOOP_FUNCTION } =
    props;
  const labels = useXeLabels();

  return (
    <NotificationGroupKendo
      className="notification-group"
      style={{ alignItems: 'flex-start', flexWrap: 'wrap-reverse' }}
    >
      {notifications.map(({ message, toMessage, style, id }) => (
        <Fade enter={true} exit={true} key={id}>
          <Notification
            type={{ style: style || ERROR_NOTIFICATION, icon: true }}
            closable={true}
            onClose={() => onCloseNotification(id)}
          >
            <span>{message || (toMessage && toMessage(labels))}</span>
          </Notification>
        </Fade>
      ))}
    </NotificationGroupKendo>
  );
};

NotificationGroup.propTypes = {
  notifications: PropTypes.array,
  onCloseNotification: PropTypes.func,
};
