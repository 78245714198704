export const NAME_FORMAT_FULL = 'F, G S';
export const DEFAULT_ALERT_TIME = 5000;
export const PDF_FILE_TYPE = 'application/pdf';

export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_ARRAY = Object.freeze([]);
export const NOOP_FUNCTION = Object.freeze(() => {});
export const NULL_FUNCTION = Object.freeze(() => null);
export const REACT_NOOP = Object.freeze(() => <></>);
export const TRUE_FUNCTION = Object.freeze(() => true);

export const DEFAULT_QUERY_STALE_TIME = 15000;

// Kendo notification style types
export const SUCCESS_NOTIFICATION = 'success';
export const INFO_NOTIFICATION = 'info';
export const ERROR_NOTIFICATION = 'error';
export const WARNING_NOTIFICATION = 'warning';

// Notification configuration filters
export const SMSG_FILTER_ID = 'SMSG';
export const CAL_FILTER_ID = 'CAL';

// worklist
export const AUTHORIZATION = 'authorization';

// db naming
export const ANON_DB_NAME = 'Anonymized';
