import { schemaGet } from '../../../schema/schemaTypeBuilder';
import VisitCorrespondenceDetailSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';
import { Button, Flexbox, Checkbox } from '../../';
import { isFalse } from '../../../utils';
import { LETTER_STATUS_DRAFT, LETTER_STATUS_FINAL } from '../constants';
import { EMPTY_OBJECT } from '../../../constants';

/**
 * @typedef ToFooterOptions
 * @property {{ LetterStatus: string }} visitCorrespondenceEnter
 * @property {import('../constants').LetterWizardParams} params
 * @property {(letterStatus: string) => void} onSave
 * @property {(ev: Event) => void} onClose
 * @property {(ev: Event) => void} onBack
 * @property {(ev: Event) => void} onNext
 * @property {boolean} saveEnabled
 * @property {boolean} backEnabled
 * @property {boolean} nextEnabled
 */

/**
 * @param {ToFooterOptions} options
 */
export const toFooter = (options) => {
  const {
    visitCorrespondenceEnter = EMPTY_OBJECT,
    checkAutoGenerateEnabled,
    onCheckAutoGenerate,
    isQuickLetter,
    onSave,
    onClose,
    onBack,
    onNext,
    backEnabled,
    nextEnabled,
    saveEnabled,
    labels,
  } = options;

  const { XeVisitCorrespRecip = [] } = visitCorrespondenceEnter;

  const hasAtLeastOneRecipient = XeVisitCorrespRecip.length > 0;

  return () => {
    const isEditable = schemaGet(
      VisitCorrespondenceDetailSchema,
      'LetterStatus.IsEditable',
      visitCorrespondenceEnter
    );
    const cannotEdit = !!isEditable && isFalse(isEditable);

    const SaveButton = ({ dataElementName, letterStatus, children }) => {
      return (
        <Button
          dataElementName={dataElementName}
          className="xe-popup__button"
          onClick={() => onSave({ letterStatus })}
          disabled={cannotEdit || !saveEnabled || !hasAtLeastOneRecipient}
          look="outline"
        >
          {children}
        </Button>
      );
    };

    const SaveAsDraftButton = () => {
      return (
        <SaveButton
          dataElementName="saveDraft"
          letterStatus={LETTER_STATUS_DRAFT}
        >
          {labels.SaveAsDraft}
        </SaveButton>
      );
    };

    const SaveAsFinalButton = () => {
      return (
        <SaveButton
          dataElementName="saveFinal"
          letterStatus={LETTER_STATUS_FINAL}
        >
          {labels.SaveAsFinal}
        </SaveButton>
      );
    };

    return (
      <Flexbox
        dataElementName="letterWizard__footer"
        justifyContent="space-between"
        alignItems="center"
        className="flex-1"
      >
        {checkAutoGenerateEnabled ? (
          <Checkbox
            dataElementName="letterWizard__autoGenerateCorrespondence"
            wrapperClassName="padding-horizontal-medium"
            label={labels.AUTO_GENERATE_CORRESPONDENCE}
            checked={isQuickLetter}
            onChange={onCheckAutoGenerate}
          />
        ) : (
          <div />
        )}
        <div>
          <Button
            dataElementName="back"
            className="xe-popup__button"
            onClick={onBack}
            disabled={!backEnabled}
            look="outline"
          >
            {labels.Back}
          </Button>
          <SaveAsDraftButton />
          <SaveAsFinalButton />
          <Button
            dataElementName="next"
            className="xe-popup__button"
            onClick={onNext}
            disabled={!nextEnabled}
            look="outline"
          >
            {labels.Next}
          </Button>
          <Button
            dataElementName="close"
            className="xe-popup__button"
            onClick={onClose}
            look="outline"
          >
            {labels.Close}
          </Button>
        </div>
      </Flexbox>
    );
  };
};
