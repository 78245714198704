import { formatDate, calculateOffset } from '../utils';
import { isoStrAsLuxon } from '../../../g11n/ISODates';
import { ALERT_OUTLINE } from '../../../icons';
import ToolTip from '../../ToolTip';
import { Icon } from '../../Icon';
import Flexbox from '../../Flexbox';
export const DecisionActualDisplay = (props) => {
  const { DecisionDueDate, DecisionDate, DecisionAfterDeadlineLabel } = props;

  if (!DecisionDate) return '—';

  const luxonActualDate = isoStrAsLuxon(DecisionDate);
  const luxonDueDate = !!DecisionDueDate
    ? isoStrAsLuxon(DecisionDueDate)
    : null;

  if (!DecisionDueDate || (luxonDueDate && luxonActualDate < luxonDueDate)) {
    return <Flexbox>{`${formatDate(DecisionDate)}`}</Flexbox>;
  }
  return (
    <>
      <Flexbox alignItems="center">
        <span style={{ color: 'red' }}>
          {`${formatDate(DecisionDate)} (${calculateOffset(
            luxonDueDate,
            luxonActualDate
          )})`}
        </span>
        <ToolTip value={DecisionAfterDeadlineLabel} placement="top-end">
          <Icon
            icon={ALERT_OUTLINE}
            color="red"
            className="margin-left-small"
          />
        </ToolTip>
      </Flexbox>
    </>
  );
};
