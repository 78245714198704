import castPath from 'lodash/_castPath';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ofType, combineEpics } from 'redux-observable';
import { animationFrameScheduler, of } from 'rxjs';
import {
  debounce,
  filter,
  pluck as pluckFrp,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import DefaultSmartBookInstanceSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';
import DefaultSmartBookSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { EMPTY_ARRAY, NOOP_FUNCTION, EMPTY_OBJECT } from '../../constants';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { localeFormat } from '../../format/luxonToDisplayString';
import { castFunction, identity } from '../../fp/fp';
import { get, pluck, set } from '../../fp/object';
import { isEmpty, isNil, isObjectLike, not } from '../../fp/pred';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { useEffect$ } from '../../hooks/useEffect$';
import { useReducer$ } from '../../hooks/useReducer$';
import { useRef$ } from '../../hooks/useRef$';
import {
  toJSONSchemaReducer,
  MERGE_PATCH,
} from '../../schema/JSONSchemaReducer';
import { SchemaReducer, useSchema } from '../../schema/SchemaReducer';
import { getSchemaProperties } from '../../utils/schema';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import { Label } from '../Label';
import { TabStrip, TabStripTab } from '../TabStrip';
import { inactivePathMarker } from './constants';
import { SmartBookNodeContext } from './hooks';
import primarySchemaKeys from './primarySchemaKeys';
import reducers from './reducers';
import { toRequiresReducer } from './requiresReducer';
import './styles.css';
import { Templates } from './templates';
import { TEMPLATE_ID_PLAN_OF_CARE_LITE } from './templates/constants';
import { isRadioComponent, toSmartBookNodePath } from './utils';
import { Flexbox } from '../../components';

const reducer = combinePredicatedReducers(...reducers);

const isCheckboxComponent = ({ AttrType } = {}) => AttrType === 'SelectLabel';
const isCheckableComponent = (x) =>
  isRadioComponent(x) || isCheckboxComponent(x);

/**
 *
 * @param {string} path
 */
const explodePath = (path) => {
  return castPath(path).reduce((result, currentSegment) => {
    const [previousSegment] = result.slice(-1);
    if (!previousSegment) {
      return [currentSegment];
    }
    return [...result, `${previousSegment}.${currentSegment}`];
  }, []);
};

const keysThatRequireIsSet = Object.values(primarySchemaKeys).reduce(
  (keys, currentKey) => {
    return keys.includes(currentKey) ? keys : [...keys, currentKey];
  },
  []
);

const pathToCommaDelimited = (path) => {
  return path.split('.').reduce((acc, item) => {
    return [...acc, item];
  }, []);
};

const toDerivedActions = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  const { path: startPath } = action;
  const { instance } = state;

  if (!startPath) return [action];

  const pathWithoutProperty = toSmartBookNodePath(startPath);
  const pathsToNodes = explodePath(pathWithoutProperty);

  return pathsToNodes.reduce(
    (derivedActions, currentPathSegment) => {
      const pathNode = get(currentPathSegment, instance);

      if (Array.isArray(pathNode)) {
        const modifiedNode = pathNode[pathWithoutProperty.slice(-1)];

        if (
          !pathNode.find(isRadioComponent) ||
          !isRadioComponent(modifiedNode)
        ) {
          return derivedActions;
        }

        const additionalActions = pathNode.reduce((actions, node, index) => {
          const path = `${currentPathSegment}.${index}`;

          if (startPath.startsWith(path)) return actions;

          return [
            ...actions,
            {
              path,
              type: MERGE_PATCH,
              value: {
                Modified: true,
                IsSet: false,
              },
            },
          ];
        }, []);

        return [...derivedActions, ...additionalActions];
      }

      return derivedActions;
    },
    [action]
  );
};

const toModifiedTree = (startPath, incomingValue, startObject) => {
  if (!startPath) return startObject;

  const [updatedProperty] = startPath.match(/\b([a-zA-Z]+)$/) ?? [];

  const incomingIsSet = (() => {
    if (!updatedProperty && isObjectLike(incomingValue)) {
      const value = keysThatRequireIsSet.find((key) => key in incomingValue);
      return !!value;
    }

    return keysThatRequireIsSet.includes(updatedProperty);
  })();

  const pathWithoutProperty = toSmartBookNodePath(startPath);
  const pathsToNodes = explodePath(pathWithoutProperty);

  return pathsToNodes.reduce((result, currentPathSegment) => {
    const pathNode = get(currentPathSegment, result);

    if (Array.isArray(pathNode)) {
      return result;
    }

    const { Modified, IsSet } = pathNode;

    if (currentPathSegment === pathWithoutProperty) {
      // Let the json schema reducer handle IsSet changes to checkable components but still set Modified
      // while we're here
      if (isCheckableComponent(pathNode)) {
        if (!Modified) {
          return set(
            currentPathSegment,
            {
              ...pathNode,
              Modified: true,
            },
            result
          );
        }
        return result;
      }

      // No update required
      if (Modified && incomingIsSet === IsSet) {
        return result;
      }

      return set(
        currentPathSegment,
        {
          ...pathNode,
          Modified: true,
          IsSet: incomingIsSet,
        },
        result
      );
    }

    // Incoming "IsSet: false" should not affect ancestor nodes
    if (!incomingIsSet) {
      return result;
    }

    // No ancestor node change required
    if (IsSet && Modified) {
      return result;
    }

    return set(
      currentPathSegment,
      {
        ...pathNode,
        Modified: true,
        IsSet: incomingIsSet,
      },
      result
    );
  }, startObject);
};

const smartbookActionScope = null;

const toSmartBookJSONReducer =
  (refData, XeSmartBookInstanceSchema) => (instance, schema, baseValidator) => {
    const schemaReducer = toJSONSchemaReducer(instance, schema, baseValidator);
    const requiresReducer = toRequiresReducer(
      instance,
      refData,
      XeSmartBookInstanceSchema
    );

    return (state, initialAction = EMPTY_OBJECT) => {
      /**
       * @type {{ path: string, type: string }}
       */
      const { type = '' } = initialAction;

      // NOTE: This is an attempt to ensure that scoped actions like metaSchema/UPSERT
      // don't cause unintended side effects with the requires rules or section selection
      // See SYNUI-6649. Radio buttons are unintentionally getting unchecked (JDM)
      const actionTypeScope = type.match(/\w+\//g);

      if (actionTypeScope !== smartbookActionScope) {
        return schemaReducer(state, initialAction);
      }

      const actions = toDerivedActions(state, initialAction);
      return actions.reduce((reducedState, action) => {
        const { path = '', value } = action;

        const { instance: i0 } = reducedState || {};
        const modifiedTree = toModifiedTree(path, value, i0);

        const s1 = schemaReducer(
          reducedState == undefined
            ? reducedState
            : { ...reducedState, instance: modifiedTree },
          action
        );
        const { instance: i1 } = s1;

        const i2 = requiresReducer(i1, action);
        if (i1 === i2) return { ...s1, instance: i1 };

        return {
          ...s1,
          instance: i2,
        };
      }, state);
    };
  };

export const SmartBook = (props) => {
  const { instance, dataPath, valueFn = identity, ...rest } = props;

  const {
    value = instance,
    onValueChange = NOOP_FUNCTION,
    onValidationChange = NOOP_FUNCTION,
  } = useSchema(dataPath);

  const lastValueRef = useRef(value);
  const initialSchemaReducerValueRef = useRef(value);
  const newValueRefCounter = useRef(0);

  const initialSchemaReducerValue = useMemo(() => {
    if (value !== lastValueRef.current) {
      lastValueRef.current = value;
      initialSchemaReducerValueRef.current = value;
      newValueRefCounter.current += 1;
      return value;
    }
    return initialSchemaReducerValueRef.current;
  }, [value]);

  return (
    <SmartBookInner
      {...rest}
      key={newValueRefCounter.current}
      value={initialSchemaReducerValue}
      callback={(instance, valid) => {
        lastValueRef.current = instance;
        onValueChange(castFunction(valueFn)(instance));
        onValidationChange(valid);
      }}
    />
  );
};

const LastModifiedHeader = (props) => {
  const { templateId, rootNode } = props;

  const { ModifiedTStamp } = rootNode;

  const labels = useXeLabels();

  // This could be for any template, but the plan of care is the only one that shows this today.
  // Remove this line to have it apply anywhere (JDM)
  if (templateId === TEMPLATE_ID_PLAN_OF_CARE_LITE && ModifiedTStamp) {
    return (
      <Flexbox className="last-modified-header">
        <Label className="border-bottom padding-all-small box-sizing-border-box stretch-x">
          {labels.LastModified}:{' '}
          {toDisplayDateFromISOString(ModifiedTStamp, localeFormat.LONG)}
        </Label>
      </Flexbox>
    );
  }

  return null;
};

const SmartBookTemplateWrapper = (props) => {
  const {
    XeUITemplateSet,
    schema,
    rootNode,
    labels,
    validityStyles,
    readOnly,
    onValidated,
    ipid,
    enterpriseId,
  } = props;

  const [selectedDocumentTab, setSelectedDocumentTab] = useState(0);

  // In this case, we have very little clues on what to render, so we'll
  // use the default template and hope for the best
  if (!XeUITemplateSet) {
    const DefaultTemplate = Templates.default;
    const { BookID } = rootNode;

    return (
      <div className="smartbook__template">
        <DefaultTemplate
          bookId={BookID}
          schema={schema}
          rootNode={rootNode}
          labels={labels}
          validityStyles={validityStyles}
          readOnly={readOnly}
          onValidated={onValidated}
          ipid={ipid}
          enterpriseId={enterpriseId}
        />
      </div>
    );
  }

  if (XeUITemplateSet.length === 1) {
    const { BookID, UITemplate } = XeUITemplateSet[0];

    const Template = Templates[UITemplate];

    return (
      <>
        <LastModifiedHeader
          templateId={UITemplate}
          rootNode={rootNode}
          className="last-modified-header"
        />
        <div className="smartbook__template">
          <Template
            bookId={BookID}
            schema={schema}
            rootNode={rootNode}
            labels={labels}
            validityStyles={validityStyles}
            readOnly={readOnly}
            onValidated={onValidated}
            ipid={ipid}
            enterpriseId={enterpriseId}
            templateId={UITemplate}
          />
        </div>
      </>
    );
  }

  return (
    <TabStrip
      className="stretch-y"
      selected={selectedDocumentTab}
      onSelect={({ selected }) => setSelectedDocumentTab(selected)}
    >
      {XeUITemplateSet.map((templateItem) => {
        const { BookID, ID, Name, UITemplate } = templateItem;

        const Template = Templates[UITemplate];

        return (
          <TabStripTab key={ID} title={Name}>
            <LastModifiedHeader templateId={UITemplate} rootNode={rootNode} />
            <div className="smartbook__template">
              <Template
                bookId={BookID}
                schema={schema}
                rootNode={rootNode}
                labels={labels}
                validityStyles={validityStyles}
                readOnly={readOnly}
                onValidated={onValidated}
                ipid={ipid}
                enterpriseId={enterpriseId}
                templateId={UITemplate}
              />
            </div>
          </TabStripTab>
        );
      })}
    </TabStrip>
  );
};

const SmartBookInner = (props) => {
  const {
    name,
    dataElementName,
    value,
    callback,
    onChange = NOOP_FUNCTION,
    SmartBookSchema = DefaultSmartBookSchema,
    SmartBookInstanceSchema = DefaultSmartBookInstanceSchema,
    validityStyles = false,
    readOnly,
    ipid,
    enterpriseId,
    ...divProps
  } = props;

  const labels = useXeLabels();
  const refData = useXeRefData();

  const callback$ = useRef$(callback);

  const epicWithDeps = useCallback(() => {
    return combineEpics()();
  }, []);

  const [state = {}, dispatch, action$] = useReducer$(reducer, epicWithDeps);

  const { invalidNodePaths = EMPTY_ARRAY } = state;

  const invalidNodePaths$ = useRef$(invalidNodePaths);
  const onChange$ = useRef$(onChange);

  useEffect$(() => {
    const animationFrame$ = of(null, animationFrameScheduler);
    const debounceFrame = () => (source$) =>
      source$.pipe(debounce(() => animationFrame$));
    return invalidNodePaths$.pipe(
      filter(not(isNil)),
      debounceFrame(),
      withLatestFrom(
        action$.pipe(ofType('queueChange'), pluckFrp('value')),
        onChange$,
        callback$
      ),
      tap(([invalidNodePaths, value, onChange, callback]) => {
        const { instance, changed } = value;
        const valid =
          invalidNodePaths.filter((path = '') => {
            //SNET-540: Due to how smartbook is trying to validate hidden items, we need to inspect the visibility of the items
            //it believes are currently invalid. If they are not showing on the screen, we return valid as true. (JAC)
            const invalidPathVisibility = pluck(...pathToCommaDelimited(path))(
              instance
            )?.IsVisible;
            return (
              invalidPathVisibility && !path.startsWith(inactivePathMarker)
            );
          }).length == 0;
        const nextValue = {
          instance,
          valid,
          changed,
        };
        callback(instance, valid);
        onChange(nextValue);
      })
    );
  }, [callback$, onChange$, invalidNodePaths$]);

  const memoizedValidityCallback = useCallback(
    (path, nextValid, isSection = false) => {
      return queueMicrotask(() => {
        dispatch({
          type: 'updateNodeValidity',
          value: nextValid,
          path,
          isSection,
        });
      });
    },
    [dispatch]
  );

  const toSmartBookSchemaReducer = useMemo(() => {
    return toSmartBookJSONReducer(refData, SmartBookInstanceSchema);
  }, [refData, SmartBookInstanceSchema]);

  const SmartBookContextValue = useMemo(() => {
    return {
      isValidNode: (path) => {
        const { value = EMPTY_ARRAY } = invalidNodePaths$;
        return !value.includes(toSmartBookNodePath(path));
      },
    };
  }, [invalidNodePaths$]);

  if (!value || isEmpty(value)) return null;

  const XeUITemplateSet = pluck('UISetID', 'XeUITemplateSet')(value);

  return (
    <div
      data-component-name="Smartbook"
      data-element-name={dataElementName}
      className={withClassNameModifiers('smartbook', {
        ['validity-styles']: validityStyles,
        ['read-only']: readOnly,
      })}
      data-read-only-label={labels.ReadOnly}
      {...divProps}
    >
      <SchemaReducer
        schema={SmartBookSchema}
        toJsonReducer={toSmartBookSchemaReducer}
        onChange={(params) => {
          const {
            changed,
            instance: instance0,
            changedProps,
            schemaValidatedProps,
          } = params;

          if (!changedProps || isEmpty(changedProps)) {
            return dispatch({
              type: 'queueChange',
              value: {
                instance: instance0,
                changed,
                schemaValidatedProps,
              },
            });
          }

          const schemaProperties = getSchemaProperties(SmartBookSchema);
          const instance1 =
            'Modified' in schemaProperties
              ? {
                  ...instance0,
                  Modified: true,
                }
              : instance0;

          return dispatch({
            type: 'queueChange',
            value: {
              instance: instance1,
              changed,
              schemaValidatedProps,
            },
          });
        }}
        initialValue={value}
        dangerouslyRetainCompletelyOutdatedState={true}
      >
        <SmartBookNodeContext.Provider value={SmartBookContextValue}>
          <SmartBookTemplateWrapper
            XeUITemplateSet={XeUITemplateSet}
            schema={SmartBookInstanceSchema}
            rootNode={value}
            labels={labels}
            validityStyles={validityStyles}
            readOnly={readOnly}
            onValidated={memoizedValidityCallback}
            ipid={ipid}
            enterpriseId={enterpriseId}
          />
        </SmartBookNodeContext.Provider>
      </SchemaReducer>
    </div>
  );
};
