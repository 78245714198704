import { queryBookAsInstance } from 'services/smart-books/xe-smart-books-svc';
import { Flexbox, Label } from '../../../../components';
import { EMPTY_ARRAY } from '../../../../constants';
import { useEnterprise } from '../../../../contexts/XeEnterpriseContext';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import { useXeQuery } from '../../../../data/useXeQuery';
import { identity } from '../../../../fp/fp';
import { ADD, CRISIS_PLAN_ARROW } from '../../../../icons';
import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { withClassNameModifiers } from '../../../../utils/withClassNameModifiers';
import { GridLayout } from '../../../GridLayout';
import { Icon } from '../../../Icon';
import IconButton from '../../../IconButton';
import { ExpandableNode } from '../../components/ExpandableNode';
import { useSmartBookNodeContext } from '../../hooks';
import primarySchemaKeys from '../../primarySchemaKeys';
import { Text } from '../Text';
import './styles.css';
import { toNodePropertiesWithDefaults } from '../utils';
import { useXeLabels } from '../../../../contexts/XeLabelContext';

/*
 * CarePlanCrisis can be rendered both as a wrapper component or an invidual text area.
 *
 * `StartedBy` is a property we can use to tell the difference.
 */

const toCrisisItemEnterpriseId = (crisisItem) => {
  return crisisItem?.StartedBy?.EnterpriseID ?? '';
};

const CarePlanCrisisItem = (props) => {
  const { node, readOnly } = props;

  const {
    userData: { EnterpriseID },
  } = useEnterprise();

  const { EDIT_VISITDOC_CARE_PLAN } = useXeRights();

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { ResultValue, Name } = nodeWithDefaults;

  const { EnterpriseID: nodeEnterpriseID, EnterpriseName } =
    toCrisisItemEnterpriseId(nodeWithDefaults);

  return EnterpriseID === nodeEnterpriseID &&
    EDIT_VISITDOC_CARE_PLAN &&
    !readOnly ? (
    <GridLayout
      templateColumns="max-content 1fr"
      alignItems="center"
      className="padding-vertical-medium"
    >
      <Icon icon={CRISIS_PLAN_ARROW} />
      {/* I know this looks goofy but here me out. */}
      {/* CarePlanCrisis in this case acts as a Text attr type without displaying the node's Name here */}
      {/* Also setting the AttrDetail to be a default of 2 rows to match what the initial spec (JDM) */}
      <Text
        {...props}
        node={{
          AttrDetail: 'Rows=2',
          ...node,
          Name: undefined,
        }}
        dataPath={primarySchemaKeys.Text}
        placeholder={Name}
      />
    </GridLayout>
  ) : (
    <Flexbox
      className="care-plan-crisis--external padding-right-large padding-vertical-small"
      justifyContent="space-between"
    >
      <div>{ResultValue}</div>
      <div>{EnterpriseName}</div>
    </Flexbox>
  );
};

const CarePlanCrisisWrapper = (props) => {
  const {
    node,
    dataPath = primarySchemaKeys.CarePlanCrisis,
    onValidated,
    readOnly,
    children,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    BookID,
    Name,
    IsRequired: initialIsRequired,
    IsVisible: initialIsVisible,
  } = nodeWithDefaults;

  const { EDIT_VISITDOC_CARE_PLAN } = useXeRights();

  const {
    userData: { EnterpriseID, ResourceID },
  } = useEnterprise();

  const {
    fullPath,
    onValueChange,
    value: crisisNodes = EMPTY_ARRAY,
  } = useSchema(dataPath);

  const labels = useXeLabels();

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [IsRequired = initialIsRequired, IsVisible = initialIsVisible] =
    useSchemaSelector((instance = {}) => {
      const { IsVisible, IsRequired } = instance;

      return [IsRequired, IsVisible];
    });

  const hasExistingItemForEnterprise = !!crisisNodes.find((child) => {
    const { EnterpriseID: startedByEnterpriseId } =
      toCrisisItemEnterpriseId(child);
    return startedByEnterpriseId === EnterpriseID;
  });

  const { refetch: queryCrisisItems } = useXeQuery(
    queryBookAsInstance({ bookId: BookID }, identity),
    {
      enabled: false,
      onSuccess: ({ results }) => {
        const nodesWithStartedBy = results.map((node) => ({
          ...node,
          StartedBy: { EnterpriseID: { EnterpriseID }, ResourceID },
        }));

        onValueChange([...crisisNodes, ...nodesWithStartedBy]);
        onValidChange(true);
      },
    }
  );

  if (!IsVisible) return null;

  return (
    <ExpandableNode
      defaultExpanded={true}
      node={node}
      ItemElement={
        <Flexbox
          alignItems="center"
          justifyContent="space-between"
          className="fill-parent-width"
        >
          <Flexbox>
            <Label
              className={`${withClassNameModifiers('smartbook-label', {
                invalid: valid !== null && !valid,
              })} margin-vertical-small`}
              wrapText={false}
              required={IsRequired}
            >
              {Name}
            </Label>
            {EDIT_VISITDOC_CARE_PLAN &&
            !hasExistingItemForEnterprise &&
            !readOnly ? (
              <IconButton
                dataElementName="add"
                icon={ADD}
                className="margin-left-small"
                onClick={() => {
                  queryCrisisItems();
                }}
              />
            ) : null}
          </Flexbox>
          <Label className="padding-right-large bold">
            {labels.Enterprise}
          </Label>
        </Flexbox>
      }
    >
      {children}
    </ExpandableNode>
  );
};

const CarePlanCrisisInterim = (props) => {
  const { node } = props;

  const { StartedBy } = node;

  if (StartedBy) {
    return <CarePlanCrisisItem {...props} />;
  }

  return <CarePlanCrisisWrapper {...props} />;
};

export { CarePlanCrisisInterim as CarePlanCrisis };
