import { pluck } from '../../../fp/object';
import { DateTime } from 'luxon';
import {
  Checkbox,
  DropDownList,
  TelephoneInput,
  TextInput,
  GridLayout,
  UIControlLabel,
  Label,
  Icon,
  ToolTip,
  ZipCodeInput,
} from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { useXeRights } from '../../../contexts/XeUserRightsContext';
import { useEffect } from 'react';
import {
  useSchemaDispatch,
  useSchemaSelector,
} from '../../../schema/SchemaReducer';
import { UPSERT } from '../../../schema/JSONSchemaReducer';
import { INFORMATION } from '../../../icons';
import {
  luxonToDisplayString,
  localeFormat,
} from '../../../format/luxonToDisplayString';
import PropTypes from 'prop-types';
import '../styles.css';
import { interval, map, startWith } from 'rxjs';
import { useReducer$ } from '../../../hooks/useReducer$';
import { useXeAppProperties } from '../../../contexts/XeAppPropertyContext';
import { isObjectLike } from '../../../fp/pred';

const MAIL = 'MAIL';
const FAX = 'FAX';

const pluckID = pluck('id');
const pluckText = pluck('text');
const pluckLanguageID = pluck('LanguageID');
const pluckName = pluck('Name');

// TODO - These are both currently hardcoded but should ultimately be derived from some server config values. See SYNUI-8796 (MM)
// We are using the Name property in the Language objects per discussion on SYNUI-9001.
// Using an ID would be better, but one client uses non-standard IDs for their data so using Name is a simpler short-term fix (MM)
const DEFAULT_FALLBACK_LANGUAGE_NAME = 'English';
const ENGLISH_LANGUAGE_ID = 'ENG';

/**
 * @param {String} name
 * @param {String} label
 */
const FallbackLanguageLabelFn = (name = '', label = '') => {
  return (
    <>
      <ToolTip value={label}>
        <Icon
          icon={INFORMATION}
          className="margin-right-small fallback-language__icon"
        />
      </ToolTip>
      {name}
    </>
  );
};

/**
 * @typedef {import('services/generated/types').VisitCorrespondenceDefSpec$XeLanguage} VisitCorrespondenceDefSpec$XeLanguage
 *
 * @param {Object} props
 * @param {String} props.dataPath
 * @param {VisitCorrespondenceDefSpec$XeLanguage} props.defaultLanguage
 * @param {String} props.className
 */
const ProviderCannotEditLanguage = (props) => {
  const { dataPath, defaultLanguage, className } = props;

  const { LanguageID } = defaultLanguage;

  const labels = useXeLabels();

  const schemaDispatch = useSchemaDispatch();

  useEffect(() => {
    if (!!LanguageID) {
      schemaDispatch({
        type: UPSERT,
        path: 'LanguageID',
        value: LanguageID,
      });
    }
  }, [LanguageID, schemaDispatch]);

  // A bit of a hack to display the language name since the Label's dataPath only has the LanguageID value (MM)
  const labelFn = () => defaultLanguage?.Name;

  return (
    <Label
      dataPath={dataPath}
      descriptor={labels.Language}
      labelFn={labelFn}
      className={className}
    />
  );
};

/**
 * @typedef {import('services/generated/types').VisitCorrespondenceDefSpec$XeLanguage} VisitCorrespondenceDefSpec$XeLanguage
 *
 * @param {Object} props
 * @param {String} props.dataPath
 * @param {Boolean} props.editCorrespondenceLanguage
 * @param {Boolean} props.isPreferredLanguageThresholdLanguage
 * @param {VisitCorrespondenceDefSpec$XeLanguage} props.defaultLanguage
 * @param {String} props.className
 */
const CannotEditLanguage = (props) => {
  const {
    dataPath,
    isPreferredLanguageThresholdLanguage,
    defaultLanguage,
    className,
  } = props;

  const { LanguageID } = defaultLanguage;

  const labels = useXeLabels();

  const schemaDispatch = useSchemaDispatch();

  useEffect(() => {
    if (!!LanguageID) {
      schemaDispatch({
        type: UPSERT,
        path: 'LanguageID',
        value: LanguageID,
      });
    }
  }, [LanguageID, schemaDispatch]);

  if (isPreferredLanguageThresholdLanguage) {
    // A bit of a hack to display the language name since the Label's dataPath only has the LanguageID value (MM)
    const labelFn = () => defaultLanguage?.Name;

    return (
      <Label
        dataPath={dataPath}
        descriptor={labels.Language}
        labelFn={labelFn}
        className={className}
      />
    );
  }

  return (
    <Label
      dataPath={dataPath}
      descriptor={labels.Language}
      labelFn={() =>
        FallbackLanguageLabelFn(
          defaultLanguage?.Name,
          labels.WRITTEN_LANG_NOTFOUND
        )
      }
      className={className}
    />
  );
};

const ProviderRecipientForm = (props) => {
  const { context = EMPTY_OBJECT, recipient = EMPTY_OBJECT } = props;

  const { SendMethod } = recipient;

  const labels = useXeLabels();
  const appProperties = useXeAppProperties();

  // There should exist an appProperties.DefaultLanguage that will be used for Providers
  // If that does not exist we will use our fallback value to find the language object in the languages collection (MM)
  const providerLanguageFindFn = !!appProperties?.DefaultLanguage
    ? ({ LanguageID }) => LanguageID === appProperties.DefaultLanguage
    : ({ Name }) => Name === DEFAULT_FALLBACK_LANGUAGE_NAME;

  const { EDIT_CORRESPONDENCE_LANGUAGE } = useXeRights();

  const { languages = EMPTY_ARRAY } = context;

  // This assumes the DefaultLanguage and the DEFAULT_FALLBACK_LANGUAGE_NAME will be valid find values to match on (MM)
  const defaultLanguage = languages.find(providerLanguageFindFn);

  const { OutreachMethod, OutreachMethodMail, XeStateProvince, XeCountry } =
    useXeRefData();

  const isSendMethodMail = SendMethod === MAIL;

  return (
    <GridLayout templateColumns="repeat(2, auto 1fr)">
      <DropDownList
        dataPath="SendMethod"
        dataElementName="recipientForm__sendMethod"
        data={OutreachMethod}
        descriptor={labels.Method}
        valueFn={pluckID}
        labelFn={pluckText}
        required
      />
      {isSendMethodMail ? (
        <DropDownList
          dataElementName="recipientForm__detail"
          descriptor={labels.Detail}
          dataPath="SendMethodDetail"
          data={OutreachMethodMail}
          valueFn={pluckID}
          labelFn={pluckText}
          required
        />
      ) : (
        <>
          <div />
          <div />
        </>
      )}
      <TextInput
        dataElementName="recipientForm__name"
        dataPath="Name"
        descriptor={labels.Name}
        required
      />
      <UIControlLabel dataElementName="recipientForm__provider__label">
        {labels.Provider}
      </UIControlLabel>
      <Checkbox
        dataElementName="recipientForm__provider"
        dataPath="IsProvider"
        wrapperClassName="align-self-center"
      />
      {EDIT_CORRESPONDENCE_LANGUAGE ? (
        <DropDownList
          dataElementName="recipientForm__language"
          descriptor={labels.Language}
          dataPath="LanguageID"
          data={languages}
          valueFn={pluckLanguageID}
          labelFn={pluckName}
          comparator={(listItem, value) => {
            const id = value?.LanguageID || value;
            return listItem === id;
          }}
        />
      ) : (
        <ProviderCannotEditLanguage
          dataPath="LanguageID"
          defaultLanguage={defaultLanguage}
          className="align-self-center"
        />
      )}
      <TelephoneInput
        dataElementName="recipientForm__fax"
        dataPath="Fax"
        descriptor={labels.Fax}
        required={SendMethod === FAX}
      />
      <TextInput
        dataElementName="recipientForm__addressLine1"
        dataPath="AddressLine1"
        descriptor={labels.LineOne}
        required={isSendMethodMail}
      />
      <TextInput
        dataElementName="recipientForm__addressLine2"
        dataPath="AddressLine2"
        descriptor={labels.LineTwo}
      />
      <TextInput
        dataElementName="recipientForm__city"
        dataPath="City"
        descriptor={labels.City}
        required={isSendMethodMail}
      />
      <DropDownList
        dataElementName="recipientForm__state"
        dataPath="StateProvince"
        data={XeStateProvince}
        descriptor={labels.State}
        valueFn={pluckID}
        labelFn={pluckText}
        required={isSendMethodMail}
      />
      <DropDownList
        dataElementName="recipientForm__country"
        descriptor={labels.Country}
        dataPath="CountryID"
        data={XeCountry}
        valueFn={pluckID}
        labelFn={pluckText}
      />
      <ZipCodeInput
        dataElementName="recipientForm__zip"
        dataPath="ZipPostalCode"
        descriptor={labels.Zip}
        required={isSendMethodMail}
      />
    </GridLayout>
  );
};

/*
  This epic and reducer are only for generating the current time that updates every minute.
  Because we only have one value we don't need an action type from the epic and can simply pass the value itself in an object.
  The reducer gets the object as its second argument, so we just destructure and return to the outside world. (MM)
*/
const reducer = (state, { value } = EMPTY_OBJECT) => {
  return value;
};

const epic = (action$, state$) => {
  return interval(1000 * 60).pipe(
    startWith(0),
    map((x) => ({ value: DateTime.now() }))
  );
};

/**
 *
 * @param {DateTime} date - a Luxon DateTime object
 * @returns {string} a string in a locale date format for display purposes
 */
const translationTimeFormatter = (date) =>
  luxonToDisplayString(date, localeFormat.LONG);

const toWrittenLanguage = (WrittenLanguageID = '') => {
  return isObjectLike(WrittenLanguageID)
    ? WrittenLanguageID.LanguageID
    : WrittenLanguageID;
};

const PatientRecipientForm = (props) => {
  const { context = EMPTY_OBJECT, recipient = EMPTY_OBJECT } = props;
  const { SendMethod } = recipient;

  const labels = useXeLabels();

  const selectedLanguage = useSchemaSelector(pluck('LanguageID'));
  const selectedTranslationTime = useSchemaSelector(
    pluck('TrnVendorReqRtnHours')
  );

  const { EDIT_CORRESPONDENCE_LANGUAGE } = useXeRights();

  const [currentDate] = useReducer$(reducer, epic);

  const {
    languages = EMPTY_ARRAY,
    selectedCustomLanguage = EMPTY_OBJECT,
    WrittenLanguageID = EMPTY_OBJECT,
  } = context;

  const { IsTranslationReq: isTranslationRequired } = selectedCustomLanguage;

  const preferredThresholdLanguage = languages.find(
    ({ LanguageID }) => LanguageID === toWrittenLanguage(WrittenLanguageID)
  );
  const isPreferredLanguageThresholdLanguage = !!preferredThresholdLanguage;

  // [SYNUI-8663] defaultLanguage is an LACare specific thing. If a patient's preferred written language is in a list of
  // specific supported languages, then we use it. Otherwise we default back to some predetermined fallback.
  // The fallback is currently hardcoded above, but it should be something ideally derived from server config data.
  // NOTE - The fallback language is assumed to be in the list of "threshold" languages from the server. (MM)
  const defaultLanguage =
    isPreferredLanguageThresholdLanguage && !recipient.IsProvider
      ? preferredThresholdLanguage
      : languages.find(({ Name }) => Name === DEFAULT_FALLBACK_LANGUAGE_NAME);

  const defaultLanguageLocale = defaultLanguage?.Locale;

  const translationTimeRefDataKey = `${defaultLanguageLocale}_TranslationReqHours`;

  const {
    OutreachMethod,
    OutreachMethodMail,
    [translationTimeRefDataKey]: translationTimeRefData,
    XeStateProvince,
    XeCountry,
  } = useXeRefData();

  const isSendMethodMail = SendMethod === MAIL;

  // TODO - Instead of English, the selectedLanguage check should be derived from some server config ID value. See SYNUI-8796 (MM)
  const canScheduleTranslation =
    isTranslationRequired &&
    selectedLanguage !== ENGLISH_LANGUAGE_ID &&
    !!translationTimeRefData;

  return (
    <GridLayout templateColumns="repeat(2, auto 1fr)">
      <DropDownList
        dataPath="SendMethod"
        dataElementName="recipientForm__sendMethod"
        data={OutreachMethod}
        descriptor={labels.Method}
        valueFn={pluckID}
        labelFn={pluckText}
        required
      />
      {isSendMethodMail ? (
        <DropDownList
          dataElementName="recipientForm__detail"
          descriptor={labels.Detail}
          dataPath="SendMethodDetail"
          data={OutreachMethodMail}
          valueFn={pluckID}
          labelFn={pluckText}
          required
        />
      ) : (
        <>
          <div />
          <div />
        </>
      )}
      <TextInput
        dataElementName="recipientForm__name"
        dataPath="Name"
        descriptor={labels.Name}
        required
      />
      <UIControlLabel dataElementName="recipientForm__provider__label">
        {labels.Provider}
      </UIControlLabel>
      <Checkbox
        dataElementName="recipientForm__provider"
        dataPath="IsProvider"
        wrapperClassName="align-self-center"
      />
      {EDIT_CORRESPONDENCE_LANGUAGE ? (
        <DropDownList
          dataElementName="recipientForm__language"
          descriptor={labels.Language}
          dataPath="LanguageID"
          data={languages}
          valueFn={pluckLanguageID}
          labelFn={pluckName}
          comparator={(listItem, value) => {
            const id = value?.LanguageID || value;
            return listItem === id;
          }}
        />
      ) : (
        <CannotEditLanguage
          isPreferredLanguageThresholdLanguage={
            isPreferredLanguageThresholdLanguage
          }
          labels={labels}
          dataPath="LanguageID"
          defaultLanguage={defaultLanguage}
          className="align-self-center"
        />
      )}
      {canScheduleTranslation && (
        <DropDownList
          descriptor={labels.TranslTime}
          data={translationTimeRefData}
          valueFn={pluck('item', 'ScoreValue')}
          labelFn={pluck('text')}
          dataPath="TrnVendorReqRtnHours"
          required
        >
          {!!selectedTranslationTime && (
            // Adding 1 minute so we're never "behind" because of mismatched seconds value (MM)
            <ToolTip
              value={
                currentDate
                  ? `${labels.ExpectedTransRtn}: ${translationTimeFormatter(
                      currentDate.plus({
                        hours: selectedTranslationTime,
                        minutes: 1,
                      })
                    )}`
                  : ''
              }
            >
              <Icon
                icon={INFORMATION}
                className="margin-right-small fallback-language__icon"
              />
            </ToolTip>
          )}
        </DropDownList>
      )}
      <TelephoneInput
        dataElementName="recipientForm__fax"
        dataPath="Fax"
        descriptor={labels.Fax}
        required={SendMethod === FAX}
      />
      <TextInput
        dataElementName="recipientForm__addressLine1"
        dataPath="AddressLine1"
        descriptor={labels.LineOne}
        required={isSendMethodMail}
      />
      <TextInput
        dataElementName="recipientForm__addressLine2"
        dataPath="AddressLine2"
        descriptor={labels.LineTwo}
      />
      <TextInput
        dataElementName="recipientForm__city"
        dataPath="City"
        descriptor={labels.City}
        required={isSendMethodMail}
      />
      <DropDownList
        dataElementName="recipientForm__state"
        dataPath="StateProvince"
        data={XeStateProvince}
        descriptor={labels.State}
        valueFn={pluckID}
        labelFn={pluckText}
        required={isSendMethodMail}
      />
      <DropDownList
        dataElementName="recipientForm__country"
        descriptor={labels.Country}
        dataPath="CountryID"
        data={XeCountry}
        valueFn={pluckID}
        labelFn={pluckText}
      />
      <ZipCodeInput
        dataElementName="recipientForm__zip"
        dataPath="ZipPostalCode"
        descriptor={labels.Zip}
        required={isSendMethodMail}
      />
    </GridLayout>
  );
};

// These two are largely the same but their defaultLanguage logic is very different.
// I'm sure there is a better way to abstract this.
// As of now it didn't seem worth the effort and complication when we only have 2 versions of the form (MM)
export const RecipientForm = ({
  recipient = EMPTY_OBJECT,
  context = EMPTY_OBJECT,
}) => {
  // Language selection logic is different for Providers (MM)

  // We determine which form and the corresponding language dropdown/label logic to use via the 'IsPatient', 'IsProvider', and
  // 'EDIT_CORRESPONDENCE_LANGUAGE' we get from the backend, we needed to do all these weird language code due to different
  // enterprises using different language code (ex. EN, ENG, English) SYNUI-9010 (JCh)

  // if 'IsPatient=true' return PatientRecipientForm uses threshold language logic and translation time
  // if 'IsProvider=true', always show English Language in either 'DropDownList' or 'CannotEditLanguage'
  // if 'EDIT_CORRESPONDENCE_LANGUAGE=true', show 'DropDownList' with the associated language to the Recipient
  // if 'EDIT_CORRESPONDENCE_LANGUAGE=false' show 'CannotEditLanguage'

  if (recipient?.IsPatient) {
    return <PatientRecipientForm recipient={recipient} context={context} />;
  }

  // ProviderRecipientForm
  // The two cases below shouldn't really fall under the ProviderRecipientForm but we are using ProviderRecipientForm as an
  // "everything else when Recipient is not Patient" form
  // case 1: Recipient was manually added, IsPatient=undefined/false and IsProvider=undefined/false
  // case 2: Recipient is personal contact of the member  (sister, spouse, parent, etc)
  //         which means IsProvider=false and IsPatient=false
  // (JCh)
  return <ProviderRecipientForm recipient={recipient} context={context} />;
};

export default RecipientForm;

RecipientForm.propTypes = {
  recipient: PropTypes.object,
  context: PropTypes.object,
};

CannotEditLanguage.propTypes = {
  dataPath: PropTypes.string,
  isPreferredLanguageThresholdLanguage: PropTypes.bool,
  defaultLanguage: PropTypes.object,
  className: PropTypes.string,
};
