import { combineEpics } from 'redux-observable';
import epics from './epics';
import reducers from './reducers';
import DefaultView from './views/DefaultView';
import {
  SHOULD_CLOSE_WIZARD,
  LETTER_SAVE_COMPLETE,
  SET_PRE_POPUP,
} from './actions';
import { useReducer$ } from '../../hooks/useReducer$';
import { useRef$ } from '../../hooks/useRef$';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useEffect$ } from '../../hooks/useEffect$';
import { ofType } from '../../frp/operators/ofType';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useCallback } from 'react';
import { EMPTY_OBJECT } from '../../constants';

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

/**
 * @typedef CreateLetterWizardProps
 * @property {import('./constants').LetterWizardParams} params
 * @property {() => void} onClose
 * @property {(saveResponse: any) => void} onSave
 */

/**
 * @type {React.FunctionComponent<CreateLetterWizardProps>}
 */
export const LetterWizard = (props) => {
  const { params, onClose, onSave } = props;

  const menuNode = useMenuNode();
  const params$ = useRef$(params);

  const epicWithDeps = useCallback(
    (action$, state$) =>
      epic(action$, state$, {
        menuNode$: of(menuNode),
        params$,
      }),
    [menuNode, params$]
  );

  const [state = EMPTY_OBJECT, dispatch, action$] = useReducer$(
    reducer,
    epicWithDeps
  );

  useEffect$(() => {
    return action$.pipe(
      ofType(SHOULD_CLOSE_WIZARD),
      tap(() => {
        onClose();
      })
    );
  }, [action$, onClose]);

  useEffect$(() => {
    return action$.pipe(
      ofType(LETTER_SAVE_COMPLETE),
      tap(({ value: { savedLetter, previousLetter } = EMPTY_OBJECT }) => {
        // TODO: passing this as a tuple right now to simulate 2 arguments refactor this when this isn't part of a feature (PDD)
        onSave([savedLetter, previousLetter]);
      })
    );
  }, [action$, onSave]);

  const { showLetterWizard, authPrePopupTransient: PrePopup } = state;

  if (PrePopup) {
    return (
      <PrePopup
        auth={params?.WorkList ?? EMPTY_OBJECT}
        onClose={() => {
          dispatch({
            type: SHOULD_CLOSE_WIZARD,
          });
        }}
        onSave={() => {
          dispatch({
            type: SET_PRE_POPUP,
          });
        }}
      />
    );
  }

  if (showLetterWizard) {
    return <DefaultView {...props} dispatch={dispatch} state={state} />;
  }

  return null;
};

export default LetterWizard;
