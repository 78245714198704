import PropTypes from 'prop-types';

/**
 * @typedef {'auto'|'hidden'|'scroll'|'visible'|'none'} OverflowValue
 */

/**
 * @typedef OverflowContainerProps
 * @property {OverflowValue} overflow
 * @property {OverflowValue} overflowX
 * @property {OverflowValue} overflowY
 */

/**
 *
 * @param {OverflowContainerProps & React.HTMLAttributes} props
 */
export const OverflowContainer = (props) => {
  const {
    className,
    children,
    overflow = 'auto',
    overflowX,
    overflowY,
    style,
  } = props;

  const baseClassName = 'xjs-scrolling-container';

  const containerStyle = (() => {
    /**
     * @type {CSSStyleDeclaration}
     */
    const styleObject = { ...(style || {}) };
    if (overflow) {
      styleObject.overflow = overflow;
    }
    if (overflowX) {
      styleObject.overflowX = overflowX;
    }
    if (overflowY) {
      styleObject.overflowY = overflowY;
    }
    return styleObject;
  })();

  return (
    <div
      className={className ? `${baseClassName} ${className}` : baseClassName}
      style={containerStyle}
    >
      {children}
    </div>
  );
};

const OVERFLOW_TYPES = ['auto', 'hidden', 'scroll', 'visible', 'none'];

OverflowContainer.propTypes = {
  overflow: PropTypes.oneOf(OVERFLOW_TYPES),
  overflowX: PropTypes.oneOf(OVERFLOW_TYPES),
  overflowY: PropTypes.oneOf(OVERFLOW_TYPES),
};
