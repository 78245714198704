import { createContext } from 'react';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { Observable } from 'rxjs';

export const WorklistTemplateWrapperContext = createContext({
  // figure out a way to get the api in here. is it worth it?
  // right now every function is passed as a prop which could get us into prop hell
  worklistApi: EMPTY_OBJECT,
  worklistType: '',
  worklistVariation: '',
  worklistDefaultSpec: undefined,
  worklistDispatch: NOOP_FUNCTION,
  worklistAction$: new Observable(),
  worklistDetail: EMPTY_OBJECT,
  /**
   * @type {ReturnType<import('./utils').toWorklistVariationData>}
   */
  worklistVariationData: EMPTY_OBJECT,
  worklistTypeBit: undefined,
});

/* ==== Worklist Types ==== */
export const AUTH = 'AUTH';
export const ISSUE = 'ISSUE';
export const RX_ISSUE = 'RX_ISSUE';
export const PHM = 'PROGRAM';
export const PROVIDER_PORTAL = 'PROVIDER_PORTAL';
/* ==== */

/* ==== Common Type Identifiers === */
export const INPATIENT_AUTH_TYPE_ID = 'AUTH_IPA';
export const OUTPATIENT_AUTH_TYPE_ID = 'AUTH_SVC';
export const REFERRAL_AUTH_TYPE_ID = 'AUTH_REFERRAL';
export const RXTICKET_ISSUE_TYPE_ID = 'RXTICKET';

export const REQUESTED_PROVIDER_TYPE_ID = 'UM_REQUESTED';
export const FACILITY_PROVIDER_TYPE_ID = 'UM_FACILITY';
export const REQUESTING_PROVIDER_TYPE_ID = 'UM_REQUESTING';
export const PROGRAM_REFER_BY_TYPE_ID = 'PROGRAM_REFER_BY';
export const PROGRAM_OWNER_TYPE_ID = 'PROGRAM_OWNER';
export const PROGRAM_DELEGATE_TYPE_ID = 'PROGRAM_DELEGATE';

export const PRESCRIBER_ISSUE_TYPE_ID = 'PRESCRIBER';
export const PHARMACY_ISSUE_TYPE_ID = 'PHARMACY';

export const BED_TYPE_SERVICE_TYPE_ID = 'BED_TYPE';
export const SERVICE_SERVICE_TYPE_ID = 'SERVICE';

export const SERVICE_GROUP_CAT_CONTENT_TYPE_ID = 'SERVICEGROUP';

export const NETWORK_STATUS_OON = 'OON';
export const NETWORK_STATUS_PAR = 'PAR';

export const METHOD_ID_AUTO = 'AUTO';
/* ==== */

/* ==== Worklist Components (Tabs) ==== */
export const SynergyComponentIDs = {
  APPEAL_DETAIL: 'SynergyMM.Components.XeAppealDetail',
  AUTH_DETAIL_IP: 'SynergyMM.Components.AuthDetailIP',
  AUTH_DETAIL_SVC: 'SynergyMM.Components.AuthDetailSvc',
  AUTH_DETAIL_REFERRAL: 'SynergyMM.Components.AuthDetailReferral',
  CLINICAL_GUIDELINES: 'SynergyMM.Components.ClinicalGuidelines',
  CONCURRENT_REVIEW: 'SynergyMM.Components.ConcurrentReview',
  GRIEVANCE_DETAIL: 'SynergyMM.Components.GrievanceDetail',
  ISSUE_DETAIL: 'SynergyMM.Components.XeIssueDetail',
  MEMBER_AUTH_LIST: 'SynergyMM.Components.MemberAuthList',
  TIMELINE_CLINDOC_LIST: 'SynergyMM.Components.TimelineClindocList',
  TIMELINE_HISTORY_LIST: 'SynergyMM.Components.TimelineHistoryList',
  TIMELINE_ITEM_LIST_ANG: 'SynergyMM.Components.TimelineNoteListAnG',
  TIMELINE_ITEM_LIST_AUTH: 'SynergyMM.Components.TimelineNoteListAuth',
  XE_PATIENT_PROGRAM: 'SynergyMM.Components.XePatientProgram',
  XE_ENROLLED_PROGRAM: 'SynergyMM.Components.XeEnrolledProgram',
  RX_TICKET_DETAIL: 'SynergyMM.Components.RxTicketDetail',
  TIMELINE_ITEM_LIST_RX_TICKET: 'SynergyMM.Components.TimelineNoteListRxTicket',
  XE_ENROLLED_PERIODS: 'SynergyMM.Components.XeEnrolledPeriods',
  DASHBOARD_ADHOC_COMPONENT: 'SynergyShared.Features.DashboardAdhocReport',
};
/* ==== */

/* ==== Worklist Variables ==== */
export const MAX_WORKLIST_SPLIT = 2;
/* ==== */

/* ==== Reference Data Mapping ==== */
export const WorklistVariationRefDataMap = {
  [AUTH]: {
    type: 'AUTH_TYPE',
    subType: (authTypeRefData) => {
      return authTypeRefData.reduce((acc, curr) => {
        if (!curr) return acc;

        const { item: { XeAuthType = [] } = {} } = curr;
        return [
          ...acc,
          ...XeAuthType.map((o) => {
            const { AuthTypeID, Name, Active } = o;
            return {
              item: o,
              text: Name,
              id: AuthTypeID,
              Active: Active,
            };
          }),
        ];
      }, []);
    },
  },
  [ISSUE]: {
    type: 'PATIENT_ISSUE_TYPE',
    subType: 'PATIENT_ISSUE_SUB_TYPE',
  },
  [PHM]: {
    type: 'CMGMT_TYPES',
    subType: (cmgmtTypesRefData) => {
      return cmgmtTypesRefData.reduce((acc, curr) => {
        if (!curr) return acc;

        const {
          item: { XeCaseManagementProgram = [] },
        } = curr;
        return [
          ...acc,
          ...XeCaseManagementProgram.map((o) => {
            const { ProgramID, Name, Active } = o;
            return {
              item: o,
              text: Name,
              id: ProgramID,
              Active: Active,
            };
          }),
        ];
      }, []);
    },
  },
};
/* ==== */

/* ==== Worklist Default Popup Title*/

export const worklistPopupTitleMap = {
  [RX_ISSUE]: 'IssueDetails',
  [ISSUE]: 'IssueDetails',
  [AUTH]: 'AuthDetails',
  [PHM]: 'ProgramDetails',
  [PROVIDER_PORTAL]: 'AuthDetails',
};

/* ==== */

export const worklistReopenPopupLabelMap = {
  [RX_ISSUE]: {
    title: 'ReopenIssue',
    explanation: 'ReopenExplanationIssue',
  },
  [ISSUE]: {
    title: 'ReopenIssue',
    explanation: 'ReopenExplanationIssue',
  },
  [AUTH]: {
    title: 'ReopenAuth',
    explanation: 'ReopenExplanationAuth',
  },
  [PHM]: {
    title: 'ReopenProgram',
    explanation: 'ReopenExplanationProgram',
  },
  [PROVIDER_PORTAL]: {
    title: 'ReopenAuth',
    explanation: 'ReopenExplanationAuth',
  },
};
