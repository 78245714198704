import { Flexbox } from '../..';
import { SchemaReducer } from '../../../schema/SchemaReducer';
import { Text } from '../AttrTypes/Text';
import { Date } from '../AttrTypes/Date';
import { Fragment } from 'react';
import { GridLayout } from '../../GridLayout';

export const TelephonicNote = (props) => {
  const { readOnly, labels, onValidated } = props;

  return (
    <SchemaReducer dataPath={'XeVisitNote.0'}>
      <GridLayout templateRows="max-content 1fr" className="full-height">
        <Flexbox direction="column" alignItems="flex-start">
          <Date
            className="margin-left-none"
            component={Fragment}
            readOnly={readOnly}
            onValidated={onValidated}
            descriptor={labels.ServiceDate}
            dataPath="RecordedDate"
          />
        </Flexbox>
        <Text
          className="stretch-y"
          readOnly={readOnly}
          onValidated={onValidated}
          dataPath="Notes"
          resizeY={false}
        />
      </GridLayout>
    </SchemaReducer>
  );
};
