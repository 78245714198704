import { useCallback, useMemo, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { identity } from '../../fp/fp';
import { pluck } from '../../fp/object';
import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import { useActiveList } from '../../hooks/useActiveList';
import { UIControlLabel } from '../Label';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

export const MultiSelectDropDown = (props) => {
  const {
    dataElementName = '',
    dataPath,
    valueFn = identity,
    labelFn = identity,
    data,
    onChange = NOOP_FUNCTION,
    className,
    onBlur = NOOP_FUNCTION,
    dataItemKey,
    value: propsValue = EMPTY_ARRAY,
    validityStyles: propsValidityStyles,
    descriptor,
    descriptorClassName,
    required: propsRequired,
    onValidation = NOOP_FUNCTION,
    useActiveFilter = true,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value = propsValue,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = NOOP_FUNCTION,
    onValidationChange = onValidation,
    schemaNode,
  } = useSchema(dataPath);

  const validityStyles = schemaNode ? touched : propsValidityStyles;
  const required = schemaRequired || propsRequired;

  const multiSelectRef = useRef();

  const memoizedValue = useMemo(() => {
    if (!!dataItemKey) {
      const dataValues = value.map((value) => pluck(dataItemKey)(value));
      return data.filter((item) =>
        dataValues.includes(pluck(dataItemKey)(item))
      );
    }
    return data.filter((item) => value.includes(valueFn(item)));
  }, [data, value, valueFn, dataItemKey]);

  const memoizedValidity = useMemo(() => {
    if (!validityMessage) return undefined;
    return false;
  }, [validityMessage]);

  const memoizedOnChange = useCallback(
    (ev) => {
      const {
        target: { value, validity },
      } = ev;
      const valueFnResult = value.map((item) => valueFn(item));
      onValueChange(valueFnResult);
      onValidationChange(toUIValid(validity));
    },
    [onValueChange, onValidationChange, valueFn]
  );

  const memoizedItemRender = useCallback(
    (element, listItem) => {
      const { props: elementProps } = element;
      const { dataItem } = listItem;
      return cloneElement(element, elementProps, labelFn(dataItem));
    },
    [labelFn]
  );

  const tags = memoizedValue.map((item) => ({
    text: labelFn(item),
    data: [item],
  }));

  const memoizedOnBlur = useCallback(
    (event) => {
      onBlur(event);
      onTouched();
    },
    [onBlur, onTouched]
  );

  // We are showing either Active=true/false data with backend configuration
  // through the AttrDetail property SNET-914 (JCh)
  const activeList = useActiveList(data, value);
  const filteredList = useActiveFilter ? activeList : data;

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__label` : ''
          }
          className={descriptorClassName}
          required={required}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <KendoMultiSelect
        ref={(ref) => {
          if (ref && multiSelectRef.current !== ref) {
            ref.element.setAttribute(
              'data-component-name',
              'MultiSelectDropDown'
            );
            ref.element.setAttribute('data-element-name', dataElementName);
          }
          multiSelectRef.current = ref;
        }}
        className={`multi-select__xnetjs ${className}`}
        value={memoizedValue}
        onChange={memoizedOnChange}
        itemRender={memoizedItemRender}
        data={filteredList}
        required={required}
        tags={tags}
        validityStyles={validityStyles}
        valid={memoizedValidity}
        validationMessage={validityMessage}
        onBlur={memoizedOnBlur}
        {...passThroughProps}
      />
    </>
  );
};

MultiSelectDropDown.propTypes = {
  data: PropTypes.array.isRequired,
  dataPath: PropTypes.string,
  value: PropTypes.array,
  valueFn: PropTypes.func,
  labelFn: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  dataElementName: PropTypes.string,
};

export default MultiSelectDropDown;
