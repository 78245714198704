import { SchemaContext, toSchemaNode } from '../../schema/SchemaReducer';
import PropTypes from 'prop-types';
import { useMemo, useContext } from 'react';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import DateTimePicker from '../DateTimePicker';
import NumberInput from '../NumberInput';
import TextInput from '../TextInput';

const isTypeString = type => type === 'string';
const isTypeBoolean = type => type === 'boolean';
const isTypeDate = type => type === 'date';
const isSchemaTypeDate = ({ type, format }) =>
  isTypeString(type) && isTypeDate(format);
const isTypeDateTime = type => type === 'date-time';
const isSchemaTypeDateTime = ({ type, format }) =>
  isTypeString(type) && isTypeDateTime(format);
const isTypeLocalDateTime = type => type === 'local-date-time';
const isSchemaTypeLocalDateTime = ({ type, format }) =>
  isTypeString(type) && isTypeLocalDateTime(format);
const isTypeText = type => type === 'text';
const isTypeNumber = type => type === 'integer' || type === 'number';
const isSchemaType = (fn, { type }) => fn(type);

const toObjectDisplay = (o = {}) =>
  Object.entries(o)
    .map(ar => ar.join(':'))
    .join();
const toBaseElementType = (schemaNode = {}, typeOverride = '') => {
  if (isTypeBoolean(typeOverride) || isSchemaType(isTypeBoolean, schemaNode)) {
    return Checkbox;
  } else if (isTypeDate(typeOverride) || isSchemaTypeDate(schemaNode)) {
    return DatePicker;
  } else if (
    isTypeDateTime(typeOverride) ||
    isSchemaTypeDateTime(schemaNode) ||
    isTypeLocalDateTime(typeOverride) ||
    isSchemaTypeLocalDateTime(schemaNode)
  ) {
    return DateTimePicker;
  } else if (
    isTypeText(typeOverride) ||
    isSchemaType(isTypeString, schemaNode)
  ) {
    return TextInput;
  } else if (
    isTypeNumber(typeOverride) ||
    isSchemaType(isTypeNumber, schemaNode)
  ) {
    return NumberInput;
  }

  if (!typeOverride) {
    console.error(
      `No defined renderer for node ${toObjectDisplay(schemaNode)}`
    );
  }
  return TextInput;
};

export const Input = props => {
  const { dataPath, type } = props;

  const { value: { schema } = {} } = useContext(SchemaContext) || {};

  const schemaNode = toSchemaNode(dataPath, schema);

  const BaseElement = useMemo(() => {
    const { oneOf } = schemaNode || {}; //dont default this in the function call please

    if (oneOf) {
      //Find the first one we actually know how to render and use it
      return toBaseElementType(oneOf.find(toBaseElementType), type);
    }

    return toBaseElementType(schemaNode, type);
  }, [schemaNode, type]);

  return <BaseElement {...props} />;
};

export default Input;

Input.propTypes = {
  dataPath: PropTypes.string,
};
