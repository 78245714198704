import PropTypes from 'prop-types';
import { Label } from '../../../Label';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';

export const NameLabel = (props) => {
  const { children, className, invalid, ...passthroughProps } = props;

  return (
    <Label
      className={`${withClassNameModifiers('smartbook-descriptor', {
        invalid,
      })} ${className}`}
      wrapText
      {...passthroughProps}
    >
      {children}
    </Label>
  );
};

NameLabel.propTypes = {
  ...Label.propTypes,
  children: PropTypes.node,
  wrapText: Label.propTypes.wrapText,
};
