import { useSchema } from '../../../../schema/SchemaReducer';
import { Flexbox } from '../../../../components';
import NumberInput from '../../../../components/NumberInput';
import primarySchemaKeys from '../../primarySchemaKeys';
import { baseNodeValidator } from '../../validators';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { toNodePropertiesWithDefaults } from '../utils';
import { useSmartBookNodeContext } from '../../hooks';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

export const Integer = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node,
    onValidated,
    dataPath = primarySchemaKeys.Integer,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsRequired, IsVisible, ResultMin, ResultMax } = node;

  const { value, validityMessage, onValueChange, fullPath } =
    useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;

  return (
    <WrappingComponent>
      <NumberInput
        dataElementName={Name}
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        exclusiveMinimum={0}
        required={IsRequired}
        disabled={readOnly}
        value={value}
        onChange={(value /* , valid */) => {
          onValueChange(value);
          onValidChange(baseNodeValidator(true, value, IsRequired));
        }}
        min={parseInt(ResultMin)}
        max={parseInt(ResultMax)}
      />
    </WrappingComponent>
  );
};
