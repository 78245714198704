import { useSchema } from '../../../../schema/SchemaReducer';
import { useSchemaDispatch } from '../../../../schema/SchemaReducer';
import { MERGE_PATCH } from '../../../../schema/JSONSchemaReducer';
import { pluck } from '../../../../fp/object';
import { ButtonGroup, Flexbox } from '../../../../components';
import primarySchemaKeys from '../../primarySchemaKeys';
import { baseNodeValidator } from '../../validators';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { toAttributesObject } from '../../utils';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

const defaultValueFn = (value, previousValue) => {
  const { text: ResultValue, id: ResultValueID, item } = value;

  if (ResultValueID == previousValue) {
    return {
      ScoreValue: pluck('ScoreValue')(item),
      ResultValue: undefined,
      ResultValueID: undefined,
    };
  }

  return {
    ScoreValue: pluck('ScoreValue')(item),
    ResultValue,
    ResultValueID: `${ResultValueID}`,
  };
};

export const ButtonBar = (props) => {
  const {
    readOnly,
    component: WrappingComponent = DefaultComponent,
    node,
    onValidated,
    dataPath = primarySchemaKeys.ButtonBar,
    refDataId: propsRefDataId,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, IsRequired, IsVisible } = nodeWithDefaults;

  const { required: refDataId = propsRefDataId } =
    toAttributesObject(AttrDetail);

  const { [refDataId]: RefData } = useXeRefData();

  const schemaDispatch = useSchemaDispatch();
  const { value, validityMessage, fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <WrappingComponent
      dataElementName={Name}
      data-component-name="SmartbookButtonBar"
    >
      <ButtonGroup
        descriptor={Name}
        descriptorClassName={withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })}
        required={IsRequired}
        disabled={readOnly}
        className="margin-left-small"
        data={RefData}
        value={value}
        labelFn={pluck('text')}
        comparator={(value, { id } = {}) => {
          return value == id;
        }}
        onChange={(selectedValue) => {
          const nextValue = valueFn(selectedValue, value);

          schemaDispatch({
            type: MERGE_PATCH,
            value: nextValue,
          });
          onValidChange(
            baseNodeValidator(
              true,
              nextValue[primarySchemaKeys.ButtonBar],
              IsRequired
            )
          );
        }}
      />
    </WrappingComponent>
  );
};
