import { useMemo, useEffect } from 'react';
import { Label } from '../../../../components';
import { GridLayout } from '../../../../components/GridLayout';
import './styles.css';
import { useSchema, useSchemaSelector } from '../../../../schema/SchemaReducer';
import { pluck } from '../../../../fp/object';
import primarySchemaKeys from '../../primarySchemaKeys';
import { substitutionSetValidator } from '../../validators';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';

export const SubstitutionSet = (props) => {
  const {
    children,
    node,
    dataPath = primarySchemaKeys.SubstitutionSet,
    onValidated,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const {
    AttrDetail,
    Name,
    IsRequired,
    XeSmartBookInstance: initialXeSmartBookInstance = [],
  } = nodeWithDefaults;

  const { onValueChange: resultValueCallback, fullPath } = useSchema(dataPath);

  const { onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const [xeSmartBookInstance = initialXeSmartBookInstance] = useSchemaSelector(
    ({ XeSmartBookInstance } = {}) => {
      return [XeSmartBookInstance];
    }
  );

  const wellKnownIds = xeSmartBookInstance.map(pluck('WellKnownID')).join('|');

  const [columns, rows] = AttrDetail.split('|');

  const gridTemplateRows = `repeat(${rows}, max-content)`;
  const gridTemplateColumns = `repeat(${columns}, max-content)`;

  const substitutionTokens = useMemo(
    () => Name.match(/\{.*?\}/g) || [],
    [Name]
  );
  const subTokensRegExp = useMemo(
    () => new RegExp(`(${substitutionTokens.join('|')})`, 'g'),
    [substitutionTokens]
  );
  const substituionTextArray = useMemo(
    () => Name.split(subTokensRegExp),
    [Name, subTokensRegExp]
  );
  const textData = useMemo(
    () =>
      substituionTextArray.map((s) => {
        const [, token] = s.match(/\{(.*?)\}/) || [];
        return {
          rawText: s,
          token: token,
        };
      }),
    [substituionTextArray]
  );

  const substitutionInputData = xeSmartBookInstance.reduce((data, xesbi) => {
    const { WellKnownID, ResultValue } = xesbi;
    return {
      ...data,
      [WellKnownID]: ResultValue,
    };
  }, {});

  const resultValue = textData
    .map(({ rawText, token }) => {
      if (token in substitutionInputData) {
        return substitutionInputData[token] || rawText;
      }
      return rawText;
    })
    .join('');

  useEffect(() => {
    resultValueCallback(resultValue);
    onValidChange(
      substitutionSetValidator(resultValue, IsRequired, wellKnownIds)
    );
  }, [
    resultValueCallback,
    wellKnownIds,
    onValidChange,
    fullPath,
    IsRequired,
    resultValue,
  ]);

  const SubstitutionRender = () => {
    return (
      <Label required={IsRequired} wrapText={true}>
        {textData.map((td, index) => {
          const { rawText, token } = td;
          if (token) {
            const maybeSubstitute = substitutionInputData[token] || rawText;
            return (
              <Label
                key={`${maybeSubstitute}_${index}`}
                className="smartbook__substitution-render"
              >
                {maybeSubstitute}
              </Label>
            );
          }
          return rawText;
        })}
      </Label>
    );
  };

  return (
    <div className="margin-vertical-medium">
      <SubstitutionRender />
      <hr />
      <GridLayout
        templateRows={gridTemplateRows}
        templateColumns={gridTemplateColumns}
      >
        {children}
      </GridLayout>
    </div>
  );
};
